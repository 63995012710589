@import "../../../styles/lib";

.StarsRating {
  &__rating {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
   
  }
}

.star {
  cursor: pointer;
  width: 20px;
  height: 20px;
  filter: brightness(90%) sepia(1) grayscale(100%);
  /*  background-color: #ABABAB;;
    -webkit-clip-path: polygon(
      50% 0%,
      63% 38%,
      100% 38%,
      69% 59%,
      82% 100%,
      50% 75%,
      18% 100%,
      31% 59%,
      0% 38%,
      37% 38%
    );
    clip-path: polygon(
      50% 0%,
      63% 38%,
      100% 38%,
      69% 59%,
      82% 100%,
      50% 75%,
      18% 100%,
      31% 59%,
      0% 38%,
      37% 38%
    );  */

  transition: color 0.2s ease-out;

  &:hover {
    cursor: pointer;
  }
}

.star.star-selected {
  // background-color: #ff8900;
  filter: brightness(100%) sepia(0) grayscale(0);
}
