@import "../../styles/lib";

.WhyChooseGoCoffeGo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: rem(104);

  &__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: rem(103);
  }

  &__title {
    font-size: rem(36);
    font-family: $cairo;
    font-weight: 400;
    letter-spacing: 0.6rem;
    margin-top: rem(54);
    margin-bottom: rem(31);
    text-align: center;
  }

  &__image {
    position: absolute;
    right: rem(0);
    top: rem(-10);
    width: rem(400);
    height: rem(400);
    object-fit: contain;
  }
}

@include tablet-big {
  .WhyChooseGoCoffeGo {
    &__image {
      right: 10px;
    }
  }
}

@include tablet {
  .WhyChooseGoCoffeGo {
    &__image {
      right: 6px;
      width: 200px;
      height: 200px;
    }
  }
}

@include mobile {
  .WhyChooseGoCoffeGo {
    &__items {
      display: grid;
      grid-template-columns: 1fr;
    }

    &__image {
      display: none;
    }

    &__title {
      font-weight: 600;
      font-size: 20px;
      line-height: em(24px, 20px);
      width: 165px;
      letter-spacing: 0.4rem;
    }
  }
}
