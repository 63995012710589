@import "src/styles/lib";

.RadioButton {
  margin-bottom: rem(13);
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &__outer-circle {
    background: white;
    width: rem(25);
    height: rem(25);
    border: rem(1) solid $black;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: rem(10);
    transition: all 0.1s linear;
  }

  &__inner-circle {
    width: rem(15) !important;
    height: rem(15) !important;
    border-radius: 50%;
    background-color: transparent;
    transition: all 0.1s linear;

    &--active {
      background-color: #00d1d6;
    }
  }

  &__label {
    color: $black;
    padding-right: rem(8);
    font-size: rem(25);
  }
}

@include mobile {
  .RadioButton {
    &__label {
      font-size: 18px;
    }
  }
}
