@import "src/styles/lib";

.Modal {
  max-width: fit-content !important;
  z-index: 999999 !important;


  &__icon {
    position: absolute;
    right: rem(12);
    top: rem(12);
    cursor: pointer;
    z-index: 2;
  }
}

@include mobile {
  .Modal {
    margin-left: 5% !important;
    max-width: 90% !important;
  }
}
