@import "src/styles/lib";

.Subscribe {
  display: flex;
  flex-direction: column;
  margin-top: rem(234);
  &__primary-container {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      background: url("https://assets.gocoffeego.com/v2/assets/images/man-pink.png")
        no-repeat;
      width: rem(460);
      height: rem(437);
      background-size: contain;
    }
  }

  &__primary-title {
    width: rem(895);
    max-height: rem(247);
  }

  &__sign-up {
    margin: 0 auto rem(20);
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    max-width: rem(1000);
    padding: 0 10px;
  }
  &__main-title {
    max-width: rem(1200);
    text-align: center;
    font-size: rem(36);
    margin: rem(130) auto rem(30);
    font-weight: 700;
    padding: 0 10px;
    line-height: 1.5;
  }

  &__subscription1 {
    margin-top: rem(162);
    margin-left: 5.3%;
    display: flex;
    flex-direction: column;
    width: 94.7%;
    height: 100%;
    padding-bottom: rem(61);
    padding-left: rem(275);
    background: black;
    background: url("https://assets.gocoffeego.com/v2/assets/images/subscribe-bg.svg")
      no-repeat;
    background-size: cover;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: rem(-82);
      right: 0;
      width: rem(239);
      height: rem(239);
      border-radius: 50%;
      background: #00d1d6;
      opacity: 1;
    }
  }

  &__video-container {
    position: absolute;
    right: rem(57);
    bottom: rem(27);
    width: rem(572);
    height: rem(572);
    overflow: hidden;
    object-fit: contain;
    border-radius: 50%;
  }

  &__video-container > video {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: rem(570);
  }

  &__subscription2 {
    display: block;
    margin-top: rem(204);
    margin-right: 5.3%;
    display: flex;
    justify-content: flex-end;
    width: 94.7%;
    height: 100%;
    padding-bottom: rem(61);
    padding-right: rem(205);
    background: black;
    background: url("https://assets.gocoffeego.com/v2/assets/images/subscribe-bg-2.svg")
      no-repeat;
    background-size: cover;
    position: relative;
  }

  &__subscription2-mobile {
   //  align-items: center;
   //  margin: 0 auto;
  }

  &__people {
    position: absolute;
    left: rem(56);
    bottom: rem(40);
    width: rem(550);
    height: rem(550);
    object-fit: contain;
  }

  &__blue-bg {
    position: absolute;
    left: rem(56);
    bottom: rem(40);
    width: rem(590);
    height: rem(590);
    object-fit: contain;
  }

  &__people-mobile {
    display: none;
  }

  &__stocksy {
    position: absolute;
    right: 0;
    bottom: rem(-350);
    width: rem(407);
    height: rem(407);
    object-fit: contain;
  }

  &__number {
    width: rem(140);
    height: rem(140);
    border-radius: 50%;
    border: 2px solid black;
    background: white;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    justify-content: center;
    font-size: rem(36);
    font-weight: 700;
    position: absolute;
    top: rem(-69);
  }

  &__title {
    text-align: center;
    font-size: rem(30);
    font-weight: 700;
    color: white;
    z-index: 2;
    margin-top: rem(114);
    position: relative;
  }

  &__description {
    font-size: rem(24);
    font-weight: 400;
    color: white;
    text-align: center;
    line-height: rem(40px, 24px);
    margin-top: rem(40);
    margin-bottom: rem(40);
    letter-spacing: 0.1rem;
    z-index: 2;
  }

  &__button {
    background: transparent;
    border: 2px solid white;
    color: white;
    z-index: 2;
    transition: all 200ms ease-in-out;

    &:hover {
      border: 2px solid $blue;
      color: white;
    }
  }
}

@include tablet {
  .Subscribe {
    &__primary-container {
      &::before {
        display: none;
      }
    }

    &__primary-title {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &__subscription1 {
      padding-left: 20px;
    }

    &__subscription2 {
      padding-right: 20px;
    }
  }
}

@include mobile {
  .Subscribe {
    margin-top: 100px;
    &__primary-container {
      &::before {
        display: none;
      }
    }

    &__primary-title {
      width: 263px !important;
      padding: 0 10px !important;
      &::after {
        content: "";
        position: absolute;
        height: 90px;
        width: 90px;
        border-radius: 50%;
        background: #ff00ff;
        top: -61px;
        left: -45px;
      }
    }
    &__primary-title div {
      font-size: 28px;
      padding: 0 10px;
    }

    &__sign-up {
      display: flex;
      align-self: center;
      font-size: 16px;
      line-height: em(24px, 16px);
      margin-bottom: 20px;
    }

    &__video-container {
      position: absolute;
      right: 0;
      left: 0;
      margin-left: auto;
      margin-right: auto;
      bottom: 29px;
      width: 291px;
      height: 291px;
      object-fit: contain;
      overflow: hidden;
    }

    &__subscription1 {
      align-items: center;
      margin-left: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 752px;
      padding-bottom: rem(61);
      padding-left: 0;
      background: black;
      background: url("https://assets.gocoffeego.com/v2/assets/images/subscribe-bg-mobile.svg")
        no-repeat;
      background-size: cover;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: -61px;
        right: 27px;
        width: 174px;
        height: 174px;
        border-radius: 50%;
        background: #00d1d6;
      }
    }

    &__subscription2 {
      display: block;
      align-items: center;
      margin-left: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 752px;
      padding-bottom: rem(61);
      padding-left: 0;
      padding-right: 0;
      background: black;
      background: url("https://assets.gocoffeego.com/v2/assets/images/subscribe-bg-mobile.svg")
        no-repeat;
      background-size: cover;
      position: relative;
      justify-content: normal;
      margin-top: 194px;
    }

    &__people {
      display: none;
    }

    &__people-container {
      margin-top: 20px;
      position: relative;
    }

    &__people-mobile {
      display: block;
      height: 299px;
      width: 299px;
      object-fit: contain;
    }

    &__title {
      margin-top: 100px;
      font-size: 20px;
      max-width: 125px;
    }

    &__description {
      font-size: 16px;
      line-height: em(30px, 16px);
      margin-top: 34px;
      margin-bottom: 45px;
    }

    &__content {
      max-width: 322px;
    }

    &__stocksy {
      display: none;
    }

    &__button {
      min-width: 200px;
      height: 55px;
      font-size: 17px;
    }

    &__number {
      font-size: 26px;
      top: -52px;
      width: 104px;
      height: 104px;
    }
  }
}
