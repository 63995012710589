@import "../../../styles/lib";

.ContactUsModal {
  width: 50vw;
  height: rem(400);
  overflow-y: auto;

  &__container {
    font-size: 16px;
  }

  &__header {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(2, 0, 36);

    background: linear-gradient(
      0deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(255, 255, 255, 0) 0%,
      rgba(245, 233, 13, 1) 40%
    );
  }
  &__header-title {
    font-weight: bold;
    padding: 0px 10px;
    text-align: center;
  }

  &__text {
    font-weight: 700;
    margin-top: rem(20);
  }
  &__close {
    position: absolute;
    right: rem(200);
    cursor: pointer;
  }
  &__body {
    padding: 0px 10px;
  }
  &__list-title {
    font-weight: bold;
    padding-bottom: 20px;
  }
  &__list-paragraph {
    padding-bottom: 5px;
  }
  &__paragraph-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  &__button {
    margin-top: rem(20);
  }
  &__bags-title {
    font-weight: bold;
    text-decoration: underline;
  }
  &__title-section {
    padding-bottom: 10px;
    font-weight: bold;
    text-decoration: underline;
  }
  &__email {
    font-weight: bold;
  }
  p {
    line-height: 28px;
  }
}
@include desktop-big {
  .ContactUsModal {
    &__close {
      right: 20px;
      width: 3%;
    }
    &-title {
      font-size: 22px;
    }
  }
}
@include desktop {
  .ContactUsModal {
    &__close {
      right: 15px;
      width: 3%;
    }
    &__header {
      &-title {
        font-size: 21px;
      }
    }
  }
}
@include tablet-big {
  .ContactUsModal {
    &__header {
      height: 80px;
      &-title {
        font-size: 20px;
      }
    }
    &__paragraph-section {
      position: relative;
      bottom: 20px;
    }
    &__close {
      position: absolute;
      right: 10px;
    }
  }
}
@include tablet {
  .ContactUsModal {
    overflow: hidden;
    &__header {
      height: 80px;
      &-title {
        font-size: 18px;
        width: 95%;
      }
    }
    &__paragraph-section {
      position: relative;
      bottom: 20px;
    }
    &__close {
      position: absolute;
      right: 10px;
      width: 5%;
    }
  }
}

@include mobile {
  .ContactUsModal {
    overflow: hidden;
    width: 100%;
    &__header {
      display: flex;
      justify-content: space-between;
      height: 80px;
      text-align: center;
      &-title {
        font-size: 17px;
        width: 95%;
      }
    }
    &__paragraph-section {
      position: relative;
      bottom: 30px;
    }
    &__close {
      position: absolute;
      right: 10px;
      width: 5%;
    }
  }
}
@include smallMobile {
  .ContactUsModal {
    overflow: hidden;
    width: 100%;
    &__header {
      height: 80px;
      &-title {
        font-size: 14px;
      }
    }
    &__paragraph-section {
      position: relative;
      bottom: 30px;
    }
    &__close {
      position: absolute;
      right: 10px;
      width: 20px;
    }
  }
}
