@import "src/styles/lib";

.PayAsYouGoCart {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding-top: rem(27);
  padding-bottom: rem(27);
  padding-left: rem(26);
  padding-right: rem(26);
  cursor: pointer;
  align-items: center;
  justify-items: center;
  width: 100%;

  &--calendar {
    background: transparent;
    width: fit-content;
  }

  &--brew {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  &--quiz {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  &__image {
    object-fit: contain;
    margin-bottom: rem(50);

    &--taste {
      width: rem(327);
      height: rem(385);
    }

    &--border {
      width: rem(261);
      height: rem(261);
      border: 3px solid black;
      border-radius: 50%;
      padding: rem(19);

      &--active {
        border: 3px solid #ffec01;
      }
    }

    &--brew {
      width: rem(300);
      height: rem(395);
      object-fit: contain;
      margin-bottom: rem(35);
    }

    &--quiz {
      width: rem(200);
      height: rem(200);
      object-fit: contain;
      margin-bottom: rem(35);
    }

    &--quantity {
      width: rem(421);
      height: rem(301);
      margin-bottom: rem(20);
    }
  }

  &__title {
    text-transform: uppercase;
    font-size: rem(28);
    font-weight: 600;
    line-height: em(30px, 30px);
    text-align: center;
    max-width: rem(400);
    padding: rem(10);
    &--quiz {
      font-size: rem(28);
      font-weight: 400;
    }

    &--active {
      background: #ffec01;
    }

    &--calendar {
      width: rem(262);
      height: rem(262);
      object-fit: contain;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-bottom: rem(22);
      background: black;
      color: white;

      &--active {
        background: #ffec01;
        padding: rem(10) rem(30);
        color: black;
      }
    }

    &--quantity {
      max-width: rem(362);
    }
  }

  &__description {
    font-size: rem(32);
    font-weight: 600;
    line-height: em(30px, 23px);
    text-align: center;
  }
}

@include tablet {
  .PayAsYouGoCart {
    &--brew {
      padding-top: rem(27);
      padding-bottom: rem(27);
      padding-left: rem(26);
      padding-right: rem(26);
    }

    &--calendar {
      min-width: rem(300);
    }
  }
}

@include tablet-big {
  .PayAsYouGoCart {
    &__image {
      &--border {
        width: rem(230);
        height: rem(230);
      }
    }
  }
}

@include desktop {
  .PayAsYouGoCart {
    &__image {
      &--border {
        width: rem(230);
        height: rem(230);
      }
    }
  }
}

@include mobile {
  .PayAsYouGoCart {
    min-width: 155px;

    &--brew {
      padding-top: rem(27);
      padding-bottom: rem(27);
      padding-left: rem(26);
      padding-right: rem(26);
    }

    &__image {
      &--quantity {
        width: rem(175);
        height: rem(175);
        margin-bottom: rem(20);
      }
    }

    &__title {
      &--quantity {
        max-width: rem(182);
      }
      &--quiz {
        font-size: 15px;
      }
    }
  }
}
