@import "src/styles/lib";

.ProductPage {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;

  &__loader {
    min-height: 100vh;
  }

  &__content {
    padding-left: 20%;
    padding-right: 20%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: rem(20);
  }

  &__line {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: rem(622);
      height: rem(622);
      border-radius: 50%;
      background: #ffec00;
      left: rem(-363);
      bottom: rem(-76);
      z-index: -1;
    }
  }
}

@include tablet {
  .ProductPage {
    &__content {
      padding-left: 10%;
      padding-right: 10%;
    }
  }
}

@include mobile {
  .ProductPage {
    &__content {
      padding-left: 5%;
      padding-right: 5%;
      grid-template-columns: 1fr;
      overflow-x: hidden;
      grid-gap: 0;
    }

    &__line {
      display: none;
    }
  }
}
