@import "src/styles/lib";

.ProductDetails {
  padding-top: rem(213);

  &__buttons {
    margin-top: rem(20);
    display: flex;
    align-items: center;
  }

  &__or {
    font-size: rem(30);
    padding-left: rem(15);
    padding-right: rem(15);
  }

  &__row {
    display: flex;
  }

  &__shape {
    display: none;
  }

  &__title1 {
    font-size: rem(18);
    font-weight: 700;
    color: $white;
    background: $black;
    padding: rem(15);
    width: fit-content;
    position: relative;
    margin-bottom: rem(34);
    text-transform: uppercase;
    min-width: rem(130);

    &::after {
      content: "";
      border-radius: 50%;
      width: rem(154);
      height: rem(154);
      background: #00d1d6;
      z-index: -1;
      position: absolute;
      right: rem(-106);
      top: rem(-121);
    }
  }

  &__title2 {
    font-size: rem(42);
    font-weight: 700;
    line-height: em(36px, 36px);
    margin-top: 0;
    margin-bottom: rem(10);
  }

  &__reviews {
    display: flex;
    margin-bottom: rem(25);
  }

  &__reviews-button {
    font-size: rem(20);
    font-weight: 700;
    color: #0acdd4;
    margin: 0;
    margin-left: rem(13);
  }

  &__stars {
    width: rem(152);
    height: rem(27);
    object-fit: contain;
  }

  &__short-description {
    font-weight: 400;
    font-size: rem(20);
    max-width: rem(324);
    line-height: em(24px, 18px);
    margin-top: rem(38);
    margin-bottom: rem(40);
  }

  &__short-message {
    font-size: rem(26);

    &--2 {
      margin-bottom: rem(30);
    }
  }

  &__price {
    width: 100%;
    font-weight: 700;
    font-size: rem(34);
    color: #ff8900;
    margin: 0;
    padding-bottom: rem(22);
    border-bottom: 2px solid $black;
    &--sale {
      color: $red;
    }
  }

  &__retail-price {
    &--sale {
      text-decoration: line-through;
      margin-right: 0.4rem;
    }
  }

  &__title3 {
    font-size: rem(20);
    font-weight: 700;
    margin-top: rem(24);
    margin-bottom: rem(40);
  }

  &__dropdown {
    width: rem(220);
    border: 2px solid $black;
    font-weight: 600;
    font-size: rem(20);
    padding-left: rem(9);
  }

  &__line {
    border-bottom: 2px solid $black;
    margin-top: rem(70);
    width: 100%;
  }

  &__cart-button {
    background-color: $blue;
    color: $white;
    border: none;
    font-weight: 700;
  }

  .PurchaseOptions {
    &__other-sizes {
      margin-top: rem(24);

      font-weight: 600;
      font-size: rem(24);
    }

    &__items {
      display: flex;
      margin-top: rem(10);
      margin-left: rem(5);
    }
  }

  .AutoShipDetails {
    margin-top: rem(28);
    margin-bottom: rem(44);
  }
}

@include mobile {
  .ProductDetails {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 70px;

    &__title1-container {
      display: flex;
      justify-content: center;
    }

    &__title1 {
      margin-bottom: 25px;
      font-size: 16px;
      align-self: center;
      &::after {
        display: none;
      }
    }

    &__title2 {
      max-width: 268px;
      text-align: center;
      font-size: 24px;
      margin-bottom: rem(20);
      align-self: center;
    }

    &__reviews {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__stars {
      margin-bottom: 13px;
    }

    &__reviews-button {
      font-size: 16px;
    }

    &__short-description {
      margin-top: 15px;
      margin-bottom: 33px;
      font-size: 18px;
      text-align: center;
      line-height: em(24px, 18px);
      max-width: 322px;
      align-self: center;
    }

    &__price {
      font-size: 24px;
      padding-bottom: rem(20);
      text-align: center;
    }

    &__title3 {
      margin-top: 40px;
      margin-bottom: 24px;
      font-size: 16px;
    }

    &__line {
      margin-top: 40px;
      width: 100%;
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }


    .AutoShipDetails {
      &__radio-button {
        display: none;
      }
    }

    &__button,
    &__cart-button {
      margin: 5px auto;
      position: relative;
      width: 220px;
      font-size: 16px;
      align-self: center;
    }

    .PurchaseOptions {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      &__items {
        display: flex;
        justify-content: center;
      }

      &__other-sizes {
        display: flex;
        justify-content: center;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 700;
        margin-top: rem(24);
        margin-bottom: rem(40);
      }

      &__other-sizes-container {
        margin-top: rem(20);
        display: block;
        align-items: center;
        flex-direction: column;
      }
    }

    .SelectGrinds {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }
}
