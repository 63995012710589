@import "../../../styles/lib";

.CorporateGifts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: $cairo;
  position: relative;
  padding-right: rem(100);
  &::before {
    content: "";
    position: absolute;
    height: rem(295);
    width: rem(294);
    border-radius: 50%;
    left: rem(100);
    bottom: rem(470);
    background-color: #ffec00;
    z-index: 1;
    opacity: 0.8;
  }
  &::after {
    content: "";
    position: absolute;
    height: rem(262);
    width: rem(262);
    border-radius: 50%;
    right: rem(40);
    bottom: rem(430);
    background-color: #fe0101;
    z-index: -1;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
    width: rem(570);
    padding-top: rem(90);
  }

  &__subtitle {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.2em;
    line-height: rem(24);
    font-size: rem(24);
    z-index: 2;
  }

  &__title {
    position: relative;
    font-size: rem(36);
    letter-spacing: 0.2em;
    text-transform: uppercase;
    font-weight: normal;
    z-index: 2;
    margin-top: rem(70);
    margin-bottom: rem(60);
    text-align: center;
    line-height: 1.2;
    &::before {
      content: "";
      position: absolute;
      height: rem(8);
      width: rem(82);
      left: 43.5%;
      right: 0;
      top: rem(-36);
      background-color: $black;
    }
  }

  &__btn {
    margin-bottom: rem(55);
    font-size: rem(16);
    text-transform: uppercase;
    width: rem(283);
    height: rem(66);
    letter-spacing: 0.1em;
    line-height: rem(32);
    font-weight: 700;
    z-index: 2;
  }

  &__people-img {
    position: absolute;
    width: rem(819);
    height: rem(819);
    background-image: url("https://assets.gocoffeego.com/v2/assets/images/people.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    right: -6.5rem;
    top: -26rem;
    transform: rotateY(180deg);
    border-radius: 50%;
  }

  &__images {
    position: relative;
    height: rem(482);
    background-image: url("https://assets.gocoffeego.com/v2/assets/images/quiz-cta-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    transform: rotateY(180deg);
    padding-right: 20rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: rem(95);
    &::after {
      content: "";
      position: absolute;
      height: rem(180);
      width: rem(180);
      border-radius: 50%;
      left: 25%;
      bottom: rem(-40);
      background-color: #6ae200;
      z-index: -1;
    }
  }

  &__franchman {
    width: rem(338);
    height: rem(385);
    margin-bottom: rem(-10);
    z-index: 2;
  }

  &__italian {
    background: url("https://assets.gocoffeego.com/v2/assets/images/italian.svg");
    background-position: cover;
    background-size: 100%;
    background-repeat: no-repeat;
    width: rem(274);
    height: rem(397);
    margin-bottom: rem(-6);
    position: relative;
    z-index: 0;
  }
}

@include tablet {
  .CorporateGifts {
    &::before {
      left: rem(80);
    }
    &::after {
      right: rem(-70);
    }
    &__people-img {
      right: -7rem;
      top: -25rem;
      width: 44rem;
      height: 44rem;
    }

    &__images {
      &::after {
        left: 16%;
      }
    }
  }
}

@include mobile {
  .CorporateGifts {
    padding-right: 0;
    &::after {
      display: none;
    }

    &::before {
      height: 128px;
      width: 128px;
      left: rem(60);
      bottom: 0;
      top: 40rem;
    }

    &__wrap {
      margin-bottom: 30px;
      padding-top: rem(55);
    }

    &__subtitle {
      font-size: 16px;
      letter-spacing: 3.2px;
      line-height: 18px;
      width: 161px;
      text-align: center;
    }

    &__title {
      font-size: 20px;
      margin-top: 40px;
      width: 210px;
      letter-spacing: 4px;
      line-height: 24px;
      margin-bottom: 40px;
      &::before {
        content: "";
        position: absolute;
        height: rem(6);
        width: rem(59);
        left: 40%;
        right: 0;
        top: -20px;
      }
    }

    &__franchman {
      display: none;
    }

    &__italian {
      width: 136px;
      height: 198px;
      margin-right: 80px;
    }

    &__people-img {
      width: 251px;
      height: 273px;
      right: -20px;
      top: -13rem;
    }

    &__images {
      background-position: 80% 20%;
      display: flex;
      height: 195px;
      align-items: baseline;
      justify-content: flex-end;
      &::after {
        content: "";
        position: absolute;
        height: 67px;
        width: 67px;
        border-radius: 50%;
        left: -35px;
        top: rem(-20);
      }
    }

    &__btn {
      width: fit-content;
      height: 46px;
      font-size: 14px;
      line-height: 22.3px;
      letter-spacing: 2.8px;
      margin-bottom: 5rem;
    }
  }
}
