@import "src/styles/lib";

.Purveyor {
  width: 21.25rem;
  min-height: 28.375rem;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  align-items: center;
  font-family: $cairo;
  padding: 1.25rem 3.125rem;
  z-index: 1;
  overflow: hidden;
  position: relative;
  background-color: #f6f6f6;

  &__pick_banner {
    margin-top: rem(5);
    max-width: rem(280);
    width: fit-content;
    height: auto;
  }

  &__recommend_banner {
    margin-top: rem(5);
    max-width: rem(330);
    width: fit-content;
    height: auto;
  }

  &__background {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    background-color: $black;
    clip-path: polygon(0 0, 0% 100%, 100% 0);
    height: 53%;
    width: 100%;
  }
  &__review-badge {
    width: rem(88);
    height: rem(88);
    position: absolute;
    z-index: 3;
    left: 73%;
    top: rem(35);
  }

  &__image {
    width: rem(320);
    height: rem(340);
    object-fit: contain;
    z-index: 2;
    margin-bottom: rem(10);
  }

  &__title {
    font-size: rem(24);
    font-weight: 700;
    text-align: center;
    flex-grow: 1;
  }

  &__details {
    font-size: rem(24);
    line-height: rem(42);
    font-weight: 300;
    flex-grow: 1;
    text-align: center;
  }

  &__label-sale {
    position: absolute;
    top: 5%;
    left: 7%;
    display: inline-flex;
    text-align: center;
    align-items: center;
    width: rem(67);
    height: rem(67);
    color: $white;
    border-radius: 50%;
    z-index: 3;
    text-transform: uppercase;
    font-size: rem(16);
    line-height: rem(24);
    font-weight: 700;
    background-color: $red;
    overflow: hidden;
  }

  &__label-sale-procent {
    font-size: rem(24);
    line-height: rem(24);
    letter-spacing: 0;
  }

  &__price {
    &--sale {
      text-decoration: line-through;
    }
  }

  &__sale-price {
    color: $red;
    font-weight: 600;
  }

  &__sale-note {
    text-align: center;
    font-size: rem(20);
    font-weight: 700;
    color: $blue;
  }

}

@include mobile {
  .Purveyor {
    &__pick_banner {
      width: 160px;
      height: auto;
    }
  
    &__recommend_banner {
      width: 160px;
      height: auto;
    }
  }
}