@import "src/styles/lib";

.LoginForm {
  border: 1px solid black;
  padding-top: rem(52);
  padding-bottom: rem(52);
  padding-left: 4%;
  padding-right: 4%;
  background: white;
  margin-top: rem(135);
  width: rem(737);
  position: relative;
  z-index: 10;

  &::after {
    content: "";
    position: absolute;
    bottom: rem(336);
    right: rem(-271);
    height: rem(313);
    width: rem(313);
    background-size: contain;
    background-image: url("https://assets.gocoffeego.com/v2/assets/images/signup-icon.png");
  }

  &__link-container {
    text-align: start;
    font-size: rem(23);
    font-weight: 400;
    margin-top: rem(20);
    display: flex;
    justify-content: space-between;
  }

  &__label2 {
    font-size: rem(23);
    font-weight: 400;
    margin-left: rem(10);
  }

  &__link {
    font-size: rem(23);
    font-weight: 400;
    color: #0d89aa;
    text-align: end;
    &:hover {
      color: #0d89aa;
    }
  }

  &__input-container {
    margin-bottom: rem(60);
    position: relative;
    height: rem(60);

    &--sign-in {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        left: rem(8);
        background: url("https://assets.gocoffeego.com/v2/assets/icons/peaberry-icon.png")
          no-repeat;
        background-size: contain;
        height: rem(32);
        width: rem(32);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        @include tablet {
          display: block;
        }
      }
    }

    &--sign-up {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        left: rem(8);
        background: url("https://assets.gocoffeego.com/v2/assets/icons/lock.png")
          no-repeat;
        background-size: contain;
        height: rem(32);
        width: rem(32);
        @include tablet {
          display: block;
        }
      }
    }
  }

  &__required {
    font-size: rem(23);
    color: red;
    margin-top: rem(10);
  }

  &__input {
    padding-left: rem(45) !important;
    width: 100%;
    border-radius: 0 !important;
    font-size: 16px !important;
    border: 0 !important;
    border-bottom: 1px solid #000000 !important;

    &::placeholder {
      color: black !important;
    }
  }

  &__row {
    display: flex;
  }

  &__remember-me {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__remember {
    display: flex;
    align-items: center;
  }

  &__button {
    margin-top: rem(55);
    height: rem(60);
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    background: $blue;
    border: none;
    width: 100%;
    font-size: rem(32);
    font-weight: 500;
    font-family: $rubik;
    cursor: pointer;
    outline: none;
    color: #ffffff;
    font-weight: bold;
    transition: all 300 ease-in-out;
    &:hover {
      background: darken($blue, 1%);
    }
  }
}

@include mobile {
  .LoginForm {
    border: none;

    &::after {
      display: none;
    }

    &__button {
      height: 46px !important;
    }
  }
}
