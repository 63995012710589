@import "../../../styles/lib";

.ApplePay {
    &__express-checkout {
        border: 1px solid #707070;
        position: relative;
        padding: 5% 10%;
        margin-bottom: rem(30);
      }
    
      &__express-checkout-title {
        background: #ffffff;
        font-weight: 700;
        width: fit-content;
        text-transform: uppercase;
        letter-spacing: 0.02em;
        position: absolute;
        top: -14px;
        left: 8px;
        padding: 2px 5px;
    
      }
}