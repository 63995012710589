@import "../../../styles/lib";

.EmailPreferences {
  font-family: $cairo;
  padding-left: rem(150);
  padding-right: rem(150);
  border: 3px solid $black;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 1000px;
  margin: rem(160) auto rem(200);

  &__wrap {
    align-items: center;
  }

  &__message {
    color: $blue;
    font-size: rem(18);
  }

  &__title {
    font-size: rem(25);
    line-height: rem(47);
    letter-spacing: 0.02em;
    margin: rem(13) 0;
  }

  &__button {
    margin: rem(30) auto rem(13);
    background-color: transparent;
    border: 3px solid $black;
    font-size: rem(25);
    letter-spacing: 0.02em;
    font-weight: 400;
    font-family: $rubik;
    cursor: pointer;
    outline: none;
    width: rem(290);
    height: 3.4375rem;
    display: block;
    transition: all 200ms ease-in-out;
    color: black;
    &:hover {
      border: 3px solid $blue;
    }
  }

  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    left: 0;
    opacity: 0.01;
  }
  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    position: relative;
    padding-left: 2.5rem;
    font-size: rem(25);
    letter-spacing: 0.02em;
    cursor: pointer;
  }

  [type="checkbox"]:not(:checked) + label:before,
  [type="checkbox"]:checked + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: rem(30);
    height: rem(30);
    border: 1px solid #707070;
    background: $white;
    transition: all 200ms;
    border-radius: 100%;
  }

  [type="checkbox"]:not(:checked) + label:after,
  [type="checkbox"]:checked + label:after {
    content: "";
    position: absolute;
    top: rem(3.2);
    left: rem(3.2);
    background-color: #00d1d6;
    width: rem(23.5);
    height: rem(23.5);
    transition: all 0.2s;
    border-radius: 100%;
  }

  [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0) rotate(45deg);
  }
}

@include mobile {
  .EmailPreferences {
    margin: 20px 20px 60px;
    padding: 0 7%;
    font-size: 16px;

    [type="checkbox"]:not(:checked) + label:after,
    [type="checkbox"]:checked + label:after {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      width: 11px;
      height: 11px;
    }
    &__message {
      color: $blue;
      font-size: 13px;
    }
  }
}
