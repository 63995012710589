@import "src/styles/lib";

.Grinding {
  padding-top: rem(100);
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &__image {
    &.left {
      float: left;
    }

    &.right {
      float: right;
    }
  }

  &__title {
    font-size: rem(30);
    font-weight: 700;
    position: relative;
    margin-top: rem(40);
    text-align: center;
    margin-bottom: rem(10);
  }

  &__description {
    margin: 0;
    font-size: rem(24);
    font-weight: 400;
    position: relative;
    margin-bottom: rem(30);
    line-height: em(35px, 24px);
  }
}

@include mobile {
  .Grinding {
    &__image {
      width: 50%;
    }
    &__description-image {
      &.left {
        float: left;
        width: 40%;
      }

      &.right {
        float: right;
        width: 40%;
      }
    }

    &__description {
      font-size: 16px;
      min-width: auto;
    }
  }
}
