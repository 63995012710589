@import "src/styles/lib";

.PreviousPeaberryRecommends {
  &__title-container {
    width: 100%;
    height: rem(379);
    background: $black;
    margin-bottom: rem(10);
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: rem(-86);
      left: rem(70);
      background: url("https://assets.gocoffeego.com/v2/assets/images/peaberry-green.png");
      width: rem(301);
      height: rem(301);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &__message {
    margin-top: rem(70);
    font-weight: 600;
    text-align: center;
    font-size: rem(30);
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: rem(100);
  }

  &__line {
    margin-top: rem(100);
  }

  &__title {
    padding-top: rem(111);
  }
}

@include mobile {
  .PreviousPeaberryRecommends {
    &__title-container {
      &::before {
        left: 5px;
        bottom: -90px;
      }
    }
  }
}
