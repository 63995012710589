@import "../../../../styles/lib";

.PhotoUploaderModal {
    position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0,0,0,0.75);
    z-index: 1050;

    &__container {
        height: 70%;
        width: rem(1000);
        border-radius: 0;
        background-color: $white;
        font-family: $cairo;
        z-index: 5;
        overflow: auto;
    }

    &__message {
        color: $red;
        margin-bottom: 3rem;
        font-size: rem(24);

    }

    &__subtitle-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #d7d7d7;
        padding: 1rem 4rem;
    }

    &__close {
        font-size: rem(40);
    }

    &__content {
        padding: 2rem 4rem;
    }

    &__title {
        margin-bottom: 4rem;
    }

    &__input {
        margin-bottom: 5rem;
    }

    &__text {
        margin-bottom: 1rem;
        font-size: rem(24);
    }

    &__preview {
        display: block;
        width: 250px;
        height: 250px;
        border: 3px solid $black;
        margin-bottom: 3rem;
    }

    &__canvas {
        opacity: 0;
        height: 0;
    }

    &__button {
        width: rem(140);
        height: rem(60);
        border: rem(3) solid $black;
        background-color: transparent;
        font-size: rem(28);
        margin-bottom: 2rem;
        margin-left: rem(20);
        transition: all 200ms ease-in-out;
        &:hover {
            border: rem(3) solid $blue; 
        }

    }
}

@include mobile {
    .PhotoUploaderModal {
        &__container {
            height: 80%;
            width: 90%;
        }
    }
}