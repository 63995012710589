@import "src/styles/lib";

.Tea {
    margin-top: rem(180);
    &__description {
        text-align: center;
        font-size: 16px;
        margin: 0 auto;
        font-weight: 500;
        max-width: rem(1000);
        padding: 0 rem(20);
    }
    &__main-title {
        margin: rem(160) auto rem(24);
        text-align: center;
        font-size: rem(34);
        margin-bottom: rem(24);
        font-weight: 700;
    }
    &__products-wrapper {
        max-width: rem(1800);
        margin: rem(100) auto rem(100);
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: rem(20);
        align-content: baseline;
        position: relative;
    }

    &__lady {
      position: absolute;
      top: -150px;
      left: 0;
      bottom: 0;
      width: rem(320);
   
    }

    &__flowers {
        width: rem(350);
        position: absolute;
        top: -108px;
        right: 60px;
        bottom: 0;
        z-index: 10;
    }
}

@include desktop {
    .Tea {
        &__products-wrapper {
            grid-template-columns: repeat(3, 1fr);
            padding: 0 4%;
        }
    }
}


@include tablet-big {
    .Tea {
        &__products-wrapper {
            grid-template-columns: repeat(3, 1fr);
            padding: 0 4%;
        }
        &__lady {
            position: absolute;
            top: -120px;
          }
    }
}

@include tablet {
    .Tea {
        &__products-wrapper {
            grid-template-columns: repeat(2, 1fr);
            padding: 0 4%;
        }
        &__container {
            padding: 0;
        }
        &__lady {
            position: absolute;
            top: -120px;
            left: -50px;
        }
        &__flowers {
            top: -70px;
            right: 30px;
        }
    }
}
@include mobile {
    .Tea {
        &__products-wrapper {
            grid-template-columns: 1fr;
            justify-items: center;
        }
        &__lady {
            display: none;
         }
         &__flowers {
            display: none; 
        }
      
    }
}