@import "src/styles/lib";

.Cart {
  width: 100%;
  height: 100%;
  padding-top: rem(200);
  padding-bottom: rem(100);
  position: relative;
  overflow-x: hidden !important;

  &__empty-message {
    @include empty-message;
  }

  &__scooter-girl {
    width: 20%;
    object-fit: contain;
    margin-top: rem(20);
  }
  &__message {
    display: flex;
    justify-items: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: rem(60);
  }

  &__shipping-info {
    color: $blue;
    cursor: pointer;
    &:hover {
      color: $black;
    }
  }

  &__banner {
    border: 1px solid $black;
    margin: 0 5%;
  }

  &__banner-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 2rem 2rem 0 2rem;
    text-align: center;
  }

  &__banner-title {
    font-size: rem(55);
    font-weight: 600;
    margin-bottom: rem(10);
  }

  &__banner-text {
    font-size: rem(22);
    margin-bottom: rem(10);
  }
  &__banner-list {
    font-size: rem(20);
    margin-bottom: rem(10);
  }

  &__banner-list li {
    &:hover {
      color: $blue;
    }
  }  

  &__profPeaberry {
    width: rem(250);
    height: rem(450);
  }

  &__description1 {
    font-size: rem(28);
    padding: 0 2% 1%;
    font-weight: 500;
  }

  &__description2 {
    font-size: rem(20);
    padding: 0 2% 2%;
  }

  &__shopping-link {
    width: rem(300);
    font-size: rem(27);
    text-align: center;
    margin-top: rem(40);
    font-family: $cairo;

    &:focus {
      color: $blue;
    }

    &:hover {
      color: $blue;
    }
  }

  &__nav {
    background: #ebebeb;
    height: rem(35);
    margin-top: rem(160);
    align-items: center;
    display: flex;
    padding-left: 10%;
    padding-right: 20%;
  }

  &__nav-title {
    font-weight: 400;
    font-size: rem(16);
    line-height: em(24px, 16px);

    &--active {
      color: #ff0000;
    }
  }

  &__right-arrow {
    padding-left: rem(23);
    padding-right: rem(23);
  }

  &__title {
    font-weight: 600;
    font-size: rem(35);
    margin-top: rem(43);
    margin-bottom: rem(50);
    line-height: em(32px, 35px);
    margin-left: 10%;
    margin-right: 10%;
  }

  &__content {
    padding-top: rem(160);
    position: relative;
    overflow-x: hidden !important;
    overflow-y: hidden !important;

    &::after {
      content: "";
      position: absolute;
      height: rem(415);
      width: rem(415);
      border-radius: 50%;
      top: rem(57);
      right: rem(-268);
      background-color: #ffec00;
      z-index: 3;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: auto auto;
  }

  &__container {
    display: grid;
    grid-template-columns: 70% 30%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .Items {
    border-right: 1px solid #c7c7c7;
    min-height: rem(500);
    &__row {
      display: grid;
      grid-template-columns: 90% 10%;
    }

    &__menu {
      display: grid;
      grid-template-columns: 40% 30% 15% 15%;
      border-bottom: 1px solid #c7c7c7;
      padding-bottom: rem(10);
    }

    &__title {
      text-align: center;
      font-size: rem(24);
      font-weight: 400;

      &:first-child {
        text-align: left;
      }
    }
  }

  .Total {
    border-radius: 7px;
    height: fit-content;
    padding-left: rem(52);

    &__line {
      margin-top: rem(10);
      border-bottom: 1px solid #c7c7c7;
      margin-bottom: rem(10);
    }

    &__title {
      font-size: rem(30);
      font-weight: 600;
      border-bottom: 1px solid #c7c7c7;
      margin-top: rem(-8);
      margin-bottom: rem(10);
      padding-bottom: rem(8);
    }

    &__title2 {
      font-size: rem(23);
      font-weight: 600;
      margin-top: rem(22);
    }

    &__title3 {
      font-size: rem(23);
      font-weight: 400;
    }

    &__title4 {
      z-index: 4;
      font-size: rem(30);
      font-weight: 600;
      margin-top: rem(28);
    }

    &__row {
      display: flex;
      justify-content: space-between;
      margin-bottom: rem(14);
    }

    &__row-container {
      display: flex;
      flex-direction: column;
    }

    &__button {
      text-transform: uppercase;
      border: none;
      background: #3dcc50;
      font-size: rem(25);
      font-weight: 400;
      color: white;
      width: 100%;
      height: rem(60);
      outline: none;
      cursor: pointer;
      margin-top: rem(55);
      transition: all 200ms ease-in-out;

      &:hover {
        background: #83bf41;
      }
    }
  }
}

@include tablet-big {
  .Cart {
    &__container {
      grid-template-columns: 80% 20%;
    }

    &__content {
      &::after {
        display: none;
      }
    }

    .Items {
      &__menu {
        padding-right: 25px;
      }
    }
    .Total {
      &__button {
        font-size: 12px;
      }
    }
  }
}

@include tablet {
  .Cart {
    &__content {
      &::after {
        display: none;
      }
    }

    &__container {
      grid-template-columns: 100%;
      padding-left: 3%;
      padding-right: 3%;
      grid-gap: rem(10);
    }
    .Total {
      &__button {
        font-size: 10px;
      }
    }
  }

  .Items {
    &__title {
      text-align: left !important;
    }
    &__menu {
      padding-right: 20px;
    }
    &__row {
      padding-left: 10px;
    }
  }
}

@include mobile {
  .Cart {
    padding-top: 70px;
    &__nav {
      display: none;
    }

    &__scooter-girl {
      width: 37%;
    }

    &__empty-message {
      margin-top: 30px;
      min-height: 100%;
    }

    &__container {
      grid-template-columns: 1fr;
      grid-gap: 0;
      padding-left: 5%;
      padding-right: 5%;
    }

    &__content {
      padding-top: 50px;
      &::after {
        display: none;
      }
    }

    .Total {
      margin-top: 20px;
      border-radius: 0;
      border-top: 1px solid #c7c7c7;
      padding-top: 10px;
      padding-left: 0;
      &__title {
        display: none;
      }

      &__title2 {
        margin: 0;
        font-size: 15px;
        font-weight: 400;
      }

      &__line {
        background: $blue;
        margin-top: 10px;
      }

      &__title3 {
        margin: 0;
        font-size: 15px;
        font-weight: 400;
      }

      &__title4 {
        font-size: 15px;
        font-weight: 700;
      }
    }
  }
}
