@import "./src/styles/lib";

.Order {
  min-height: 100vh;
  padding-top: rem(180);
  position: relative;

  &__column-1 {
    margin-bottom: rem(150);
  }

  &__column-2 {
    padding-left: 5%;
    //  padding-right: 5%;
    border-left: 1px solid $lineGrey;
  }

  &__title3 {
    line-height: em(30, 30);
    font-weight: 500;
    font-size: rem(30);
  }

  &__refunded-image {
    position: absolute;
    width: fit-content;
    z-index: 3;
    right: rem(100);
    top: rem(-50);
  }

  &__voided-image {
    position: absolute;
    width: fit-content;
    z-index: 3;
    top: 0px;
    left: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: rem(200);
  }

  &__title {
    font-size: rem(28);
    font-weight: 600;
    padding-bottom: rem(30);
  }

  &__container {
    border: 1px solid $lineGrey;
    margin-left: 10%;
    margin-right: 10%;
    padding-right: rem(60);
    padding-left: rem(60);
    margin-bottom: rem(100);
  }

  &__button-wrap {
    display: flex;
  }

  &__button {
    margin-right: rem(30);
    //color: black;
  }

  &__details {
    display: grid;
    grid-template-columns: 4fr 1fr;
    border-top: 2px solid $lineGrey;
    padding-top: rem(40);
    padding-bottom: rem(40);
  }

  .OrderShippingDetails {
    margin-right: 5%;
    height: fit-content;
    padding-bottom: rem(30);
    border-bottom: 2px solid $lineGrey;

    &__title {
      font-size: rem(34);
      font-weight: 700;
      text-align: center;
    }

    &__title2 {
      font-weight: 700;
      font-size: rem(30);
    }

    &__columns {
      padding-top: rem(50);
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    &__info {
      margin-top: rem(20);
    }
  }

  .OrderSummary {
    &__row {
      margin-top: rem(30);
      display: flex;
      justify-content: space-between;
    }

    &__title {
      font-size: rem(34);
      font-weight: 700;
      text-align: center;
    }

    &__subtitle {
      margin-top: rem(30);
      padding-bottom: rem(30);
      font-weight: 700;
    }
  }

  .OrderSpecials {
    border-top: 1px solid #ced4da;
    &__title {
      font-size: rem(34);
      font-weight: 700;
      text-align: center;
      padding-top: rem(30);
      line-height: 1.5;
    }
  }

  .OrderBillingAddress {
    border-top: 1px solid $lineGrey;
    &__title {
      margin-top: rem(30);
      margin-bottom: rem(30);
      font-size: rem(34);
      font-weight: 700;
      text-align: center;
    }

    &__billing-details {
      display: flex;
      flex-direction: column;
      padding-bottom: rem(5);
    }
  }

  .OrderProductDetails {
    position: relative;
    &__container {
      display: grid;
      grid-template-columns: 20% 40% 15% 10% 10%;
      grid-gap: rem(20);
      align-items: center;
      margin-top: rem(30);
      padding-bottom: rem(30);
      margin-right: 5%;
      border-bottom: 2px solid #ced4da;
    }

    &__review-button {
      font-size: rem(20);
      font-weight: 700;
      color: #0acdd4;
      margin: 0;
      margin-left: rem(13);
      border: none;
      outline: none;
      background: transparent;
      text-transform: uppercase;
    }

    &__image {
      max-width: rem(200);
      width: fit-content;
    }
    &__product-purveyor {
      font-weight: 600;
    }

    &__product-purveyor,
    &__product-name {
      text-align: center;
      font-size: rem(24);
    }

    &__product-name {
      margin-bottom: rem(30);
    }

    &__button-wrap {
      display: flex;
      justify-content: center;
    }

    &__button {
      width: rem(170);
      height: rem(66);
      &:hover {
        color: $blue;
      }
    }

    &__download-button {
       max-width: rem(400);
       width: 100%;
       height: rem(66);
       background: $blue;
       color: $white;
       border: none;
       font-weight: 600;
       font-size: rem(22);
       letter-spacing: 0.03em;
       text-transform: uppercase;
       position: relative;
     
       &::after {
         content: '';
         width: 100%;
         height: 100%;
         border: 3px solid $blue;
         position: absolute;
         top: 0;
         left: 0;
         z-index: -1;
         background: $blue;
         border-radius: inherit;
         @include animateDowloadButton(pulse, 2.5s, linear, infinite);
       } 
    }

    &__download-note {
      font-size: rem(20);
      margin-top: 10px;
    }

    &__download-btn-wrapper {
       display: flex;
       flex-direction: column;
       align-items: center;
       padding: 0 rem(30);
    }

    &__product-details {
      text-align: center;
      font-size: rem(24);
    }
  }

  .OrderPaymentMethod {
    border-top: 1px solid $lineGrey;
    padding-top: rem(30);
    padding-right: 5%;
    margin-top: rem(30);
    padding-bottom: rem(30);
    &__title {
      font-size: rem(34);
      font-weight: 700;
      text-align: center;
    }

    &__row {
      margin-top: rem(30);
      display: flex;
      justify-content: space-between;
    }
  }

  .OrderTracking {
    margin-top: rem(30);
  }
}

@include tablet {
  .OrderProductDetails {
    &__container {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
  }
}

@include mobile {
  .Order {
    &__container {
      padding: 0 5px 0 5px;
    }

    &__title {
      font-size: 16px;
      margin: rem(40) auto rem(20);
    }

    &__column-2 {
      border-left: none;
      padding-right: 5%;
    }

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: auto;
    }
    &__button-wrap {
      margin-bottom: rem(40);
    }

    &__details {
      display: grid;
      grid-template-columns: 1fr;
    }

    .OrderShippingDetails {
      margin-right: 5%;
      margin-left: 5%;
    }

    .OrderShippingDetails__columns {
      display: flex;
      flex-direction: column;
    }

    .OrderTracking {
      margin-top: rem(30);
      margin-right: 5%;
      margin-left: 5%;
    }

    .OrderProductDetails {
      &__container {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 0;
        align-items: center;
        margin-top: rem(30);
        padding-bottom: rem(30);
        margin-right: 5%;
        margin-left: 5%;
        border-bottom: 2px solid #ced4da;
      }

      &__image-wrap {
        display: flex;
        flex-direction: column;
      }

      &__button-wrap {
        margin: 0 auto 10px;
      }

      &__download-note {
        font-size: 10px;
      }

      &__button {
        width: fit-content;
        font-size: 12px;
      }

      &__product-details,
      &__product-name,
      &__product-purveyor {
        font-size: 14px;
      }

      &__image {
        margin: 0 auto;
      }
    }
  }
}

@include print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  .Order {
    min-height: 100vh;
    padding-top: 0;
  }

  .Header,
  .Footer .TopHeader {
    display: none;
  }

  .OrderBillingAddress {
    border-top: 1px solid $lineGrey;
    &__title {
      margin-top: rem(30);
      margin-bottom: rem(30);
      font-size: rem(34);
      font-weight: 700;
      text-align: center;
    }

    &__billing-details {
      display: flex;
      flex-direction: column;
      padding-bottom: rem(5);
    }
  }

  .OrderShippingDetails {
    margin-right: 5%;
    height: fit-content;
    padding-bottom: rem(30);
    border-bottom: 2px solid $lineGrey;

    &__title {
      font-size: rem(34);
      font-weight: 700;
      text-align: center;
    }

    &__title2 {
      font-weight: 700;
      font-size: rem(30);
    }

    &__columns {
      padding-top: rem(50);
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    &__info {
      margin-top: rem(20);
    }
  }

  .OrderProductDetails {
    position: relative;

    &__container {
      display: grid;
      grid-template-columns: 20% 40% 15% 10% 10%;
      grid-gap: rem(20);
      align-items: center;
      margin-top: rem(30);
      padding-bottom: rem(30);
      margin-right: 5%;
      border-bottom: 2px solid #ced4da;
    }

    &__image {
      max-width: rem(200);
      width: fit-content;
    }
    &__product-purveyor {
      font-weight: 600;
    }

    &__product-purveyor,
    &__product-name {
      text-align: center;
      font-size: rem(24);
    }

    &__product-name {
      margin-bottom: rem(30);
    }

    &__button-wrap {
      display: flex;
      justify-content: center;
    }

    &__button {
      width: rem(170);
      height: rem(66);
    }

    &__product-details {
      text-align: center;
      font-size: rem(24);
    }
  }

  .OrderPaymentMethod {
    border-top: 1px solid $lineGrey;
    padding-top: rem(30);
    padding-right: 5%;
    margin-top: rem(30);
    padding-bottom: rem(30);
    &__title {
      font-size: rem(34);
      font-weight: 700;
      text-align: center;
    }

    &__row {
      margin-top: rem(30);
      display: flex;
      justify-content: space-between;
    }
  }

  .OrderTracking {
    margin-top: rem(30);
  }

  .OrderSummary {
    &__row {
      margin-top: rem(30);
      display: flex;
      justify-content: space-between;
    }

    &__title {
      font-size: rem(34);
      font-weight: 700;
      text-align: center;
    }

    &__subtitle {
      margin-top: rem(30);
      padding-bottom: rem(30);
      font-weight: 700;
    }
  }

  .OrderShippingDetails {
    margin-right: 5%;
    margin-left: 5%;
  }

  .OrderShippingDetails__columns {
    display: flex;
    flex-direction: column;
  }

  .OrderTracking {
    margin-top: rem(30);
    margin-right: 5%;
    margin-left: 5%;
  }
}
