@import "../../../styles/lib";

.PurchaseHistory {
  font-family: $cairo;
  margin-bottom: rem(150);
  padding: 0 20% 0 15%;
  margin-top: 11rem;
  display: grid;
  // grid-template-columns: repeat(2, 1fr);

  &__order-image {
    width: rem(530);
    height: rem(630);
    margin-bottom: 1.5rem;
  }

  &__order {
    margin-top: -5.5rem;
    padding-top: 1.5rem;
    margin-left: -3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      transform: rotate(90deg);
      top: -15rem;
      left: 14rem;
      width: 2px;
      height: 29rem;
      background-color: $black;
    }
  }

  &__order-title,
  &__history-title {
    text-transform: uppercase;
    font-size: rem(36);
    letter-spacing: 0.2rem;
    font-weight: normal;
    line-height: 1.5;
  }

  &__order-title {
    margin-bottom: 2rem;
  }

  &__history-title {
    display: inline;
    margin-right: 1rem;
  }

  &__order-link {
    font-weight: 500;
    color: $black;
    font-size: rem(24);
    //letter-spacing: 0.05em;
    line-height: rem(50);
    //text-transform: uppercase;
    text-decoration: none;
    &:hover {
      color: #00d1d6;
      transition: all 200ms ease-in-out;
    }
  }

  &__history {
    margin-left: -4rem;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      transform: rotate(90deg);
      top: -16rem;
      left: 14.4rem;
      width: 2px;
      height: 29rem;
      background-color: $black;
    }
  }

  &__history-title-wrap {
    width: rem(600);
  }

  &__history-btn,
  &__rate-btn {
    border: none;
    background: transparent;
    color: #00d1d6;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-weight: 600;
  }

  &__history-btn {
    font-size: rem(24);
  }

  &__history-products {
    display: grid;
    grid-template-columns: 302px;
  }

  &__history-products-wrap {
    display: grid;
    grid-template-rows: 1fr;
    max-width: 370px;
    width: fit-content;
  }

  &__history-date {
    font-size: rem(24);
    letter-spacing: 0.05rem;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }

  &__rate {
    display: flex;
    align-items: center;
    padding: 2rem 0;
  }

  &__rate-btn {
    font-size: rem(16);
    margin-left: 1rem;
  }

  &__no-purchase {
    margin: rem(40) auto;
    font-size: rem(20);
    height: 500px;
  }
}

@include tablet-big {
  .PurchaseHistory {
    &__stars {
      height: 16px;
    }
  }
}

@include tablet {
  .PurchaseHistory {
    &__history-products {
      display: grid;
      grid-template-columns: rem(302);
      grid-gap: 0;
    }

    &__stars {
      height: 16px;
    }
  }
}

@include desktop {
  .PurchaseHistory {
    &__history-title,
    &__history-date,
    &__history-products {
      margin-left: 0;
    }

    &__stars {
      height: 16px;
    }

    &__order {
      align-items: initial;
      margin-top: -4rem;
      &::before {
        content: "";
        top: -15rem;
        left: 14.5rem;
        width: 2px;
        height: 29rem;
        background-color: $black;
      }
    }
  }
}
@include desktop-big {
  .PurchaseHistory {
    padding: 0;
    position: relative;
    left: 77px;
  }
}
@media only screen and (min-width: 1921px) {
  .PurchaseHistory {
    padding: 0;
    position: relative;
    left: 77px;
  }
}

@include mobile {
  .PurchaseHistory {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    padding-left: 25px;
    padding-right: 25px;
    justify-items: center;

    &__order {
      margin-top: 0;
      margin-bottom: 6rem;
      align-items: initial;
      width: 240px;
      &::before {
        left: 55%;
      }
    }
    &__order-link {
      text-align: left;
      font-size: 16px;
    }

    &__stars {
      height: 20px;
    }

    &__rate-btn {
      font-size: 12px;
    }

    &__order-title {
      text-align: center;
    }

    &__order-image {
      display: none;
    }

    &__order-wrap {
      display: flex;
      flex-direction: column;
    }

    &__history {
      display: grid;
      grid-template-rows: 1fr;
      align-items: center;
      justify-content: center;
      margin-right: 0;
      margin-left: 0;
      &::before {
        left: 50%;
      }
    }

    &__history-title-wrap {
      text-align: center;
      margin-left: 0;
      margin-bottom: 0;
    }

    &__history-date {
      text-align: center;
      margin-left: 0;
    }

    &__history-products {
      display: grid;
      grid-template-columns: 1fr;
      margin: 0;
    }

    &__history-products-wrap {
      display: grid;
      grid-template-columns: 1fr;
    }
    &__no-purchase {
      font-size: 15px;
      height: auto;
    }
  }
}
