@import "../../../styles/lib";

.CoffeeClubInfoModal {
  width: 60vw;
  height: 80vh;
  overflow-y: auto;

  &__container {
    font-size: 16px;
  }

  &__header {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(2, 0, 36);
    background: linear-gradient(
      0deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 209, 214, 1) 40%
    );
  }
  &__header-title {
    font-weight: bold;
    padding: 0px 20px;
  }
  &__close {
    margin-right: 20px;
    cursor: pointer;
  }

  &__list-title {
      margin-top: rem(30);
      margin-bottom: rem(30);
      font-weight: 700;
  }

  &__list-subtitle {
     margin: rem(20) 0;
     font-weight: 700;
  }

  &__list-text {
    font-weight: 700;
    margin-bottom: rem(10);
  }

  &__list-text-bold {
    font-weight: 700;
  }

  &__body {
    padding: 0px 15px 0px 0px;
  }

  &__list {
      margin: 30px auto;
  }

  &__list-item {
    padding-bottom: 15px;
    list-style-type: disc;
    display: list-item;
    font-weight: 500;
    line-height: 1.7;
    letter-spacing: 0.02em;
  }
 
  &__title-section {
    padding-bottom: 10px;
    font-weight: bold;
    text-decoration: underline;
  }
 
}

@include mobile {
  .CoffeeClubInfoModal {
    width: 100%;
  }
}