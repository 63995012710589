@import "src/styles/lib";

.EspressoDrinks {
  padding-top: rem(100);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &__main-title {
    font-size: rem(30);
    font-weight: 700;
    position: relative;
    margin-bottom: rem(50);
    margin-top: rem(40);
    text-transform: uppercase;
  }

  &__title {
    font-size: rem(30);
    font-weight: 700;
    position: relative;
    margin-top: rem(40);
    text-align: center;
    margin-bottom: rem(10);
  }

  &__title1 {
    font-size: rem(45);
    font-weight: 700;
    position: relative;
    margin-top: rem(40);
    text-align: center;
    margin-bottom: 0;
  }

  &__shape {
    width: rem(67);
    height: rem(67);
    position: absolute;
    bottom: rem(46);
    left: rem(-54);
  }

  .Espressos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-bottom: 2px solid #c2c2c2;
  }

  &__description {
    min-width: rem(1169);
    margin: 0;
    font-size: rem(24);
    font-weight: 400;
    position: relative;
    margin-bottom: rem(30);
    line-height: em(35px, 24px);
  }

  &__line {
    width: 100%;
    margin-top: rem(101);
  }
}

@include mobile {
  .EspressoDrinks {
    &__description {
      font-size: 16px;
      min-width: auto;
    }

    &__line {
      display: none;
    }

    .Espressos {
      grid-template-columns: 1fr;
    }
  }
}
