@import "src/styles/lib";

.SignUp {
  width: 100%;
  margin-top: rem(234);
  padding-bottom: rem(150);
  background: url("https://assets.gocoffeego.com/v2/assets/images/signup-backgorund-2.svg")
    no-repeat top 50px left 0;
  background-size: cover;
  min-height: 100vh;
  &__title {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: rem(227);
      left: rem(-50);
      background: url("https://assets.gocoffeego.com/v2/assets/images/signup-background.svg")
        no-repeat;
      height: rem(501);
      background-size: contain;
      width: 100%;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 100;
  }
}

@include tablet {
  .SignUp {
    //background: none;
  }
}

@include mobile {
  .SignUp {
    margin-top: 70px;
    background: none;

    &__title {
      &::before {
        display: none;
      }
    }
  }
}
