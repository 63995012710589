@import "../../../styles/lib";

.ViewMoreButton {
  display: flex;
  justify-items: center;
  justify-content: center;
  margin-top: 0 !important ;

  &__button {
    cursor: pointer;
    outline: none;
    color: #00d1d6;
    text-transform: uppercase;
    text-decoration: underline;
    margin-top: 0;
    letter-spacing: 0.29rem;
    border: none;
    font-size: rem(24);
    font-weight: 700;
    font-family: $cairo;
    background: transparent;
  }
}

@include mobile {
  .ViewMoreButton {
    font-size: 1.43rem;
    margin-top: rem(29);
    &__button {
      font-size: 16px;
    }
  }
}
