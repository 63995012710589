@import "src/styles/lib";

.WriteReview {
  border: 3px solid $black;
  height: auto;
  min-height: rem(700);
  padding: rem(90) rem(150) rem(50);

  &__form {
    display: flex;
    align-items: center;
    //margin: 0 rem(130) rem(100);
    text-align: center;
    font-size: rem(25);
    line-height: rem(47);
    position: relative;
    background-color: $white;
  }
  /*   &::before {
        position: absolute;
        content: "";
        background: url('../../../assets/images/black-bg.png') no-repeat center center;
        background-size: 100%;
        top: rem(-520);
        left: rem(-170);
        width: rem(1548);
        height: rem(803);
        z-index: -1;
    }
 */
  &__image-wrap {
   // margin-top: -6.5rem;
    margin-right: rem(20);
  }

  &__product-purveyor-name {
    font-weight: 600;
  }

  &__image {
    width: rem(244);
    height: rem(244);
    object-fit: contain;
  }

  &__button {
    width: rem(380);
    height: rem(66);
    font-weight: 400;
    line-height: rem(47);
    font-size: rem(24);
    margin: rem(20) auto;
    position: relative;
    background-color: $white;
    z-index: 5;
    border: 3px solid $black;
    transition: all 200ms ease-in-out;
    color: black;
    &:hover {
      border: 3px solid $blue;
    }
  }

  &__button2, &__button3 {
    width: rem(380);
    height: rem(66);
    font-weight: 400;
    line-height: rem(47);
    font-size: rem(24);
   // margin: rem(20) auto 0;
    position: relative;
    background-color: $white;
    z-index: 5;
    border: 3px solid $black;
    transition: all 200ms ease-in-out;
    color: black;
    &:hover {
      border: 3px solid $blue;
    }
  }

  &__button2 {
    margin: rem(40) auto rem(20);
  }

  &__checkbox-label {
    z-index: 1;
  }

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      width: rem(199);
      height: rem(199);
      background-color: $red;
      border-radius: 50%;
      z-index: 0;
      bottom: rem(-40);
      right: rem(-90);
    }
    &::before {
      position: absolute;
      content: "";
      width: rem(230);
      height: rem(230);
      background: url("https://assets.gocoffeego.com/v2/assets/images/Learn-flowers2.png")
        no-repeat center center;
      background-size: 100%;
      z-index: 0;
      top: rem(-100);
      right: rem(-130);
    }
  }

  &__textarea {
    width: 100%;
    height: rem(300);
    border: 1px solid #00000029;
    padding: rem(20);
    background-color: $white;
    z-index: 1;
    resize: none;
    &:focus {
      outline: 1px solid #00000029;
    }
  }
  &__message {
    color: $blue;
    font-size: rem(20);
    text-align: center;
  }

  &__error-message {
    color: $red;
    font-size: rem(20);
    text-align: center;
  }

  &__checkbox-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__checkbox-input {
    margin-right: 5px;
  }
}

@include mobile {
  .WriteReview {
    padding: 5%;
    overflow: hidden;

    &__form {
      display: flex;
      flex-direction: column;
      // padding: 40px;
    }

    &__image-wrap {
      margin-top: 0;
      margin-right: 0;
      margin-bottom: 20px;
    }

    &__container {
      &::after {
        bottom: rem(-40);
        right: -20px;
      }
    }

    &__button {
      width: fit-content;
    }
  }
}
