@import "src/styles/lib";

.Post {
    width: 100%;
    height: 100%;
    padding-top: rem(80);
    padding-bottom: rem(200);

    &__title-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 7.5rem;
        padding: 0 rem(20);
 
    }

    &__title {
        font-weight: 500;
        text-transform: uppercase;
        font-size: 2.4rem;
        margin-top: 2.5rem;
        margin-bottom: 5rem;
        line-height: 1.8529411765em;
        letter-spacing: 0.1em;
        text-align: center;
    }

    &__content {
        margin: 0 10%;
        font-size: 16px;
    }
}