@import "../../../styles/lib";

.Special {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: rem(300);
  background-color: $white;
  font-family: $cairo;
  flex: 1 1;
  margin: rem(20);

  &__image-container {
    flex-grow: 1;
  }

  &__title {
    font-size: rem(26);
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 1rem;
    &:hover {
      color: $blue;
    }
  }

  &__titles {
    font-size: rem(22);
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    &:hover {
      color: $blue;
    }
  }

  &__text {
    font-size: rem(30);
    font-weight: 600;
    line-height: em(34px, 30px);
    text-align: center;
    padding-bottom: rem(40);
    flex-grow: 1;
    text-transform: capitalize;
  }

  &__image {
    width: rem(180);
    height: rem(180);
    object-fit: contain;
    margin-bottom: rem(20);
  }

  &__images {
    width: rem(160);
    height: rem(160);
    object-fit: contain;
    margin-bottom: rem(20);
  }
}

@include mobile {
  .Special {
   // width: 90%;
    text-align: center;

    &__title,
    &__text {
      font-size: 12px;
      line-height: 16px;
    }
  }
}
