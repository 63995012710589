.flickity-prev-next-button.previous {
  z-index: 20 !important;
  pointer-events: fill;
}

.flickity-prev-next-button.next {
  z-index: 20 !important;
  pointer-events: fill;
}

.flickityBase {
  z-index: 2;
}
