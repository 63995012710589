@import "src/styles/lib";

.Recipe {
  margin-top: rem(150);
  margin-left: 20%;
  margin-right: 20%;
  margin-bottom: rem(100);

  &__title {
    font-size: rem(40);
    font-weight: 700;
    position: relative;
    margin-top: rem(40);
    margin-bottom: rem(10);
  }

  &__by {
    font-size: rem(24);
  }

  &__title2 {
    font-size: rem(30);
    font-weight: 700;
    position: relative;
    margin-top: rem(40);
    margin-bottom: rem(10);
  }

  &__description {
    margin: 0;
    font-size: rem(24);
    font-weight: 400;
    position: relative;
    margin-bottom: rem(30);
    line-height: em(35px, 24px);
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: rem(50);
    grid-gap: rem(200);
    margin-bottom: rem(70);
  }

  &__image-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__image {
    width: rem(369);
    height: rem(369);
    object-fit: contain;
  }

  &__grid2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: rem(50);
    grid-gap: rem(200);
    margin-bottom: rem(70);
  }

  &__ingredients-list {
    margin: 0;
    padding: 0;
  }

  &__directions-list {
    margin: 0;
    padding: 0;
  }

  &__item {
    margin-left: rem(20);
    font-size: rem(24);
    font-weight: 400;
    position: relative;
    margin-bottom: rem(30);
    line-height: em(35px, 24px);
  }
}

@include tablet-big {
  .Recipe {
    margin-left: rem(60);
    margin-right: rem(60);
  }
}

@include tablet {
  .Recipe {
    margin-left: rem(60);
    margin-right: rem(60);
  }
}

@include mobile {
  .Recipe {
    margin-left: 45px;
    margin-right: 45px;
    &__grid {
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }

    &__grid2 {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }

    &__title2 {
      text-align: center;
    }
  }
}
