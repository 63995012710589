@import "src/styles/lib";

.OrderSuccess {
  padding-left: 15%;
  padding-right: 15%;
  padding-top: rem(90);
  min-height: 100vh;

  &__title {
    margin-bottom: rem(27);
    font-size: rem(34);
    font-weight: 600;
    max-width: 100%;
    color: #6ae200;
  }

  &__container {
    margin-top: rem(58);
    display: grid;
    grid-template-columns: 55% 35%;
    grid-gap: 10%;
    border-bottom: 2px solid $lineGrey;
    padding-bottom: 5rem;
  }

  &__details {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: rem(50);
    height: fit-content;
  }

  &__subtitle {
    padding-bottom: rem(10);
    text-align: center;
    font-weight: 700;
    font-size: rem(24);
  }

  &__text {
    padding-bottom: rem(10);
    text-align: center;
    font-weight: 400;
    font-size: rem(22);

    &--email {
      font-size: rem(26);
      margin-top: rem(26);
    }
  }
}

@include mobile {
  .OrderSuccess {
    &__container {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
  }
}
