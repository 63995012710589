@import "./src/styles/lib";

.GiftCertificates {
  padding-top: rem(250);
  font-family: $cairo;
  color: $black;
  overflow: hidden;
  padding-bottom: 0;
  margin-bottom: 0;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    bottom: rem(40);
    right: rem(-100);
    width: rem(358);
    height: rem(358);
    border-radius: 50%;
    background-color: #ffec00;
  }

  &::after {
    position: absolute;
    content: "";
    background: url("https://assets.gocoffeego.com/v2/assets/images/giftcertificate-flowers.png")
      no-repeat center center;
    background-size: 100%;
    width: rem(325);
    height: rem(320);
    top: rem(180);
    right: rem(-90);
    z-index: 4;
  }
  &__main-title {
    text-align: center;
    font-size: rem(34);
    margin-top: rem(32);
    margin-bottom: rem(24);
    font-weight: 700;
  }
  &__main-description {
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      max-width: rem(1200);
      z-index: 2;
  }

  &__subtitle-wrap {
    margin-top: rem(190);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__subtitle {
    margin-top: rem(32);
    font-size: rem(39);
    font-weight: 600;
    letter-spacing: 0.04em;
    line-height: rem(39);
    max-width: rem(720);
  }

  &__container {
    background: url("https://assets.gocoffeego.com/v2/assets/images/certificates/greenbg.png");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
    min-height: rem(961);
    position: relative;
    &::before {
      position: absolute;
      content: "";
      top: rem(-40);
      left: rem(70);
      width: rem(228);
      height: rem(228);
      border-radius: 50%;
      background-color: #ff008e;
      opacity: 0.8;
    }

    &::after {
      position: absolute;
      content: "";
      background: url("https://assets.gocoffeego.com/v2/assets/images/certificates/4stars.png")
        no-repeat center center;
      background-size: 100%;
      width: rem(325);
      height: rem(320);
      bottom: 0;
      left: rem(720);
      z-index: 4;
    }
  }

  &__required {
    font-size: rem(18);
    color: red;
    margin-top: rem(10);
  }
  &__message {
    font-size: rem(24);
    // margin-top: rem(10);
    text-align: center;
    margin-top: 2rem;
    color: $blue;
    flex: 100%;
  }

  &__wrap {
    display: flex;
    justify-content: center;
    margin-top: rem(120);
  }

  &__image {
    width: rem(700);
    object-fit: contain;
    height: rem(500);
    opacity: 1;
    transition: all 200ms ease-in-out;
    display: block;
  }

  &__more-info {
    font-size: rem(24);
    font-weight: 500;
    display: flex;
    flex-direction: column;
    margin-top: rem(50);
    margin-left: rem(40);
    margin-bottom: rem(40);
   
  }

  &__more-info li {
     list-style-type: disc;
     margin-left: rem(100);
     z-index: 5;
     font-weight: 600;
  }

  &__faq, &__terms {
    color: $blue;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    &:hover {
      color: $black;
    }
  }

  &__image-hide {
    opacity: 0;
    height: 0;
    display: none;
  }

  &__inputs {
    display: grid;
    grid-template-columns: rem(221) rem(221) rem(221);
    gap: rem(10);
    align-items: bottom;
    margin-bottom: rem(40);
  }

  &__input {
    border: 1px solid #707070 !important;
    border-radius: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: rem(221) !important;
    height: 30px !important;
    font-size: rem(20) !important;
  }

  &__textarea-wrap {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: bottom;
    position: relative;
    margin-bottom: rem(40);
    &::before {
      position: absolute;
      content: "";
      background: url("https://assets.gocoffeego.com/v2/assets/images/skater-boy.png")
        no-repeat center center;
      background-size: 100%;
      width: rem(143);
      height: rem(196);
      bottom: 0;
      right: rem(-125);
      transform: rotate(30deg);
    }
  }

  &__textarea {
    width: rem(680) !important;
    min-height: 120px;
    height: rem(190) !important;
    resize: none !important;
    border: 1px solid #707070 !important;
    border-radius: 0 !important;
    font-size: rem(20) !important;
    &:focus {
      outline: rem(1) solid $black;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__form-button {
    width: rem(284);
    height: rem(58);
    font-family: $cairo;
    font-size: rem(18);
    line-height: rem(39);
    font-weight: 700;
    letter-spacing: 0.04em;
    z-index: 5;
    background-color: $white;
    border: 3px solid $black;
   // margin-top: rem(58);
    transition: all 200ms ease-in-out;
    color: black;
    &:hover {
      border: 3px solid $blue;
    }
  }
  &__label {
    font-family: $cairo;
    font-size: rem(18);
    line-height: rem(39);
    font-weight: 600;
    letter-spacing: 0.04em;
  }

  &__select-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  &__select {
    min-width: 100%;
    padding-left: 0;
    height: 30px !important;
    border: 1px solid #707070;
    color: $black;
    background: $white;
  }
}

@include laptop {
  .GiftCertificates {
    min-height: fit-content;
    &__container {
      background-size: contain;
      //padding-bottom: 5rem;
    }
  }
}

@include mobile {
  .GiftCertificates {
    padding-top: 80px;
    &::after {
      top: 0;
      right: rem(-90);
    }

    &__container {
      height: 880px;
      padding-right: 5%;
      padding-left: 5%;
      background-size: cover;
      &::before {
        display: none;
      }
      &::after {
        display: none;
      }
    }
    &__wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 3rem;
    }

    &__image {
      margin-right: 0;
      margin-bottom: 10px;
    }

    &__textarea {
      font-size: 16px !important;
    }

    &__textarea-wrap {
      justify-items: flex-start;
    }

    &__inputs {
      display: grid;
      grid-template-columns: rem(583) !important;
    }

    &__select-input {
      align-items: flex-start;
    }

    &__label {
      font-size: 16px;
    }

    &__required {
      text-align: left;
      font-size: 11px;
      padding: 0px;
      margin: 0;
    }

    &__message {
      font-size: 16px;
      color: #707070;
    }

    &__input,
    &__select {
      height: 28px !important;
      font-size: 16px !important;
      width: rem(583) !important;
      margin-bottom: 4px !important;
      padding: 0 0 0 5px !important;
    }
  }
}
