@import "src/styles/lib";

.MemberPicks {
  margin-top: rem(234);
  margin-bottom: rem(234);
  &__description {
    text-align: center;
    font-size: 16px;
    margin: 0 auto 30px;
    font-weight: 500;
    max-width: rem(1200);
    padding: 0 rem(20);
  }
  &__title {
    max-width: rem(1200);
    text-align: center;
    font-size: rem(36);
    margin: 0 auto rem(50);
    font-weight: 700;
  }

  &__content {
    padding-left: rem(130);
    padding-right: rem(130);
    padding-top: rem(0);
    display: grid;
    grid-template-columns: 1fr 3fr;
  }

  &__cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
}

@include tablet {
  .MemberPicks {
    &__content {
      padding-left: 5%;
      padding-right: 5%;
      grid-gap: 20px;
    }
  }
}

@include mobile {
  .MemberPicks {
    margin-top: 70px;
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      grid-template-columns: 1fr;
      padding-left: 5%;
      padding-right: 5%;
    }
  }
}
