@import "src/styles/lib";

.AudioTrack {
  &__song-text {
    font-size: rem(30);
    letter-spacing: 0.08em;
    line-height: rem(36);
    margin-top: rem(30);
    font-weight: 700;
    position: relative;
    text-transform: uppercase;
    &::before {
      content: "";
      background: url("https://assets.gocoffeego.com/v2/assets/icons/music-note.svg")
        no-repeat center center;
      background-size: 100%;
      position: absolute;
      top: rem(-15);
      left: rem(-100);
      width: rem(64);
      height: rem(76);
    }
    &::after {
      content: "";
      background: url("https://assets.gocoffeego.com/v2/assets/icons/music-note.svg")
        no-repeat center center;
      background-size: 100%;
      width: rem(64);
      height: rem(76);
      position: absolute;
      top: rem(-5);
      right: rem(-60);
    }
  }

  &__play-icon {
    background: url("https://assets.gocoffeego.com/v2/assets/icons/music/play.svg")
      no-repeat center center;
    background-size: cover;
    width: rem(50);
    height: rem(50);
    margin-bottom: rem(30);
    border: none;
    outline: none;
    cursor: pointer;
  }

  &__controls {
    display: flex;
    margin-top: rem(50);
    justify-content: space-around;
    min-width: 280px;
  }

  &__prev,
  &__next {
    outline: none;
    border: none;
    width: rem(50);
    height: rem(50);
  }

  &__prev {
    background: url("https://assets.gocoffeego.com/v2/assets/icons/music/previous.svg")
      no-repeat center center;
  }

  &__next {
    background: url("https://assets.gocoffeego.com/v2/assets/icons/music/next.svg")
      no-repeat center center;
  }

  &__pause-icon {
    background: url("https://assets.gocoffeego.com/v2/assets/icons/music/stop.svg")
      no-repeat center center;
    background-size: 92%;
    width: rem(50);
    height: rem(50);
    // margin: 0;
    margin-bottom: rem(30);
    cursor: pointer;
    border: none;
    outline: none;
  }

  &__play-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 rem(30);
  }

  &__play {
    font-size: rem(24);
    font-weight: 600;
    letter-spacing: 0.05em;
    line-height: rem(24);
    cursor: pointer;
    text-transform: uppercase;
  }
}

@include mobile {
  .AudioTrack {
    &__song-text {
      text-align: center;
      width: 240px;
      &::before {
        top: rem(-15);
        left: rem(-80);
      }
    }
  }
}
