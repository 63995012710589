@import "src/styles/lib";

.PrePaidSubscription {
  cursor: pointer;
  &__image {
    border: 3px solid $black;
    object-fit: contain;
    width: rem(420);

    &:hover {
      border: 3px solid $blue;
    }

    &--selected {
      border: 3px solid $blue;
    }
  }

  &__image-container {
    position: relative;
  }

  &__view-more {
    margin-top: rem(10);
    line-height: em(24px, 24px);
  }

  &__radio-button {
    position: absolute;
    top: rem(15);
    right: rem(0);
  }
}

@include mobile {
  .PrePaidSubscription {
    &__image {
      width: rem(300);
      object-fit: contain;
    }

    &__view-more {
      margin-top: 7px;
    }
  }
}
