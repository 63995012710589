@import "src/styles/lib";

.RecentlyViewed {
  height: 100%;
  width: 100%;
  margin-top: rem(89);
  margin-bottom: rem(100);
  position: relative;

  &__image {
    object-fit: contain;
    width: rem(337);
    height: rem(571);
    position: absolute;
    top: rem(-253);
    right: 0;
    z-index: 3;
  }

  &__message {
    text-align: center;
    font-size: rem(30);
  }

  &__title {
    margin-bottom: rem(31);
  }

  &__slider {
    padding-top: rem(34);
    padding-left: 11%;
    padding-right: 11%;
    //background: url("../../../assets/images/recently-viewed-items-background.svg")
    //  no-repeat top 0 left 0;
    background-size: 100% 261px;
  }
}

@include tablet {
  .RecentlyViewed {
    &__image {
      display: none;
    }
  }
}

@include mobile {
  .RecentlyViewed {
    &__slider {
      background: none;
    }
    &__image {
      display: none;
    }

    &__slider {
      padding-left: 5%;
      padding-right: 5%;
    }
  }
}
