@import "src/styles/lib";

.MemberPicksCard {
  border: 1px solid #707070;
  width: rem(1038);
  padding: rem(85);
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  background: white;
  position: relative;

  &.circle {
    margin-bottom: rem(45);

    &:before {
      content: "";
      height: rem(263);
      width: rem(263);
      background: #6ae200;
      border-radius: 50%;
      position: absolute;
      left: rem(-72);
      top: rem(166);
      z-index: -1;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__product {
    width: rem(212);
    height: rem(212);
    object-fit: contain;
    margin-top: rem(42);
  }

  &__title {
    font-weight: 700;
    font-size: rem(22);
    text-align: center;
    color: $black;
  }

  &__description {
    font-weight: 300;
    font-size: rem(20);
    line-height: em(20px, 20px);
    text-align: center;
    color: #000;
    max-width: rem(236);
  }

  &__weight {
    font-weight: 300;
    font-size: rem(20);
    line-height: em(20px, 20px);
    text-align: center;
    color: #000;
    max-width: rem(236);
    margin-bottom: rem(20);
  }

  &__review {
    font-weight: 300;
    font-size: rem(20);
    line-height: em(30px, 20px);
    max-width: rem(386);
    margin-bottom: rem(20);
  }

  &__person {
    margin-top: rem(42);
    width: rem(181);
    height: rem(181);
    object-fit: contain;
  }

  &__date {
    margin-top: rem(13);
    font-weight: 600;
    font-size: rem(20);
    text-align: center;
    color: #000;
    max-width: rem(290);
  }

  &__type {
    font-weight: 600;
    font-size: rem(20);
    text-align: center;
    color: #000;
    max-width: rem(290);
  }

  &__button {
    margin-top: rem(27);
  }

  &__person-details {
    font-weight: 400;
    font-size: rem(20);
    line-height: em(20px, 20px);
    text-align: center;
    color: $black;
    margin-top: rem(10);
  }
}

@include tablet {
  .MemberPicksCard {
    width: fit-content;
    padding: 20px;
  }
}

@include mobile {
  .MemberPicksCard {
    width: 290px;
    padding: 20px;
    display: flex;
    grid-template-columns: 1fr;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    &__column-1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      order: 1;
    }

    &__content {
      order: 3;
    }

    &__column-3 {
      order: 2;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__title {
      margin-top: 10px;
    }
  }
}
