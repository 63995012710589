@import "./src/styles/lib";

.Tracking {
  height: 100%;
  padding-top: rem(180);

  &__column-2 {
    padding-left: 5%;
    padding-right: 5%;
    border-left: 1px solid $lineGrey;
  }

  &__title3 {
    line-height: em(30, 30);
    font-weight: 500;
    font-size: rem(30);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: rem(200);
  }

  &__title {
    font-size: rem(34);
    font-weight: 600;
    line-height: em(34, 34);
    margin-bottom: rem(10);
  }

  &__container {
    border: 1px solid $lineGrey;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: rem(200);
    padding-right: rem(60);
    padding-left: rem(60);
    padding-top: rem(50);
  }

  &__button {
    margin-right: rem(30);
    color: black;
  }

  &__details {
    display: grid;
    grid-template-columns: 4fr 1fr;
    border-top: 2px solid $lineGrey;
    padding-top: rem(40);
    padding-bottom: rem(40);
  }

  .Activity {
    margin-top: rem(100);
    margin-bottom: rem(100);

    &__title {
      font-size: rem(34);
      border-bottom: 1px solid $lineGrey;
      margin-bottom: rem(30);
    }

    &__text {
      line-height: em(40, 25);
      font-weight: 500;
      font-size: rem(30);
    }
  }
}
