@import "src/styles/lib";

.BrewingTips {
  padding-top: rem(100);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &__container {
    display: flex;
  }

  &__main-image {
    width: 100%;
    margin-bottom: 20px;
  }

  &__description-image {
    height: rem(150);
    &.left {
      float: left;
    }

    &.right {
      float: right;
    }
  }

  &__title {
    font-size: rem(30);
    font-weight: 700;
    position: relative;
    margin-top: rem(40);
    text-align: center;
    margin-bottom: rem(10);
  }

  &__title1 {
    font-size: rem(45);
    font-weight: 700;
    margin-top: rem(40);
    text-align: center;
    margin-bottom: 0;
  }

  &__title2 {
    font-size: rem(30);
    font-weight: 700;
    text-align: center;
    margin-bottom: rem(50);
    margin-top: 0;
  }

  &__description {
    min-width: rem(1169);
    margin: 0;
    font-size: rem(24);
    font-weight: 400;
    position: relative;
    margin-bottom: rem(30);
    line-height: em(35px, 24px);
  }
}

@include mobile {
  .BrewingTips {
    &__peaberry {
      display: none;
    }

    &__shape {
      width: 28px;
      height: 28px;
      top: -29px;
      left: -29px;
    }

    &__flowers {
      display: none;
    }

    &__content {
      padding: 0;
    }

    &__peaberry-sitting {
      display: none;
    }

    &__description {
      font-size: 16px;
      min-width: auto;
    }

    &__line {
      display: none;
    }
  }
}
