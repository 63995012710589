@import "../../../styles/lib";

.SubscribeSection {
  width: 100%;
  &__content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: #000000;
    position: relative;
    margin-bottom: rem(10);
  }

  &__image {
    object-fit: contain;
    width: rem(330);
    height: rem(430);
    position: absolute;
    left: 0;
    bottom: rem(-117);
    z-index: 0;
  }

  &__yellow-dot {
    object-fit: contain;
    width: 10%;
    position: absolute;
    left: 5%;
    bottom: rem(-206);
    z-index: 1;
  }

  &__title1 {
    color: white;
    font-size: rem(36);
    font-family: $cairo;
    font-weight: 700;
    letter-spacing: 0.6rem;
    margin-top: rem(106);
    margin-bottom: rem(25);
    text-align: center;
  }

  &__title2 {
    color: white;
    font-size: rem(36);
    font-family: $cairo;
    font-weight: 400;
    letter-spacing: 0.6rem;
    margin-top: rem(50);
    margin-bottom: rem(42);
  }

  &__description {
    color: white;
    font-size: rem(28);
    line-height: em(38px, 28px);
    font-family: $cairo;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: rem(60);
    width: rem(1102);
    text-align: center;
  }

  &__button {
    border: 3px solid $white;
    color: $white;
    font-size: rem(24);
    font-family: $cairo;
    font-weight: 700;
    letter-spacing: 0.3rem;
    margin-top: 0;
    margin-bottom: rem(124);
    width: rem(330);
    height: rem(77);

    &:hover {
      color: $white;
    }
  }
}

@include mobile {
  .SubscribeSection {
    &__title1 {
      font-size: 16px;
    }

    &__title2 {
      width: 210px;
      font-size: 20px;
      font-weight: 600;
      line-height: em(24px, 20px);
      text-align: center;
      margin-top: 18px;
    }
    &__button {
      font-size: 16px;
    }

    &__image {
      width: 121px;
      bottom: -110px;
    }

    &__yellow-dot {
      width: 82px;
      left: 17px;
      bottom: -109px;
    }

    &__description {
      font-size: 16px;
      font-weight: 400;
      max-width: 338px;
      padding: 0 15px;
      margin-bottom: 46px;
    }
  }
}
