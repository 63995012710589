@import "src/styles/lib";

.ProductItem {
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  align-items: center;
  width: rem(290);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding-left: rem(39);
  padding-right: rem(39);
  padding-top: rem(28);
  padding-bottom: rem(28);
  border: 3px solid black;
  margin-left: rem(40);
  position: relative;
  background: white;
  max-width: rem(290);

  &__image {
    object-fit: contain;
    width: rem(102);
    height: rem(170);

    &--gift {
      width: rem(170);
      height: rem(170);
    }
  }

  &__heart {
    position: absolute;
    right: rem(15);
    top: rem(10);
    cursor: pointer;
    color: transparent;
    object-fit: contain;
    width: rem(31);
    height: rem(31);
    z-index: 100;

    &--active {
      color: red;
    }
  }

  .Remove {
    display: flex;
    position: absolute;
    right: rem(10);
    top: rem(10);
    cursor: pointer;

    &:hover {
      .Remove__title {
        display: block;
      }
    }

    &__title {
      display: none;
      font-size: rem(16);
      font-weight: 400;
      margin-right: rem(16);
    }

    &__icon {
      width: rem(15);
      object-fit: contain;
    }
  }

  &__title {
    margin-top: rem(8);
    font-size: rem(20);
    font-weight: 600;
    flex-grow: 1;
    min-height: rem(45);
    text-align: center;
    margin-bottom: rem(8);
  }

  &__description {
    flex-grow: 1;
    font-size: rem(20);
    font-weight: 400;
  }

  &__button {
    min-width: 100%;
    border: 3px solid black;
    background: white;
    font-size: rem(20);
    font-weight: 400;
    margin-top: rem(16);
   // height: rem(41);
    height: rem(60);
  }
}

@media only screen and (max-width: 512px) {
  .ProductItem {
    width: 45%;

    &__image {
      width: 100px;
    }

    &__title {
      font-size: 14px;
      min-height: 40px;
    }

    &__description {
      font-size: 14px;
      flex-grow: 1;
    }

    &__button {
      height: 30px !important;
    }

    .Remove {
      &__icon {
        width: 15px;
      }
    }
  }
}
