@import "src/styles/lib";

.Videos {
  height: auto;

  &__main-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  &__wrap {
    margin-top: rem(150);
  }
  &__main-description {
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    max-width: rem(1200);
    padding: 0 10px;
  }
  &__seo-title {
    max-width: rem(1200);
    text-align: center;
    font-size: rem(36);
    margin: rem(130) auto rem(30);
    font-weight: 700;
    padding: 0 10px;
  }

  &__modifier {
    display: flex;
    justify-content: center;
  }

  &__header {
    text-align: center;
  }
  &__channel {
    padding-top: rem(20);
    font-size: rem(27);
    font-weight: 600;
    &:hover {
      color: #0acdd4;
    }
  }

  &__image {
    width: rem(300);
  }
  &__content {
    padding: rem(150) 0 rem(350) rem(50);
    text-align: center;
    font-family: $cairo;
    border-top: 2px solid black;
    position: relative;
    top: rem(200);
    right: rem(25);
  }
  &__description {
    font-size: rem(24);
    width: rem(520);
    padding-top: rem(20);
    padding-bottom: rem(20);
  }
  &__channels {
    font-size: rem(27);
    font-weight: 600;
    &:hover {
      color: #0acdd4;
    }
  }
  &__title {
    font-size: rem(25);
    padding-bottom: rem(20);
    padding-top: rem(20);
    &:hover {
      color: #0acdd4;
    }
  }
  &__count {
    font-size: rem(23);
    padding-bottom: rem(20);
  }
  &__watch-videos {
    font-size: rem(26);
    font-weight: 500;
    &:hover {
      color: #0acdd4;
    }
  }
}
@include desktop {
  .Videos {
    &__wrap {
      width: 200px;
    }
    &__category-image {
      width: 280px;
    }
    &__description {
      padding-right: 50px;
    }
  }
}
@include tablet {
  .Videos {
    &__main-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    &__description {
      width: 350px;
      text-align: center;
      padding-left: 15px;
    }
    &__content {
      border: none;
    }
  }
}
@include tablet-big {
  .Videos {
    &__main-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    }
    &__description {
      width: 350px;
      text-align: center;
      padding-left: 15px;
      font-size: 16px;
    }
    &__content {
      border: none;
    }
  }
}
@include mobile {
  .Videos {
    &__main-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    }
    &__category-image {
      width: 300px;
    }
    &__description {
      width: 300px;
    }
    &__content {
      border-top: none;
      width: 100%;
      padding-bottom: 70px;
      padding-top: 50px;
      border-bottom: 2px solid black;
    }
    &__image {
      padding-top: 190px;
    }
    &__wrap {
      margin-top: -50px;
    }
  }
}
