@import "../../../styles/lib";

.Banner {
  max-width: rem(1600);
  width: 100%;
  height: fit-content;
  position: relative;
  border: 3px solid $black;
  background-color: $white;

  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    a {
      position: relative;
    }
    a:nth-child(3n+3){
      &::before {
        content: "";
        position: absolute;
        border-bottom: 1px solid #c7c7c7;
        bottom: 3%;
        left: -220%;
        width: 310%;
        height: 1px;
      }
    }
    a:nth-last-child(1){
      &::before {
        display: none;
      }
   }
  }

  &::before {
    content: "";
    position: absolute;
    top: rem(-60);
    left: rem(40);
    width: rem(189);
    height: rem(189);
    border-radius: 50%;
    background-color: $red;
    z-index: -1;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: rem(-60);
    right: rem(-80);
    width: rem(189);
    height: rem(189);
    border-radius: 50%;
    background-color: #ffec00;
    z-index: -1;
  }

  &__slider {
    padding: rem(60) rem(20) 0;
    min-height: rem(450);
  }

  &__spacial-title {
    text-align: center;
    padding: 3rem;
    font-weight: 600;
  }

  &__loaders {
    justify-content: center;
    width: 100%;
    min-height: rem(400);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: rem(10);
    grid-column-gap: rem(20);
    align-items: center;
    padding: 2%;
  }

  &__title-wrapper {
    background: #000000;
    display: flex;
    align-items: center;
    margin-top: -1px;
    margin-right: -1px;
  }

  &__title-white-line {
    background-color: #ffffff;
    width: 100%;
    height: rem(20);
  }

  &__title {
    text-transform: uppercase;
    text-align: center;
    padding-top: rem(14);
    padding-bottom: rem(14);
    font-size: rem(34);
    font-weight: 600;
    color: #f6ff00;
    margin: 0 rem(35);
    letter-spacing: 0.15em;
  }
}

@include tablet-big {
  .Banner {
    margin-top: rem(50);
    &__container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@include tablet {
  .Banner {
    margin-top: rem(132);
    &__container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      a:nth-child(3n+3){
        &::before {
         display: none;
        }
       }
      a:nth-child(2n+2){
        &::after {
          content: "";
          position: absolute;
          border-bottom: 1px solid #c7c7c7;
          bottom: 3%;
          left: -130%;
          width: 220%;
          height: 1px;
        }
      }
      a:nth-last-child(1){
         &::after {
           display: none;
         }
      }
      
    }
  }
}

@include mobile {
  .Banner {
    max-width: 380px;
    width: 100%;
    padding: 0;
    margin: 0 auto;

    &__container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      
      a:nth-child(3n+3){
        &::before {
         display: none;
        }
       }
      a:nth-child(2n+2){
        &::after {
          content: "";
          position: absolute;
          bottom: 1%;
          left: 0;
          transform: translate(-50%, -50%);
          width: 300px;
          height: 1px;
          background: #c7c7c7;
        }
      }
      a:nth-last-child(1){
         &::after {
           display: none;
         }
      }
      
    }

    &__loaders {
      justify-items: center;
      padding: 5%;
      grid-row-gap: 20px;
      grid-column-gap: 10px;
      grid-template-columns: 1fr;
    }
  }
}
