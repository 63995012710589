@import "src/styles/lib";

.PrimaryTitle {
  position: relative;
  z-index: 2;

  &__title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    top: 0;
    margin-bottom: auto;
    margin-top: auto;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    background: $black;
    width: rem(660);
    height: rem(253);
  }

  &__title {
    text-transform: uppercase;
    font-family: $rubik;
    font-size: rem(60);
    font-weight: 500;
    color: $white;
    line-height: em(60px, 60px);
    text-align: center;
    letter-spacing: 0.025rem;
    padding-left: rem(56);
    padding-right: rem(56);
    margin-top: rem(-5px);
  }
}

@include mobile {
  .PrimaryTitle {
    &__title-container {
      width: 263px !important;
      height: 101px;
    }

    &__title {
      font-size: 26px;
      padding-left: 32px;
      padding-right: 32px;
    }
  }
}
