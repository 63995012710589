@import "../../../styles/lib";

.TakeQuiz {
  margin-top: rem(96);

  &__content {
    background: #000000;
    padding-top: rem(66);
    padding-bottom: rem(55);
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;

    .title {
      color: white;
      font-weight: 700;
      font-size: rem(26);
      margin-bottom: rem(34);
      letter-spacing: 0.1em;
    }

    .subtitle {
      color: white;
      font-weight: 700;
      font-size: rem(24);
      margin: rem(34) 0 0;
      letter-spacing: 0.15em;
      text-align: center;
    }

    .button-container {
      position: relative;

      .circle {
        width: rem(142);
        height: rem(142);
        position: absolute;
        top: rem(80);
        left: rem(175);
      }
    }
  }

  &__button {
    border: 3px solid $white;
    color: $white;
    transition: all 200ms ease-in-out;
    &:hover {
      border: 3px solid $blue;
      color: $white;
    }
  }
}

@include mobile {
  .TakeQuiz {
    &__content {
      .title {
        width: 291px;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
      }
      .subtitle {
        font-size: 16px;
      }
    }
  }
}
