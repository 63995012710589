@import "../../../styles/lib";

.CheckBalance {
  width: 100%;
  height: 100%;
  padding: 0 0 rem(100);
  font-family: $cairo;
  overflow: hidden;

  &__main-container {
    height: 100%;
    padding: rem(100) 10% rem(50);
    margin-bottom: rem(400);
  }

  &__wrap {
    margin-left: auto;
    margin-right: auto;
    max-width: 1700px;
    padding: rem(20) rem(60);
    margin-top: rem(100);
    border: 3px solid $black;
    position: relative;
    &::before {
      content: "";
      background: url("https://assets.gocoffeego.com/v2/assets/images/yogagirl.svg")
        no-repeat center center;
      background-size: 100%;
      position: absolute;
      top: 0;
      left: rem(-92);
      width: rem(166);
      height: rem(220);
    }
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__required {
    color: $red;
    font-size: rem(18);
  }

  &__text {
    font-size: rem(25);
    line-height: rem(47);
    margin-bottom: 2rem;
    text-align: center;
  }

  &__shopping {
    color: #00d1d6;
  }

  &__balance {
    font-weight: 700;
  }

  &__form-group {
    margin-bottom: rem(30);
    margin-left: rem(30);
  }
  &__form-group2 {
    margin-bottom: rem(30);
  }

  &__form-text {
    margin-bottom: 1rem;
    font-size: rem(25);
    line-height: rem(47);
  }

  &__success-modal {
    max-width: rem(800);
    height: rem(400);
    border: 3px solid $black;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }

  &__success-modal-title {
    font-size: rem(32);
    padding: 0 20px;
  }

  &__success-modal-link {
    color: $blue;
    font-size: rem(32);
    text-decoration: underline;
  }

  &__input {
    background: transparent;
    // margin-left: 22px;
    margin-bottom: rem(10);
    letter-spacing: 0.02em;
    font-size: rem(20);
    height: rem(40);
    border: 1px solid #707070;
    padding: rem(10);
    max-width: rem(633);
    width: 100%;
    -webkit-appearance: none;
    border-radius: 0 !important;
  }

  &__button {
    background-color: transparent;
    border: 3px solid $black;
    // text-transform: uppercase;
    font-size: rem(24);
    letter-spacing: 0.02em;
    line-height: rem(47);
    font-weight: 400;
    font-family: $rubik;
    cursor: pointer;
    outline: none;
    margin: 0 auto;
    width: rem(290);
    height: rem(55);
    display: block;
    transition: all 200ms ease-in-out;
    color: black;
    &:hover {
      border: 3px solid $blue;
    }
  }

  input[type="text"]:focus {
    outline: 1px solid $black;
    background-color: transparent;
    color: $black;
    -webkit-appearance: none;
    border-radius: 0 !important;
  }
}
.maxTv {
  margin-bottom: rem(920);
}

@include mobile {
  .CheckBalance {
    padding-top: 0;
    &__wrap {
      margin-left: 10%;
      margin-right: 10%;
      margin-top: 25px;
      &::before {
        width: 100px;
        height: 180px;
        top: 30px;
        left: -60px;
      }
    }

    &__container {
      display: grid;
      grid-template-columns: 1fr;
      padding: 0 20px;
    }

    &__form-group {
      margin-left: 0;
    }

    &__form-group,
    &__form-group2 {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__button {
      line-height: normal;
    }
  }
}
