@import "../../../styles/lib";

.WhyChooseGoCoffeGoItem {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-right: rem(31);
  padding-left: rem(31);
  border-right: 3px solid black;
  padding-top: rem(69);
  padding-bottom: rem(32);

  &:nth-child(3) {
    border-right: none;
  }

  &__image {
    width: rem(100);
    height: rem(100);
  }

  &__title {
    margin-top: rem(19);
    margin-bottom: rem(36);
    font-size: rem(30);
    font-weight: 400;
  }

  &__description {
    font-size: rem(21);
    font-family: $cairo;
    font-weight: 400;
    text-align: center;
    width: rem(280);
    line-height: em(27px, 21px);
    margin: 0;
    min-height: rem(111);
  }
}

@include mobile {
  .WhyChooseGoCoffeGoItem {
    border-right: none;
    border-bottom: 2px solid black;
    padding-top: 46px;
    padding-bottom: 40px;

    &__description {
      font-size: 16px;
    }

    &:nth-child(3) {
      border-bottom: none;
    }

    &__title {
      font-size: 25px;
      font-weight: 700;
    }

    &__image {
      width: 110px;
      height: 110px;
    }
  }
}
