@import "src/styles/lib";

.QuizQuestionTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;

  &__question-number {
    font-size: rem(32);
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: rem(25);
  }

  &__selection {
    margin-left: rem(10);
  }

  &__title {
    font-size: rem(32);
    font-weight: 400;
    text-transform: uppercase;
    margin-top: rem(19);
    letter-spacing: 0.2rem;
  }
}
@include mobile {
  .QuizQuestionTitle {
    &__title {
      text-align: center;
      line-height: 1.5;
      padding: 0 10px;
    }
  }
}
