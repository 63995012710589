@import "src/styles/lib";

.Purveyors {
  padding: rem(180) 0 rem(160);
  position: relative;
  font-family: $cairo;
  overflow: hidden;
  &::before {
    position: absolute;
    content: "";
    width: rem(269);
    height: rem(280);
    top: rem(160);
    right: rem(260);
    background: url("https://assets.gocoffeego.com/v2/assets/images/purveyors-flowers.png")
      no-repeat center center;
    background-size: 100%;
    z-index: 4;
  }
  &__heading {
    text-align: center;
    margin-top: rem(50);
    font-weight: 700;
  }
  &__review {
    width: 100px;
    height: 100px;
    background: red;
  }
  &__review-badge {
    width: 200px;
    height: 100px;
    background: blue;
    position: absolute;
    z-index: 1000;
    left: 77%;
    bottom: 500px;
  }

  &__main-container {
    width: rem(967);
    margin: rem(80) auto 0;
    border: 3px solid $black;
    border-bottom: 0;
    background-color: $white;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      width: rem(614);
      height: rem(614);
      top: rem(60);
      left: rem(-350);
      border-radius: 50%;
      background-color: #ff008e;
      z-index: -1;
    }
    &::before {
      position: absolute;
      content: "";
      width: rem(260);
      height: rem(515);
      top: rem(60);
      left: rem(-260);
      background: url("https://assets.gocoffeego.com/v2/assets/images/prof-peaberry-with-a-cup-new.png")
        no-repeat center center;
      background-size: 100%;
      z-index: 0;
    }
  }

  &__container {
    padding: rem(50) rem(35) rem(50) rem(60);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  &__image {
    width: rem(280);
    height: rem(286);
    object-fit: contain;
  }

  &__info {
    font-size: rem(24);
    font-weight: 700;
    line-height: rem(42);
    z-index: 1;
  }

  &__info-item {
    display: grid;
    grid-template-columns: 20% 80%;
    justify-items: baseline;
    gap: rem(20);
  }

  &__info-name {
    justify-self: flex-end;
  }

  &__main-img-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    height: rem(350);
    background-color: $black;
  }
  &__circle {
    position: absolute;
    width: rem(193);
    height: rem(193);
    bottom: rem(-100);
    right: rem(-120);
    border-radius: 50%;
    background-color: #ffec00;
    z-index: 10;
  }
  &__main-img {
    max-width: rem(961);
    max-height: rem(350);
    object-fit: contain;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: relative;
  }

  &__description {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &-container {
      border: 3px solid black;
      border-top: none;
      width: rem(967);
      height: 100%;
      padding: rem(20);
    }

    &-content {
      margin-bottom: rem(50);
      font-size: rem(20);
    }
    &-text {
      margin-bottom: rem(20);
    }
    &-link {
      font-size: rem(28);
      font-weight: 700;
      margin-bottom: rem(20);

      &:hover {
        cursor: pointer;
      }
    }
    &-process {
      font-weight: 700;
      font-size: rem(22);
      :first-child {
        color: #ff6600;
      }
    }
  }

  &__special {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: rem(60);
    background: #000000;
    //margin-top: rem(20);
  }

  &__special-text {
    margin: 0;
    font-size: rem(36);
    color: #fefefe;
    font-family: $cairo;
    span {
      color: #f5e90d;
    }
  }

  &__products-wrap {
    position: relative;
  }

  &__products-icon-container {
    position: relative;
  }

  &__button {
    margin-top: rem(40);
  }

  &__products {
    padding: rem(80) rem(130) rem(60);
    padding-left: 10%;
    padding-right: 10%;
    display: grid;
    grid-template-columns: repeat(4, rem(340));
    justify-content: center;
    gap: rem(112);
    position: relative;

    .child:nth-last-child(1) {
      background: red;
    }

  }
}

.Purveyors__loaders {
  padding: rem(60) rem(130);
  padding-left: 10%;
  padding-right: 10%;
  display: grid;
  grid-template-columns: repeat(4, rem(340));
  justify-content: center;
  gap: rem(112);
  position: relative;
}

@include tablet {
  .Purveyors {
    &__products {
      display: grid;
      grid-template-columns: repeat(3, rem(340));
      justify-content: center;
      gap: rem(120);
    }

    &__products-icon-container {
      position: relative;
      &::before {
        top: rem(680);
        left: rem(150);
      }

      &::after {
        top: rem(1240);
        right: rem(180);
      }
    }
    &__description {
      margin-left: 20 px;
      margin-right: 20 px;
    }
  }

  .Purveyors__loaders {
    padding: rem(60) rem(130);
    padding-left: 10%;
    padding-right: 10%;
    display: grid;
    grid-template-columns: repeat(3, 187px);
    justify-content: center;
    gap: 80px;
    position: relative;
  }
}

@include mobile {
  .Purveyors {
    padding-top: 60px;
    &::before {
      top: 20px;
      right: 0;
      background: url("https://assets.gocoffeego.com/v2/assets/images/purveyors-flowers.png")
        no-repeat center center;
      background-size: 100%;
      z-index: 4;
    }

    &__special {
      height: auto;
      text-align: center;
    }
    &__image {
      width: rem(280);
      height: rem(286);
      margin-bottom: 2rem;
    }

    &__main-img-wrap {
      border: 3px solid black;
      margin: 0px 20px;
      border-top: 0px;
      //margin-bottom: 5rem;
    }
    &__main-img {
      object-fit: fill;
    }
    &__main-container {
      width: auto;
      margin-left: 20px;
      margin-right: 20px;
      &::before,
      &::after {
        display: none;
      }
    }
    &__container {
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;
    }

    &__products {
      padding: 45px 10px;
      display: grid;
      grid-template-columns: repeat(2, rem(340));
      justify-content: center;
      gap: 10px;
      &::before {
        top: rem(1250);
      }
    }

    &__products-icon-container {
      position: relative;
      &::before {
        top: rem(560);
        left: rem(100);
      }

      &::after {
        top: 1080px;
        right: 60px;
      }
    }

    &__products-wrap {
      &::before {
        top: rem(200);
      }

      &::after {
        bottom: rem(280);
      }
    }

    &__description-container {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  .Purveyors__loaders {
    padding-bottom: rem(130);
    margin-top: -60px;
    padding-left: 5%;
    padding-right: 5%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    gap: 10px;
    position: relative;
  }
}
