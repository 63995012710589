@import "src/styles/lib";

.PreviousMemberPicks {
  width: 100%;

  .test-bg {
    position: relative;
  }

  &__message {
    margin-top: rem(30);
    font-weight: 600;
    text-align: center;
    font-size: rem(30);
  }

  &__button {
    font-size: 16px;
    margin-top: 40px;
  }

  &__background {
    width: 100%;
    object-fit: contain;
    margin-bottom: rem(10);
  }

  &__title {
    position: absolute;
    top: rem(95);
  }

  &__cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: rem(74);
  }

  &__line {
    margin-top: rem(74);
  }

  &__title-container {
    position: relative;
  }
}

@include mobile {
  .PreviousMemberPicks {
    &__title {
      position: relative;
      margin-bottom: 0;
    }

    &__cards {
      &:first-child {
        margin-top: 0;
      }
    }
  }
}
