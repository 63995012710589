@import "src/styles/lib";

.PeaberryRecommendations {
  width: 100%;
  //background: url("../../../assets/images/peaberry-recommendations-background.svg")
  //  no-repeat left rem(147) top rem(100);
  //background-size: 84%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  //min-height: rem(1900);
  padding-top: rem(102);

  &__button {
    &:hover {
      color: black;
      border: 3px solid $blue;
    }

    &:focus {
      color: black;
      border: 3px solid $blue;
    }
  }

  &__bg {
    position: relative;
    width: 80%;
    object-fit: contain;
  }

  &__bg-mb {
    display: none;
  }

  &__main-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: rem(160);
  }

  &::before {
    content: "";
    position: absolute;
    width: rem(272);
    height: rem(527);
    right: rem(285);
    top: rem(-27);
    background: url("https://assets.gocoffeego.com/v2/assets/images/peaberry-blue.svg")
      no-repeat;
    z-index: 3;
    background-size: 100%;
  }

  &::after {
    content: "";
    position: absolute;
    width: rem(588);
    height: rem(855);
    right: rem(309);
    top: rem(439);
    background: url("https://assets.gocoffeego.com/v2/assets/images/man-smelling-coffee.png")
      no-repeat;
    z-index: 2;
    background-size: 100%;
  }

  &__title {
    margin-bottom: rem(59);
    font-size: rem(36);
    font-family: $cairo;
    font-weight: 400;
    letter-spacing: 0.6rem;
    line-height: em(36px, 36px);
    margin-top: rem(36);
  }

  &__image-container {
    display: none;
  }
}

@include tablet {
  .PeaberryRecommendations {
    &::after {
      background-size: 80%;
    }
    &::before {
      right: 20px;
    }

    &::after {
      right: 25px;
    }

    &__image-container {
      display: none;
    }
  }
}

@include mobile {
  .PeaberryRecommendations {
    padding-top: 0;
    margin-top: 39px;

    &__main-title {
      top: 0px;
    }

    &__bg {
      display: none;
    }

    &__bg-mb {
      display: block;
      width: 100%;
      object-fit: contain;
    }

    &__middle-line {
      margin-top: 35px;
    }

    &__title {
      text-align: center;
      margin-top: 22px;
      margin-bottom: 33px;
      font-size: 16px;
      font-family: $cairo;
      font-weight: 600;
      letter-spacing: 0.3rem;
      line-height: em(24px, 20px);
      width: 230px;
    }

    &::before {
      background: url("https://assets.gocoffeego.com/v2/assets/images/peaberry-blue.svg")
        no-repeat;
      width: 94px;
      height: 208px;
      background-size: 100%;
      right: 0;
      top: -20px;
      z-index: 1;
    }

    &::after {
      display: none;
    }

    &__image-container {
      margin-top: rem(26);
      display: block;
      position: relative;
      width: 100%;
    }

    &__spiral {
      position: absolute;
      left: 0px;
      top: 0;
      object-fit: contain;
      z-index: 2;
      width: 58%;
    }

    &__man {
      position: absolute;
      right: 0;
      top: 20px;
      width: 50%;
      object-fit: contain;
    }
  }
}
