@import "./src/styles/lib";

.CorporateGift {
  width: 100%;
  height: 100%;
  margin-top: rem(240);
  font-family: $cairo;
  position: relative;
  padding-bottom: rem(260);
  //padding-top: rem(90);
  &::after {
    content: "";
    position: absolute;
    background: url("https://assets.gocoffeego.com/v2/assets/images/lady.png")
      no-repeat center center;
    background-size: 100%;
    transform: scaleX(-1);
    width: rem(318);
    height: rem(338);
    top: rem(42);
    right: rem(420);
    z-index: 2;
  }
  &::before {
    content: "";
    position: absolute;
    background: url("https://assets.gocoffeego.com/v2/assets/images/cup-of-coffee.png")
      no-repeat center center;
    background-size: 100%;
    width: rem(386);
    height: rem(386);
    bottom: rem(5);
    left: rem(-90);
    z-index: 5;
  }

  &__container {
    margin: rem(160) rem(130) 0;
    outline: rem(3) solid $black;
    padding: rem(20) 0;
    min-height: rem(455);
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    position: relative;
    background-color: $white;
    z-index: 3;
    &::before {
      content: "";
      position: absolute;
      width: rem(334);
      height: rem(334);
      border-radius: 50%;
      background-color: #ff8900;
      opacity: 0.7;
      top: rem(-240);
      right: rem(-150);
      z-index: 4;
    }
    &::after {
      content: "";
      position: absolute;
      background: url("https://assets.gocoffeego.com/v2/assets/images/people.png")
        no-repeat bottom right;
      background-size: 130%;
      border-radius: 50%;
      width: rem(443);
      height: rem(443);
      bottom: rem(-200);
      right: rem(-55);
      z-index: 0;
    }
  }

  &__text {
    padding-left: rem(50);
    padding-right: rem(10);
    padding-bottom: rem(40);
    font-size: rem(30);
    line-height: rem(60);
    font-weight: 600;
    position: relative;
    text-align: center;
    &::after {
      content: "";
      position: absolute;
      background: url("https://assets.gocoffeego.com/v2/assets/images/four-flowers.png")
        no-repeat;
      background-size: contain;
      width: rem(250);
      height: rem(220);
      top: rem(-200);
      left: rem(-55);
      z-index: -1;
    }
  }

  &__border {
    width: 0.2px;
    height: 100%;
    background-color: #e6e6e6;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      background: url("https://assets.gocoffeego.com/v2/assets/images/dog.png")
        no-repeat center center;
      background-size: contain;
      width: rem(150);
      height: rem(132);
      bottom: rem(-10);
      left: rem(-149);
      z-index: -1;
    }
  }

  &__options {
    display: flex;
    justify-self: center;
    font-size: rem(30);
    line-height: rem(45);
    font-weight: 600;
    z-index: 4;
  }
}

@include desktop {
  .CorporateGift {
    padding-bottom: rem(300);
    &::before {
      bottom: 0;
      left: rem(-90);
    }
  }
}

@include laptop {
  .CorporateGift {
    &::before {
      bottom: rem(-60);
      left: rem(-80);
    }
  }
}

@include mobile {
  .CorporateGift {
    margin-top: 50px;
    &::before {
      display: none;
    }
    &::after {
      right: 0;
    }

    &__container {
      display: grid;
      grid-template-columns: 1fr;
      justify-content: center;
      margin-left: 5%;
      margin-right: 5%;
      &::before {
        display: none;
      }
      &::after {
        width: 160px;
        height: 160px;
        bottom: rem(-200);
        right: rem(-20);
      }
    }

    &__border {
      display: none;
    }

    &__text {
      border-bottom: 0.2px solid #e6e6e6;
      padding: 20px;
      display: flex;
      justify-content: center;
    }

    &__options {
      padding: 20px;
    }
  }
}
