@import "../../../styles/lib";

.GiftIntro {
  font-family: $cairo;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  height: rem(607);

  &__line1 {
    position: absolute;
    background: $black;
    width: 100%;
    height: rem(20);
    top: 49.5%;
  }

  &__line2 {
    position: absolute;
    background: $black;
    width: 100%;
    height: rem(20);
    top: 54%;
  }

  &__coffee-img {
    position: absolute;
    left: 0;
    top: rem(220);
    z-index: 2;
    border-radius: 50%;
    width: rem(446);
    height: rem(446);
  }

  &__title-wrap {
    width: rem(660);
    height: rem(253);
    background-color: $black;
    display: flex;
    align-items: center;
    margin-top: rem(86);
    z-index: 2;
  }

  &__title {
    text-align: center;
    color: $white;
    text-transform: uppercase;
    line-height: rem(60);
    font-size: rem(60);
    letter-spacing: 0.025rem;
    font-weight: bold;
    margin: 0;
    padding: 0 5rem;
  }

  &__text {
    font-weight: 600;
    font-size: rem(24);
    line-height: rem(36);
    letter-spacing: 0.01em;
    margin-top: rem(25);
  }

  &__gift-img {
    position: absolute;
    right: rem(390);
    top: rem(66);
    width: rem(310);
    height: rem(309);
    z-index: 1;
    border-radius: 50%;
  }
}

@include desktop {
  .GiftIntro {
    &__coffee-img {
      width: rem(375);
      height: rem(375);
      left: 0;
    }

    &__gift-img {
      right: 16rem;
    }
  }
}

@include tablet-big {
  .GiftIntro {
    &__coffee-img {
      width: rem(430.8);
      height: rem(430.8);
      left: 0;
      top: rem(210);
    }

    &__gift-img {
      right: rem(108);
    }
  }
}

@include tablet {
  .GiftIntro {
    &__title {
      width: rem(766.7);
      font-size: rem(77);
    }
    &__coffee-img {
      width: rem(250);
      height: rem(250);
      left: 0;
      top: rem(220);
    }

    &__gift-img {
      right: rem(-66);
      top: rem(84);
      width: rem(320);
      height: rem(320);
    }
  }
}

@include mobile {
  .GiftIntro {
    height: auto;
    &__line1 {
      height: 8.7px;
      top: 18.5%;
    }

    &__line2 {
      height: 8.7px;
      top: 23.5%;
    }

    &__gift-img,
    &__coffee-img {
      display: none;
    }

    &__title-wrap {
      margin-top: 0;
      width: 264px;
      height: 96px;
    }

    &__title {
      font-size: 30px;
      letter-spacing: 0.75px;
      line-height: 30px;
      margin-top: 0;
      width: 100%;
      padding: 0;
    }

    &__text {
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      width: 263px;
      height: 65px;
      line-height: 24px;
      margin-top: 31px;
      margin-bottom: 23px;
    }
  }
}
