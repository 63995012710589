@import "src/styles/lib";

.Footer {
  background: $black;
  width: 100%;

  &__main-container {
    width: 100%;
    height: 100%;
    padding: rem(100) 10% rem(100);
  }

  &__first-row {
    display: grid;
    grid-template-columns: repeat(4, auto);

    .Logo {
      &__image {
        width: rem(278);
        height: rem(173);
        object-fit: contain;
      }

      &__copyright {
        font-size: 16px;
        color: $white;
        width: rem(233);
        font-weight: 700;
        line-height: em(22px, 16px);
        margin-top: rem(28);
      }
    }

    .SubscribeFooter {
      &__title {
        margin-top: rem(44);
        color: $white;
        font-size: rem(24);
        font-weight: 600;
        margin-left: rem(8);
        margin-bottom: rem(26);
      }

      &__success-subscription {
        color: white;
        padding-top: rem(15);
        font-size: rem(24);
        max-width: rem(450);
      }

      &__error-subscription {
        max-width: rem(450);
        color: white;
        padding-top: rem(15);
        font-size: rem(24);
      }

      &__input-container {
        width: rem(470);
        position: relative;

        &--sign-in {
          &::before {
            content: "SIGN";
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }

      &__input {
        width: rem(470);
        height: rem(60);
        border-radius: rem(20);
        padding-left: rem(20);
        padding-right: rem(45);
        position: relative;
        font-size: 16px;

        &:focus {
          outline: none;
        }
      }

      &__input-icon {
        content: "";
        background: url("https://assets.gocoffeego.com/v2/assets/icons/arrow-right.svg")
          no-repeat center center;
        background-size: 100%;
        width: 15px;
        position: absolute;
        margin-top: auto;
        margin-bottom: auto;
        top: 0;
        bottom: 0;
        right: rem(17);
        cursor: pointer;
      }
    }

    .Info {
      margin-top: rem(44);
      margin-bottom: rem(44);
      display: grid;
      grid-template-columns: 1fr 1fr;

      &__text {
        margin: 0;
        font-size: 16px;
        font-weight: 700;
        color: $white;
        line-height: em(56px, 26px);
        transition: all 200ms ease-in-out;
        &:hover {
          color: $yellow;
        }
      }
    }

    .Social {
      margin-top: rem(44);
      display: flex;
      align-items: flex-start;

      &__link {
        cursor: pointer;
        margin: 0;
      }
      &__link-image {
        width: rem(40);
        height: rem(40);
        margin-right: 10px;
        object-fit: contain;
      }
    }
  }

  .isDisabled {
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
  }

  .Companies {
    width: 100%;
    &__title {
      font-size: rem(36);
      font-weight: 700;
      color: $yellow;
      margin-top: rem(4);
      margin-bottom: rem(30);
      text-align: center;
    }

    &__items {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: rem(63);
      align-items: center;
    }

    &__item {
      margin-right: rem(50);
      margin-left: rem(50);
      margin-bottom: rem(62);
    }

    &__image {
      width: 270px;
      height: 70px;
      object-fit: contain;
    }

    &__line {
      background: $yellow;
      width: 100%;
      height: rem(4);
    }
  }
}

@include tablet {
  .Footer {
    &__first-row {
      display: grid;
      grid-template-columns: repeat(2, auto);
    }
  }
}

@include mobile {
  .Footer {
    padding-left: 45px;
    padding-right: 45px;

    &__first-row {
      display: grid;
      grid-template-columns: 1fr;

      .SubscribeFooter {
        &__input-container {
          width: 100%;
        }

        &__input {
          width: 100%;
          height: 30px;
        }
        &__title {
          font-size: 16px;
        }
      }

      .Logo {
        &__copyright {
          font-size: 12px;
        }

        &__image {
          margin-left: -50px;
        }
      }

      .Social {
        &__link-image {
          width: 25px;
          height: 25px;
          margin-right: 10px;
          object-fit: contain;
        }
      }
    }

    .Info {
      &__text {
        font-size: 16px;
        line-height: 2.2;
      }
    }

    .Companies {
      margin-top: 39px;
      &__items {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 6rem;
      }
      &__image {
        max-width: 190px;
        max-height: 70px;
      }

      &__line {
        position: absolute;
        left: 0;
      }
      &__item {
        margin: 0;
        margin-bottom: 33px;
      }
    }
  }
}
