@import "src/styles/lib";

.SignUpForm {
  border: 1px solid black;
  padding-top: rem(52);
  padding-bottom: rem(52);
  padding-left: rem(91);
  padding-right: rem(91);
  background: white;
  margin-top: rem(135);
  width: rem(804);
  position: relative;
  z-index: 10;

  &::after {
    content: "";
    position: absolute;
    bottom: rem(136);
    right: rem(-271);
    height: rem(313);
    width: rem(313);
    background-size: contain;
    background-image: url("https://assets.gocoffeego.com/v2/assets/images/signup-icon.png");
  }

  &__link-container {
    text-align: start;
    font-size: rem(28);
    font-weight: 400;
    margin-top: rem(13);
    display: flex;
    //justify-content: space-between;
  }

  &__label2 {
    font-size: rem(28);
    font-weight: 400;
    margin-left: rem(10);
  }

  &__link {
    font-size: rem(28);
    font-weight: 500;
    color: #0d89aa;
    text-align: end;
    margin-left: 5px;
    &:hover {
      color: #0d89aa;
    }
  }

  &__input-container {
    margin-bottom: rem(25);
    position: relative;
  }

  &__required {
    font-size: rem(22);
    color: red;
    margin-top: rem(10);
    height: 100%;
    width: 100%;

    &--password {
      font-size: rem(18);
    }
  }

  &__input {
    color: black !important;
    width: 100%;
    border-radius: 0 !important;
    font-size: rem(25) !important;
    border: 0 !important;
    border-bottom: 1px solid #000000 !important;
    padding-left: 0 !important;

    &::placeholder {
      color: black !important;
    }
  }

  &__row {
    display: flex;
  }

  &__button {
    color: #ffffff;
    margin-top: rem(133);
    height: rem(60);
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    background-color: $blue;
    border: none;
    width: 100%;
    font-size: rem(32);
    font-weight: 500;
    font-family: $rubik;
    cursor: pointer;
    outline: none;
    transition: all 300 ease-in-out;
    &:hover {
      background: darken($blue, 1%);
    }
  }
}

@include mobile {
  .SignUpForm {
    border: none;

    &__input {
      font-size: 16px !important;
    }

    &::after {
      display: none;
    }

    &__button {
      height: 46px !important;
    }
  }
}
