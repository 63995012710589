@import "src/styles/lib";

.PaymentMethod {
  &__container {
    position: relative;
    width: 100%;
  }

  &__message {
    font-weight: 700;
    margin-top: rem(20);
    margin-bottom: rem(20);
    font-size: rem(24);
  }
  &__message2 {
    font-weight: 500;
    margin-top: rem(10);
    margin-bottom: rem(20);
    font-size: rem(22);
  }

  &__shape {
    opacity: 0.7;
    height: 100%;
    width: 100%;
    position: absolute;
    background: #fefefe;
    z-index: 10 !important;
    transition: all 0.3s ease;
  }

  &__shape-title {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    height: fit-content;
    z-index: 11 !important;
    max-width: rem(600);
    font-size: rem(28);
    font-weight: 600;
    line-height: em(29px, 24px);
  }

  &__paypal-title {
    padding-top: rem(4);
    font-size: rem(25);
    font-weight: 700;
    height: fit-content;
    margin-bottom: rem(30);
    border-bottom: 2px solid #c7c7c7;
  }

  &__paypal {
    padding-top: rem(20);
    margin-top: rem(50);
  }

  &__paypal-overlay {
      position: relative;
      margin-bottom: -8px;
      &::after {
          content: 'Please select a shipping address';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background:rgba(255,255,255, 0.5);
          z-index: 1000;
          color: black;
          font-size: rem(36);
          font-weight: 500;
          text-align: center;
          padding-top: rem(40);
      }
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: rem(70);

    &--autoship {
      grid-template-columns: 1fr;
    }
  }

  &__or-2 {
    font-size: rem(24) !important;
  }

  &__title {
    padding-top: rem(4);
    font-size: rem(25);
    font-weight: 700;
    height: fit-content;
    margin-bottom: rem(8);
  }

  &__quote-container {
    margin-top: rem(50);
    padding: rem(20);
    border: 1px solid #000000;
   // background: #b8ea70;
  }
  &__quote {
    font-size: rem(32);
    font-family: $cairo;
    font-weight: 500;
  }

  &__quote-author {
    text-align: right;
    font-size: rem(26);
    font-style: italic;
    font-weight: 600;
  }

  .DefaultAddress {
    margin-top: rem(35);
    &__title {
      font-size: rem(30);
    }

    &__expiriation {
      font-size: rem(30);
      margin-top: rem(35);

      &--date {
        margin-top: 0;
        padding-bottom: rem(20);
      }
    }
  }

  &__remember {
    margin-top: rem(15);
  }
}

@include mobile {
  .PaymentMethod {
    margin-top: 20px;
    &__title {
      font-size: 17px;
    }

    &__content {
      grid-row-gap: 20px;
    }
    .DefaultAddress {
      &__title {
        font-size: 15px;
      }

      &__expiriation {
        font-size: 15px;
      }
    }
  }
}
