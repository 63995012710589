@import "./src/styles/lib";

.FAQ {
  padding-top: rem(280);
  padding-bottom: rem(240);
  font-family: $cairo;
  color: $black;
  overflow: hidden;
  position: relative;
  height: 100%;
  &::after {
    position: absolute;
    content: "";
    width: rem(340);
    height: rem(340);
    background-color: $red;
    border-radius: 50%;
    bottom: rem(600);
    left: rem(-170);
    z-index: 3;
  }
  &__seo-description {
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    max-width: rem(1200);
    padding: 0 10px;
  }
  &__seo-title {
    max-width: rem(1200);
    text-align: center;
    font-size: rem(36);
    margin: rem(180) auto rem(30);
    font-weight: 700;
    padding: 0 10px;
  }
  &__container {
    position: relative;
    padding-bottom: rem(100);
    padding-top: rem(60);
    &::after {
      position: absolute;
      content: "";
      background: url("https://assets.gocoffeego.com/v2/assets/images/faq-bg.png")
        no-repeat center center;
      background-size: 100% 99%;
      width: 100%;
      height: rem(2450);
      bottom: 0;
      top: 30px;
      right: 0;
      left: 0;
      z-index: -1;
    }
  }

  &__circle {
    position: absolute;
    width: rem(633);
    height: rem(633);
    background-color: #6ae200;
    border-radius: 50%;
    bottom: rem(-470);
    right: rem(700);
    z-index: -1;
  }

  &__questions-wrap {
    position: relative;
  }

  &__title {
    &::before {
      position: absolute;
      content: "";
      background: url("https://assets.gocoffeego.com/v2/assets/images/girl-at-the-window.png")
        no-repeat center center;
      background-size: 100%;
      width: rem(248);
      height: rem(248);
      bottom: rem(-80);
      right: rem(-160);
      z-index: 4;
    }
  }

  &__main-wrapper {
    position: relative;
  }

  &__subtitle-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    &::before {
      position: absolute;
      content: "";
      width: rem(340);
      height: rem(340);
      background-color: #ff008e;
      border-radius: 50%;
      top: rem(1350);
      right: rem(-40);
      z-index: 0;
      opacity: 0.8;
    }
  }

  &__subtitle {
    margin-top: rem(60);
    margin-bottom: rem(110);
    font-size: rem(36);
    font-weight: 600;
    line-height: rem(60);
    text-transform: uppercase;
  }

  &__questions-container {
    position: relative;
    margin: 0 rem(130);
    transition: all 200ms ease-in-out;
    cursor: pointer;
    width: rem(1200);
    margin: 0 auto;
    &::after {
      position: absolute;
      content: "";
      background: url("https://assets.gocoffeego.com/v2/assets/images/peaberry-faq.png")
        no-repeat center center;
      background-size: 100%;
      background-color: #ffffff;
      border-radius: 50%;
      width: rem(254);
      height: rem(254);
      top: rem(-220);
      left: rem(-120);
      z-index: 4;
    }
    &::before {
      position: absolute;
      content: "";
      background: url("https://assets.gocoffeego.com/v2/assets/images/faq-flowers.png")
        no-repeat center center;
      background-size: 100%;
      width: rem(417);
      height: rem(203);
      top: rem(-150);
      right: rem(-130);
      z-index: -1;
    }
  }

  &__question-wrap {
    border: rem(3) solid $black;
    background-color: $white;
    margin-bottom: rem(24);
    position: relative;
  }

  &__question {
    padding: rem(24) rem(80) rem(24) rem(80);
    font-size: rem(34);
    line-height: rem(60);
    font-weight: 600;
    transition: all 300ms ease-in-out;
  }

  &__line1,
  &__line2 {
    position: absolute;
    width: 16px;
    height: 2px;
    background-color: $black;
    top: rem(52);
    left: rem(40);
    transition: all 300ms ease-in-out;
  }

  &__line1 {
    transform: rotate(90deg);
  }

  &__answers {
    font-size: rem(24);
    font-weight: 300;
    line-height: rem(60);
    padding: 0 rem(80) rem(30);
  }

  .active {
    color: $blue;
    .FAQ__line1 {
      transform: rotate(45deg);
      background-color: $blue;
    }
    .FAQ__line2 {
      transform: rotate(-45deg);
      background-color: $blue;
    }
  }
}

@include mobile {
  .FAQ {
    padding-top: 50px;
    &::after {
      left: -100px;
      z-index: -1;
    }
    &__subtitle-wrap {
      z-index: 5;
    }
    &__subtitle {
      margin-top: 15px;
      z-index: 5;
    }

    &__container {
      position: relative;
    }

    &__circle {
      right: 50px;
    }

    &__questions-container {
      margin: 0 auto;
      width: 340px;
      &::after {
        top: -105px;
        left: -60px;
      }
      &::before {
        top: -50px;
        right: -60px;
      }
    }

    &__question {
      padding-left: 40px;
      padding-right: 40px;
    }

    &__line1,
    &__line2 {
      left: rem(35);
      width: 12px;
      height: 2px;
    }

    &__subtitle-wrap {
      &::after {
        bottom: -240px;
        right: 8%;
        z-index: -1;
      }
    }
  }
}
