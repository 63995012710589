@import "../../../../styles/lib";

.RecipesItem {
  width: fit-content;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__image {
    width: rem(369);
    height: rem(369);
    //background: #c2c2c2;
    object-fit: cover;
  }

  &__title {
    margin-top: rem(49);
    margin-bottom: rem(53);
    font-size: rem(30);
    font-weight: 700;
    line-height: em(36px, 30px);
    width: rem(370);
    min-height: rem(70);
    text-transform: uppercase;
    flex-grow: 1;
  }

  &__description {
    margin: 0;
    width: rem(256);
    font-size: rem(24);
    font-weight: 400;
    line-height: em(36px, 24px);
  }
}

@include mobile {
  .RecipesItem {
    &__image {
      object-fit: cover;
      width: 130px;
      height: 130px;
    }

    &__title {
      max-width: 130px;
      font-size: 15px;
      text-align: center;
    }

    &__description {
      display: none;
    }
  }
}
