@import "src/styles/lib";

.ProductStory {
  height: 100%;
  padding-top: rem(65);
  padding-bottom: rem(95);
  width: 100%;

  &__image-container {
    position: relative;
    width: rem(758);
    height: rem(789);

    &::before {
      //content: " ";
      width: rem(278);
      height: rem(278);
      border-radius: 50%;
      background: #ff0006;
      position: absolute;
      top: rem(332);
      left: -10px;
    }
  }
  &__review-badge {
    position: absolute;
    width: rem(130);
    height: rem(130);
    left: rem(450);
    top: rem(-40);
    z-index: 2;
  }

  &__image {
    max-width: rem(800);
    margin-top: rem(150);
    object-fit: contain;
    height: rem(750);
    width: rem(750);
    position: relative;
    margin-left: -4rem;
  }

  &__coffee-review {
    position: absolute;
    top: 0;
    right: 12%;
    z-index: 10;
  }

  &__sale {
    width: 60px;
    height: 60px;
    position: absolute;
    right: 70px;
    top: 70px;
    line-height: 1.1;
    border-radius: 50%;
    font-weight: 600;
    z-index: 2;
    background-color: $red;
    color: $white;
  }

  &__sale-wrap {
    font-size: 16px;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
  }

  &__sale-procent {
    font-size: 18px;
  }
}

@include mobile {
  .ProductStory {
    padding-top: 32px;
    padding-bottom: 46px;
    display: flex;
    justify-items: center;
    justify-content: center;

    &__image-container {
      position: relative;
      width: 172px;
      height: 295px;
      &::before {
        //content: " ";
        width: 105px;
        height: 105px;
        top: 126px;
        left: -56px;
      }
    }
    &__review-badge {
      width: 50px;
      height: 50px;
      position: relative;
      top: 90px;
      left: 150px;
    }

    &__coffee-review {
      top: 20px;
      right: 2%;
      width: 70px;
      height: 70px;
      object-fit: contain;
    }

    &__sale {
      right: 0;
      top: 0;
    }

    &__image {
      width: 172px;
      height: 293px;
      margin: 0;
    }

    &__container {
      display: none;
    }
  }
}
