@import "src/styles/lib";

.Story {
  margin-top: rem(125);
  &__title {
    font-size: rem(30);
    font-weight: 700;
    margin: 0;
    position: relative;
  }

  &__title-shape {
    width: rem(67);
    height: rem(67);
    position: absolute;
    top: rem(-42);
    left: rem(-55);
  }

  &__pick_banner {
    margin-top: rem(20);
    max-width: rem(550);
    width: fit-content;
    display: block;
  }

  &__peaberry-recommends_banner {
    max-width: rem(400);
    width: fit-content;
    margin-top: rem(15);
  }

  &__description {
    text-align: justify;
    font-size: rem(24);
    font-weight: 400;
    line-height: em(34px, 24px);
    max-width: rem(550);
    margin-top: rem(20);
    margin-bottom: 0;
  }

  &__title2 {
    border-top: 2px solid $black;
    font-size: rem(24);
    font-weight: 700;
    margin-top: rem(27);
    margin-bottom: rem(32);
    max-width: rem(470);
    padding-top: 22px;
  }

  &__title3 {
    max-width: rem(550);
    font-size: rem(24);
    font-weight: 400;
    margin-bottom: rem(12);
  }

  &__line {
    display: none;
  }
}

@include mobile {
  .Story {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;

    &__title {
      display: none;
    }

    &__extraction {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 10px;
    }

    &__shape {
      display: none;
    }

    &__description {
      margin-top: rem(10);
      font-size: 16px;
    }

    &__title2 {
      text-align: center;
      font-size: 16px;
      max-width: 186px;
      margin-bottom: 11px;
      padding-top: 0;
      border-top: none;
    }

    &__line {
      display: block;
      border-bottom: 2px solid $black;
      width: 100%;
      margin-bottom: 14px;
    }

    &__title3 {
      font-size: 16px;
    }
  }
}
