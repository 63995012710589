@import "src/styles/lib";

.ShipmentCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__image {
    height: rem(255);
    width: rem(232);
    object-fit: contain;
    cursor: pointer;
  }

  &__button {
    margin-top: rem(60);
    color: black;
  }
}

@include mobile {
  .ShipmentCard {
    margin-bottom: 20px;
    &__image {
      width: 270px;
      height: 270px;
      object-fit: contain;
    }
  }
}
