@import "src/styles/lib";

.QuizQuestion3 {
  margin-top: rem(48);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: rem(107);
  width: 100%;
  position: relative;
  padding-top: rem(20);

  &::before {
    content: "";
    position: absolute;
    left: rem(48);
    top: rem(298);
    background: url("https://assets.gocoffeego.com/v2/assets/images/girl-quiz.png")
      no-repeat;
    width: 27%;
    height: 27%;
    background-size: contain;
  }

  &__answer {
    &--active {
      background: #ffec00;
    }
  }

  &::after {
    content: "";
    position: absolute;
    right: rem(-133);
    top: rem(546);
    border-radius: 50%;
    background: #d449b9;
    width: rem(507);
    height: rem(507);
    background-size: contain;
  }

  &__main-image {
    width: rem(298);
    height: rem(298);
    object-fit: contain;
    margin-bottom: rem(44);
  }

  &__answers {
    margin-top: rem(87);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  &__answer {
    cursor: pointer;
  }

  &__answer-title {
    font-weight: 700;
    font-size: rem(28);
    letter-spacing: 0.01em;
    text-transform: uppercase;

    &--active {
      color: $blue;
    }
  }

  &__description {
    margin-top: rem(19);
    font-weight: 400;
    font-size: rem(24);
    letter-spacing: 0.08em;
    line-height: em(24px, 17px);
    max-width: rem(241);

    &--active {
      color: $blue;
    }
  }

  &__lines {
    width: 100%;
    position: relative;
  }
}

@include mobile {
  .QuizQuestion3 {
    &::before {
      display: none;
    }

    &::after {
      display: none;
    }

    &__answers {
      grid-template-columns: repeat(2, 1fr);
    }

    &__answer-title {
      font-size: 14px;
      text-align: center;
    }

    &__description {
      text-align: center;
      font-size: 14px;
      max-width: 200px;
    }

    &__lines {
    }
  }
}

@include tablet {
  .QuizQuestion3 {
    &::after {
      width: rem(407);
      height: rem(407);
    }
    &::before {
      position: absolute;
      left: 0;
    }
  }
}
