@import "src/styles/lib";

.Regions {
  padding-top: rem(100);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &__main-image {
    width: 100%;
    margin-bottom: 20px;
  }

  &__title {
    font-size: rem(30);
    font-weight: 700;
    position: relative;
    margin-top: rem(40);
    text-align: center;
    margin-bottom: rem(10);
  }

  &__title1 {
    font-size: rem(45);
    font-weight: 700;
    position: relative;
    margin-top: rem(40);
    text-align: center;
    margin-bottom: 0;
  }

  &__title2 {
    font-size: rem(30);
    font-weight: 700;
    position: relative;
    text-align: center;
    margin-bottom: rem(50);
    margin-top: 0;
  }

  &__shape {
    width: rem(67);
    height: rem(67);
    position: absolute;
    bottom: rem(46);
    left: rem(-54);
  }

  &__description {
    min-width: rem(1169);
    margin: 0;
    font-size: rem(24);
    font-weight: 400;
    position: relative;
    margin-bottom: rem(30);
    line-height: em(35px, 24px);
  }
}
.RegionsItems {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: rem(50);

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-right: 3px solid #000000;
    padding-top: rem(18);
    padding-bottom: rem(18);
    padding-right: rem(28);
    padding-left: rem(28);
    &:hover {
      cursor: pointer;
      color: $blue;
    }
    &:nth-last-child(1) {
      border-right: none;
    }
  }
  &__item-title {
    font-size: rem(24);
    font-weight: 700;
    &--active {
      color: $blue;
    }
  }

  &__content {
    p {
      font-size: rem(24);
      padding-bottom: rem(20);
    }
    h1 {
      margin-top: rem(30);
      margin-bottom: rem(10);
      font-weight: bold;
      font-size: rem(32);
    }
    h3 {
      margin-top: rem(30);
      margin-bottom: rem(10);
      font-weight: bold;
      font-size: rem(28);
    }
    .BottomLinks {
      &:hover {
        color: $blue;
      }
    }
  }
}

@include mobile {
  .Regions {
    &__peaberry {
      display: none;
    }

    &__flowers {
      display: none;
    }

    &__content {
      padding: 0;
    }

    &__peaberry-sitting {
      display: none;
    }

    &__description {
      font-size: 16px;
      min-width: auto;
    }

    &__line {
      display: none;
    }
  }

  .RegionsItems {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0;
    width: 100%;

    &__item {
      border-right: none;
      border-bottom: 2px solid $black;
      margin-left: 50px;
      margin-right: 50px;
    }
    &__item-title {
      font-size: 12px;
    }

    &__content {
      p {
        font-size: 16px;
      }
    }
  }
}
