@import "../../../styles/lib";

.Filter {
  font-family: $cairo;
  font-size: rem(16);
  line-height: 1.5;
  width: rem(324);
  background: white;
  &__fiter-category-link {
    cursor: pointer;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: $black;
    font-weight: 700;
    font-size: rem(20);
    line-height: rem(24);
    padding: 1.5rem 0;
    border-top: 1px solid #000000;
  }

  .SelectedFilter {
    display: flex;
    flex-direction: column;
    margin-bottom: rem(20);
    &__item {
      position: relative;
      //margin-right: rem(15);
      //border: 1px solid black;
      //box-shadow: 0 4px 10px -4px #aaa;
      padding: rem(10);
      padding-left: 0;
      display: inline-block;
      font-size: rem(22);
      cursor: pointer;
      margin-bottom: rem(10);
      color: $blue;
      font-weight: 600;
      transition: opacity 0.5s;
      opacity: 1;
    }

    .fadeout {
    //  animation: removed-item-animation 0.8s
    //    cubic-bezier(0.65, -0.02, 0.72, 0.29);
     // opacity: 0;
    }

    &__icon {
      position: absolute;
      top: 0;
      bottom: 0;
      margin-bottom: auto;
      margin-top: auto;
      right: rem(10);
    }
  }
  &__container-button {
    background-color: transparent;
    position: relative;
    display: flex;
    align-items: center;
    border: none;
    border-top: rem(2) solid $black;
    padding: rem(24) 0;
    transition: all 200ms ease-in-out;
    cursor: pointer;
    width: 100%;
    letter-spacing: 0.2em;
    font-weight: 700;
    font-size: rem(20);

    &:hover {
      color: #00d1d6;
    }
  }

  &__title {
    font-size: rem(24);
    color: #00d1d6;
    font-weight: 600;
    letter-spacing: 0.15em;
    line-height: rem(24);
    text-transform: uppercase;
    margin: 0;
    padding-bottom: rem(25.6);
  }

  &__clear-btn {
    background-color: transparent;
    border: none;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: $lightGray;
    font-weight: bold;
    font-size: rem(22);
    padding-left: 0;
    margin-bottom: rem(32);
    cursor: pointer;
    outline: none;
  }

  &__image {
    display: block;
    width: rem(540);
    height: rem(720);
    margin-left: rem(-130);
    margin-top: rem(80);
  }
}

@include mobile {
  .Filter {
    margin-left: 56px;
    margin-right: 56px;
    padding-bottom: 10px;
    flex: 100%;
    width: 302px;
    position: relative;
    margin-top: rem(100) !important;
    &__fiter-category-link {
      font-size: 14px;
      line-height: 1.5;
    }

    .SelectedFilter {
      &__item {
        font-size: 15px;
      }
    }

    &__line1,
    &__line2 {
      position: absolute;
      width: 20px;
      height: 1px;
      background-color: $black;
      top: 10px;
      right: 0;
      transition: all 200ms ease-in-out;
    }

    &__clear-btn {
      margin-bottom: 0;
      font-size: 16px;
    }

    &__line1 {
      transform: rotate(90deg);
    }

    &__title {
      font-size: 18px;
      letter-spacing: 3.6px;
      line-height: 24px;
    }

    &__image {
      display: none;
    }

    .active {
      .Filter__line1 {
        transform: rotate(45deg);
      }
      .Filter__line2 {
        transform: rotate(-45deg);
      }
    }

    &__container {
      display: none;
      opacity: 0;

      &.active {
        display: block;
        width: 100%;
        opacity: 1;
        background: $white;
        z-index: 5;
        margin-left: 0;
        overflow-y: auto;
        overflow-x: hidden;
        transition: all 0.3s ease-in-out;
      }
    }
    &__container-button {
      font-size: 14px;
    }
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes removed-item-animation {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  30% {
    opacity: 1;
    transform: translateX(50px);
  }

  80% {
    opacity: 1;
    transform: translateX(-800px);
  }

  100% {
    opacity: 0;
    transform: translateX(-800px);
  }
}
