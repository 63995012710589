@import "src/styles/lib";

.QuizLines {
  width: 100%;
  margin-top: rem(200);

  &__red {
    height: rem(14);
    background: #ed3228;
    margin-bottom: rem(18);
    z-index: 1;
  }

  &__blue {
    height: rem(14);
    background: #1568b3;
  }
}

@include mobile {
  .QuizLines {
    margin-top: 40px;
  }
}
