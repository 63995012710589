@import "src/styles/lib";

.SelectItem {
  position: relative;

  &__item {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    &--none {
      padding-left: rem(8);
      cursor: pointer;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    padding: rem(11) rem(10);
    height: rem(29);
    position: relative;
    cursor: pointer;
    &::after {
      position: absolute;
      content: "";
      top: rem(12);
      right: rem(6);
      width: 0;
      height: 0;
      border: rem(8) solid transparent;
      border-color: #707070 transparent transparent transparent;
    }
  }

  &__header {
    font-size: 16px;
    font-weight: 400;
    color: black;
    line-height: 1.2;

    &--active {
      font-weight: 700;
    }
  }

  &__icon {
    position: absolute;
    right: rem(-8);
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    cursor: pointer;
  }

  &__items {
    width: 100%;
    position: absolute;
    z-index: 10;
    background: #ffffff;
    display: none;
    border: 2px solid #ccd9e2;
    margin-top: rem(10);
    padding: 10px;
    top: 30px;

    &.active {
      display: block;
      min-height: rem(100);
      max-height: rem(300);
      overflow-y: scroll;
    }
  }

  &__item-container {
    padding: rem(12) rem(4);
    border-radius: 0;
    line-height: rem(21);
    margin-bottom: 2px;

    &.activeLabel {
      background: #f2f5f7;
    }

    &:hover {
      background: #f2f5f7;
      cursor: pointer;
    }
  }
}

@include mobile {

  .SelectItem {
    height: 100%;
    &__container {
      height: 36px;
      &::after {
        position: absolute;
        content: "";
        top: 15px;
        right: 6px;
        width: 0;
        height: 0;
        border: rem(10) solid transparent;
        border-color: #707070 transparent transparent transparent;
      }
    }

    &__header {
      font-size: 16px !important;
    }

    &__item {
      font-size: 16px;
      line-height: em(20px, 16px);
    }
    &__items {
      padding: 2px 4px;
      top: 32px;
    }
    }

}
