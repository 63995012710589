@import "src/styles/lib";

.QuizQuestion5 {
  margin-top: rem(48);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: rem(107);
  width: 100%;
  position: relative;
  padding-top: rem(20);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: rem(90);
    background: url("https://assets.gocoffeego.com/v2/assets/images/man-with-circle.png")
      no-repeat;
    width: rem(398);
    height: rem(547);
    background-size: contain;
  }

  &__answer {
    &--active {
      background: #ffec00;
    }
  }

  &__text {
    font-size: rem(28);
    font-weight: 400;
  }

  &__preferences {
    font-size: rem(28);
    font-weight: 700;
    cursor: pointer;
    padding: rem(29) rem(50) rem(29) rem(50);
   // border-left: 2px solid #4c4c4c;
    margin-bottom: rem(85);

    &--active {
      background: #ffec00;
    }
    cursor: pointer;
  }

  &__main-image {
    width: rem(298);
    height: rem(298);
    object-fit: contain;
    margin-bottom: rem(44);
  }

  &__answers {
    margin-top: rem(87);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-content: center;
  }

  &__answer {
    cursor: pointer;
    padding: rem(29) rem(50) rem(29) rem(50);
    border-right: 2px solid #4c4c4c;
    margin-bottom: rem(85);
     &:nth-child(3) {
      border-right: none;
    } 

    &:nth-child(7) {
      border-right: none;
    }
  }

  &__answer-title {
    font-weight: 700;
    font-size: rem(28);
    letter-spacing: 0.01em;
    text-transform: uppercase;

    &--active {
      color: $blue;
    }
  }

  &__description {
    margin-top: rem(19);
    font-weight: 400;
    font-size: rem(24);
    letter-spacing: 0.08em;
    line-height: em(24px, 17px);
    max-width: rem(241);

    &--active {
      color: $blue;
    }
  }

  &__car {
    position: absolute;
    width: rem(688);
    height: rem(353);
    object-fit: contain;
    right: rem(28);
    bottom: rem(48);
  }

  &__target {
    height: rem(114);
    width: rem(114);
    object-fit: contain;
    position: absolute;
    bottom: rem(-30);
    right: rem(26);
  }

  &__lines {
    width: 100%;
    position: relative;
  }

  &__lines-style {
    margin-top: rem(88);
  }
}

@include tablet-big {
  .QuizQuestion5 {
    height: 40vh;
    &::before {
      left: 0;
      width: rem(350);
    }
    &__car {
      right: 0;
      width: rem(515);
      bottom: rem(0);
    }
    &__answers {
      grid-gap: rem(2);
    }
    &__lines {
      position: relative;
      top: rem(95);
    }
  }
}

@include mobile {
  .QuizQuestion5 {
    &::before {
      display: none;
    }

    &__car {
      display: none;
    }

    &__answer {
      display: flex;
      align-items: center;
      flex-direction: column;
      border-right: none;
      margin-bottom: 10px;
    }

    &__preferences {
      border-left: none;
      text-align: center;
      font-size: 16px;
    }

    &__answers {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      max-width: 980%;
    }

    &__answer-title {
      font-size: 16px;
      text-align: center;
    }

    &__description {
      text-align: center;
      font-size: 15px;
      max-width: 200px;
    }

    &__lines {
    }
  }
}

@include tablet {
  .QuizQuestion5 {
    &::before {
      width: rem(300);
      height: rem(300);
      position: absolute;
      left: 0;
    }
    &__car {
      width: rem(488);
      height: rem(200);
    }
    &__answers {
      margin-bottom: rem(100);
      margin-left: rem(100);
    }
  }
}

@include tablet-big {
  .QuizQuestion5 {
    margin-bottom: 300px;
  }
}

@include desktop {
  .QuizQuestion5 {
    &__car {
      width: rem(788);
      height: rem(200);
      object-fit: contain;
      right: rem(-200);
      bottom: rem(48);
    }

    &::before {
      left: 0;
      width: rem(350);
    }
  }
}
@include desktop-big {
  .QuizQuestion5 {
    &__car {
      height: rem(300);
      right: rem(-50);
    }
  }
}
