@import "../../../styles/lib";

.Button {
  background-color: transparent;
  border: 3px solid #000000;
  width: rem(220);
  height: rem(66);
  font-size: rem(16);
  font-weight: 500;
  font-family: $rubik;
  cursor: pointer;
  outline: none;
  transition: all 200ms ease-in-out;

  &:hover {
    border: 3px solid $blue;
  }

  &:focus {
    border: 3px solid $blue;
  }
}

@include mobile {
  .Button {
    width: fit-content;
    font-size: 14px;
    min-height: 40px;
    height: 46px !important;
    min-width: 130px;
  }
}
