@import "../../../styles/lib";

.HeroBanner {
  padding-top: rem(130);
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &__tm {
    position: absolute;
    font-size: rem(30);
    top: rem(-10);
    right: rem(-20);
  }

  &__button2 {
    margin-top: rem(20);
  }
  &__button, &__button2  {
    font-size: rem(22);
    width: rem(280);
    height: rem(77);
  }

  &__buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: rem(27);
    margin-top: rem(38);
  }

  &__title-container {
    width: rem(426);
    margin-top: rem(-29);
    background: #000000;
    color: white;
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    padding: rem(75);
    margin-bottom: rem(110);
  }

  &__title {
    font-size: rem(60);
    line-height: em(60px, 60px);
    color: white;
    font-family: $cairo;
    font-weight: 700;
    text-align: center;
    position: relative;
  }

  &__main-title {
    font-size: rem(28);
    line-height: 1.5;
    font-family: $cairo;
    font-weight: 700;
    text-align: center;
    position: relative;
  }

  &__text {
    font-size: rem(24);
    font-family: $cairo;
    color: #010101;
    font-weight: 400;
    max-width: rem(769);
    text-align: center;
    margin-top: rem(30);
  }

  &__image {
    z-index: 3;
    width: rem(483);
    height: rem(460);
    object-fit: contain;
  }
}

@include mobile {
  .HeroBanner {
    padding-top: 10px;
    &__main-title {
      font-size: 17px;
      padding: 0 25px;
    }
    
    &__image {
      width: 177px;
      height: 190px;
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 !important;
    }

    &__button {
      width: 203px;
      height: 46px;
      margin-bottom: 7px !important;
      font-size: 14px;

      &:nth-child(1) {
        margin-top: 0;
      }
    }

    &__button2 {
      width: 203px;
      height: 46px;
      font-size: 14px;
    }

    &__title-container {
      width: fit-content;
      z-index: -1;
      padding: 25px 55px 25px 55px;
    }

    &__title {
      font-size: 30px;
      line-height: em(30px, 30px);
      color: white;
      font-family: $cairo;
      font-weight: 700;
      text-align: center;
    }

    &__text {
      font-size: 16px;
      font-weight: 600;
      line-height: em(24px, 16px);
      min-width: 343px;
      margin-top: 21px;
      margin-bottom: 40px;
      padding: 0 15px;
    }
  }
}
