@import "src/styles/lib";

.StartQuiz {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  text-transform: uppercase;
  margin-top: rem(130);
  font-family: $cairo;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: rem(370);
    height: rem(370);
    top: rem(100);
    left: rem(-100);
    background-color: $black;
    border-radius: 50%;
  }

  &__small-img {
    position: absolute;
    width: rem(293);
    height: rem(293);
    object-fit: contain;
    top: rem(515);
    right: rem(150);
    background: url("https://assets.gocoffeego.com/v2/assets/images/bw-girl.png")
      no-repeat center center;
    background-size: 100%;
  }

  &__title {
    margin-bottom: rem(120);
    font-size: rem(44);
    letter-spacing: 0.05em;
  }

  &__img {
    margin-top: rem(80);
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(574);
    height: rem(574);
    position: relative;
    background: url("https://assets.gocoffeego.com/v2/assets/images/takequiz.png")
      no-repeat center center;
    background-size: 100%;
    object-fit: contain;
    margin-bottom: rem(60);
    cursor: pointer;
    transition: all 200ms ease-in-out;
    &::after {
      content: "";
      background-color: $red;
      position: absolute;
      width: rem(370);
      height: rem(370);
      transform: scale(0.6) translate(0, 0);
      border-radius: 50%;
    }
  }

  &__text {
    font-size: rem(36);
    text-transform: uppercase;
    width: rem(213.5);
    // line-height: rem(60);
    line-height: 1;
    letter-spacing: 0.025em;
    font-weight: 600;
    text-align: center;
    z-index: 1;
  }
}

@include mobile {
  .StartQuiz {
    &::before {
      display: none;
    }

    &__small-img {
      display: none;
    }

    &__img {
      width: rem(500);
      height: rem(500);
      margin-top: 0;
    }
  }
}

.StartQuiz__img:hover {
  &::after {
    animation: 2s linear 0s infinite alternate quizButtonAnimation;
  }
}

@keyframes quizButtonAnimation {
  from {
    transform: scale(0.6) translate(0, 0);
  }
  to {
    transform: translate(0, 0) scale(1);
  }
}
