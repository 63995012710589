@import "src/styles/lib";

.SelectBox3 {
  width: rem(123);
  position: relative;

  &__item {
    font-size: rem(16);
    font-weight: 400;
    margin: 0;
  }

  &__container {
    display: flex;
    align-items: center;
    border-radius: 0;
    padding: rem(10);
    height: rem(29);
    position: relative;
    cursor: pointer;

    &::after {
      position: absolute;
      content: "";
      top: rem(18);
      right: rem(4);
      width: 0;
      height: 0;
      border: rem(8) solid transparent;
      border-color: #707070 transparent transparent transparent;
    }
  }

  &__header {
    font-size: rem(18);
    font-weight: 400;
    color: black;
    padding-top: rem(18);
  }

  &__icon {
    position: absolute;
    right: rem(-8);
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    cursor: pointer;
  }

  &__items {
    width: 100%;
    position: absolute;
    z-index: 5;
    background: #ffffff;
    display: none;
    border-radius: 0;
    border: 2px solid #ccd9e2;
    margin-top: rem(20);
    padding: 10px;
    height: rem(300);
    overflow: auto;

    &.active {
      display: block;
    }
  }

  &__item-container {
    padding: rem(8);
    border-radius: 0;
    line-height: rem(21);

    &.activeLabel {
      background: #f2f5f7;
    }

    &:hover {
      background: #f2f5f7;
      cursor: pointer;
    }
  }
}

@include mobile {
  .SelectBox3 {
    &__container {
      &::after {
        border: 5px solid transparent;
        border-color: #707070 transparent transparent transparent;
        top: 9px;
        right: 4px;
      }
    }
    &__item {
      font-size: 14px;
      line-height: em(20px, 14px);
    }

    &__header {
      font-size: 14px;
      padding-top: 6px;
    }
  }
}
