@import "src/styles/lib";

.RoasterArticle {
  padding-bottom: rem(95);

  &__content {
    display: grid;
    grid-template-columns: 35% 65%;
    padding-left: 20%;
  }

  &__title {
    font-size: rem(30);
    font-weight: 700;
    margin: 0;
    position: relative;
    margin-top: rem(144);
    max-width: rem(234);
    line-height: em(36px, 30px);
    text-transform: uppercase;
  }

  &__title-shape {
    width: rem(67);
    height: rem(67);
    position: absolute;
    top: rem(-42);
    left: rem(-55);
  }

  &__image-frame {
    width: 100%;
    background: #c2c2c2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title1 {
    font-size: rem(72);
    color: $white;
    line-height: em(86px, 72px);
    max-width: rem(283);
  }

  &__logo {
    width: rem(238);
    height: rem(237);
    object-fit: contain;
    margin-left: rem(67);
    margin-top: rem(205);
  }

  &__description-container {
    margin-top: rem(71);
  }

  &__description-title {
    padding-right: rem(5);
    font-weight: 700;
  }

  &__description {
    text-align: justify;
    font-size: rem(27);
    line-height: em(30px, 24px);
    font-weight: 400;
    max-width: rem(700);
    margin-bottom: rem(41);
  }

  .AwardsSection {
    max-width: rem(270);
    margin-left: rem(54);
    margin-top: rem(42);

    &__line {
      border-bottom: 2px solid $black;
      max-width: rem(270);
    }

    &__title1 {
      font-size: rem(20);
      text-transform: uppercase;
      font-weight: 700;
      margin-top: rem(28);
    }

    &__title2 {
      font-size: rem(20);
      font-weight: 400;
      line-height: em(24px, 16px);
      margin-bottom: rem(27);
    }
  }

  &__button {
    width: fit-content;
    margin-left: rem(54);
    position: relative;
    padding-left: rem(21);
    padding-right: rem(21);
    font-size: rem(20);
    transition: all 200ms ease-in-out;

    &:hover {
      color: black;
      border: 3px solid $black;
    }

    &:focus {
      color: black;
      border: 3px solid $black;
    }

    &::after {
      content: "";
      width: rem(463);
      height: rem(463);
      border-radius: 50%;
      background: #00d1d6;
      position: absolute;
      right: rem(-278);
      top: rem(-241);
      z-index: -1;
    }
  }
}

@include tablet {
  .RoasterArticle {
    &__description-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  .AwardsSection {
    text-align: center;
  }
}

@include mobile {
  .RoasterArticle {
    display: block;

    &__description {
      font-size: 16px;
    }

    &__title {
      font-size: 16px;
      margin: 0;
      margin-bottom: 20px;
      font-weight: 700;
      margin-top: 50px;
      margin-left: 29px;
    }

    &__title-shape {
      width: 28px;
      height: 28px;
      top: -29px;
      left: -29px;
    }

    &__logo-container {
      display: flex;
      justify-content: center;
    }

    &__logo {
      align-self: center;
      margin-top: 20px;
      margin-left: 0;
    }

    &__content {
      padding: 0;
      grid-template-columns: 1fr;
    }

    &__button {
      display: none;
    }

    .AwardsSection {
      margin: 0;
      max-width: 100%;

      &__line {
        max-width: 100%;
      }

      &__title1 {
        font-size: 16px;
      }

      &__title2 {
        font-size: 16px;
      }
    }
  }
}
