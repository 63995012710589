@import "src/styles/lib";

.EspressoItem {
  display: flex;
  margin-top: rem(50);
  padding: rem(10);
  width: 100%;
  justify-content: space-between;
  align-items: center;

  &.item {
    border-bottom: 2px solid #c2c2c2;
  }

  &:last-child {
    border-bottom: none;
  }

  &.reverse {
    flex-direction: row-reverse;
  }

  &__title {
    font-size: rem(30);
    font-weight: 700;
    position: relative;
    margin: 0;
    color: #ff6600;

    &.reverse {
      text-align: end;
    }

    &.color {
      color: $black;
    }
  }

  &__description {
    &.half-width {
      width: 85%;
    }

    &.reverse {
      width: 100%;
      text-align: end;
      padding-left: rem(100);
    }
    margin: 0;
    font-size: rem(24);
    font-weight: 400;
    position: relative;
    margin-bottom: rem(30);
    line-height: em(35px, 24px);
  }

  &__container {
    display: flex;
    margin-left: rem(20);
  }

  &__image {
    width: rem(300);
    height: rem(300);
    object-fit: contain;
  }
}

@include mobile {
  .EspressoItem {
    display: flex;
    flex-direction: column;

    &.reverse {
      flex-direction: column;
    }

    &__description {
      &.half-width {
        width: 85%;
      }

      &.reverse {
        width: 100%;
        padding-left: 0;
        text-align: start;
      }
    }

    &__title {
      &.reverse {
        text-align: left;
      }
    }
  }
}
