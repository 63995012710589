@import "src/styles/lib";

.NewsletterForm {
    display: flex;
    flex-direction: column;

    &__input-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: rem(5);
      position: relative;
    }

    &__input {
        width: rem(445);
        height: rem(41);
        margin-left: rem(20);
        border: 1px solid #707070 !important;
        border-radius: 0 !important;
        padding: 0 rem(10);
        -webkit-appearance: none;
        &:focus-visible {
            outline: 1px solid !important;
            box-shadow: none !important;
            -webkit-appearance: none;
          }
    }

    &__button {
        width: rem(445);
        height: rem(50);
        margin-top: rem(60);
        border: 3px solid $black;
        background-color: transparent;
        line-height: 1.5;
        margin-left: auto;
        transition: all 200ms ease-in-out;
        &:hover {
            border: 3px solid $blue; 
        }
    }

    &__required {
        color: $red;
        display: block;
        font-size: 14px;
        margin-left: 1.4rem;
        &::first-letter {
          text-transform: uppercase;
        }
    }

    &__message {
        color: $red;
        margin-top: rem(20);
        font-size: rem(24);
        width: rem(445);
        margin-left: auto;
        text-transform: capitalize;
    }

    &__message-link {
        text-decoration: underline;
        color: $blue;
        padding-left: 0;
        margin-left: rem(10);
        &:hover {
            text-decoration: none;
        }
    }
}

@include laptop {
    .NewsletterForm {
        &__button {
            height: 36px;
        }

        &__input {
            height: 30px;
        }
    }
}

@include mobile {
    .NewsletterForm {
        &__input-container {
            flex-direction: column;
            align-items: center;
        }

        &__message {
            font-size: 11px;
        }

        &__required {
            color: $red;
            display: block;
            font-size: 11px;
            margin-left: 11px;
        }
       
       
    }
}