@import "src/styles/lib";

.AwardWinners {
  padding-top: rem(250);
  font-family: $cairo;
  color: $black;
  overflow: hidden;
  padding-bottom: rem(100);
  font-size: rem(24);
  font-weight: 600;
  line-height: rem(45);
  &::before {
    content: "";
    position: absolute;
    width: rem(392);
    height: rem(255);
    background: url("https://assets.gocoffeego.com/v2/assets/images/left3stars.png");
    background-size: 100%;
    top: rem(440);
    left: rem(320);
    z-index: 3;
  }
  &::after {
    content: "";
    position: absolute;
    width: rem(430);
    height: rem(257);
    background: url("https://assets.gocoffeego.com/v2/assets/images/right3stars.png");
    background-size: 100%;
    top: rem(440);
    right: rem(240);
    z-index: 3;
  }
  &__title {
    text-align: center;
    font-size: rem(34);
    margin-bottom: rem(24);
    font-weight: 700;
   }

  &__description {
    text-align: center;
    font-size: 16px;
    margin: 0 auto;
    font-weight: 500;
    max-width: rem(1200);
    padding: 0 rem(20);
  }

  &__image-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: rem(130);
    margin-bottom: rem(20);
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: rem(440);
      height: rem(440);
      background-color: $red;
      border-radius: 50%;
      bottom: rem(-320);
      right: rem(680);
      z-index: -1;
    }
  }

  &__trophy-image {
    width: rem(200);
    height: rem(250);
    margin: rem(20) auto 0;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 22% 73%;
    grid-gap: rem(40);
    padding: 0 3%;
  }

  &__container {
    max-width: rem(1500);
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: rem(40);
    align-content: baseline;
    margin-bottom: rem(75);
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: rem(502);
      height: rem(502);
      background-color: #ff008e;
      border-radius: 50%;
      bottom: rem(320);
      left: rem(180);
      z-index: -1;
    }
    &::after {
      content: "";
      position: absolute;
      width: rem(502);
      height: rem(502);
      background-color: #ffed00;
      border-radius: 50%;
      bottom: rem(920);
      right: rem(-140);
      z-index: -1;
    }
  }
}

@include laptop {
  .AwardWinners {
    &__container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@include tablet-big {
  .AwardWinners {
    &::before {
      left: rem(80);
    }
    &::after {
      right: rem(30);
    }

  }
}

@include tablet {
  .AwardWinners {
    &::before {
      left: rem(80);
    }
    &::after {
      right: rem(30);
    }
    &__container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-items: center;
    }
  }
}

@include mobile {
  .AwardWinners {
    padding-top: 60px;
    &::before,
    &::after {
      display: none;
    }
    &__wrapper {
      display: grid;
      grid-template-columns: 100%;
      align-items: center;
      justify-content: center;
      grid-gap: 0;
    }
    &__container {
      display: grid;
      padding: 0 5%;
      grid-template-columns: 1fr;
     
      &::after {
        bottom: rem(2520);
      }
    }

    &__trophy-image {
      width: rem(173);
      height: rem(225);
    }

    &__image-wrap {
      margin-bottom: 0;
      &::before {
      display: none;
      }
    }
    &__filter {
      margin: 0 auto;
  }
  }
}
