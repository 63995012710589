@import "src/styles/lib";

.BillingAddress {
  width: 100%;

  &__button {
    width: 100%;
    text-transform: uppercase;
    height: rem(60);
    font-size: rem(20);
    font-family: $cairo;
    border: 3px solid $blue;
    background: white;
  }

  &__buttons {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__or {
    font-size: rem(20);
    margin-left: rem(15);
    margin-right: rem(15);
  }
}
