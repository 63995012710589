@import "src/styles/lib";

.CoffeeShipments {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: rem(82);
  padding-left: 10%;
  padding-right: 10%;

  &__roasters-title {
    font-size: rem(25);
    font-weight: 600;
    line-height: em(25px, 25px);
    max-width: rem(800);
    text-align: center;
  }

  &__slider {
    //width: 18%;
    width: 100%;
    margin-bottom: rem(40);
    pointer-events: none;
  }

  &__title {
    font-weight: 600;
    font-size: rem(34);
    margin-top: rem(24);
    text-align: center;
  }

  &__description {
    font-weight: 400;
    font-size: rem(24);
    margin-top: rem(10);
    text-align: center;
  }

  &__line {
    margin-top: rem(40);
    margin-bottom: rem(53);
  }

  &__section-title {
    text-transform: uppercase;
    font-size: rem(32);
    font-weight: 700;
    text-align: center;
    margin-bottom: rem(32);
  }

  &__shipments {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  &__shipment {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__one {
    display: grid;
    grid-gap: rem(44);
    grid-template-columns: repeat(3, 1fr);
    padding-right: rem(60);
    border-right: 1px solid $lineGrey;
  }

  &__two {
    display: grid;
    grid-gap: rem(44);
    padding-left: rem(60);
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-end;
  }
}

@include tablet {
  .CoffeeShipments {
    &__one {
      padding: 0;
      margin-bottom: 40px;
    }

    &__two {
      padding: 0;
    }

    &__shipments {
      grid-template-columns: 1fr;
    }

    &__shipment {
      display: flex;
      flex-direction: column;
    }

    &__title {
      margin-bottom: 20px;
    }
  }
}

@include mobile {
  .CoffeeShipments {
    &__one {
      border-right: none;
      padding: 0;
      grid-template-columns: 1fr;
    }
    &__section-title {
      margin-bottom: 0;
    }

    &__slider {
      width: 60%;
    }

    &__shipment {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__title {
      margin-top: 30px;
    }

    &__two {
      padding: 0;
      grid-template-columns: 1fr;
    }

    &__shipments {
      grid-template-columns: 1fr;
    }
  }
}
