@import "src/styles/lib";

.Page404 {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: rem(300);

  &__logo {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    padding-bottom: rem(20);
    padding-left: rem(31);
  }

  &__logo-image {
    width: rem(265);
    height: rem(509);
    object-fit: contain;
  }

  &__not-found {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    font-size: rem(65);
    font-weight: 700;
  }

  &__button {
    margin-top: rem(32);
    width: rem(500);
    height: rem(64);
    font-size: rem(37);
    font-weight: 400;
  }
}
