@import "../../../../styles/lib";

.MySubscriptionPlan {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 3px solid $black;
    margin-top: rem(160);
    margin-right: rem(130);
    margin-left: rem(130);
    margin-bottom: rem(130);
    position: relative;
    &::before {
      content: "";
      background: url("https://assets.gocoffeego.com/v2/assets/images/2men.png")
        no-repeat center center;
      background-size: 100%;
      position: absolute;
      width: rem(183);
      height: rem(260);
      top: rem(-110);
      right: rem(90);
      z-index: -1;
    }
    &::after {
      content: "";
      position: absolute;
      width: rem(191);
      height: rem(191);
      border-radius: 50%;
      background-color: #ff008e;
      top: rem(380);
      left: rem(-120);
      z-index: 2;
    }
  }

  &__tour {
    background-color: $black;
    color: $white;
    padding: rem(20) rem(100) rem(50) rem(60);
    display: flex;
  }

  &__image-wrap {
    display: flex;
    align-items: center;
    padding-top: rem(60);
  }

  &__image {
    width: rem(350);
    height: rem(250);
    margin-right: rem(20);
  }
  &__text-wrap {
    text-align: center;
    font-family: $cairo;
  }

  &__tour-name,
  &__plan,
  &__text {
    font-size: rem(34);
    line-height: rem(50);
  }
  &__tour-name {
    font-weight: 700;
  }

  &__plan {
    font-weight: 600;
    margin-bottom: rem(10);
  }

  &__text {
    font-weight: 300;
  }

  &__shipments {
    display: flex;
    text-align: center;
    position: relative;
    padding: 0 1rem;
    &::after {
      content: "";
      position: absolute;
      width: rem(325);
      height: rem(325);
      border-radius: 50%;
      background-color: #ffec00;
      bottom: rem(-180);
      right: rem(-120);
      z-index: -1;
    }
  }

  &__shipment-one-coffee-plan,
  &__shipment-two-coffees-plan {
    display: flex;
    align-items: flex-end;
  }

  &__shipment-3coffee-img,
  &__shipment-6coffee-img,
  &__shipment-12coffee-img {
    margin-top: rem(45);
    margin-bottom: rem(60);
  }

  &__shipment-3coffee-img {
    width: rem(235);
    height: rem(128);
  }
  &__shipment-6coffee-img {
    width: rem(232);
    height: rem(256);
  }
  &__shipment-6coffee-img {
    width: rem(204);
    height: rem(255);
  }

  &__shipment-btn {
    width: rem(218);
    height: rem(44);
    font-size: rem(24);
    font-weight: 600;
    font-family: $cairo;
    background-color: $white;
  }

  &__shipment-text {
    margin-top: rem(10);
    font-size: rem(34);
    font-weight: 600;
    line-height: rem(63);
  }

  &__shipment-subtext {
    font-size: rem(18);
    font-weight: 300;
    line-height: rem(33);
  }
}

@include desktop {
  .MySubscriptionPlan {
    &__shipments {
      display: flex;
      flex-direction: column;
      text-align: center;
      flex-wrap: wrap;
    }
    &__shipment-one-coffee-plan,
    &__shipment-two-coffees-plan {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
    }

    &__shipment-item {
      display: flex;
      flex-direction: column;
      margin-right: 4rem;
    }
  }
}

@include laptop {
  .MySubscriptionPlan {
    &__shipments {
      display: flex;
      flex-direction: column;
      text-align: center;
      flex-wrap: wrap;
    }
    &__shipment-one-coffee-plan,
    &__shipment-two-coffees-plan {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      flex-wrap: wrap;
    }

    &__shipment-item {
      display: flex;
      flex-direction: column;
      margin-right: 2rem;
      align-items: center;
    }

    &__shipment-btn {
      width: fit-content;
      line-height: 0;
    }
  }
}

@include mobile {
  .MySubscriptionPlan {
    &__tour {
      flex-direction: column;
      padding: 10px 30px 20px;
    }
    &__image-wrap {
      justify-content: center;
      margin-bottom: 20px;
    }
  }
}
