@import "../../../styles/lib";

.Merch {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  font-family: $cairo;
  &::before {
    content: "";
    position: absolute;
    height: rem(209);
    width: rem(209);
    border-radius: 50%;
    left: rem(-40);
    top: rem(-150);
    background-color: #ff8900;
    z-index: 1;
    opacity: 0.9;
  }

  &__message {
    font-size: rem(26);
    color: $blue;
    font-family: $cairo;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    text-align: center;
    font-weight: 700;
    z-index: 10;
  }

  &__button {
    margin-bottom: 3.4375rem;
    height: 4.125rem;
    width: 13.75rem;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    font-weight: bold;
    z-index: 2;
    transition: all 200ms ease-in-out;
    &:hover {
      border-color: $blue;
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
    width: rem(570);
    padding-top: rem(85);
  }

  &__subtitle {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.2em;
    font-size: rem(24);
    line-height: rem(32);
    z-index: 2;
  }

  &__title {
    position: relative;
    font-size: rem(36);
    letter-spacing: 0.2em;
    text-transform: uppercase;
    font-weight: normal;
    z-index: 2;
    margin-top: rem(50);
    text-align: center;
    line-height: rem(36);
    height: rem(165);
    &::before {
      content: "";
      position: absolute;
      height: rem(8);
      width: rem(82);
      left: 42%;
      right: 0;
      top: rem(-30);
      background-color: $black;
    }
  }

  &__GoCoffeeGo {
    text-transform: capitalize;
  }

  &__items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: rem(95);
  }

  &__car-img {
    position: absolute;
    left: rem(-50);
    top: rem(-60);
    width: rem(549);
    height: rem(549);
    border-radius: 50%;
  }

  &__girl-img {
    position: absolute;
    right: rem(-150);
    bottom: rem(40);
    width: rem(706);
    height: rem(706);
    z-index: 1;
    border-radius: 50%;
  }
}

@include tablet {
  .Merch {
    &__car-img {
      width: rem(450);
      height: rem(450);
    }
    &__girl-img {
      width: rem(650);
      height: rem(650);
    }
  }
}

@include mobile {
  .Merch {
    &::before {
      content: "";
      position: absolute;
      height: 87px;
      width: 87px;
      border-radius: 50%;
      top: rem(0);
      left: 30%;
      transform: translate(-70%, 0);
      background-color: #ff8900;
      z-index: 1;
      opacity: 0.9;
    }

    &__message {
      margin-bottom: rem(50);
    }

    &__car-img {
      width: 230px;
      height: 230px;
      left: 50%;
      transform: translate(-50%, 0);
      top: rem(40);
    }
    &__wrap {
      margin-bottom: 0;
      padding-top: 270px;
    }

    &__subtitle {
      font-size: 16px;
    }

    &__title {
      font-size: 20px;
      margin-top: 40px;
      width: 210px;
      line-height: 24px;
      letter-spacing: 4px;
      margin-bottom: 40px;
      &::before {
        content: "";
        position: absolute;
        height: rem(6);
        width: rem(59);
        left: 40%;
        right: 0;
        top: -20px;
      }
    }

    &__button {
      margin-top: 2rem;
      margin-bottom: 6rem;
      width: 153px;
      height: 46px;
      font-size: 14px;
    }

    &__items {
      justify-content: center;
      padding: 0 62px;
      position: relative;
      margin-bottom: 3rem;
      &::before {
        content: "";
        position: absolute;
        height: 56px;
        width: 56px;
        border-radius: 50%;
        top: rem(-75);
        left: rem(-40);
        background-color: #ff008e;
        z-index: 1;
        opacity: 0.9;
      }
    }

    &__girl-img {
      position: absolute;
      right: rem(-70);
      bottom: rem(-70);
      width: 240px;
      height: 240px;
    }
  }
}
