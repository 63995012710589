@import "../../../styles/lib";

.PreferencesBased {
  display: flex;
  justify-content: space-evenly;
  background: url("https://assets.gocoffeego.com/v2/assets/images/quiz-cta-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: rem(461);
  margin-bottom: rem(266);
  color: $white;
  font-family: $cairo;
  position: relative;

  &__btn {
    color: $white;
    text-transform: uppercase;
    border-color: $white;
    position: relative;
    z-index: 2;
  }

  &__wrap {
    width: rem(590);
    margin-top: rem(122);
    position: relative;
    z-index: 1;
    &::after {
      content: "";
      position: absolute;
      bottom: rem(-70);
      left: rem(-180);
      width: rem(227);
      height: rem(227);
      border-radius: 50%;
      background-color: #00d1d6;
      z-index: 0;
    }
  }

  &__middle-line {
    display: none;
  }

  &__text {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.2em;
    line-height: rem(36);
    font-size: rem(30);
    // height: rem(154);
    margin-bottom: 20px;
  }

  &__snake-img {
    position: relative;
    width: rem(293);
    height: rem(641);
    margin-top: rem(-232);
    margin-left: rem(80);
    z-index: 2;
  }

  &__beans-img {
    width: rem(531);
    height: rem(531);
    background: url("https://assets.gocoffeego.com/v2/assets/images/hands-holding-beans.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $black;
    border-radius: 50%;
    margin-top: rem(80);
    margin-right: rem(80);
  }
}

@include mobile {
  .PreferencesBased {
    margin-bottom: 40px;
    margin-top: 160px;
    //height: 378px;
    position: relative;
    display: flex;
    justify-content: center;
    background: url("https://assets.gocoffeego.com/v2/assets/images/coffee-faq-headline-bg-mb.svg");
    background-position: 100% bottom;
    background-repeat: no-repeat;
    background-size: auto;
    height: 100%;
    min-height: 240px;

    &__beans-img {
      width: 220px;
      height: 220px;
      position: absolute;
      border-radius: 100%;
      top: -110px;
      right: -35px;
      margin-right: 0;
    }
    &__wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 330px;
      text-align: center;
      margin-top: -370px;
      color: $black;
      &::after {
        bottom: 0;
        left: rem(100);
        width: 102px;
        height: 102px;
      }
    }

    &__text {
      //  height: 171px;
      width: 329px;
      font-size: 20px;
      letter-spacing: 4px;
      line-height: 30px;
      padding: 0 5%;
    }

    &__middle-line {
      display: block;
      margin-bottom: 18px;
    }

    &__btn {
      color: $black;
      border-color: $black;
      margin-top: 5px;
      width: 200px;
      height: 54px;
      letter-spacing: 3px;
      line-height: 32px;
      font-size: 14px;
      font-weight: 600;
    }

    &__snake-img {
      width: 116px;
      height: 254px;
      margin-top: 0;
      position: absolute;
      left: 0;
      top: -50px;
      margin-left: 26px;
    }
  }
}

@include tablet {
  .PreferencesBased {
    &__text {
      margin-bottom: 20px;
    }
  }
}
