@import "src/styles/lib";

.GuestSignUpForm {
  border: 1px solid black;
  padding-top: rem(52);
  padding-bottom: rem(52);
  padding-left: rem(91);
  padding-right: rem(91);
  background: white;
  width: rem(737);
  position: relative;
  z-index: 10;

  &__link-container {
    text-align: start;
    font-size: rem(28);
    font-weight: 400;
    margin-top: rem(13);
    display: flex;
  }

  &__label2 {
    font-size: rem(28);
    font-weight: 400;
    margin-left: rem(10);
  }

  &__link {
    font-size: rem(28);
    font-weight: 500;
    color: #0d89aa;
    text-align: end;
    margin-left: 5px;
    &:hover {
      color: #0d89aa;
    }
  }

  &__input-container {
    margin-bottom: rem(25);
    position: relative;
  }

  &__required {
    font-size: rem(22);
    color: red;
    margin-top: rem(10);
    height: 100%;
    width: 100%;

    &--password {
      font-size: rem(18);
    }
  }

  &__input {
    color: black !important;
    width: 100%;
    border-radius: 0 !important;
    font-size: rem(25) !important;
    border: 0 !important;
    border-bottom: 1px solid #000000 !important;
    padding-left: 0 !important;

    &::placeholder {
      color: black !important;
    }
  }

  &__row {
    display: flex;
  }

  &__button {
    color: #ffffff;
    margin-top: rem(60);
    height: rem(60);
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    background-color: $blue;
    border: none;
    width: 100%;
    font-size: rem(32);
    font-weight: 600;
    font-family: $rubik;
    cursor: pointer;
    outline: none;
    transition: all 300 ease-in-out;
    text-transform: uppercase;
    &:hover {
      background: darken($blue, 1%);
    }
  }

  &__gift-certificate-msg {
    margin-top: 5px;
    font-size: rem(20);
  }
}

@include mobile {
  .GuestSignUpForm {
    border: none;

    &__input {
      font-size: 16px !important;
    }

    &::after {
      display: none;
    }

    &__button {
      height: 46px !important;
    }
  }
}
