@import "src/styles/lib";

.PayAsYouGo {
  padding-top: rem(200);
  padding-bottom: rem(200);
  position: relative;
  overflow: hidden;
  height: 100%;

  &:before {
    content: "";
    position: absolute;
    top: rem(520);
    left: rem(-139);
    border-radius: 50%;
    width: rem(279);
    height: rem(279);
    background: #ff8901;
  }

  &__question1-image {
    width: rem(610);
    height: rem(420);
    object-fit: contain;
  }

  &__coffee-image {
    width: rem(344);
    height: rem(203);
    object-fit: contain;
  }

  &__buzz-image {
    width: rem(278);
    height: rem(368);
    object-fit: contain;
  }

  &__question-container {
    position: relative;
  }

  &__all-clubs {
    position: absolute;
    top: rem(185);
    right: rem(-200);
    border-radius: 50%;
    width: rem(180);
    height: rem(180);
    background: #6ae200;
    padding: rem(50) rem(10) 0;
    text-align: center;
    text-transform: uppercase;
    color: $white;
    font-weight: 600;
    font-size: rem(30);
    cursor: pointer;
  }

  &__question-number {
    z-index: 10;
    letter-spacing: 0.2rem;
    font-weight: 700;
    font-size: rem(29);
    background: black;
    color: white;
    padding: rem(18) rem(57);
    margin-bottom: rem(18);

    &--light {
      margin-left: rem(11);
      font-weight: 400;
    }
  }

  &__question {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    margin-bottom: rem(142);
    padding-top: rem(20);
    position: relative;

    &:nth-child(5) {
      &:before {
        content: "";
        position: absolute;
        top: rem(879);
        left: rem(-75);
        border-radius: 50%;
        width: rem(330);
        height: rem(330);
        background: #01d2d7;
      }
    }

    &:nth-child(6) {
      &:before {
        content: "";
        position: absolute;
        top: rem(60);
        right: rem(-123);
        border-radius: 50%;
        width: rem(350);
        height: rem(350);
        background: #ff8901;
      }
    }
  }

  &__cart {
    cursor: pointer;
    background: #fff;
    border: 3px solid black;
    position: relative;

    &--active {
      border: 3px solid $blue;
    }

    &:hover {
      border: 3px solid $blue;
    }

    &:nth-child(2) {
      .PayAsYouGo__cart-title {
        position: absolute;
        left: 0;
        margin-left: rem(32);
      }
    }
  }

  &__cart-title {
    height: fit-content;
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    right: 0;
    font-weight: 700;
    font-size: rem(23);
    margin-right: rem(32);
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__row1 {
    display: flex;
    grid-template-columns: 1fr 1fr;
    grid-gap: rem(318);
    position: relative;

    &:after {
      @include arrow-down;
    }
  }

  &__row2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: rem(373);
    position: relative;

    &:after {
      @include arrow-down;
    }
  }

  &__row3 {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: rem(60);
  }

  &__row4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: rem(35);
  }

  &__row5 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: rem(24);
  }

  &__row6 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: rem(292);
    position: relative;
    &:after {
      @include arrow-down;
    }
  }

  &__row7 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: rem(121);

    position: relative;

    &:after {
      @include arrow-down;
    }
  }

  &__row8 {
    position: absolute;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: rem(96);
  }

  &__title {
    font-size: rem(35);
    font-weight: 600;
    letter-spacing: 0.1rem;
    margin-bottom: rem(46);
    text-align: center;
  }

  &__title2 {
    font-size: rem(23);
    font-weight: 600;
    letter-spacing: 0.1rem;
    margin-bottom: rem(37);
  }

  &__options {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__button-container {
    display: flex;
    justify-content: center;
  }

  &__lines {
    position: relative;
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__button {
    font-family: $cairo;
    outline: none;
    font-size: rem(24);
    font-weight: 700;
    color: black;
    letter-spacing: 0.1em;
    width: rem(272);
    position: relative;
    display: flex;
    justify-content: center;

    &::after {
      position: absolute;
      content: "";
      background: #ff26a0;
      border-radius: 50%;
      width: rem(202);
      height: rem(202);
      z-index: -1;
      right: rem(-150);
      top: rem(135);
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }

    &:focus {
      color: black;
    }

    &:hover {
      color: black;
    }
  }
}

@include tablet-big {
  .PayAsYouGo {
    &:before {
      display: none;
    }
    &__row3 {
      grid-gap: rem(2);
    }
    &__row5 {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: rem(24);
    }
  }
}

@include tablet {
 
  .PayAsYouGo {
    &:before {
      display: none;
    }
    &__row3 {
      grid-template-columns: 1fr 1fr 1fr;
    }
    &__row4 {
      grid-template-columns: 1fr 1fr;
    }

    &__row5 {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: rem(24);
    }
  }
}

@include desktop {
  .PayAsYouGo {
    &__row3 {
      grid-gap: rem(1);
    }
  }
}

@include mobile {
  .PayAsYouGo {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 50px;
    padding-bottom: 50px;

    &:before {
      display: none;
    }

    &__all-clubs {
      position: absolute;
      top: rem(-70);
      right: rem(-50);
      border-radius: 50%;
      width: rem(180);
      height: rem(180);
     
    }

    &__question-container {
      display: flex;
      flex-direction: column;
    }

    &__coffee-image {
      width: 130px;
    }

    &__buzz-image {
      width: 130px;
    }

    &__question-number {
      width: 100%;
      padding: 10px;
      font-size: 13px;
      text-align: center;
    }

    &__question {
      &:nth-child(5) {
        &:before {
          display: none;
        }
      }
      &:nth-child(6) {
        &:before {
          display: none;
        }
      }
    }
    &__row1 {
      grid-template-columns: 1fr 1fr;
      grid-gap: rem(24);
    }

    &__row2 {
      grid-template-columns: 1fr 1fr;
      grid-gap: rem(24);
    }

    &__row3 {
      grid-template-columns: 1fr 1fr;
      grid-gap: rem(24);
    }

    &__row4 {
      grid-template-columns: 1fr 1fr;
      grid-gap: rem(24);
    }

    &__row5 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: rem(24);
    }

    &__row6 {
      display: grid;
      grid-gap: rem(24);
    }

    &__row7 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: rem(24);
    }

    &__row8 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: rem(24);
    }

    &__button {
      padding: 20px;
      font-size: 18px;
      width: fit-content;
    }
  }
}
