@import "./src/styles/lib";

.BrilliantRecommendations {
  position: relative;
  background-image: url("https://assets.gocoffeego.com/v2/assets/images/black-1.svg");
  background-size: 100%;
  &__shape {
    width: 100%;
    height: rem(483);
    background: url("https://assets.gocoffeego.com/v2/assets/images/shape-square.svg")
      no-repeat right top rem(41);
    object-fit: contain;
    position: absolute;
  }

  &__girl-in-dot {
    position: absolute;
    top: rem(1007);
    left: rem(90);
    object-fit: contain;
    width: rem(318);
    height: rem(318);
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    padding-top: rem(77);
  }

  &__content-title {
    margin-top: rem(38);
    margin-bottom: rem(102);
    font-size: rem(30);
    font-family: $cairo;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 0.4rem;
  }

  &__items {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: rem(92);
    margin-bottom: rem(103);
  }

  &__yellow-circle {
    position: absolute;
    right: rem(-104);
    top: rem(243);
    width: rem(197);
    height: rem(197);
    object-fit: contain;
  }

  &__line {
    position: relative;
  }

  &__circle {
    position: absolute;
    object-fit: contain;
    right: 0;
    bottom: rem(-150);
    width: rem(350);
    height: rem(450);
  }
}

@include tablet {
  .BrilliantRecommendations {
    background-size: 110%;
    &__girl-in-dot {
      display: none;
    }
    &__circle {
      width: rem(250);
    }
  }
}

@include mobile {
  .BrilliantRecommendations {
    background: url("https://assets.gocoffeego.com/v2/assets/images/black-1-mobile.svg")
      no-repeat left 0px;
    background-size: 100%;

    &__items {
      grid-template-columns: 1fr;
    }

    &__content {
      padding-top: 28px;
    }

    &__girl-in-dot {
      display: none;
    }

    &__yellow-circle {
      position: absolute;
      right: -6.5rem;
      top: 410px;
      width: 12.3125rem;
      object-fit: contain;
      opacity: 0.5;
    }

    &__shape {
      display: none;
    }

    &__content-title {
      font-size: 18px;
      line-height: em(24px, 20px);
      letter-spacing: 0.1rem;
      width: 303px;
      text-align: center;
      margin-top: 13px;
      max-width: 250px;
    }

    &__circle {
      right: 0;
      bottom: -45px;
      width: 142px;
    }
  }
}
