@import "./src/styles/lib";

.CoffesByRoast {
  width: 100%;
  padding-top: rem(101);
  padding-bottom: rem(101);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__content {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__content-title {
    margin-top: rem(46);
    margin-bottom: rem(50);
    font-size: rem(36);
    font-weight: 400;
    font-family: $cairo;
    text-align: center;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

@include mobile {
  .CoffesByRoast {
    &__content-title {
      font-size: 20px;
      line-height: em(24px, 20px);
      letter-spacing: 4px;
      width: 303px;
    }

    &__content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

  }
}
