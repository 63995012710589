@import "../../../styles/lib";

.SelectPlan {
  border: 1px solid $black;
  padding-top: rem(13);
  padding-bottom: rem(13);
  margin-bottom: rem(23);
  position: relative;
  &__title {
    font-size: rem(28);
    font-weight: 600;
  }

  &__plan-title {
    padding: rem(10);
    font-weight: 600;
  }

  &__icon {
    position: absolute;
    right: 10px;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    cursor: pointer;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    font-weight: 400;
    line-height: em(16px, 16px);
    margin: 0;
  }

  &__header {
    height: 100%;
    padding-left: rem(35);
    padding-right: rem(60);
    position: relative;
    cursor: pointer;
    font-size: rem(25);
    width: 100%;
  }

  &__title {
    font-size: rem(25);
    font-weight: 600;
    //color: #d1cece;
    color: rgba(16,16,16,0.4);
  }

  &__title2 {
    width: 100%;
    font-size: rem(25);
    font-weight: 600;
    display: flex;
    justify-content: space-between;
  }

  &__title3 {
    font-size: rem(25);
    font-weight: 400;
    color: $blue;
    outline: none;
    border: none;
    background: transparent;
    margin-left: auto;
  }

  &__items {
    overflow-y: hidden;
    //max-height: rem(300);
    width: 100%;
    position: absolute;
    z-index: 5;
    display: none;
    background: white;
    border: 2px solid #ccd9e2;
    margin-top: rem(18);
    padding: 10px;

    &.active {
      display: block;
    }
  }

  &__item-container {
    padding: rem(8);
    border-radius: 4px;
    line-height: rem(21);

    &.activeLabel {
      background: #f2f5f7;
    }

    &:hover {
      background: #f2f5f7;
      cursor: pointer;
    }
  }
}
@include tablet {
  .SelectPlan__item {
    font-size: 14px;
  }
}
@include mobile {
  .SelectPlan {
    width: 100%;
    &__item {
      font-size: 13px;
    }
  }
}
