@import "../../../styles/lib";

.MyCoffeeClubs {
  padding-top: rem(250);
  padding-bottom: rem(300);
  font-family: $cairo;
  color: $black;
  overflow: hidden;

  &__default-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 3px solid $black;
    margin-top: rem(160);
    margin-right: rem(130);
    margin-left: rem(130);
    padding: rem(60) 0 rem(70);
    position: relative;
    background-color: $white;
    &::before {
      content: "";
      background: url("https://assets.gocoffeego.com/v2/assets/images/2men.png")
        no-repeat center center;
      background-size: 100%;
      position: absolute;
      width: rem(183);
      height: rem(260);
      top: rem(-110);
      left: rem(90);
      z-index: -1;
    }
    &::after {
      content: "";
      position: absolute;
      width: rem(272);
      height: rem(272);
      border-radius: 50%;
      background-color: #ff008e;
      top: rem(-225);
      right: rem(-120);
      z-index: 2;
    }
  }

  &__button {
    width: rem(312);
    height: rem(70);
    font-weight: 600;
    font-size: rem(34);
    line-height: rem(50);
    font-family: $cairo;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: rem(189);
      height: rem(189);
      border-radius: 50%;
      background-color: #ffec00;
      bottom: rem(-120);
      right: rem(-160);
      z-index: 2;
    }
  }

  &__title {
    font-weight: 600;
    font-size: rem(34);
    margin-bottom: rem(48);
    line-height: em(50px, 34px);
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 3px solid $black;
    margin: rem(160) rem(130) rem(40);
    padding-top: rem(60);
    position: relative;
    background-color: $white;
    &::before {
      content: "";
      background: url("https://assets.gocoffeego.com/v2/assets/images/2men.png")
        no-repeat center center;
      background-size: 100%;
      position: absolute;
      width: rem(183);
      height: rem(260);
      top: rem(-110);
      left: rem(90);
      z-index: -1;
    }
    &::after {
      content: "";
      position: absolute;
      width: rem(272);
      height: rem(272);
      border-radius: 50%;
      background-color: #ff008e;
      top: rem(-225);
      right: rem(-120);
      z-index: 2;
    }
  }
  &__info {
    text-align: center;
    font-size: rem(34);
    line-height: rem(50);
    font-weight: 400;
    margin-bottom: rem(20);
    z-index: 0;
    &::before {
      content: "";
      position: absolute;
      width: rem(325);
      height: rem(325);
      border-radius: 50%;
      background-color: #ffec00;
      bottom: rem(-100);
      left: rem(-120);
      z-index: -1;
    }
  }

  &__message {
    text-align: center;
    font-size: rem(24);
    color: $blue;
    z-index: 2;
  }

  &__info-text {
    z-index: 2;
  }

  &__wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    gap: rem(200);
    margin-bottom: rem(100);
  }

  &__image {
    width: rem(350);
    height: rem(381);
  }

  &__club-name,
  &__club-info-shipment {
    font-size: rem(38);
    line-height: rem(50);
    font-weight: 600;
    text-align: center;
  }

  &__plan,
  &__plan-coffees {
    font-size: rem(34);
    line-height: rem(50);
    font-weight: 400;
    text-align: center;
  }

  &__club-info-shipment-date {
    font-weight: 400;
    font-size: rem(34);
    margin-top: rem(10);
    margin-bottom: rem(100);
  }

  &__club-info-button {
    display: block;
    margin: 0 auto rem(25);
    width: rem(550);
    height: rem(70);
    font-size: rem(34);
    // line-height: rem(56);
    padding: 0 auto;
    font-weight: 600;
    font-family: $cairo;
    transition: all 200ms ease-in-out;
    text-align: center;
    &:hover {
      border-color: $blue;
      color: $black;
    }
  }
}
@include tablet {
  .MyCoffeeClubs__wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    grid-gap: 0;
    gap: 0;
    margin-bottom: 6.25rem;
  }
}

@include mobile {
  .MyCoffeeClubs {
    padding-top: 50px;
    margin: 0 auto;

    &__container,
    &__default-container {
      padding: 5% 10%;
      margin: 90px 5% 40px;
    }

    &__default-container {
      &::after {
        display: none;
      }
    }

    &__wrap {
      display: grid;
      grid-template-columns: 1fr;
      gap: 40px;
    }

    &__title {
      text-align: center;
      margin-top: 10px;
    }

    &__club-info-button {
      width: 260px;
    }

    &__button {
      width: fit-content;
    }
    &__club-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }
}
