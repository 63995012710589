@import "src/styles/lib";

.AboutUs {
  padding-top: rem(200);
  padding-bottom: rem(150);
  overflow: hidden;
  font-family: $cairo;
  //  padding-left: 10%;
  // padding-right: 10%;

  &__main-title-wrapper {
    position: relative;
    margin-bottom: 140px;
    &:after {
      content: "";
      position: absolute;
      top: rem(-70);
      left: rem(200);
      width: rem(202);
      height: rem(202);
      background-color: #ff8901;
      border-radius: 50%;
      z-index: 2;
    }
  }

  &__main-title {
    width: 586px;
    height: 134px;
    &::after {
      content: "";
      width: rem(318);
      height: rem(281);
      position: absolute;
      top: rem(-120);
      right: rem(-430);
      z-index: 5;
      background: url("https://assets.gocoffeego.com/v2/assets/images/peace-sign.png")
        center 100%;
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }

  /*  &__girl-image {
    width: rem(318);
    height: rem(281);
    position: absolute;
    top: rem(-200);
    right: rem(500);
    z-index: 5;
  } */

  &__flowers {
    position: absolute;
    top: rem(-180);
    right: rem(100);
    object-fit: contain;
    width: rem(320);
  }

  &__stars {
    position: absolute;
    width: 92px;
    height: 95px;
    left: rem(331);
    bottom: 0;
  }

  &__description-container {
    position: relative;
  }

  &__image {
    object-fit: contain;
    width: rem(284);
  }

  &__container {
    padding-left: 10%;
    padding-right: 10%;
    margin: rem(140) auto rem(200);
    position: relative;
    &::after {
      content: "";
      position: absolute;
      background-color: #22a1f2;
      width: rem(250);
      height: rem(250);
      bottom: rem(-340);
      right: rem(50);
      border-radius: 50%;
    }
  }

  &__wrapper {
    padding-left: 10%;
    padding-right: 10%;
    margin-top: rem(200);
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: rem(-220);
      right: 10%;
      background: url("https://assets.gocoffeego.com/v2/assets/images/team/drinking-coffee.png")
        center 100%;
      background-repeat: no-repeat;
      background-size: 100%;
      width: rem(271);
      height: rem(280);
    }
  }

  &__history-wrapper {
    padding-left: 10%;
    padding-right: 10%;
    position: relative;
    margin-top: rem(180);
    /*   &::after {
      content: "";
      position: absolute;
      top: rem(-190);
      right: 10%;
      background: url("../../assets/images/team/drinking-coffee.png") center 100%;
      background-repeat: no-repeat;
      background-size: 100%;
      width: rem(271);
      height: rem(280);
    } */
  }

  &__subtitle {
    margin-bottom: rem(46);
    font-size: rem(39);
    line-height: rem(61);
    letter-spacing: 0.025em;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-gap: rem(30);
    align-items: center;
    //  justify-items: flex-end;
    // grid-gap: 30px;
    margin-bottom: rem(80);
  }

  &__grid-history {
    display: grid;
    grid-template-columns: 1fr 4fr;
    align-items: flex-start;
    //  justify-items: flex-end;
    grid-gap: 30px;
    margin-bottom: rem(140);
  }

  &__name,
  &__role {
    font-size: rem(24);
  }

  &__role {
    margin-bottom: rem(35);
  }

  &__xoxo {
    object-fit: contain;
    width: rem(165);
    height: rem(145);
    margin-top: rem(55);
  }

  &__description-history {
    margin: 0;
    font-size: rem(22);
    font-weight: 400;
    position: relative;
    margin-bottom: rem(20);
    line-height: em(32px, 22px);
    letter-spacing: 0.046em;
    // text-align: justify;
  }

  &__description {
    margin: 0;
    font-size: rem(22);
    font-weight: 400;
    position: relative;
    margin-bottom: rem(20);
    line-height: em(32px, 22px);
    letter-spacing: 0.042em;
  }
}

@include tablet {
  .AboutUs {
    &__main-title {
      width: 350px;
      height: 120px;
      &::after {
        content: "";
        width: rem(318);
        height: rem(281);
        top: rem(-95);
      }
    }
  }
}

@include mobile {
  .AboutUs {
    padding-top: rem(150);

    &__main-title {
      width: 350px;
      height: 100px;
      &::after {
        display: none;
      }
    }

    &__main-title-wrapper {
      margin-bottom: 70px;
      &::after {
        display: none;
      }
    }

    &__wrapper {
      margin-top: rem(100);
      &::after {
        display: none;
      }
    }

    &__description,
    &__description-history {
      font-size: rem(25);
      line-height: rem(36);
    }

    &__name,
    &__role {
      font-size: rem(26);
    }

    &__image {
      // width: 100%;
      height: auto;
    }

    &__flowers {
      display: none;
    }

    &__stars {
      display: none;
    }

    &__grid,
    &__grid-history {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 5%;
      justify-items: center;
    }

    &__container,
    &__wrapper,
    &__history-wrapper {
      padding-left: 5%;
      padding-right: 5%;
    }

    &__subtitle,
    &__name,
    &__role {
      text-align: center;
    }
  }
}
