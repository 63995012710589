@import "../../../../styles/lib";

.FilterCheckBoxCategory {
  line-height: rem(24);
  &__wrap {
    position: relative;
    display: flex;
    flex-direction: column;
  //  align-items: center;
    border-top: rem(2) solid $black;
    padding: rem(24) 0;
    transition: all 200ms ease-in-out;
    cursor: pointer;
  }
  &__subtitle {
    display: block;
    font-size: 14px;
    color: rgb(132, 131, 131);
    padding-bottom: rem(24);
    margin-top: -10px;
  }

  &__name {
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: $black;
    font-weight: 700;
    font-size: rem(20);
    line-height: rem(24);
  }

  &__line1, &__line2 {
    position: absolute;
    width: 16px;
    height: 1px;
    background-color: $black;
    top: rem(37);
    right: 0;
    transition: all 200ms ease-in-out;
  }

  &__line2 {
    transform: rotate(90deg);
  }

  &__items {
    display: none;
  }

  &__item {
    display: block;
    position: relative;
    cursor: pointer;
    padding-left: rem(30);
    margin-bottom: rem(12);
    font-size: rem(16);
    user-select: none;
  }

  .open {
    .FilterCheckBoxCategory__line2 {
      transform: rotate(45deg);
    }

    .FilterCheckBoxCategory__line1 {
      transform: rotate(-45deg);
    }
  }

  .open {
    &__items {
      display: block;
      line-height: rem(24);
      transition: all 300ms ease-in-out;
    }
  }

  &__input-wrap {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__outer-circles {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    border: 1px solid $black;
    background: $white;
    transition: all 200ms;
    position: relative;
  }

  &__inner-circle {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: transparent;
    transition: all 0.1s linear;
  
    &--active {
      background-color: #00d1d6;
    }
  }
 
  &__label {
    color: $black;
    padding-left: rem(8);
    font-size: rem(22);
  }

}

 .FilterCheckBoxCategory__input-wrap--disabled{
  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:disabled + label {
    cursor: not-allowed;
    pointer-events: none;
  }
    
  [type="checkbox"]:not(:checked) + label:after,
  [type="checkbox"]:disabled + label:after {
    cursor: not-allowed;
    background-color: #ffffff;
    pointer-events: none;
  }
  [type="checkbox"]:not(:checked) + label:before,
  [type="checkbox"]:disabled + label:before {
    cursor: not-allowed;
    pointer-events: none;
  }
}

@include mobile {
  .FilterCheckBoxCategory {
    &__name {
      font-size: 14px;
      padding: 5px 0;
    }

    &__line1, &__line2 {
      width: 16px;
    }
    &__label {
        color: $black;
        font-size: 16px;
        margin-bottom: 0;
        padding-left: 10px;
    }
    &__input-wrap {
       margin-bottom: 12px;
    }
    &__inner-circle {
      width: 8px;
      height: 8px;
    }

    &__outer-circles {
      width: 14px;
      height: 14px;
    }
    
  }
}
