@import "src/styles/lib";

.AdvancedSearch {
  padding-top: rem(250);
  font-family: $cairo;
  color: $black;
  padding-bottom: rem(100);
  font-size: 16px;
  font-weight: 600;
  line-height: rem(45);

  &::after {
    content: "";
    position: absolute;
    width: rem(162);
    height: rem(133);
    background: url("https://assets.gocoffeego.com/v2/assets/images/4stars.png");
    background-size: 100%;
    top: rem(570);
    left: rem(70);
    z-index: 3;
  }
  &__container {
    border: rem(3) solid $black;
    margin: rem(160) rem(130) rem(100);
    padding: rem(46) rem(36);
    background-color: $white;
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;

    &::before {
      content: "";
      position: absolute;
      width: rem(222);
      height: rem(230);
      background: url("https://assets.gocoffeego.com/v2/assets/images/peaberry1.png")
        no-repeat;
      background-size: 100%;
      top: rem(-172);
      right: rem(80);
      z-index: 3;
    }
  }

  &__products-container {
    margin: 0 rem(130);
    padding-top: rem(20);
  }

  &__main-description {
    text-align: center;
    font-size: 16px;
    margin: 0 auto;
    font-weight: 500;
    max-width: rem(1500);
    padding: 0 rem(20);
  }
  &__title {
    max-width: rem(1200);
    text-align: center;
    font-size: rem(36);
    margin: rem(130) auto rem(30);
    font-weight: 700;
    padding: 0 rem(20);
  }

  &__products {
    display: grid;
    grid-template-columns: repeat(4, rem(370));
    gap: rem(30);
    justify-content: center;
    margin-bottom: rem(40);
  }

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: rem(30);
    margin-top: rem(38);
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: rem(162);
      height: rem(162);
      border-radius: 50%;
      background-color: #ff008e;
      top: rem(-85);
      right: rem(350);
      z-index: -1;
    }
    &::after {
      content: "";
      position: absolute;
      width: rem(200);
      height: rem(200);
      border-radius: 50%;
      background-color: $red;
      bottom: rem(-75);
      right: rem(-40);
      z-index: -1;
    }
  }

  &__input-wrap {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px;
  }
  &__input-wrap-none {
    display: none;
  }

  &__label {
    margin-right: rem(35);
    padding-top: 1px;
  }

  &__input {
    width: rem(473);
    //height: rem(47);
    border: 1px solid $black;
    padding: rem(10);
    font-size: 16px;
    line-height: rem(37);
    color: $black;
    font-weight: 600;
    &::placeholder {
      color: $black;
    }
    &:focus {
      outline: rem(1) solid $black;
    }
  }

  &__select {
    width: rem(473);
    height: rem(55);
    border: 1px solid $black;
    padding: rem(10) 0;
    background-color: $white;
  }

  &__select-item {
    width: rem(154);
    height: rem(47);
    border: 1px solid $black;
    padding: rem(10) 0;
    &:not(:last-child) {
      margin-right: rem(5);
    }
  }

  &__message {
    text-align: center;
    font-size: rem(32);
  }

  &__button {
    width: rem(473);
    height: rem(66);
    border: none;
    background-color: $blue;
    color: $white;
    font-size: 16px;
    line-height: rem(37);
    font-weight: 700;
    margin-top: rem(30);
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    transition: all 300ms ease-in-out;
    &:hover {
      background: darken($blue, 5%);
    }
    &::before {
      content: "";
      position: absolute;
      width: rem(162);
      height: rem(162);
      border-radius: 50%;
      background-color: #ffec00;
      top: rem(-115);
      right: rem(205);
      z-index: -1;
    }
  }
}

@include laptop {
  .AdvancedSearch {
    &__wrapper {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 0;
    }

    &__products {
      grid-template-columns: repeat(3, rem(440));
    }

    &__wrap {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    &__input-wrap {
      display: flex;
      justify-content: flex-end;
    }
  }
}

@include mobile {
  .AdvancedSearch {
    padding-top: 60px;
    &::before {
      top: 240px;
      right: 40px;
    }

    &__wrapper {
      width: 100%;
    }
    &__select {
      height: 38px;
      padding: 0;
    }

    &__input {
      font-size: 16px;
      width: 100%;
      padding: 10px 6px;
    }

    &::after {
      display: none;
      top: 300px;
      left: 10px;
    }

    &__products {
      grid-template-columns: 1fr;
      justify-items: center;
    }

    &__container {
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 130px;
    }

    &__wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__input-wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 10px;
    }

    &__button {
      font-size: 16px;
      height: 42px;
    }

    &__label {
      font-size: 16px;
      margin-right: 5px;
    }
  }
}
