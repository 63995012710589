@import "./src/styles/lib";

.Orders {
  width: 100%;
  height: 100%;
  padding-top: rem(180);
  overflow: hidden;
  font-family: $cairo;
  &__title-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: rem(120);
    margin-bottom: rem(30);
  }

  &__product-reviews {
    position: relative;
  }

  &__product-reviews-background {
    position: absolute;
    background: url("https://assets.gocoffeego.com/v2/assets/images/blackbg.png")
      no-repeat center center;
    background-size: 100%;
    bottom: rem(-100);
    left: rem(-170);
    width: rem(1548);
    height: rem(810);
    z-index: -1;
  }

  &__title,
  &__product-reviews-title {
    font-weight: 500;
    text-transform: uppercase;
    font-size: rem(34);
    margin-top: rem(40);
    margin-bottom: rem(80);
    line-height: em(63px, 34px);
    letter-spacing: 0.1em;
  }

  &__no-purchase,
  &__no-purchase-review,
  &__list-products-review {
    margin: 0 rem(130) rem(100);
    height: auto;
    padding: rem(65) 0;
    text-align: center;
    font-size: rem(25);
    line-height: rem(47);
    position: relative;
    background-color: $white;
  }

  &__view-more-btn-container {
    margin-top: rem(80);
  }

  &__view-more-btn {
    letter-spacing: 0.1em;
    font-size: rem(26);
  }

  &__no-purchase {
    z-index: 2;
    &::before {
      content: "";
      background-color: $red;
      position: absolute;
      border-radius: 50%;
      right: rem(80);
      top: rem(-110);
      width: rem(215);
      height: rem(215);
      z-index: -1;
    }
    &::after {
      content: "";
      background-color: #ffec00;
      position: absolute;
      border-radius: 50%;
      left: rem(-70);
      bottom: rem(-80);
      width: rem(158);
      height: rem(158);
      opacity: 1;
    }
  }

  &__list-products-review {
    &::after {
      content: "";
      background: url("https://assets.gocoffeego.com/v2/assets/images/girl-at-the-window.png")
        no-repeat center center;
      background-size: 100%;
      position: absolute;
      right: rem(-80);
      top: rem(-110);
      width: rem(190);
      height: rem(190);
    }
  }

  &__no-purchase-review {
    &::after {
      content: "";
      background: url("https://assets.gocoffeego.com/v2/assets/images/girl-at-the-window.png")
        no-repeat center center;
      background-size: 100%;
      position: absolute;
      right: rem(-80);
      top: rem(-110);
      width: rem(190);
      height: rem(190);
    }
  }

  &__product-reviews-title-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: rem(100);
  }

  &__list-products-review-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: rem(60);
    margin-top: rem(80);
  }

  &__no-review {
    margin: rem(60) auto;
    font-size: rem(28);
    font-weight: 400;
  }

  &__list-products-review-title {
    font-size: rem(25);
    position: relative;
    z-index: 2;
    &::before {
      content: "";
      background: url("https://assets.gocoffeego.com/v2/assets/images/3stars-left.png")
        no-repeat center center;
      background-size: 100%;
      position: absolute;
      left: rem(260);
      top: rem(-70);
      width: rem(240);
      height: rem(240);
      z-index: -1;
    }
    &::after {
      content: "";
      background: url("https://assets.gocoffeego.com/v2/assets/images/3stars-right.png")
        no-repeat center center;
      background-size: 100%;
      position: absolute;
      right: rem(260);
      top: rem(-70);
      width: rem(240);
      height: rem(240);
      z-index: -1;
    }
  }

  &__order-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto rem(200);
    padding: 0 rem(95);
    position: relative;
    &::before {
      content: "";
      background-color: $red;
      position: absolute;
      border-radius: 50%;
      right: rem(100);
      top: rem(-160);
      width: rem(215);
      height: rem(215);
      z-index: -1;
    }
    &::after {
      content: "";
      background-color: #ffec00;
      position: absolute;
      border-radius: 50%;
      left: rem(10);
      bottom: rem(-20);
      width: rem(158);
      height: rem(158);
      opacity: 1;
    }
  
  }

  &__products {
    margin: 0 auto;
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, rem(370));
    grid-gap: rem(60);
    padding-bottom: rem(80);
    align-content: center;
    margin: 0 auto;
  }

  &__product {
    display: flex;
    min-width: rem(270);
    max-width: rem(370);
  }

  &__item {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #c7c7c7;
    width: 100%;
    padding: rem(30) rem(20) rem(20);
    background-color: $white;
    position: relative;
  }

  &__total-container {
    display: grid;
    grid-template-columns: 30% 40% 25%;
    grid-gap: 3%;
    margin-bottom: rem(20);
  }

  &__items-button {
    background: transparent;
    border: none;
    font-size: rem(24);
    padding-bottom: 0;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.1em;
    color: $blue;
    text-decoration: underline;
    margin-left: auto;
    padding-right: 0;
  }

  .tracking {
    cursor: pointer;
  }

  &__details {
    display: flex;
    flex-direction: column;
    margin-bottom: rem(50);
  }

  &__product-wrapper {
    margin: rem(40) 0;
    display: grid;
    grid-template-columns: repeat(3, rem(370));
    grid-gap: rem(30);
  }

  &__description {
    font-weight: 700;
    font-size: rem(28);
    margin-bottom: rem(10);
  }

  &__description:not(:last-child) {
    margin-right: rem(20);
  }

  &__description-wrapper {
    display: flex;
  }

  &__order-text {
    font-weight: 400;
    margin-left: rem(10);
  }

  &__product-info-text {
    font-weight: 400;
    margin-left: 0;
    z-index: 2;
  }

  &__item-container {
    display: grid;
    grid-template-columns: 25% 40% 30%;
    grid-gap: 3%;
    margin-bottom: rem(100);
  }

  &__product-info {
    font-size: rem(24);
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__refunded-image, &__voided-image {
    position: absolute;
    width: fit-content;
    z-index: 3;
  }

}

.refunded {
  text-decoration: line-through;
  color: #DC1414;
  position: relative;
}

@include desktop {
  .Orders {
    &__order-container {
      margin: 0 auto rem(100);
      padding: 0 rem(95);
    }
  }
}

@include laptop {
  .Orders {
    &__title-wrap {
      margin-bottom: 0;
    }
    &__order-container  {
      padding: 0 5%;
    }

    &__description {
      display: flex;
      flex-direction: column;
    }

    &__order-text {
      margin-left: 0;
    }

    &__product-wrapper {
      margin: rem(40) 0;
      grid-template-columns: repeat(2, rem(370));
    }
  }
}

@include mobile {
  .Orders {
    padding-top: 0;
    &__order-container {
      padding: 0 5%;
    }

    &__total-container {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }

    &__item-container {
      display: grid;
      grid-template-columns: 20% 35% 44%;
      grid-gap: 1%;
    }

    &__refunded-image {
      left: 0;
      bottom: 120px;
    }

    &__voided-image {
      bottom: 50px;
      left: 0;
    }

    &__product-wrapper {
      grid-template-columns: 1fr;
      justify-content: center;
    }
    &__details {
      align-items: center;
    }

    &__description-wrapper {
      display: flex;
      flex-direction: column;
      padding-left: rem(15);
    }

    &__product {
      max-width: rem(600);
    }

    &__product-reviews-title-wrap {
      padding-left: 5%;
      padding-right: 5%;
    }

    &__list-products-review-title {
      &::before,
      &::after {
        display: none;
      }
    }

    &__list-products-review,
    &__no-purchase,
    &__no-purchase-review {
      margin-right: 5%;
      margin-left: 5%;
    }
  }
}
