@import "../../../styles/lib";

.LinkButton {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  background-color: transparent;
  border: 3px solid #000000;
  width: rem(220);
  height: rem(66);
  font-size: rem(16);
  font-weight: 500;
  font-family: $rubik;
  cursor: pointer;
  outline: none;
  transition: all 200ms ease-in-out;

  &:hover {
    color: $blue;
    border: 3px solid $blue;
  }

  &:focus {
    color: $blue;
    border: 3px solid $blue;
  }
}

@include mobile {
  .LinkButton {
    height: 46px !important;
    min-width: rem(220);
    width: fit-content;
    font-size: 14px;
    padding: 13px;
  }
}
