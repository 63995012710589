@import "src/styles/lib";

.CartItem {
  border: transparent;
  //  background-color: #ffffff;
  &__shipment-number {
    font-size: rem(26);
  }

  &__container {
    display: grid;
    grid-template-columns: 90% 10%;
  }

  &__heart-container {
    position: relative;

    &:hover {
      .CartItem__heart-save {
        display: block;
      }
    }
  }

  &__add-more {
    background-color: #e4f0d9;
    transition: all 600ms ease-in-out;
  }

  &__combine {
    background-color: #e4f0d9;
    transition: all 300ms ease-in-out;
  }

  &__combine-drop {
    background-color: #e4f0d9;
    //color: #ffffff;
    font-size: rem(40);
    padding: rem(100);
    text-align: center;
    transition: all 300ms ease-in-out;
  }

  &__display-none {
    display: none;
    border: transparent;
    background-color: #ffffff;
    transition: all 300ms ease-in-out;
  }

  &__column-5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__add-more-text {
    text-align: center;
    //color: #ffffff;
    font-weight: 600;
    margin-bottom: rem(-30);
  }

  &__bottom-line {
    border-bottom: 1px solid #c7c7c7;
    margin-right: 10%;
  }

  &__specials-message {
    font-weight: 600;
    font-size: rem(20);
    margin-bottom: rem(10);
    line-height: em(42px, 20px);
    letter-spacing: 0.03em;
    &--link {
      color: $blue;
      text-decoration: underline;
    }
  }

  &__details {
    max-width: rem(300);
    display: flex;
    flex-direction: column;
    // align-items: center;
    &--gift {
      max-width: rem(350);
      width: 200px;
      height: rem(200);
    }
  }

  &__heart-save {
    display: none;
    position: absolute;
    top: rem(-35);
    left: rem(-40);
    width: 100px;
    font-size: rem(20);
  }

  &__heart {
    cursor: pointer;
    color: transparent;
    object-fit: contain;
    width: rem(31);
    height: rem(31);

    &--active {
      color: red;
    }
  }

  &__image {
    object-fit: contain;
    width: rem(86);
    height: rem(126);

    &--gift {
      width: rem(150);
      height: rem(150);
    }
  }

  &__sale-price {
    color: $red;
    font-weight: 600;
  }

  &__retail-price {
    &--sale {
      text-decoration: line-through;
    }
  }

  &__row {
    // border-bottom: 1px solid #c7c7c7;
    padding-top: rem(36);
    padding-bottom: rem(36);
    display: grid;
    grid-template-columns: 40% 25% 20% 15%;

    &--gift {
      grid-template-columns: 70% 15% 15%;
    }

    &--autoship {
      grid-template-columns: rem(80) 35% 10% 25% 15% 15%;
    }
  }

  &__input-container {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: rem(0) rem(10);
    border: 1px solid #c7c7c7;
    border-radius: 5px;
    z-index: 50;

    input[type="number"] {
      -moz-appearance: textfield;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  &__input-container--modifier {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: rem(100);
    position: relative;

    input[type="number"] {
      -moz-appearance: textfield;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &__max-message {
    position: absolute;
    top: rem(-80);
    width: rem(300);
    font-size: rem(22);
    text-align: center;
    font-weight: 600;
    color: $red;
  }

  &__input-icons {
    right: rem(2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &__arrow-up {
    cursor: pointer;
    width: 0;
    height: 0;
    border-left: rem(10) solid transparent;
    border-right: rem(10) solid transparent;
    border-bottom: rem(10) solid black;
    margin-bottom: rem(6);
  }

  &__arrow-down {
    cursor: pointer;
    width: 0;
    height: 0;
    border-left: rem(10) solid transparent;
    border-right: rem(10) solid transparent;
    border-top: rem(10) solid black;
  }

  &__input {
    height: 100%;
    width: rem(51);
    appearance: unset;
    outline: none;
    border: white;
    text-align: center;
    font-size: rem(23);
    background: white;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }
  }

  &__column-1 {
    padding-left: rem(20);
    align-items: center;
    display: flex;
    height: rem(180);
    &--gift {
      height: rem(190);
    }
  }

  &__column-2 {
    padding-left: rem(28);
    padding-right: rem(28);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__column-3 {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__column-4 {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__name {
    font-weight: 600;
    font-size: rem(25);
    padding-left: rem(22);
    padding-right: rem(22);
    &:hover {
      color: $blue;
    }

    &--purveyor {
      margin-bottom: rem(10);
      font-weight: 700;
    }

    &--gift {
      margin-bottom: rem(12);
      width: rem(500);
    }
  }

  &__description {
    // text-align: center;
    font-weight: 700;
    font-size: rem(25);
    padding-left: rem(22);
    padding-right: rem(22);
  }

  &__giftSubscription {
    width: rem(500);
    height: rem(200);
    height: 100%;
    // margin: 0 rem(20);
  }
  &__details--gift {
    width: rem(500);
  }

  &__gift-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    font-size: rem(20);
    margin-left: rem(20);
    width: 100%;
  }

  &__gift-to {
    display: flex;
    align-items: center;
    font-size: rem(20);
    line-height: rem(37);
    margin-left: auto;
  }

  &__gift-to-name {
    font-size: rem(20);
    border: 1px solid #ced4da;
    //  min-width: rem(190);
    width: 100%;
    height: 26px;
    padding: 1px 5px;
    margin-left: rem(10);
  }

  &__gift-to-wrapper {
    display: flex;
    align-items: center;
    &--to {
      margin-right: rem(10);
    }
  }

  &__gift-msg {
    border: 1px solid #ced4da;
    // width: rem(480);
    width: 100%;
    height: rem(85);
    margin-left: rem(20);
    margin-top: rem(10);
    padding: rem(10);
    overflow: auto;
    word-break: break-all;
    &--msg {
      font-size: rem(20);
    }
  }

  &__name-wrapper {
    margin-left: rem(20);
    display: flex;
    align-items: center;
  }

  &__gift-from-wrapper {
    display: flex;
    align-items: center;
  }

  &__gift-from-name {
    font-size: rem(20);
    line-height: rem(37);
  }

  &__gift-from {
    font-size: rem(20);
  }

  &__edit {
    font-size: rem(20);
    border-bottom: 1px solid $blue;
    color: $blue;
    margin-left: rem(100);
    margin-top: rem(200);
    height: rem(26);
    padding: 0;
    cursor: pointer;
    font-weight: 400;
  }

  &__title-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__title {
    font-weight: 400;
    font-size: rem(23);
    padding-left: rem(22);
    // padding-right: rem(22);
  }

  &__dropdown {
    width: rem(180);
    min-width: rem(175);
    border: none;
    border-bottom: 1px solid #c7c7c7;
  }

  &__dropdown-box {
    width: rem(155);
    min-width: rem(155);
  }

  &__icons {
    display: flex;
    //position: relative;
    //left: 20px;
    //padding-left: rem(50);
    //padding-right: rem(50);
    align-items: center;
    justify-content: center;
    padding-left: rem(10);
  }

  &__icon {
    object-fit: contain;
    width: rem(20);
    height: rem(20);
    cursor: pointer;
    z-index: 20;
  }

  &__heart {
    object-fit: contain;
    width: 20px;
    height: 20px;
    cursor: pointer;
    color: transparent;

    margin-right: rem(20);
    &--active {
      color: red;
    }
  }
}

@include tablet-big {
  .CartItem {
    &__giftSubscription {
      width: rem(450);
    }
    &__gift-to-name {
      height: 22px;
      padding: 2px 5px;
    }
    &__row {
      padding-right: 20px;
    }
    &__icon {
      position: relative;
      left: 6px;
    }
    &__column-3 {
      padding-right: 10px;
    }
    &__arrow-up {
      border-left: rem(13) solid transparent;
      border-right: rem(13) solid transparent;
      border-bottom: rem(13) solid black;
      margin-bottom: rem(13);
    }

    &__arrow-down {
      border-left: rem(13) solid transparent;
      border-right: rem(13) solid transparent;
      border-top: rem(13) solid black;
    }

    &__max-message {
      top: rem(-80);
      left: rem(-80);
    }
    &__name {
      padding-right: 30px;
    }
  }
}

@include tablet {
  .CartItem {
    &__container {
      display: grid;
      grid-template-columns: 80% 20%;
    }
    &__title {
      padding: 0;
    }
    &__gift-to-name {
      height: 22px;
      padding: 2px 5px;
    }
    &__icons {
      position: relative;
    }
    &__icon {
      position: relative;
      left: 15px;
    }
    &__heart {
      position: relative;
      left: 10px;
    }
    &__edit {
      position: relative;
      right: -3%;
      bottom: 8%;
      width: 23px;
    }
    &__giftSubscription {
      width: 95%;
    }
    &__details--gift {
      width: 95%;
    }

    &__arrow-up {
      border-left: rem(13) solid transparent;
      border-right: rem(13) solid transparent;
      border-bottom: rem(13) solid black;
      margin-bottom: rem(13);
    }

    &__arrow-down {
      border-left: rem(13) solid transparent;
      border-right: rem(13) solid transparent;
      border-top: rem(13) solid black;
    }

    &__max-message {
      top: rem(-80);
      left: rem(-70);
    }
  }
}

@include laptop {
  .CartItem {
    &__edit {
      margin-left: -5px;
    }
  }
}
@include desktop {
  .CartItem {
    &__row--autoship {
      padding-right: 20px;
    }
    &__icons {
      position: relative;
      left: 10px;
    }
    &__name {
      padding-right: 30px;
    }
  }
}
