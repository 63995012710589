@import "src/styles/lib";

.Learn {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

@include mobile {
  #Articles {
    padding-top: 10px;
  }
}
