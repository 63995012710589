@import "../../styles/lib";

.Home {
  width: 100%;
  height: 100%;
  background: url("https://assets.gocoffeego.com/v2/assets/images/heroBanner.png")
    no-repeat center top 0px;
  background-size: contain;
  overflow-x: hidden;
  overflow-y: hidden;

  &__line {
    position: relative;
    .yellow-circle {
      width: rem(256);
      height: rem(256);
      position: absolute;
      bottom: rem(-70);
      left: rem(-140);
    }
  }

  &__line1 {
    margin-top: rem(10);
  }
}
@include tablet {
  .Home {
    margin-top: 50px;
  }
}

@include mobile {
  .Home {
    background: url("https://assets.gocoffeego.com/v2/assets/images/heroBannerMobile2.png")
    no-repeat center top 0px;
    background-size: contain;
  }
}

