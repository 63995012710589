@import "../../../../styles/lib";

.Collapsible {
  margin-top: rem(20);
  &__wrap {
    position: relative;
    display: flex;
    align-items: center;
    border-top: rem(2) solid $black;
    padding: rem(24) 0;
    transition: all 200ms ease-in-out;
    cursor: pointer;
  }

  &__name {
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: $black;
    font-weight: 700;
    font-size: rem(16);
    padding-right: 2rem;
    line-height: rem(24);
  }

  &__close::before,
  &__close::after {
    content: "";
    position: absolute;
    width: rem(35);
    height: rem(2);
    background-color: $black;
    top: rem(37);
    right: 0;
    transition: all 200ms ease-in-out;
  }

  &__close::after {
    transform: rotate(90deg);
  }

  &__content {
    display: none;
  }

  &__item {
    display: block;
    position: relative;
    cursor: pointer;
    padding-left: rem(30);
    margin-bottom: rem(12);
    font-size: rem(16);
    user-select: none;
  }

  .open {
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }

    &__content {
      display: block;
      line-height: rem(24);
      transition: all 200ms ease-in-out;
    }
  }
}

@include mobile {
  .Collapsible {
    &__wrap {
      border-top: 2px solid $black;
    }
    &:first-child {
      margin-top: 40px;
    }
    &__name {
      font-size: 18px;
      font-weight: 600;
      padding-bottom: 0;
    }
  }
}
