@import "src/styles/lib";

.Characteristics {
  margin-top: rem(64);
  margin-bottom: rem(20);
  border-top: 2px solid $black;
  &__row {
    display: flex;
    align-items: center;
    margin-top: rem(15);
  }

  &__title {
    font-size: rem(20);
    background: #ff8900;
    width: fit-content;
    padding: rem(10);
    letter-spacing: 0.2rem;
    font-weight: 400;
    text-transform: uppercase;
    min-width: rem(150);
    text-align: center;
    font-family: $cairo;
  }

  &__description {
    font-size: rem(20);
    letter-spacing: 0.2rem;
    line-height: em(20px, 20px);
    font-weight: 600;
    margin-left: rem(16);
  }

  &__link-container {
    padding-top: rem(45);
    padding-bottom: rem(45);
    min-height: rem(200);
  }

  &__more-factoids {
    padding-bottom: 10px;
    min-height: rem(200);
  }

  &__more-factoids-title {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    font-size: rem(22);
    cursor: pointer;
    font-weight: 700;
    color: #0acdd4;
    margin-top: rem(30);
    margin-bottom: rem(10);
  }
}

@include mobile {
  .Characteristics {
    margin-top: 0;
    border-top: none;

    &__more-factoids {
      min-height: 100%;
    }

    &__more-factoids-title {
      font-size: 16px;
    }

    &__description {
      font-size: 16px;
    }

    .ProductDetails__title3 {
      display: none;
    }
  }
}
