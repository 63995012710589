@import "./src/styles/lib";

.CoffeStyleCircle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: rem(89);

  &:nth-child(5) {
    margin-right: 0;
  }
  &:nth-child(5) img {
    width: rem(195);
    height: rem(195);
  }

  &__wrap {
    width: rem(220);
    height: rem(220);
    border: 3px solid #000000;
    border-radius: 50%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__image {
    width: rem(180);
    height: rem(180);
    transition: all 300ms ease-in-out;
    &:hover {
      transform: scale(1.2);
    }
  }

  &__title {
    text-align: center;
    padding: rem(30);
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    font-family: $cairo;
    text-transform: uppercase;
  }
}

@include mobile {
  .CoffeStyleCircle {
    margin: 20px;
    &__image {
      transition: none;
      &:hover {
        transform: none;
      }
    }

    &:nth-child(5) {
      margin: 20px;
    }
  }
}
