@import "../../styles/lib";

.JoinClub {
  width: 100%;
  padding-top: rem(100);
  padding-bottom: rem(150);
  overflow-x: hidden;
  background: white;
  padding-left: 10%;
  padding-right: 10%;
  display: grid;
  position: relative;
  box-sizing: border-box;
  &__or-container {
    margin-top: rem(17);
    margin-bottom: rem(17);
  }

  &__or {
    font-size: rem(22);
    color: white;
    background: black;
    height: 100%;
    text-align: center;
    padding-top: rem(9);
    padding-bottom: rem(9);
    cursor: pointer;
  }

  &__main-title {
    text-align: center;
    margin-bottom: rem(60);
    z-index: 3;
  }

  &__title {
    margin-top: rem(-40);
    margin-bottom: rem(40);
    font-weight: 600;
    font-size: rem(34);
    line-height: em(35px, 34px);
    text-align: center;
    &--second {
      font-weight: 400;
      margin-bottom: rem(70);
    }
  }

  &__subtitle {
    font-weight: 600;
    font-size: rem(22);
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 0;
    color: #00d1d6;
  }

  &__container {
    display: grid;
    grid-template-columns: 45% 45%;
    grid-column-gap: 10%;
  }

  &__button {
    width: 100%;
    background: $white;
    text-transform: uppercase;
    font-size: 1.75rem;
    color: $black;
    font-weight: 600 !important;
    border: 3px solid #00d1d6;
    height: rem(66);
    margin-top: rem(100);
    margin-bottom: rem(10);
  }

  .PaymentMethod {
    width: 100%;
    &__content {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
    }
  }

  .CardBox {
    border: 1px solid black;
    min-width: rem(600);
    width: 100%;
    padding-left: rem(29);
    padding-top: rem(9);
    padding-bottom: rem(9);
    margin-bottom: rem(17);
    position: relative;

    &__input {
      margin-right: rem(10);
    }

    &__item {
      font-size: rem(22);
      margin-right: rem(17);
    }

    &__button {
      position: absolute;
      right: rem(20);
      bottom: rem(10);
      font-size: rem(20);
    }
  }

  &__content {
    background: white;
    min-width: rem(600);
    width: 100%;
    margin-top: rem(53);
    padding-top: rem(151);
    padding-bottom: rem(101);
    padding-left: 5%;
    padding-right: 5%;
    margin-left: 10%;
    margin-right: 10%;
    border: 1px solid #707070;
    position: relative;
    box-sizing: border-box;
  }

  &__message {
    text-align: center;
    font-size: rem(26);
    color: $red;
    margin-top: rem(30);

    &--required {
      margin-top: rem(30) !important;
    }
  }
}

@include mobile {
  .JoinClub {
    padding-top: 70px;
    &__container {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 0;
    }

    .PaymentMethod {
      width: 100%;
      &__cards {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 0;
      }
      &__content {
        grid-template-columns: 1fr;
      }
    }
  }
}
