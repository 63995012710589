@import "./src/styles/lib";

.Account {
  overflow: hidden;

  &__wrapper {
    display: grid;
    margin-top: 2rem;
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 20%;
  }
}

@include tablet {
  .Account {
    &__container {
      padding: 0 15%;
    }
  }
}

@include mobile {
  .Account {
    &__container {
      display: grid;
      grid-template-columns: 1fr;
      padding: 0;
    }
    &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
      position: relative;
      padding-left: 25px;
      padding-right: 25px;
    }

    &__quiz-button {
      width: 120px;
      height: 120px;
      top: 46%;
      left: 35%;
    }
  }
}
