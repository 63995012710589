@import "../../../styles/lib";

.Quantity {
    &__input-container {
        margin-top: 10px;
        display: flex;
        align-items: center;
        max-width: 150px;
        width: 100%;
        border: 1px solid #c7c7c7;
        border-radius: 5px;
    
        input[type="number"] {
          -moz-appearance: textfield;
        }
    
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
    }

    &__plus {
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 25px;
        background: transparent;
        outline: none;
        border: none;
        color: $black;
        &:disabled {
            color:  #c7c7c7 !important;
          }
      }
    
      &__minus {
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        background: transparent;
        outline: none;
        border: none;
        color: $black;
        &:disabled {
            color:  #c7c7c7 !important;
          }
      }
    
      &__input {
        width: 50%;
        height: 100%;
        appearance: unset;
        outline: none;
        border: white;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        background: transparent !important;
        color: black !important;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
    
        &:disabled {
          color: black !important;
        }
    }

   
}

@include mobile {
  .Quantity {
   
    &__input-container {
      margin-top: 10px;
      border: 1px solid #ccd9e2;
      border-radius: 5px;
      height: 33px;
      width: 120px;
    }
    &__input {
      width: 60%;
    }
    &__minus {
      width: 20%;
    }
    &__plus {
      width: 20%;
    }
  }
}