@import "src/styles/lib";

.AssignedClub {
  min-height: 100vh;
  margin-top: rem(234);
  margin-bottom: rem(150);
  display: flex;
  flex-direction: column;
  align-items: center;

  &__main-title {
    top: -100px;
    left: -180px;
  }

  &__title-wrapper {
    margin: rem(50) auto rem(30);
  }

  &__title-desc {
    margin: rem(10) auto rem(10);
    text-align: center;
    font-size: rem(36);
    font-weight: 600;
    letter-spacing: 0.003em;
    padding: 0 5%;
  }
  &__subtitle-desc {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0 auto rem(40);
    padding: 0 5%;
  }

  &__subtitle-desc li {
    list-style-type: disc;
    font-size: rem(26);
    margin-right: rem(40);
    font-weight: 500;
   
    &::marker {
      color: $blue;
      font-size: 1.2em;
    }
    
  }

  &__prof-peaberry {
    position: absolute;
    width: rem(222);
    height: rem(230);
    object-fit: contain;
    top: rem(-94);
    right: rem(10);
    z-index: 3;
}

&__bubble {
  position: absolute;
  font-size: rem(24);
  line-height: 20px;
  width: 190px;
  background: $white;
  border: 1px solid #e3e3e3;
  border-radius: rem(60);
  padding: rem(20);
  text-align: center;
  top: rem(-40);
  right: rem(250);
  color: $black;
  z-index: 4;

  &:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 6px solid #e3e3e3;
    border-right: 6px solid transparent;
    border-top: 8px solid #e3e3e3;
    border-bottom: 8px solid transparent;
    left: 189px;
    bottom: 40px;
  }
   &:after {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 6px solid #ffffff;
    border-right: 6px solid transparent;
    border-top: 8px solid #ffffff;
    border-bottom: 8px solid transparent;
    left: 187px;
    bottom: 39px;
  } 
}

&__bubble-link {
  color: $blue;
  font-weight: 500;
  cursor: pointer;
  display: block;
  transition: all 200ms ease-in-out;
  &:hover {
    color: $black;
  }
}

  &__learn-more {
    font-size: rem(30);
    font-weight: 500;
    margin-top: rem(20);
    cursor: pointer;

    &:hover {
      color: $blue;
    }
  }

  &__line {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: rem(-85);
      right: rem(299);
      border-radius: 50%;
      background: #ff0006;
      height: rem(232);
      width: rem(232);
      z-index: 3;
      opacity: 0.7;
    }
  }

  &__cart {
    margin-top: rem(105);
    width: rem(1526);
    min-height: rem(456);
    border: 3px solid $black;
    background-color: $white;
    //z-index: 1;
    display: grid;
    grid-template-columns: 30% 45% 25%;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: rem(-20);
      right: rem(80);
      background: url("https://assets.gocoffeego.com/v2/assets/images/PayAsYouGoImages/Girl-on-Scooter.png")
        center center;
      background-repeat: no-repeat;
      background-size: contain;
      width: rem(293);
      height: rem(240);
    }
  }

  &__clubs {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      display: block;
      background-color: $black;
      top: 100px;
      left: 48%;
      width: 20px;
      height: 100%;
      z-index: -1;
    }
    &::before {
      content: "";
      position: absolute;
      display: block;
      background-color: $black;
      top: 100px;
      left: 51%;
      width: 20px;
      height: 100%;
      z-index: -1;
    }

    .AssignedClub__cart {
      &::after {
        display: none;
      }
    }
  }

  &__column-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__column-2 {
    display: flex;
    flex-direction: column;
    // align-items: center;
  }

  &__column-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: rem(195);
  }

  &__image {
    object-fit: contain;
    height: rem(337);
    width: rem(310);
  }

  &__subtitle {
    text-align: center;
    font-size: rem(24);
    font-weight: 600;
    //  color: $blue;
    letter-spacing: 0.03em;
    margin-bottom: rem(30);
    text-transform: uppercase;
  }

  &__club-title {
    text-transform: uppercase;
    font-size: rem(24);
    font-weight: 700;
    margin-top: rem(31);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: rem(5);

    &--green {
      color: #6ae200;
    }
  }

  &__title {
    text-transform: uppercase;
    font-size: rem(24);
    font-weight: 700;
    margin-top: rem(31);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: rem(59);

    &--green {
      color: #6ae200;
    }
  }

  &__icon {
    object-fit: contain;
    height: rem(37);
    width: rem(24);
    margin-left: rem(9);
    cursor: pointer;
  }

  .CoffeeQuantity {
    display: flex;
    justify-content: space-between;
    padding-left: rem(36);
    padding-right: rem(36);
    border: 1px solid $black;
    padding-top: rem(13);
    padding-bottom: rem(13);
    margin-bottom: rem(23);
    width: 100%;
    &__title {
      font-size: rem(28);
      font-weight: 600;
    }
  }
  .Shipment {
    padding-left: rem(36);
    border: 1px solid $black;
    padding-top: rem(9);
    padding-bottom: rem(9);
    margin-bottom: rem(23);
    width: 100%;
    &__title {
      font-size: rem(28);
      font-weight: 600;
    }
  }

  .Buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: rem(20);

    &__go-back,
    &__all-clubs {
      border: none;
      background-color: transparent;
      color: $blue;
      outline: transparent;
      font-family: $cairo;
      font-size: rem(28);
      line-height: rem(52);
      font-weight: 600;
      text-decoration: underline;
      padding: 0;
    }

    &__go-back {
      margin-right: auto;
    }
  }

  &__button {
    position: relative;
    font-size: rem(20);
    letter-spacing: 0.1em;
    font-weight: 700;
    color: black;
  }

  &__description {
    width: rem(1526);
    padding-left: rem(242);
    padding-right: rem(242);
    padding-top: rem(40);
    padding-bottom: rem(40);
    background: $black;
  }

  &__text {
    font-size: rem(24);
    font-weight: 600;
    color: white;
    line-height: em(50px, 24px);
    text-align: center;
  }

  &__message {
    text-align: center;
    color: $blue;
    font-size: rem(24);
    margin-top: rem(20);
  }

  &__error {
    text-align: center;
    color: $red;
    font-size: rem(24);
    margin-top: rem(20);
  }

  &__required {
    color: $red;
    font-size: rem(20);
    margin-top: rem(-30);
  }

  &__price-range-question {
    text-align: right;
    color: $black;
    cursor: pointer;
    font-size: rem(20);
    transition: color 200ms ease-in-out;
    &:hover {
      color: $blue;
    }
  }
}

@include tablet {
  .AssignedClub {
    width: 100%;

    &__bubble {
      font-size: rem(24);
      line-height: 20px;
      top: rem(-40);
      right: rem(320);
    }

    &__prof-peaberry {
      position: absolute;
      width: rem(222);
      height: rem(230);
      object-fit: contain;
      top: rem(-94);
      right: 50px;
      z-index: 5;
  }

    &__line {
      &::after {
        display: none;
      }
    }

    &__cart {
      width: 90%;
      grid-template-columns: 3fr 4fr 2fr;
      margin-left: 5%;
      margin-right: 5%;

      &::after {
        display: none;
      }
    }

    &__clubs {
      width: 100%;
      grid-template-columns: 3fr 4fr 2fr;
      margin-left: 5%;
      margin-right: 5%;
      &::after {
        content: "";
        position: absolute;
        display: block;
        background-color: $black;
        top: 100px;
        left: 52%;
        width: 20px;
        height: 80%;
        z-index: -1;
      }
      &::before {
        content: "";
        position: absolute;
        display: block;
        background-color: $black;
        top: 100px;
        left: 48%;
        width: 20px;
        height: 80%;
        z-index: -1;
      }
    }

    &__column-1 {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    &__column-3 {
      margin-top: 0;
      padding-left: 10%;
      padding-right: 10%;
      padding-top: 50px;
      padding-bottom: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      justify-items: center;
    }

    &__description {
      width: 90%;
      padding-left: 5%;
      padding-right: 5%;
      margin-left: 5%;
      margin-right: 5%;
    }

    &__button {
      width: 100%;
    }
  }
}

@include mobile {
  .AssignedClub {
    margin-top: rem(70);
    width: 100%;

    &__bubble {
      font-size: rem(24);
      line-height: 20px;
      top: rem(-40);
      right: rem(270);
    }

    &__prof-peaberry {
      position: absolute;
      width: rem(222);
      height: rem(230);
      object-fit: contain;
      top: rem(-94);
      right: 20px;
      z-index: 5;
  }

  &__subtitle-desc {
    margin: 0 auto rem(55);
  }

    &__line {
      &::after {
        display: none;
      }
    }

    &__main-title {
      top: -80px;
      left: -135px;
    }

    &__cart {
      width: 90%;
      grid-template-columns: 1fr;
      padding-left: 5%;
      padding-right: 5%;
      margin-left: 5%;
      margin-right: 5%;

      &::after {
        display: none;
      }
    }

    &__clubs {
      width: 100%;
      grid-template-columns: 1fr;
     
      &::after {
        content: "";
        position: absolute;
        display: block;
        background-color: $black;
        top: 100px;
        left: 52%;
        width: 20px;
        height: 100%;
        z-index: -1;
      }
      &::before {
        content: "";
        position: absolute;
        display: block;
        background-color: $black;
        top: 100px;
        left: 44%;
        width: 20px;
        height: 100%;
        z-index: -1;
      }
    }

    &__column-1 {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    &__column-2 {
      width: 100%;
    }

    &__column-3 {
      margin-top: 0;
      padding-top: 50px;
      padding-bottom: 50px;
    }

    &__description {
      width: 90%;
      padding-left: 5%;
      padding-right: 5%;
      margin-left: 5%;
      margin-right: 5%;
    }
  }
  .Shipment {
    width: 200px;
  }
}
