@import "src/styles/lib";

.YouMayAlsoLike {
  height: 100%;
  width: 100%;
  margin-top: rem(89);
  margin-bottom: rem(100);


  &__no-items {
    @include empty-message;
  }

  &__slider {
    margin-top: rem(-300);
    margin-bottom: rem(118);
    padding-left: 11%;
    padding-right: 11%;
  }

  &__line {
    background: black;
    width: 100%;
    height: rem(91);
    margin-bottom: rem(28);
    position: relative;
    margin-top: rem(40);
    //&:nth-child(2) {
    //  margin-bottom: 0;
    //}
  }
}

@include mobile {
  .YouMayAlsoLike {
    &__slider {
      padding-left: 5%;
      padding-right: 5%;
    }
  }
}
