@import "src/styles/lib";

.ViewAddress {
  min-height: rem(100);
  &__content {
    border: 1px solid black;
    padding: rem(11) rem(23);
  }

  &__description {
    font-size: rem(20);
    margin-bottom: rem(10);
  }
}

@include mobile {
  .ViewAddress {
    &__description {
      font-size: 14px;
    }
  }
}
