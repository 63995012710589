@import "../../../styles/lib";

.MyCreditCards {
  width: 100%;
  height: 100%;
  padding-bottom: rem(240);
  padding-top: rem(160);
  font-family: $cairo;
  color: $black;
  font-size: rem(20);
  display: flex;
  justify-content: center;

  &__forms-wrap {
    padding: rem(44) rem(60);
    border: 1px solid $black;
    width: rem(800);
  }

  &__cards {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    overflow: visible;
  }

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: rem(461);
    min-height: rem(546);
    border: 3px solid $black;
    padding: rem(30) rem(40);
    font-size: rem(25);
    line-height: rem(47);
    margin-right: rem(20);
    margin-top: rem(20);
  }

  &__type-card-img {
    width: 100%;
    max-width: rem(100);
    height: auto;
    margin-bottom: rem(20);
  }

  &__image-wrap {
    display: flex;
    justify-content: space-between;
    height: 50px;
  }

  &__card-info {
    margin-bottom: rem(20);
    min-height: rem(240);
  }
  &__card-info--detail {
    overflow-wrap: break-word;
  }

  &__no-cards {
    width: rem(461);
    min-height: rem(546);
    border: 3px solid $black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-right: rem(20);
    margin-top: rem(20);
  }

  &__no-cards-img {
    width: rem(300);
    height: auto;
    margin-top: rem(100);
    margin-left: rem(20);
  }

  &__cards-wrap {
    padding-left: 10%;
    padding-right: 10%;
  }

  &__subtitle {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  &__edit-button,
  &__delete-button {
    width: rem(136);
    height: rem(50);
    border: none;
    outline: none;
    font-size: rem(25);
    font-weight: 500;
    color: black;
  }

  &__edit-button {
    margin-right: rem(30);
    background-color: $blue;
  }

  &__delete-button {
    background-color: #ff888b;
  }

  &__button {
    border: none;
    outline: none;
    width: rem(309);
    height: rem(66);
    background-color: #00d1d6;
    font-size: rem(25);
    line-height: rem(47);
    margin-bottom: rem(58);
    transition: all 200ms ease-in-out;
    color: black;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }
  }

  &__default-wrap {
    display: flex;
    align-items: center;
    label {
      font-size: 12px;
      margin-left: 10px;
    }
  }

  &__default input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  &__default-control {
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 1px solid $black;
    transform: translateY(-0.05em);
    position: relative;
    &::before {
      content: "";
      width: 12px;
      height: 12px;
      box-shadow: inset 12px 12px #00ff6f;
      border-radius: 50%;
      top: 0;
      left: 0;
      position: absolute;
    }
  }

  &__default-control-not {
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 1px solid $black;
    transform: translateY(-0.05em);
    position: relative;
  }
  &__club-card-wrapper {
    margin-top: rem(10);
  }

  &__label {
    font-size: 12px;
  }

  &__labels {
    font-size: rem(20);
    cursor: pointer;
  }

  &__radio-button {
    margin-bottom: 0.2125rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
  }

  &__outer-circles {
    width: 16px;
    height: 16px;
    min-width: rem(18);
    min-height: rem(18);
    border: rem(1) solid #707070;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: rem(10);
    transition: all 200ms;
  }

  &__inner-circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: transparent;
    transition: all 200ms;

    &--active {
      background-color: #00ff6f;
    }
  }
}

.goBack {
  font-size: rem(24);
  color: $blue;
  padding-left: 10%;
  margin-top: rem(100);
  margin-bottom: rem(-160);
}

@include mobile {
  .MyCreditCards {
    padding-top: rem(60);

    &__cards {
      grid-template-columns: rem(461);
    }
  }
  .goBack {
    font-size: rem(24);
    color: $blue;
    padding-left: 20%;
    margin-top: 20px;
    margin-bottom: -20px;
  }
}

@include tablet {
  .MyCreditCards {
    &__cards {
      grid-template-columns: repeat(2, rem(461));
    }
  }
}
