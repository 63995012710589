@import "../../../../styles/lib";

.SelectCountry2 {
  width: 100%;
  border: 1px solid black;
  height: rem(62);
  //border-radius: rem(15);
  position: relative;
  margin-bottom: rem(26);
  background-color: $white;
  &:focus {
    border: 1px solid $blue !important;
    outline: none !important;
  }

  &__header-disabled {
    pointer-events: none;
    opacity: 0.02;
    &:focus {
      border: none !important;
    }
  }

  &--highlight {
    background: #c4f4f6 !important;
  }

  &__arrow-down {
    cursor: pointer;
    width: 0;
    height: 0;
    border-left: rem(10) solid transparent;
    border-right: rem(10) solid transparent;
    border-top: rem(10) solid #00d1d6;
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
  }

  &__icon {
    position: absolute;
    right: 10px;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    cursor: pointer;
  }

  &__item {
    font-size: 16px;
    font-weight: 400;
    line-height: em(16px, 16px);
    margin: 0;
    &:focus {
      border: none !important;
    }
  }

  &__header {
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding-left: rem(19);
    padding-right: rem(44);
    position: relative;
    cursor: pointer;
    overflow: hidden;
    &:focus {
      border: 1px solid $blue !important;
      outline: none !important;
    }
    &:focus-visible {
      border: none !important;
    }
  }

  &__title {
    font-size: rem(25);
    font-weight: 400;
    color: #d1cece;
    &:focus {
      border: none !important;
    }
  }

  &__title2 {
    font-size: rem(25);
    font-weight: 400;
    &:focus {
      border: none !important;
    }
  }

  &__title3 {
    font-size: rem(25);
    font-weight: 400;
    color: $blue;
    outline: none;
    border: none;
    background: transparent;
    margin-left: auto;
  }

  &__icon {
    position: absolute;
    right: 10px;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    cursor: pointer;
  }

  &__items {
    overflow-y: scroll;
    // max-height: rem(500);
    max-height: rem(300);
    width: 100%;
    position: absolute;
    z-index: 5;
    display: none;
    background: white;
    border: 2px solid #ccd9e2;
    margin-top: rem(10);
    padding: 10px;

    &.active {
      display: block;
    }
  }

  &__item-container {
    padding: rem(8);
    border-radius: 4px;
    line-height: rem(21);

    &.activeLabel {
      background: #f2f5f7;
    }

    &:hover {
      background: #f2f5f7;
      cursor: pointer;
    }
  }
}

@include mobile {
  .SelectCountry2 {
    height: rem(70);
    &__items {
      // max-height: rem(700);
      max-height: 250px;
    }
  }
}
