@import "src/styles/lib";

.EditGiftCertificate {
  width: rem(500);
  height: rem(200);
  height: 100%;
  margin: 0 rem(20);
  &__form {
    //  display: grid;
    // grid-template-columns: ;
  }

  &__form-button {
    width: 80px;
    background-color: transparent;
    padding: 0;
    border: none;
    text-decoration: underline;
    line-height: 1;
    color: $blue;
    font-size: rem(20);
    margin-left: 0;
    margin-top: 0;
    &:hover {
      border-color: $blue;
    }
  }

  &__inputs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  &__input-container {
    display: flex;
    align-items: center;
    font-size: rem(20);
  }

  &__label {
    margin-right: rem(10);

    &--from {
      margin-left: rem(20);
    }
  }

  &__button-wrap {
    font-size: rem(18);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__textarea {
    height: rem(85) !important;
    width: 100%;
    resize: none;
    font-size: rem(20) !important;
    margin-bottom: rem(10);
    background-color: transparent !important;
    border: 1px solid #c4c7c7;
  }

  &__input {
    height: rem(40) !important;
    margin-bottom: rem(10) !important;
    margin-right: 0 !important;
    font-size: rem(20) !important;
    width: 100%;
    background-color: transparent !important;
    border: 1px solid #c4c7c7;
  }

  &__message {
    color: $blue;
    font-size: 12px;
  }

  &__required {
    color: $red;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    margin-top: rem(-10);
  }
}

@include mobile {
  .EditGiftCertificate {
    width: rem(200);
    height: rem(430);
    margin: 0;
    &__textarea {
      min-width: 120px !important;
      height: 80px !important;
      width: 100% !important;
      resize: none;
      font-size: 13px !important;
      margin-bottom: rem(10);
      border: 1px solid $black !important;
    }

    &__inputs {
      grid-template-columns: 1fr;
    }
    &__input-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: rem(20);
    }

    &__required {
      font-size: 10px;
      line-height: 18px;
    }

    &__form {
      display: grid;
      grid-template-columns: 1fr;
    }

    &__form-button {
      font-size: 14px;
      text-align: left;
      margin-left: 0;
      margin-top: rem(20);
      margin-bottom: rem(60);
    }

    &__label {
      margin-right: rem(10);
      font-size: 14px;

      &--from {
        margin-left: 0;
      }
    }

    &__button-wrap {
      margin-bottom: rem(60);
      flex-direction: column;
      align-items: flex-start;
    }

    &__input {
      min-width: 180px !important;
      font-size: 13px !important;
      border: 1px solid $black !important;
      margin-bottom: rem(10) !important;
      margin-right: 0 !important;
      width: 100% !important;
      height: 26px !important;
    }
  }
}

@include desktop {
  .EditGiftCertificate {
    &__form-button {
      margin-left: rem(5);
    }
  }
}

@include tablet-big {
  .EditGiftCertificate {
    width: rem(450);
    &__form-button {
      text-align: right;
    }
  }
}

@include tablet {
  .EditGiftCertificate {
    width: 95%;
    &__form-button {
      text-align: right;
    }
    &__message-remaining {
      //background: red;
      //font-size: 12px;
    }
  }
}
