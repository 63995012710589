@import "../../../styles/lib";

.RedeemCard {
  font-family: $cairo;
  width: 100%;
  height: 100%;
  padding-top: rem(100);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  &__wrap {
    margin: rem(55) rem(130);
    // padding-left: rem(150);
    padding-left: auto;
    border: 3px solid $black;
    max-width: 1100px;
    width: 100%;
  }

  &__required {
    color: $red;
    font-size: rem(18);
  }

  &__success {
    color: $blue;
    font-size: rem(18);
  }

  &__title {
    font-weight: 600;
    font-size: rem(32);
    padding-top: rem(15);
    margin-bottom: rem(60);
    line-height: em(47px, 25px);
    text-align: center;
  }
  &__text-wrap {
    max-width: rem(1150);
    margin: 0 auto 1rem;
  }

  &__text {
    font-size: rem(25);
  }

  &__form {
    display: flex;
    flex-direction: column;
    max-width: rem(1150);
    margin: 0 auto;
  }

  &__form-text-wrap {
    margin: 2rem 0;
    font-size: rem(25);
    line-height: em(47px, 25px);
    max-width: rem(770);
    position: relative;
    &::before {
      content: "";
      background: url("https://assets.gocoffeego.com/v2/assets/images/skater-boy.png")
        no-repeat center center;
      background-size: 100%;
      position: absolute;
      width: rem(167);
      height: rem(228);
      top: rem(-30);
      right: rem(-130);
    }
  }

  &__input {
    background: transparent;
    letter-spacing: 2px;
    max-width: rem(760);
    width: 100%;
    margin-bottom: rem(10);
    font-size: rem(25);
    border: 1px solid $black;
    padding: rem(5);
    -webkit-appearance: none;
    box-shadow: none;
    border-radius: 0 !important;
  }

  &__button {
    background-color: transparent;
    border: 3px solid $black;
    //text-transform: uppercase;
    font-size: rem(24);
    letter-spacing: 0.02em;
    line-height: rem(47);
    font-weight: 400;
    font-family: $rubik;
    cursor: pointer;
    outline: none;
    width: rem(290);
    height: rem(66);
    margin: rem(72) auto rem(30);
    transition: all 200ms ease-in-out;
    color: black;
    &:hover {
      border: 3px solid $blue;
    }
  }

  input[type="text"]:focus {
    outline: 1px solid $black;
    background-color: transparent;
    color: $black;
    -webkit-appearance: none;
    box-shadow: none;
    border-radius: 0 !important;
  }

  &__terms-wrapper {
    max-width: rem(1150);
    margin: 0 auto 1rem;
  }

  &__terms {
    max-width: rem(200);
    font-size: rem(24);
    text-decoration: underline;
    cursor: pointer;
    font-weight: 600;
    color: $blue;
    &:hover {
      color: #000000;
    }
  }
}

@include mobile {
  .RedeemCard {
    padding-top: 0;
    &__nav {
      padding-left: 10%;
      margin-top: 0;
    }

    &__wrap {
      margin: 5% 7%;
      padding: 5%;
    }
    &__input {
      width: 100%;
    }
  }
}

@include tablet {
  .RedeemCard {
    &__text-wrap {
      margin: 0 30px;
      text-align: center;
    }
    &__form {
      text-align: center;
    }
    &__form-text-wrap {
      max-width: 100%;
      text-align: center;
    }
  }
}
