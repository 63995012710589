@import "src/styles/lib";

.TotalCart {
  height: fit-content;
  padding: 0;

  &__sale-price {
    color: $red;
  }

  &__retail-price {
    &--sale {
      text-decoration: line-through;
    }
  }

  &__shipment-container {
    margin-top: rem(30);
    margin-bottom: rem(40);
  }

  &__shipment-wrapper1,
  &__shipment-wrapper2 {
    display: flex;
    justify-content: space-between;
    padding: rem(10) 0 rem(5);
  }
  &__shipment-wrapper1 {
    border-top: 1px solid #ced4da;
    margin-top: rem(10);
  }

  &__shipment {
    padding-top: rem(10);
    padding-bottom: rem(10);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__shipping-message {
    font-size: rem(25);
    padding: rem(20) 0 rem(10);
  }

  &__shipment-title {
    font-size: rem(25);
    font-weight: 700;
    max-width: 300px;
  }

  &__special-message {
    border-bottom: 1px solid #ced4da;
    padding-top: rem(10);
    padding-bottom: rem(10);
  }

  &__special-title {
    font-size: rem(25);
    font-weight: 600;
  }

  &__item {
    cursor: pointer;
    margin-top: rem(5);
    margin-bottom: rem(5);
    display: flex;
    align-items: center;
  }

  &__image {
    object-fit: contain;
    width: rem(100);
    height: rem(100);
  }

  &__title-container {
    margin-left: rem(10);
  }

  &__title {
    font-size: rem(23);
    font-weight: 400;
  }

  &__shipment-title-wrapper {
    display: flex;
    font-size: rem(23);
  }

  &__shipment-title-cost {
    margin-left: auto;
    text-align: right;
  }

  &__price {
    margin-left: auto;
    font-size: rem(24);
    font-weight: 600;
    width: 140px;
    text-align: right;
  }

  &__last-row {
    margin-left: auto;
  }

  &__summary-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__edit-link {
    color: $blue;
    font-size: rem(24);
    cursor: pointer;
    text-decoration: underline;
  }

  .Code {
    display: flex;
    padding-top: rem(15);
    padding-bottom: rem(15);
    border-radius: 0;

    &__active {
      color: $red;
      font-size: rem(24);
      font-weight: 500;
      padding-bottom: rem(10);
    }

    &__success {
      color: #28a745;
      font-size: rem(24);
      font-weight: 500;
    }

    &__input {
      height: rem(62) !important;
      font-size: rem(25);
      font-weight: 400;
      border: 1px solid black;
    }

    &__button {
      width: rem(162);
      background: white;
      margin-left: rem(7);
      height: rem(62);
      color: black;
      border: 3px solid black;
      font-size: rem(25);
      font-weight: 400;
      transition: all 200ms ease-in-out;

      &:hover {
        border: 3px solid $blue;
      }
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
  }

  &__total {
    display: flex;
    justify-content: space-between;
    padding-top: rem(20);
  }

  &__title2 {
    font-size: rem(26);
    font-weight: 400;
    align-self: center;

    &-total {
      font-weight: 700;
      font-size: rem(30);
    }
    &-shipments {
      font-weight: 700;
      margin-bottom: rem(15);
    }
  }

  &__title3 {
    font-size: rem(32);
    font-weight: 400;
    align-self: center;
  }
}

@include tablet {
  .TotalCart {
    margin-left: 20px;
    padding: 0;
  }
}

@include mobile {
  .TotalCart {
    padding-bottom: 10px;
    padding-top: 20px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    margin-left: 0;

    &__shipment-title {
      max-width: 210px;
    }

    .GiftCard {
      &__input {
        font-size: 15px !important;
      }

      &__button {
        font-size: 15px;
      }
    }

    &__image {
      margin-right: 3px;
    }

    &__price {
      font-size: 14px;
    }

    &__title {
      font-size: 14px;
    }

    &__title2 {
      font-size: 15px;
    }
  }
}
