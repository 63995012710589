@import "src/styles/lib";

.RockstarProduct {
  position: relative;
  background: url("https://assets.gocoffeego.com/v2/assets/images/black-shape-3.svg")
    no-repeat center;
  width: rem(548);
  height: rem(548);
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-left: 2.2%;

  &__container {
    position: relative;
  }

  &__icon {
    position: absolute;
    top: rem(88);
    right: rem(-30);
    width: 48px;
    height: 48px;
  }

  &__pinterest-icon {
    width: rem(48);
    height: rem(48);
    object-fit: contain;
  }

  &__image {
    width: rem(360);
    height: rem(360);
    object-fit: contain;
    margin-bottom: rem(21);
  }
  &__button {
    color: black;
  }
}

@include tablet {
  .RockstarProduct {
    position: relative;
    background: url("https://assets.gocoffeego.com/v2/assets/images/black-shape-3.svg")
      no-repeat center;
    width: rem(448);
    height: rem(548);
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    &__icon {
      right: rem(-20);
    }
    &__image {
      margin-bottom: 30px;
    }
  }
}

@include mobile {
  .RockstarProduct {
    background-size: 100% 100%;
    width: 100%;
    height: 360px;
    &__icon {
      right: -30px;
      top: 60px;
    }

    &__image {
      margin-bottom: 30px;
      width: 220px;
      height: 220px;
      object-fit: contain;
    }
    &__pinterest-icon {
      width: 28px;
      height: 28px;
    }
  }
}
