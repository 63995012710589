@import "src/styles/lib";

.PeopleReview {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: rem(109);
  padding-bottom: rem(26);

  &__slider {
    width: 100%;
    min-height: rem(400);
  }

  &__review {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__title {
    margin-top: 0;
    font-size: rem(36);
    font-family: $cairo;
    font-weight: 400;
    letter-spacing: 0.6rem;
    margin-bottom: rem(31);
    text-align: center;
  }

  &__stars {
    margin-top: 0;
    margin-bottom: rem(49);
    width: rem(180);
    height: rem(40);
    object-fit: contain;
  }

  &__quote {
    margin-top: 0;
    font-size: rem(36);
    font-family: $cairo;
    font-weight: 700;
    line-height: em(36px, 36px);
    margin-bottom: rem(17);
    text-align: center;
    width: rem(1095);
  }

  &__author {
    margin-top: 0;
    font-size: rem(24);
    font-family: $cairo;
    font-weight: 700;
    text-align: center;
    margin-bottom: rem(92);
  }
}

@include mobile {
  .PeopleReview {
    padding-left: 20px;
    padding-right: 20px;

    &__title {
      font-size: 20px;
      margin-bottom: 53px;
      font-weight: 400;
      letter-spacing: 0.4rem;
      line-height: em(30px, 20px);
    }

    &__stars {
      margin-bottom: 12px;
      width: 80px;
      height: 20px;
    }

    &__quote {
      font-size: 20px;
      width: auto;
      text-align: justify-all;
    }

    &__author {
      font-size: 20px;
      margin-bottom: 40px;
    }
  }
}
