@import "src/styles/lib";

.TheHistoryOfCoffee {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  width: 100%;

  &__grid3 {
    display: grid;
    grid-gap: rem(10);
    //grid-template-columns: repeat(3, 1fr);
  }

  &__grid {
    display: grid;
    grid-gap: rem(10);
    grid-template-columns: repeat(2, 1fr);
  }

  &__main-image {
    width: 100%;
  }

  &__peaberry {
    position: absolute;
    top: rem(-191);
    right: rem(84);
    width: 20%;
  }

  &__description-image {
    object-fit: contain;
    height: 150px;
    &.left {
      float: left;
    }

    &.right {
      float: right;
    }
  }

  &__title {
    font-size: rem(30);
    font-weight: 700;
    position: relative;
    margin-top: rem(40);
    text-align: center;
    margin-bottom: rem(10);
  }

  &__shape {
    width: rem(67);
    height: rem(67);
    position: absolute;
    bottom: rem(46);
    left: rem(-54);
  }

  &__description {
    margin: 0;
    font-size: rem(24);
    font-weight: 400;
    position: relative;
    margin-bottom: rem(30);
    line-height: em(35px, 24px);
  }

  &__years {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: rem(50);
  }
  &__year {
    text-align: center;
    border-right: 3px solid #000000;
    padding-top: rem(18);
    padding-bottom: rem(18);
    padding-right: rem(28);
    padding-left: rem(28);
    &:hover {
      cursor: pointer;
      color: $blue;
    }
    &:nth-last-child(1) {
      border-right: none;
    }
    &-title {
      white-space: nowrap;
      font-size: rem(24);
      font-weight: 700;
      font-family: $cairo;

      &--active {
        color: $blue;
      }
    }
  }

  &__years-content {
    width: 100%;
    p {
      font-size: rem(24);
      padding-bottom: rem(20);
    }
    h3 {
      margin-top: rem(30);
      margin-bottom: rem(10);
      font-weight: bold;
      font-size: rem(28);
    }
    .BottomLinks {
      &:hover {
        color: $blue;
      }
    }
  }
}

@include mobile {
  .TheHistoryOfCoffee {
    &__container {
      display: grid;
      grid-gap: rem(10);
      grid-template-columns: 1fr;
    }

    &__description {
      font-size: 16px;
    }

    &__description-image {
      &.left {
        float: left;
        width: 40%;
      }

      &.right {
        float: right;
        width: 40%;
      }

      &.turkish {
        width: 30%;
      }
    }

    &__peaberry {
      display: none;
    }
    &__flowers {
      display: none;
    }

    &__peaberry-sitting {
      display: none;
    }

    &__description {
      min-width: auto;
    }

    &__line {
      display: none;
    }

    &__years {
      display: grid;
      grid-template-columns: 1fr;
      padding: 0;
      width: 100%;
      margin-top: 41px;
    }
    &__year {
      border-right: none;
      border-bottom: 2px solid $black;
      margin-left: 50px;
      margin-right: 50px;

      &-title {
        font-size: 12px;
      }
    }
    &__years-content {
      p {
        font-size: 16px;
      }
    }
  }
}
