@import "src/styles/lib";

.ForgotPasswordForm {
  border: 1px solid black;
  padding-top: rem(52);
  padding-bottom: rem(52);
  padding-left: rem(91);
  padding-right: rem(91);
  background: white;
  margin-top: rem(135);
  width: rem(737);

  &__description {
    font-size: rem(24);
    font-weight: 400;
    text-align: center;

    &:nth-child(2) {
      margin-bottom: rem(71);
    }
  }

  &__email-sent {
    color: #28a745;
    font-size: rem(24);
    margin-top: rem(20);
  }

  &__error {
    color: $red;
    font-size: rem(24);
    margin-top: rem(20);
  }

  &__input-container {
    height: rem(60);
    position: relative;
    &--sign-in {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 10px;
        margin-top: auto;
        margin-bottom: auto;
        left: rem(8);
        background: url("https://assets.gocoffeego.com/v2/assets/icons/peaberry-icon.png")
          no-repeat;
        background-size: contain;
        height: rem(32);
        width: rem(32);
      }
    }
  }

  &__required {
    font-size: rem(22);
    color: red;
    margin-top: rem(10);
  }

  &__input {
    padding-left: rem(45) !important;
    width: 100%;
    //height: rem(60) !important;
    border-radius: 0 !important;
    font-size: rem(24) !important;
    border: 0 !important;
    border-bottom: 1px solid #000000 !important;

    &::placeholder {
      color: black !important;
    }
  }

  &__row {
    display: flex;
  }

  &__button {
    color: black;
    margin-top: rem(30);
    height: rem(60);
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    background-color: transparent;
    border: 3px solid #000000;
    width: 100%;
    font-size: rem(32);
    font-weight: 500;
    font-family: $rubik;
    cursor: pointer;
    outline: none;
  }
}

@include mobile {
  .ForgotPasswordForm {
    border: none;

    &__input {
      font-size: 16px !important;
    }
    &__description {
      font-size: 16px;
    }

    &__input-container {
      height: rem(60);
      position: relative;
      &--sign-in {
        &::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          margin-top: auto;
          margin-bottom: auto;
          left: rem(8);
          background: url("https://assets.gocoffeego.com/v2/assets/icons/peaberry-icon.png")
            no-repeat;
          background-size: contain;
          height: rem(32);
          width: rem(32);
        }
      }
    }
  }
}
