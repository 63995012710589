@import "src/styles/lib";

.PrePaid {
  padding-top: rem(234);
  padding-bottom: rem(400);
  position: relative;
  overflow: hidden;

  &__background {
    object-fit: contain;
    width: 100%;
  }
  &__main-title {
    text-align: center;
    font-size: rem(34);
    margin-top: 0;
    margin-bottom: rem(24);
    font-weight: 700;
  }
  &__main-description {
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      max-width: rem(1200);
      margin-bottom: rem(24);
      padding: 0 rem(10);
      z-index: 2;
  }

  &__content {
    margin-top: rem(175);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: rem(173);
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: rem(340);
      height: 100%;
      width: 100%;
      background: url("https://assets.gocoffeego.com/v2/assets/images/gcg_subscribe_prepaid-bg.png")
        no-repeat;
      background-size: 100% 100%;
      z-index: -1;
    }
  }

  &__title {
    &::after {
      z-index: 2;
      content: "";
      position: absolute;
      top: rem(55);
      right: rem(-270);
      height: rem(320);
      width: rem(320);
      background: url("https://assets.gocoffeego.com/v2/assets/images/man-black.png");
      background-size: contain;
    }
  }

  &__line {
    margin-bottom: rem(53);
  }

  &__section-title {
    text-transform: uppercase;
    font-size: rem(32);
    font-weight: 700;
    text-align: center;
  }

  &__subscriptions {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: rem(50);
    margin-top: rem(100);
  }

  &__line-bottom {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: rem(-78);
      left: rem(-49);
      width: rem(279);
      height: rem(279);
      background: #ff008e;
      border-radius: 50%;
      opacity: 0.8;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: rem(-41);
      right: rem(-53);
      height: rem(448);
      width: rem(448);
      background: url("https://assets.gocoffeego.com/v2/assets/images/girl-black.png")
        no-repeat;
      background-size: cover;
    }
  }
}
@include tablet-big {
  .PrePaid {
    &__content {
      &::after {
        top: rem(400);
      }
    }
    &__subscriptions {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@include tablet {
  .PrePaid {
    &__content {
      &::after {
        top: rem(400);
      }
    }
    &__subscriptions {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@include mobile {
  .PrePaid {
    padding-top: 70px;
    &__subscriptions {
      grid-template-columns: 1fr 1fr;
    }

    &__line {
      margin-bottom: 3rem;
    }

    &__content {
       margin-top: 8rem;
       margin-bottom: 8rem;
      &::after {
        top: rem(700);
        height: 50%;
      }
    }

    &__title {
      &::after {
        bottom: -69px;
        right: -78px;
        height: 100px;
        width: 100px;
      }
    }

    &__line-bottom {
      &::after {
        height: 120px;
        width: 120px;
      }
    }
  }
}
