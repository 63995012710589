@import "src/styles/lib";

.CartPopUp {
  &__overlay {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0,0,0,0.5);
      z-index: 2000;
      cursor: pointer;
      transition: all 200ms ease;
    
    }

    &__wrapper  {
      display: flex;
      padding: rem(30) rem(20) rem(20);
      align-items: center;
     // border-top: 9px double #333;
      h2 {
        font-weight: 700;
      }
    }

    &__container {
      position: absolute;
      width: 450px;
      height: 100vh;
      top: 0;
      right: 0;
      opacity: 1;
      visibility: visible;
      margin-top: 0;
      background-color: #ffffff;
      transition: all 200ms ease;
      z-index: 2001;
      padding-bottom: rem(40);
      padding-top: 0;
      display: flex;
      flex-direction: column;
  
    }
    &__close {
      transition: all 200ms;
      font-size: rem(24);
      font-weight: bold;
      text-decoration: none;
      color: $black;
      margin-left: auto;
      cursor: pointer;
      height: 30px;
      border: none;
      background: transparent;
    }

    &__close-btn {
      transition: all 200ms;
      font-size: rem(28);
      font-weight: 400;
      text-decoration: none;
      color: $black;
      margin-left: auto;
      cursor: pointer;
      border: none;
      background: transparent;
      &:hover {
        color: $blue;
      }
    }

    &__content {
      padding: rem(30);
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0.5em;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }
      &::-webkit-scrollbar-thumb {
        background-color: $black;
      }
    }

    &__message {
      margin: auto;
      padding-left: rem(20);
    }

    &__empty-message {
      text-align: center;
      font-size: rem(32);
      margin-bottom: rem(80);
    }

    &__scooter-girl {
      max-width: 300px;
      width: 80%;
      object-fit: contain;
      
     
    }
   
     
    &__item {
      padding: 5% 0;
      margin: 0 2%;
      color: $black;
      display: flex;
      border-bottom: 1px solid #c3c3c3;
    }

    &__image {
      object-fit: contain;
      width: rem(140);
      height: rem(180);
      margin-right: rem(30);
    }

    &__subtotal-wrapper {
      margin-top: auto;
    }

    &__subtitle {
     text-transform: uppercase;
      font-size: rem(20);
      transition: all 200ms ease-in-out;
      &:hover {
        color: $blue;
      }
    }
    &__title {
      font-weight: 700;
      font-size: rem(22);
      margin-bottom: rem(10);
      transition: all 200ms ease-in-out;
      &:hover {
        color: $blue;
      }
    }
    &__details {
      font-size: rem(20);
      margin-bottom: rem(10);
    }

    &__quantity {
      justify-content: left;
    }

    &__buttons-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin: rem(20) auto rem(40);
    }

    &__subtotal {
      text-align: center;
      margin-top: rem(10);
      font-size: rem(24);
      font-weight: 600;
    }

    &__continue-link {
      display: block;
      text-align: center;
      font-size: rem(24);
      color: $blue;
      margin: rem(20) auto;
    }

    &__continue-link-text {
      text-align: center;
      font-size: rem(24);
      margin: 0 auto rem(20);
    }

    &__continue-purveyor-link {
      color: $blue;
    }

    &__button {
      width: rem(180);
      background: $blue;
      color: $white;
      border: none;
      font-weight: 600;
      transition: all 200ms ease-in-out;
      &:hover {
        color: $white;
        border: none;
      }
    }
  }

  .closePopUp {
    opacity: 0;
    visibility: hidden;
    right: -500px;
  }

  .overlay-hide {
    opacity: 0;
    visibility: hidden;
    z-index: -2;
    right: -500px;
  }
 
 

  @include mobile {
    .CartPopUp {
      z-index: 2000;
      &__container {
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        right: 0;
        padding-top: 15px;
        padding-bottom: 50px;
       
      }

      &__subtotal-wrapper {
        padding-bottom: rem(100);
      }

      &__buttons-wrapper {
        margin: rem(40) auto rem(40);
      }

  
      &__button {
        min-width: rem(220) !important;
      }
    }
  
  }