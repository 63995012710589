@import "src/styles/lib";

.NewPasswordForm {
  border: 1px solid black;
  padding-top: rem(52);
  padding-bottom: rem(52);
  padding-left: rem(91);
  padding-right: rem(91);
  background: white;
  margin-top: rem(135);
  width: rem(804);

  &__success {
    font-size: rem(24);
    margin-top: rem(20);
  }

  &__description {
    font-size: rem(24);
    font-weight: 400;
    text-align: center;
    line-height: em(30px, 24px);
    margin-bottom: rem(25);
    letter-spacing: 0.5px;
  }

  &__link-container {
    text-align: start;
    font-size: rem(28);
    font-weight: 400;
    margin-top: rem(20);
    display: flex;
    justify-content: space-between;
  }

  &__label2 {
    font-size: rem(28);
    font-weight: 400;
    margin-left: rem(10);
  }

  &__link {
    font-size: rem(28);
    font-weight: 400;
    color: #0d89aa;
    text-align: end;
    &:hover {
      color: #0d89aa;
    }
  }

  &__input-container {
    margin-bottom: rem(25);
    position: relative;
  }

  &__required {
    font-size: rem(22);
    color: red;
    margin-top: rem(10);
    height: 100%;
    width: 100%;
  }

  &__input {
    color: black !important;
    width: 100%;
    height: rem(60) !important;
    border-radius: 0 !important;
    font-size: rem(25) !important;
    border: 0 !important;
    border-bottom: 1px solid #000000 !important;
    padding-left: 0 !important;

    &::placeholder {
      color: black !important;
    }
  }

  &__row {
    display: flex;
  }

  &__button {
    margin-top: rem(55);
    height: rem(60);
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    background-color: transparent;
    border: 3px solid #000000;
    width: 100%;
    font-size: rem(32);
    font-weight: 500;
    font-family: $rubik;
    cursor: pointer;
    outline: none;
    color: black;
  }
}

@include mobile {
  .NewPasswordForm {
    border: none;
  }
}
