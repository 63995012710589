@import "./src/styles/lib";

.Brand {
  cursor: pointer;
  width: rem(262);
  height: rem(262);
  border: 3px solid #000000;
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  margin-right: rem(29);
  background: white;
  z-index: 10;

  &--all {
    background: $blue;
  }

  &__image {
    height: 120px;
    width: rem(250);
    object-fit: contain;
  }

  &__title {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }
}

@include tablet-big {
  .Brand {
    &__image {
      height: 100px;
    }
  }
}

@include tablet {
  .Brand {
    &__image {
      height: 100px;
    }
  }
}
@include mobile {
  .Brand {
    &__image {
      height: 80px;
    }
  }
}
