@import "src/styles/lib";

.CoffeKnowledge {
  &__line {
    margin-top: rem(200);
    position: relative;
    &::after {
      content: "";
      position: absolute;
      background: url("https://assets.gocoffeego.com/v2/assets/images/learn-flowers.png")
        center center;
      background-size: 100%;
      width: rem(250);
      height: rem(310);
      left: rem(-30);
      top: rem(-130);
    }
  }

  #cover {
    width: 100%;
  }

  &__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  &__background {
    //object-fit: cover;
    //width: 100%;
    //height: 100%;

    &--brewing {
      @include imagePlaceHolder(#c2bab3);
    }

    &--recipes {
      @include imagePlaceHolder(#1b2127);
    }

    &--regions {
      @include imagePlaceHolder(#b4dcf9);
    }

    &--espresso {
      @include imagePlaceHolder(#eddc04);
    }

    &--grinding {
      @include imagePlaceHolder(#2f2921);
    }

    &--making-your-brew {
      @include imagePlaceHolder(#23292b);
    }
  }

  &__background-container {
    margin-top: rem(100);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: rem(500);
  }

  &__item-selected {
    margin-left: rem(375);
    background: $black;
    font-size: rem(18);
    font-weight: bold;
    color: $white;
    width: fit-content;
    padding: rem(14);
    text-transform: uppercase;
  }

  &__title {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: rem(60);
    font-weight: 700;
    color: $white;
    background: $black;
    width: rem(659);
    height: rem(253);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $cairo;
  }

  &__image {
    position: absolute;
    left: rem(-30);
    top: rem(110);
    width: 17%;
    transform: scaleX(-1);
    width: auto;
    height: rem(500);
  }

  &__description {
    width: rem(814);
    font-size: rem(24);
    margin-top: rem(129);
    text-align: center;
    font-weight: 600;
    line-height: em(36px, 24px);
    font-family: $cairo;
  }

  &__items {
    margin-top: rem(94);
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    margin-right: 16%;
    margin-left: 16%;
    z-index: 4;
  }

  &__item {
    padding-top: rem(18);
    padding-bottom: rem(18);
    padding-right: rem(28);
    padding-left: rem(28);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-right: 3px solid $black;
    cursor: pointer;
    &:nth-last-child(1) {
      border-right: none;
    }
  }

  &__item-title {
    font-size: rem(16);
    font-weight: 700;
    line-height: em(26px, 16px);
    text-align: center;
    min-height: rem(43);
    margin-top: rem(38);

    &--active {
      color: $blue;
    }
  }

  &__item-image {
    width: rem(96);
    height: rem(76);
    object-fit: contain;
  }
}

@include tablet {
  .CoffeKnowledge {
    &__image {
      margin-left: 20px;
      width: 10%;
      object-fit: contain;
    }
  }
}

@include mobile {
  .CoffeKnowledge {
    &__background-container {
      height: fit-content !important;
    }
    &__background {
      object-fit: cover;
      width: 80%;
      height: 150px;
    }

    &__description {
      font-size: 16px;
      width: 262px;
    }

    &__line {
      margin-top: 44px;
    }

    &__image {
      display: none;
    }

    &__title {
      width: 262px;
      font-size: 26px;
    }

    &__items {
      display: grid;
      grid-template-columns: 1fr;
      padding: 0;
      width: 100%;
      margin-top: 41px;
    }

    &__item {
      border-right: none;
      border-bottom: 3px solid $black;
      margin-left: 75px;
      margin-right: 75px;
    }

    &__item-image {
      display: none;
    }

    &__item-title {
      margin: 0;
      font-size: 12px;
    }

    &__background-container {
      margin-top: 45px;
      height: 280px;
    }
  }
}
