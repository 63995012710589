@import "src/styles/lib";

.SubscriptionForm {
  margin-top: rem(37);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;

  &__input-container {
    input[type="number"] {
      -moz-appearance: textfield;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &__select-country {
    margin-bottom: 0 !important;
  }

  &__recipient {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: rem(21);
  }

  &__message {
    margin-top: rem(23);
    margin-bottom: rem(11);
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: rem(21);
  }

  &__title {
    font-size: rem(24);
    font-weight: 600;
    text-align: center;
    margin-bottom: rem(22);
  }

  &__required {
    font-size: rem(20);
    color: red;
    margin-top: rem(5);
  }

  &__input {
    border: 1px solid $black !important;
    width: 100%;
    min-width: rem(288);
    font-weight: 400 !important;
    height: rem(60) !important;
    border-radius: 0 !important;
    font-size: rem(22) !important;
  }

  &__textarea {
    margin-top: rem(10) !important;
    border-radius: 0 !important;
    border: 1px solid $black !important;
    height: rem(169) !important;
    z-index: -2 !important;
    font-size: rem(22) !important;
  }

  &__row {
    display: flex;
  }

  &__button {
    margin-top: rem(41);
    margin-bottom: rem(54);
    width: rem(354);
    color: $black;
    background-color: transparent;
    border: 3px solid #000000;
    height: rem(66);
    font-size: rem(16);
    font-weight: 500;
    font-family: $rubik;
    cursor: pointer;
    outline: none;
    position: relative;

    &:hover {
      border: 3px solid $blue;
    }

    &:focus {
      border: 3px solid $blue;
    }

    &::after {
      content: "";
      position: absolute;
      right: rem(-159);
      border-radius: 50%;
      background: #ff008e;
      bottom: rem(-20);
      z-index: 0;
      width: rem(173);
      height: rem(173);
      opacity: 0.6;
    }
  }
}

@include mobile {
  .SubscriptionForm {
    padding-left: 5%;
    padding-right: 5%;
    width: 100%;

    &__input {
      font-size: 16px !important;
      height: auto !important;
    }

    &__textarea {
      font-size: 16px !important;
      height: auto !important;
    }

    &__recipient {
      width: 100%;
      grid-template-columns: 1fr;
    }

    &__message {
      width: 100%;
    }

    &__grid {
      width: 100%;
      grid-template-columns: 1fr;
    }

    &__button {
      font-size: 16px;
    }

    &__title {
      font-size: 19px;
    }
  }
}
