@import "./src/styles/lib";

.ChooseCoffes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: rem(93);
  margin-bottom: rem(80);

  &__content {
    width: 100%;
  }

  &__title-container {
    display: flex;
    justify-content: center;
    justify-items: center;
  }

  &__title {
    margin-top: rem(46);
    margin-bottom: rem(50);
    font-size: rem(36);
    font-weight: 400;
    font-family: $cairo;
    text-align: center;
  }

  &__background-line {
    margin-top: rem(134);
    width: 100%;
    height: rem(90);
    background: #000000;
  }

  &__slider-container {
    margin-top: rem(-170);
    margin-bottom: rem(100);
  }
}

@include mobile {
  .ChooseCoffes {
    &__title {
      font-size: 20px;
      line-height: em(24px, 20px);
      letter-spacing: 4px;
      width: 303px;
      text-align: center;
    }
  }
}
