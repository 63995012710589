@import "../../../styles/lib";

.ManageMyAccount {
  text-transform: uppercase;
  font-family: $cairo;
  max-width: 530px;
  margin-top: rem(100);
  margin-left: -4rem;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    transform: rotate(90deg);
    top: -16.3rem;
    // left: 44%;
    left: 14.5rem;
    width: 2px;
    height: 29rem;
    background-color: $black;
  }

  &__title {
    font-size: rem(36);
    margin-bottom: rem(50);
    letter-spacing: 0.2em;
    font-weight: normal;
    line-height: rem(48);
  }

  &__link {
    font-weight: 500;
    color: $black;
    font-size: rem(24);
    //letter-spacing: 0.05em;
    text-decoration: none;
    line-height: rem(50);
    cursor: pointer;
    text-transform: capitalize;
    &:hover {
      color: #00d1d6;
      transition: all 200ms ease-in-out;
    }
  }

  &__links {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  &__link {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
}

@include desktop {
  .ManageMyAccount {
    &::before {
      // left: 36%;
    }
  }
}

@include tablet-big {
  .ManageMyAccount {
    &::before {
      //  left: 39%;
    }
  }
}

@include mobile {
  .ManageMyAccount {
    margin-left: 0;
    margin-bottom: 45px;
    max-width: fit-content;
    &::before {
      left: 50%;
    }
    &__title {
      text-align: center;
    }
    &__link {
      font-size: 16px;
    }
  }
}
