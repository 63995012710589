@import "src/styles/lib";

.Help {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: rem(110);
  padding-bottom: rem(200);
  &__banner-image {
    display: flex;
    justify-content: center;
    width: rem(1506);
    border: 2px solid black;
    flex-direction: column;
  }
  &__banner {
    width: rem(1500);
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    font-size: rem(25);
    grid-column-gap: rem(180);
    grid-row-gap: rem(50);
    border: 2px solid black;
    width: rem(1506);
    position: relative;
    top: rem(50);
    font-family: $cairo;
    padding: rem(30) rem(20) rem(30) rem(30);
  }
  &__title {
    font-weight: bold;
    padding-bottom: rem(10);
  }
  &__link {
    cursor: pointer;
    &:hover {
      color: #0acdd4;
    }
  }
  .Popup {
   
    &__overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.4);
      visibility: hidden;
      opacity: 0;
    }
    &__overlay:target {
      visibility: visible;
      opacity: 1;
      overflow: auto;
      padding: 0;
      margin: 0;
      z-index: 1100;
    }

    &__container {
      margin: 10% auto;
      background-color: $white;
      border: 2px solid $black;
      width: 60%;
      position: relative;
      transition: all 300ms ease-in-out;
    }
    &__close {
      position: absolute;
      top: 20px;
      right: 30px;
      transition: all 200ms;
      font-size: 30px;
      font-weight: bold;
      text-decoration: none;
      color: $black;
    }
    &__content {
      max-height: 80%;
    }

    &__text {
      padding: 5%;
      color: $black;
    }

    &__subtitle {
      font-weight: 700;
      margin-bottom: rem(20);
    }
    &__link {
      color: $blue;
      font-weight: 600;
      letter-spacing: 0.03em;
      transition: all 0.6s ease-in-out;
      &:hover {
        text-decoration: underline;
      }
    }

    &__text p {
      margin-bottom: rem(20);
    }
    &__title {
      height: 100px;
      display: flex;
      padding-left: 5%;
      font-weight: 600;
      font-size: rem(32);
      color: $black;
      text-transform: uppercase;
      align-items: center;
      justify-content: space-between;
      background: rgb(2, 0, 36);
      background: linear-gradient(
        0deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(255, 255, 255, 0) 0%,
        rgba(245, 233, 13, 1) 40%
      );
    }
  }
}
@include tablet {
  .Help {
    &__banner-image {
      width: 654px;
    }
    &__banner {
      width: 650px;
    }
    &__content {
      width: 654px;
      padding: 20px 10px 20px 20px;
    }
    &__title {
      font-size: 15px;
    }
    &__link {
      line-height: em(20px, 15px);
      font-size: 15px;
      margin-bottom: 15px;
      font-family: $cairo;
    }
  }
}
@include mobile {
  .Help {
    &__banner-image {
      display: flex;
      justify-content: center;
      width: 354px;
    }
    &__banner {
      width: 350px;
    }
    &__content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 50px;
      grid-row-gap: 10px;
      margin-left: 10px;
      margin-right: 10px;
      width: 354px;
      padding: 20px 7px 20px 15px;
    }
    &__title {
      font-size: 15px;
    }
    &__link {
      line-height: em(20px, 15px);
      font-size: 15px;
      margin-bottom: 15px;
      font-family: $cairo;
    }
    .Popup {
      &__container {
        width: 94%;
      }
    }
  }
  
}
