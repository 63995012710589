@import "src/styles/lib";

.CreditCardForm {

  &--disabled {
      position: relative;
      margin-bottom: -8px;
      &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background:rgba(255,255,255, 0.5);
          z-index: 1000;
          color: black;
          font-size: rem(36);
          font-weight: 500;
          text-align: center;
          padding-top: rem(40);
      }
  }

  .flex {
    display: flex;
  }

  &__react-input-container {
    margin-bottom: rem(30);

  }

  &__react-input {
    //width: 100% !important;
    border-radius: 0 !important;
    height: rem(60) !important;
    border: 1px solid #000000 !important;
    display: flex !important;

    label:first-of-type {
      width: 80% !important;
    }

    label:nth-of-type(2) {
      width: 60px !important;
    }
  }

  .credit-card-input {
    font-size: rem(24) !important;
  }

  .kIDwYO {
    color: red;
    font-size: rem(20) !important;
    margin-top: rem(10) !important;
  }

  &__error {
    color: red;
    font-size: rem(20);
    margin-top: rem(10);

    &--card {
      margin-bottom: rem(20);
    }
  }

  .credit-card-input {
    height: rem(40);
  }

  .jUqMDv {
    height: rem(30) !important;
  }

  #card-expiry {
    //background: green !important
  }

  #card-number {
    //width: 500px !important;
  }

  #cvc {
    width: 45px !important;
  }

  &__input {
    border: 1px solid #000000 !important;
    width: 100%;
    height: rem(60) !important;
    //border-radius: rem(15) !important;
    border-radius: 0 !important;
    font-size: rem(25) !important;

    &--card {
      border: none !important;
      &:focus {
        border: none !important;
      }
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }
  }

  &__select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(8);
  }

  &__or {
    text-align: center;
    margin-bottom: rem(20);
  }

  &__paypal {
    margin-bottom: rem(20);
  }

  &__label {
    font-weight: 600;
    font-size: rem(20);
    margin-bottom: rem(10);
  }

  &__row {
    display: flex;
    justify-content: space-between;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: rem(26);
  }

  &__title1 {
    font-size: rem(25);
    font-weight: 400;
    line-height: em(32px, 25px);
    margin-bottom: rem(22);
  }

  &__title2 {
    font-size: rem(20);
    font-weight: 400;
    line-height: em(32px, 20px);
  }

  &__title {
    padding-top: rem(4);
    font-size: rem(25);
    font-weight: 700;
    height: fit-content;
    margin-bottom: rem(8);
  }

  &__link {
    font-size: rem(20);
    font-weight: 400;
    color: $blue;
    margin-left: rem(10);

    &:hover {
      color: $blue;
    }
  }

  &__input-container {
    margin-bottom: rem(26);
  }

  &__month-container {
    margin-right: rem(10);
  }

  &__year-container {
    margin-left: rem(10);
  }

  &__image {
    object-fit: contain;
    width: rem(70);
    height: rem(50);
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    left: rem(10);
  }

  &__input-card {
    border: 1px solid #000000 !important;
    padding-left: rem(100);
    position: relative;
  }

  &__checkbox-container {
    display: flex;
    align-items: center;
  }

  &__checkbox-desc {
    font-size: rem(16);
    font-weight: 300;
    margin-left: rem(10);
  }

  &__buttons {
    display: flex;
    margin-top: rem(26);
  }

  &__button {
    font-size: rem(25);
    font-weight: 400;
    color: black;
    background: transparent;
    width: rem(280);
    border: 2px solid black;
    height: rem(62);

    &:hover {
      border: 2px solid $blue;
    }
  }

  &__button2 {
    font-size: rem(20);
    border: none;
    color: $blue;

    &:hover {
      border: none;
    }

    &:focus {
      border: none;
    }
  }
}

@include mobile {
  .CreditCardForm {
    border-right: none;
    padding-right: 0;

    &__title {
      font-size: 16px;
      margin-top: 20px;
    }

    &__label {
      font-size: 15px;
    }

    &__input {
      font-size: 15px !important;
      height: 40px !important;

      &::placeholder {
        font-size: 15px;
      }
    }

    &__grid {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }

    &__checkbox-desc {
      font-size: 12px;
    }

    &__error {
      font-size: 14px;
    }

    .kIDwYO {
      font-size: 14px !important;
    }

    &__button2 {
      margin-left: auto;
    }

    &__react-input-container {
      margin-bottom: rem(30);
    }

    &__react-input {
      border-radius: 0 !important;
      height: 40px !important;
      border: 1px solid #000000 !important;
      display: flex !important;

      label:first-of-type {
        width: 65% !important;
      }

      label:nth-of-type(2) {
        width: 60px !important;
      }

      label:nth-of-type(3) {
        width: 40px !important;
      }
    }

    #card-number {
      //height: 100%;
    }

    .credit-card-input {
      font-size: 16px !important;
      height: 35px !important;
    }

    .kIDwYO {
      color: red;
      font-size: rem(20);
      margin-top: rem(10);
    }

    .jUqMDv {
      height: 15px !important;
    }
  }
}
