@import "../../../styles/lib";

.QuizCta {
  height: rem(832);
  position: relative;
  display: flex;
  align-items: center;
  font-size: $cairo;
  margin-bottom: rem(80);

  &__italian {
    position: absolute;
    top: 0;
    left: rem(85);
    width: rem(419);
    height: rem(611);
  }

  &__wrap {
    background-image: url("https://assets.gocoffeego.com/v2/assets/images/quiz-cta-bg.png");
    background-position: left center;
    background-repeat: no-repeat;
    background-size: cover;
    height: rem(483);
    width: 100%;
    border: none;
  }

  &__quiz-btn {
    margin-left: rem(545);
    margin-top: rem(155);
    z-index: 1;
  }

  &__espresso-text {
    color: $white;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.2em;
    font-size: rem(30);
    margin-top: rem(70);
    padding-left: rem(550);
    line-height: rem(36);
    position: relative;
    z-index: 2;

    .reg {
      position: absolute;
      top: -5px;
    }
  }

  &__text {
    color: $white;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.2em;
    font-size: rem(30);
    padding-left: rem(550);
    line-height: rem(36);
    position: relative;
    z-index: 2;
  }

  &__coffee-cup {
    position: absolute;
    right: 14rem;
    top: rem(240);
    max-width: rem(586);
    max-height: rem(586);
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

@include tablet-big {
  .QuizCta {
    &__coffee-cup {
      right: 0;
    }
  }
}

@include tablet {
  .QuizCta {
    &__italian {
      width: rem(380);
      height: rem(571);
    }

    &__coffee-cup {
      width: rem(500);
      height: rem(500);
      right: rem(-40);
      top: rem(400);
    }
  }
}

@include mobile {
  .QuizCta {
    align-items: flex-end;

    &__wrap {
      background-size: cover;
      background-position: 80%;
    }

    &__text-container {
      margin-top: -185px;
      &::before {
        content: "";
        position: absolute;
        height: 4px;
        width: 41px;
        left: 45%;
        right: 0;
        top: -30px;
        background-color: $black;
      }
    }

    &__espresso-text {
      color: black;
      padding: 0;
      margin: 0;
      text-align: center;
      font-size: 20px;
    }

    &__text {
      position: relative;
      text-align: center;
      margin: 0;
      padding: 0;
      color: $black;
      font-size: 20px;
      letter-spacing: 4px;
      font-weight: 600;
      line-height: 24px;
    }

    &__italian {
      width: 125px;
      height: 230px;
      left: 10px;
      top: 240px;
      z-index: 5;
    }

    &__coffee-cup {
      width: 52%;
      height: auto;
      border-radius: 100%;
      right: rem(-80);
      bottom: 0;
    }

    &__quiz-btn {
      margin-left: rem(265);
      margin-top: rem(380);
    }
  }
}
