@import "./styles/lib";

html,
body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100%;
  h1,
  h2,
  h3,
  h4,
  h5,
  label,
  input,
  p {
    padding: 0;
    margin: 0;
    font-family: $cairo;

    //-webkit-touch-callout: none; /* iOS Safari */
    //-webkit-user-select: none; /* Safari */
    //-khtml-user-select: none; /* Konqueror HTML */
    //-moz-user-select: none; /* Old versions of Firefox */
    //-ms-user-select: none; /* Internet Explorer/Edge */
    //user-select: none; /* Non-prefixed version, currently
    //                              supported by Chrome, Edge, Opera and Firefox */
  }

  textarea,
  input[type="text"],
  input[type="number"],
  input[type="password"],
  input[type="submit"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none !important;
    border-radius: 0 !important;
  }

  a {
    text-decoration: none;
    color: #000000;

    &:hover {
      text-decoration: none;
      color: inherit;
    }
  }


  button:disabled {
    cursor: not-allowed;
  }

  .flex-row {
    display: flex;
  }

  .font-cairo {
    font-family: $cairo;
  }

  #App__content {
    //min-height: 100vh;
  }

  #App__container {
    position: relative;
  }

  .App__loader {
    min-height: 100vh;
    display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center;

    &-image {
      width: rem(400);
      height: rem(400);
      object-fit: contain;
    }
  }

  font-size: 70%;

  @include desktop {
    font-size: 65%;
  }

  @include laptop {
    font-size: 65%;
  }

  @include tablet-big {
    font-size: 55%;
  }

  @include tablet {
    font-size: 55%;
  }

  @include mobile {
    font-size: 50%;
    #App__content {
      margin-top: 43px;
      padding-top: 10px;
      //padding-top: rem(255); // header height and topHeader height + 10px
      //padding-bottom: 200px; //footer height
    }
  }
}
