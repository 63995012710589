@import "src/styles/lib";

.SectionTitle {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    margin-top: rem(42);
    margin-bottom: rem(88);
    font-size: rem(36);
    font-family: $cairo;
    font-weight: 400;
    letter-spacing: 0.2rem;
    line-height: em(36px, 36px);
    text-align: center;
    max-width: rem(600);
  }
}

@include mobile {
  .SectionTitle {
    &__title {
      text-align: center;
      margin-top: 22px;
      margin-bottom: 33px;
      font-size: 16px;
      font-family: $cairo;
      font-weight: 600;
      letter-spacing: 0.3rem;
      line-height: em(24px, 20px);
      width: 230px;
    }
  }
}
