@import "./src/styles/lib";

.Header {
  width: 100%;
  z-index: 1049;
  position: sticky;
  top: 0;
  background: #ffffff;
 
  &__nav-mobile {
    display: none;
  }

  .test-logo {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }

  .UserDropDown {
    margin-right: rem(30);
    cursor: pointer;
    position: relative;
    width: rem(93);

    &__arrow-down {
      position: absolute;
      bottom: 7px;
      right: 0;
      cursor: pointer;
      width: 0;
      height: 0;
      border-left: rem(7) solid transparent;
      border-right: rem(7) solid transparent;
      border-top: rem(7) solid #c7c7c7;
    }

    &__title {
      font-family: $cairo;
      font-size: rem(18);
      font-weight: 700;
      margin-right: rem(34);
    }

    &__name {
      font-size: rem(20);
      font-weight: 500;
      min-width: rem(180);
      &-profile {
        font-weight: 700;
      }
    }

    &__email {
      font-size: rem(20);
      padding-top: rem(10);
    }

    &__details {
      border-bottom: 1px solid #c7c7c7;
      padding: rem(15) rem(25);

      &:hover {
        color: $blue;
      }
    }

    &__log-out {
      display: block;
      padding: rem(15) rem(25);
      font-size: rem(20);
      &:hover {
        background: $blue;
        color: $white;
      }
    }

    &__login {
      white-space: nowrap;
      font-size: rem(20);
      padding: rem(15) rem(25);
      &:hover {
        background: $blue;
        color: $white;
      }
    }

    &__image {
      object-fit: contain;
      height: 30px;
      width: 30px;
    }

    &__body {
      min-width: rem(130);
      display: none;
      position: absolute;
      top: rem(30);
      right: 0;
      background: #ffffff;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      z-index: 100;

      &--active {
        display: block;
      }

      &__title {
        font-size: rem(20);
        text-align: end;
      }
    }
  }

  &__menu {
    position: relative;
    display: flex;
    padding-top: rem(10);
    padding-bottom: rem(10);
    padding-left: rem(130);
    padding-right: rem(130);
    justify-content: space-between;
  }
  &__menu-dropdown {
    overflow: hidden;
    position: absolute;
    width: 100%;
    min-height: 0;
    height: 0;
    top: 79px;
    left: 0;
    background: $white;
    transition:all 0.3s ease-in;
    z-index:1;
    box-sizing: border-box;

    &--small {
      top: 60px;
    }
  }
  &__menu-dropdown-submenu {
    padding: rem(55) rem(110) rem(10) rem(245);
    display: flex;
  
    &--gift {
     padding: rem(45) rem(20) rem(10) rem(245);
     width: 800px;
    }
  }
  &__menu-dropdown-submenu--promo a {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;

  }
  &__menu-dropdown-submenu--holiday-promo {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;

  }
  &__menu-dropdown-submenu-image {
    width: rem(240);
    height: rem(240);
    object-fit: contain;
    transition: transform .3s;
    &--cert {
      width: rem(350);
      object-fit: contain;
      height: rem(260);
    
    }
    &:hover {
      transform: scale(1.1);
    }
  }

  &__menu-dropdown-submenu ul {
    max-height: 180px;
    max-width: 700px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-right: 10%;
  }

  &__menu-dropdown-submenu-navlink {
    white-space: nowrap;
    font-family: $cairo;
    text-decoration: none;
    font-size: rem(22);
    font-weight: 600;
    letter-spacing: 0.03em;
    color: 	#3b3b3b;
    display: inline-block;
    position: relative;
    padding: 5px 0;
    margin-bottom: 2px;
    text-transform: uppercase;
    &:hover {
      color: #000000;
    }
  }


  &__nav-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__nav-left {
    display: flex;
    width: 40%;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;

    .item {
      padding-right: rem(40);
      text-decoration: none;
      &:hover {
        .Header__menu-dropdown {
          min-height: 250px;
          height: 100%;
          box-shadow: 0 rem(1) rem(1) #38414a26;
        }
        .navlink {
         cursor: pointer;
          &::before {
            transform: scaleX(1);
            transform-origin: bottom left;
            visibility: visible;
            z-index: 10;
          }
        }
      } 

      &:last-child {
        padding-right: 0;
      }

      .navlink {
        white-space: nowrap;
        font-family: $cairo;
        text-decoration: none;
        font-weight: 700;
        font-size: rem(22);
        color: #000000;
        display: inline-block;
        position: relative;
        padding-bottom: 10px;
        padding-left: 5px;
        padding-right: 5px;
        z-index: 10;
        &::before {
          content: '';
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 2px;
          bottom: 0;
          left: 0;
          background-color: #000000;
          transform-origin: bottom right;
          transition: transform 0.25s ease-out;
          visibility: hidden;
        }
      
        &:hover::before {
          transform: scaleX(1);
          transform-origin: bottom left;
          visibility: visible;
        } 
      }
    }

    .SearchMobile {
      width: 100%;
      position: relative;
      display: none;
      &__input {
        box-shadow: none;
        border-radius: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 100%;
        position: relative;
        font-size: 16px;
        cursor: pointer;
        padding: rem(10);
        font-family: $cairo;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        color: black;
        font-weight: 600;
        padding-right: 40px;
        text-transform: capitalize;
        border: 1px solid #ced4da;
        margin-bottom: rem(25);

        &:focus {
          outline: none;
        }
      }

      &__input-image {
        position: absolute;
        background: url("https://assets.gocoffeego.com/v2/assets/icons/search-icon.svg")
          no-repeat;
        background-size: 100%;
        width: 20px;
        height: 20px;
        cursor: pointer;
        right: 15px;
        background-position: center;
        top: 18%;
      }
    }

  }

  &__nav-right {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    .vertical-line {
      border-right: 1px solid #959595;
      height: rem(30);
      margin-left: rem(25);
      margin-right: rem(25);
    }

    .ShoppingCartIcon {
      position: relative;
      cursor: pointer;
      z-index: 3;
      &__icon {
        object-fit: contain;
        width: rem(63);
        height: rem(63);
      }

      &__quantity {
        position: absolute;
        top: 0;
        right: rem(-5);
        height: rem(24);
        width: rem(24);
        background: #ffec00;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: rem(1);
      }
    }

    .Search {
      position: relative;

      &__input {
        width: 100%;
        position: relative;
        border: 1px solid transparent;
        cursor: pointer;
        padding: rem(10);
        font-family: $cairo;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        color: transparent;
        font-size: rem(20);
        font-weight: 600;
        padding-right: 20px;
        text-transform: capitalize;

        &:focus {
          outline: #ced4da;
          color: black;
          border: 1px solid #ced4da;
        }
      }

      &__input-image {
        position: absolute;
        background: url("https://assets.gocoffeego.com/v2/assets/icons/search-icon.svg")
          no-repeat center center;
        background-size: 100%;
        width: rem(23);
        height: rem(23);
        cursor: pointer;
        right: rem(10);
        top: rem(15);
      }
    }
  }

  &__company-logo {
    width: rem(278);
    height: rem(173);
    object-fit: contain;
    text-align: center;
    z-index: 3;
    transition: height 0.3s ease-out;
    position: relative;
    &--small {
      width: rem(215);
      height: rem(110);
      object-fit: contain;
    }
  }

  &__music-wrapper {
    display: flex;
  }

  &__classic-button {
    font-size: rem(16);
    font-weight: 500;
    min-width: fit-content;
    cursor: pointer;
    &:hover {
      color: $blue;
    }
  }
}
.Social {
  display: none;
}

.Header__nav-left .item {
  .isMobile {
    display: none;
  }
}
@include desktop-big {
  .Social {
    display: none;
    }
  .Header {
    &__nav-right {
      width: 40%;
    }
    &__company-logo {
      position: relative;
    }
  }

}

@include desktop {
  .Social {
    display: none;
    }
  .Header {
    &__menu {
      padding-left: rem(80);
      padding-right: rem(80);
    }
    &__menu-dropdown-submenu {
      padding: rem(55) rem(130) rem(10) rem(200);
      &--gift {
        width: 780px;
        padding: rem(55) rem(20) rem(10) rem(200);
      }
    }

    &__nav-right {
      width: 40%;
    }
    &__company-logo {
      position: relative;
    }
  }
}

@include tablet-big {
  .Header {
    &__menu {
      padding-left: rem(50);
      padding-right: rem(50);
    }

    .UserDropDown {
      &__title {
        font-size: rem(18);
      }
    }

    &__company-logo {
      width: rem(170);
    }
    &__menu-dropdown-submenu {
      padding: rem(20) rem(80) rem(10) rem(170);
      &--gift {
        padding: rem(40) rem(130) rem(10) rem(170);
      }
    }
    &__menu-dropdown-submenu ul {
      width: 500px;
    }

    &__nav-left {
      .item {
        .navlink {
          font-size: rem(21);
        }
        .isMobile {
          display: none;
        }
        .shippingInfo-button {
          display: none;
        }
        .account-mobile-link {
          display: none;
        }
        .contact-us-link {
          display: none;
        }
      }
    }
  }
}

@include tablet {
  .Header {
    &__menu {
      padding-left: rem(50);
      padding-right: rem(50);
    }
    &__menu-dropdown {
      top: 70px;
    }
    &__menu-dropdown-submenu {
      padding: rem(10) rem(80) rem(10) rem(150);
      &--gift {
        padding: rem(40) rem(150) rem(10) rem(150);
      }
    }
    &__menu-dropdown-submenu ul {
      width: 480px;
    }

    &__company-logo {
      width: rem(170);
      position: relative;
      z-index: 11;
    }

    .UserDropDown {
      &__title {
        font-size: rem(18);
      }
    }

    &__nav-left {
      .item {
        padding-right: rem(20);

        .navlink {
          font-size: rem(20);
        }
        .isMobile {
          display: none;
        }

        .shippingInfo-button {
          display: none;
        }
        .account-mobile-link {
          display: none;
        }
        .contact-us-link {
          display: none;
        }
      }
    }
    &__nav-right {
      position: relative;
      .UserDropDown__title {
        font-size: rem(19);
      }
      .Search__input-image {
        width: rem(23);
      }
    }
  }
}

@include tablet-big {
  .Header {
    &__nav-right {
      width: 40%;
    }
    &__company-logo {
      position: relative;
    }
  }
}

@include mobile {
  .Header {
    width: 100%;
    position: sticky;
    top: 43px;
    left: 0;
    z-index: 111;
    background: white;
    &__nav-left .item:hover .Header__menu-dropdown {
      min-height: 0;
      height: 0;
      transform: none;
      transition: none;
    }
    &__nav-left .item:hover .Header__menu-dropdown--active {
      min-height: 500px;
      height: 100%;
      transition:all 0.3s ease-in-out;
    }
  
    &__nav-container {
      height: 100%;
      position: relative;
      display: block;
    }

    &--active {
      position: fixed;
    }

    &__logo-mb {
      object-fit: contain;
      width: 80px;
    }

    &__menu {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 0;
      height: rem(155);
    }

    &__nav-mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-top: 13px;
      padding-bottom: 13px;
      position: relative;

      .cart-icon-mobile {
        display: flex;
        cursor: pointer;
        .quantity {
          height: 15px;
          width: 15px;
          background: #ffec00;
          border-radius: 50%;
          margin-left: -10px;
          margin-top: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: rem(1);
          font-size: 14px;
        }

        .icon {
          width: 35px;
          height: 35px;
          object-fit: contain;
        }
      }

      .hamburger {
        display: block;
        z-index: 99;
        width: 30px;
        float: right;
        height: 22px;
        margin-top: 0px;
        cursor: pointer;
        max-width: 24px;
        margin-right: 15px;

        span {
          width: 100%;
          float: left;
          height: 2px;
          background: #000000;
          margin-bottom: 6px;
          transition: $transition1;

          &:last-child {
            margin-bottom: 0;
          }
        }

        &.active {
          span {
            &:first-child {
              margin-top: 7px;
              margin-bottom: 2px;
              transform: rotate(45deg);
              transition: $transition1;
            }

            &:nth-child(2) {
              opacity: 0;
              transition: $transition1;
            }

            &:nth-child(3) {
              margin-top: -12px;
              transform: rotate(-45deg);
              transition: $transition1;
            }
          }
        }
      }
    }

    .Social {
      display: block;
      margin-top: rem(80);

      &__title {
        font-size: 17px;
        margin-bottom: 10px;
      }

      &__icons {
        display: flex;
        align-items: flex-start;
      }

      &__email-container {
        margin-top: 20px;
        font-size: 16px;
      }

      &__title2 {
        font-size: 17px;
      }

      &__email {
        text-decoration: underline;
        font-weight: 600;
        font-size: 16px;
      }

      &__link {
        cursor: pointer;
        margin: 0;
      }

      &__link-image {
        width: 30px;
        height: 30px;
        margin-right: rem(10);
        object-fit: contain;
      }
    }

    .isDisabled {
      cursor: not-allowed;
      opacity: 0.5;
      text-decoration: none;
    }

    &__nav-left {
      display: none;
      padding: 20px;
      position: fixed;
      top: rem(240);
      left: 0;
      height: 100vh;
      width: 100%;
      transition: opacity 0.2s ease-in-out, visibility 0s;
      background: white;
      z-index: 100;
      &.active {
        display: block !important;
      }

    .Header__navlink-mobile-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      cursor: pointer;
    }
    .Header__navlink-mobile {
      white-space: nowrap;
      font-family: $cairo;
      text-decoration: none;
      font-weight: 700;
      font-size: 18px;
      color: #000000;
      display: inline-block;
      position: relative;
      padding-left: 60px;
    }
   /*  .Header__menu-dropdown {
      min-height: 0;
       height: 0;
    } */
    .Header__menu-dropdown--active {
      // position: static;
      background: $white;
      z-index: 400;
      box-shadow: none;
      min-height: 500px;
      height: 100%;
      top: 0;
      visibility: visible;
      transition:all 0.3s ease-in-out;
    }
    .Header__menu-dropdown-submenu--active {
    //  position: static;
      max-height: 500px;
      width: 200px;
      padding: 10px 20px;
      display: flex;
      flex-direction: column;
      box-shadow: none;
    }
    .Header__menu-dropdown-submenu--active ul {
      //  position: static;
        max-height: 500px;
        width: 200px;
        padding: 10px 20px 0 20px;
        display: flex;
        flex-direction: column;
        box-shadow: none;
    }
    .Header__menu-dropdown-submenu--holiday-promo {
      padding: 0 0 0 20px;
      flex-direction: row;
      & img  {
        margin-left: 20%;
      }
    }
    .Header__menu-dropdown-submenu--promo a {
      align-items: initial;
    }
    
    .Header__menu-dropdown-submenu-navlink {
      font-size: 16px;
    }
    

      .SearchMobile {
        width: 100%;
        position: relative;
        display: block;
        &__input {
          box-shadow: none;
          border-radius: 0;
          -webkit-appearance: none;
          -moz-appearance: none;
          width: 100%;
          position: relative;
          font-size: 16px;
          cursor: pointer;
          padding: rem(10);
          font-family: $cairo;
          -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
          color: black;
          font-weight: 600;
          padding-right: 40px;
          text-transform: capitalize;
          border: 1px solid #ced4da;
          margin-bottom: rem(25);
  
          &:focus {
            outline: none;
          }
        }
  
        &__input-image {
          position: absolute;
          background: url("https://assets.gocoffeego.com/v2/assets/icons/search-icon.svg")
            no-repeat;
          background-size: 100%;
          width: 20px;
          height: 20px;
          cursor: pointer;
          right: 15px;
          background-position: center;
          top: 18%;
        }
      }

      .item {
        margin-bottom: 4px;
        display: block;

        .navlink {
          font-size: 20px;
          &::before {
            display: none;
          }
        }
        .isMobile {
          display: block;
        }
        .shippingInfo-button {
          line-height: 1.5;
          width: fit-content;
          display: block;
          cursor: pointer;
        }
        .account-mobile-link {
          display: block;
        }
        .contact-us-link {
          display: block;
        }
      }
    }

    &__nav-right {
      display: none;
    }

    &__company-logo {
      display: none;
    }
  }
}
@include smallMobile {
  @media only screen and (max-width: $screen-xxs) {
    .Header__nav-left {
      display: none;
      padding: 20px;
      position: fixed;
      top: 14.4rem;
      left: 0;
      height: 100vh;
      width: 100%;
      transition: opacity 0.2s ease-in-out, visibility 0s;
      background: white;
      z-index: 100;
      overflow: hidden !important;

      &.active {
        display: block !important;
      }

      .item {
        margin-bottom: 4px;
        display: block;

        .navlink {
          font-size: 17px;
        }

        .shippingInfo-button {
          line-height: 1.5;
          width: fit-content;
        }
      }
    }
  }
}
