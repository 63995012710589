@import "src/styles/lib";

.AutoShip {
  width: 100%;
  height: 100%;
  padding-top: rem(200);
  padding-bottom: rem(100);
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  &__main-title {
    text-align: center;
    font-size: rem(34);
    margin-top: rem(160);
    margin-bottom: rem(24);
    font-weight: 700;
  }
  &__main-description {
    text-align: center;
    font-size: 16px;
    margin: 0 auto rem(24);
    font-weight: 500;
    max-width: rem(1200);
    padding: 0 10px;
  }

  .ViewDetails {
    margin-top: rem(40);
    max-width: rem(385);

    &__title {
      font-size: rem(28);
      font-weight: 600;

      &--link {
        &:hover {
          color: $blue;
        }
      }
    }

    &__content {
      margin-top: rem(15);
    }
    &__description {
      font-size: rem(26);
    }
  }

  &__more-info {
    position: relative;
  }

  &__bubble {
    position: absolute;
    font-size: rem(24);
    line-height: 22px;
    width: 190px;
    background: $white;
    border: 1px solid #e3e3e3;
    border-radius: rem(60);
    padding: rem(24);
    text-align: center;
    top: rem(-120);
    right: rem(250);
    color: $black;
    z-index: 4;
 
    &:before {
      content: "";
      width: 0px;
      height: 0px;
      position: absolute;
      border-left: 6px solid #e3e3e3;
      border-right: 6px solid transparent;
      border-top: 8px solid #e3e3e3;
      border-bottom: 8px solid transparent;
      left: 189px;
      bottom: 40px;
    }
     &:after {
      content: "";
      width: 0px;
      height: 0px;
      position: absolute;
      border-left: 6px solid #ffffff;
      border-right: 6px solid transparent;
      border-top: 8px solid #ffffff;
      border-bottom: 8px solid transparent;
      left: 187px;
      bottom: 39px;
    } 
  }

  &__bubble-link {
    color: $blue;
    font-weight: 500;
    cursor: pointer;
    display: block;
    transition: all 200ms ease-in-out;
    &:hover {
      color: $black;
    }
  }

  &__prof-peaberry {
      position: absolute;
      width: rem(222);
      height: rem(230);
      object-fit: contain;
      top: rem(-138);
      right: rem(10);
      z-index: 3;
  }

  &__required {
    color: red;
    font-size: rem(22);
  }

  &__updated-message {
    margin-top: rem(10);
    font-size: rem(25);
    color: #6ae200;
  }

  &__nav {
    background: #ebebeb;
    height: rem(35);
    margin-top: rem(160);
    align-items: center;
    display: flex;
    padding-left: 10%;
    padding-right: 20%;
  }

  &__nav-title {
    font-weight: 400;
    font-size: rem(16);
    line-height: em(24px, 16px);

    &--active {
      color: #ff0000;
    }
  }

  &__right-arrow {
    padding-left: rem(23);
    padding-right: rem(23);
  }

  &__title {
    font-weight: 600;
    font-size: rem(43);
    margin-top: rem(24);
    line-height: em(35px, 34px);
    margin-bottom: rem(29);
    padding: rem(10);
    text-align: center;
    &--second {
      font-weight: 400;
      margin-bottom: rem(29);
      margin-top: 0;
      font-size: rem(34);
    }
  }

  &__start-today {
    color: #74e414;
    font-size: rem(26);
    text-align: center;
    font-weight: 600;
    z-index: 5;
  }

  &__subtitle {
    position: relative;
    font-size: rem(26);
    margin-top: 0;
    line-height: em(35px, 24px);
    margin-bottom: rem(39);
    z-index: 2;
    padding-left: 28%;
    padding-right: 20%; 
    display: flex;
    flex-direction: column;
  }

  &__subtitle li {
    list-style-type: disc;
  }  

  &__title-2 {
    font-weight: 600;
    font-size: rem(34);
    margin-top: rem(163);
    margin-bottom: rem(49);
    line-height: em(35px, 34px);
    text-align: center;
    color: #6ae200;
  }

  &__content-title {
    font-weight: 600;
    font-size: rem(35);
    margin-bottom: rem(50);
    line-height: em(32px, 35px);
    margin-left: 5%;
  }

  &__primary-title {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      height: rem(436);
      width: rem(436);
      border-radius: 50%;
      top: rem(-74);
      opacity: 0.8;
      right: rem(-138);
      background-color: #ff008e;
      z-index: 10;
      background-repeat: no-repeat;
    }
  }

  &__content {
    position: relative;
    margin-bottom: rem(75);
  }

  &__grid {
    display: grid;
    grid-template-columns: auto auto;
  }

  &__container {
    display: grid;
    grid-template-columns: 80% 20%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .Items {
    border-right: 1px solid #c7c7c7;

    &__title {
      text-align: center;
      font-size: rem(20);
      font-weight: 400;

      &--item {
        text-align: left;
        padding-left: rem(50);
      }

      &:first-child {
        text-align: left;
      }
    }

    &__row {
      display: grid;
      grid-template-columns: 90% 10%;
    }

    &__menu {
      display: grid;
      grid-template-columns: rem(80) 35% 10% 25% 15% 15%;
      border-bottom: 1px solid #c7c7c7;
      padding-bottom: rem(10);
    }

    &__content-title {
      text-align: center;
      font-size: rem(20);
      font-weight: 400;

      &:first-child {
        text-align: left;
      }
    }
  }

  .AutoShipForm {
    padding-left: rem(52);

    &__select-title {
      font-size: rem(24);
      font-weight: 600;
    }

    &__start-date {
      font-size: rem(24);
      font-weight: 600;
      margin-top: rem(22);
      margin-bottom: rem(10);
    }

    &__datepicker {
      width: rem(385);
      height: rem(46);
      border: 1px solid black;
      padding-left: rem(10);
      font-size: rem(24);
      font-weight: 400;
      box-sizing: border-box;
      outline: 0;
      position: relative;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
    }

    &__button {
      width: rem(385);
      margin-top: rem(23);
      border: 3px solid #6ae200;
      background: #6ae200;
      display: block;

      &--yellow {
        border: 3px solid #ffec00;
        background: #ffec00;
      }
    }

    &__select {
      border: 1px solid black;
      width: rem(385);
      height: rem(46);
      margin-top: rem(10);

      &--hold {
        margin-bottom: rem(23);
      }
    }

    &__or {
      display: flex;
      align-items: center;
      margin-top: rem(34);
      margin-bottom: rem(40);

      .or-line {
        border-bottom: 1px solid #e3e3e3;
        width: rem(154);
      }

      .or-text {
        font-size: rem(25);
        font-weight: 400;
        margin-left: rem(34);
        margin-right: rem(34);
      }
    }
  }

  .Message__autoship {
    display: flex;
    justify-items: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    margin-bottom: 4rem;
  }

  .Status {
    margin: rem(100) 0 rem(70);
    display: flex;
    justify-content: center;
    justify-items: center;
    background-color: $black;
    padding: rem(10);
    position: relative;

    
    &__text {
      font-size: rem(28);
      color: $white;
      font-weight: 400;
      margin-right: rem(50);
      margin-left: rem(50);
      &--bold {
        font-weight: 600;
        color: #ff8900;
      }
    }
  }
}
@include desktop {
  .AutoShip {
    &__container {
      grid-template-columns: 75% 25%;
      padding-left: 3%;
      padding-right: 3%;
    }

    .Items {
      &__menu {
        padding-right: 30px;
      }
    }
  }
}

@include tablet-big {
  .AutoShip {
    &__container {
      grid-template-columns: 75% 25%;
      padding-left: 3%;
      padding-right: 3%;
    }

    &__content {
      &::after {
        display: none;
      }
    }
    .Items {
      &__menu {
        padding-right: 20px;
      }
      &__row {
        padding-right: 10px;
      }
    }
  }
}

@include tablet {
  .AutoShip {
    padding-top: 70px;

    &__prof-peaberry {
      top: rem(-126);
    }

    &__bubble {
      line-height: 16px;
      width: 190px;
      padding: rem(18);
      top: rem(-30);
      right: rem(290);
      border-radius: rem(20);
   
      &:before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 8px solid #e3e3e3;
        border-right: 8px solid transparent;
        border-top: 10px solid #e3e3e3;
        border-bottom: 10px solid transparent;
        left: 189px;
        bottom: 26px;
      }
       &:after {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 8px solid #ffffff;
        border-right: 8px solid transparent;
        border-top: 10px solid #ffffff;
        border-bottom: 10px solid transparent;
        left: 187px;
        bottom: 25px;
      } 
    }

    &__bubble-link {
      display: inline;
    }

    &__primary-title {
      &::after {
        display: none;
      }
    }

    .AutoShipForm {
      margin-top: 50px;
      width: 100%;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: center;
      justify-content: center;

      &__select {
        width: 100%;
      }

      &__select-title {
        font-size: 17px;
      }

      &__start-date {
        font-size: 17px;
      }

      &__or {
        width: 100%;
        .or-line {
          width: 100%;
        }

        .or-text {
          font-size: 16px;
        }
      }

      &__button {
        width: 100%;
      }
      &__date-wrap {
        width: 100% !important;
      }

      &__datepicker {
        width: 100% !important;
        height: 30px !important;
        font-size: 16px;

        @supports (-webkit-touch-callout: none) {
          min-width: 94%;
          border-radius: 0;
        }

        //-webkit-appearance: none;
      }
    }

    &__nav {
      display: none;
    }

    &__container {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
    &__content-title {
      font-weight: 600;
      font-size: rem(35);
      margin-bottom: rem(50);
      line-height: em(32px, 35px);
      text-align: center;
    }

    &__content {
      &::after {
        display: none;
      }
    }

    .Status {
      padding-left: 10%;
      padding-right: 10%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 7rem;
      &__text {
        margin: 0;
      }
    }
  }
}

@include mobile {
  .AutoShip {
    padding-top: 50px;

    &__prof-peaberry {
      top: rem(-63);
      right: 0;
    }

    &__bubble-link {
      display: inline;
    }

    &__bubble {
      line-height: 14px;
      width: 190px;
      padding: rem(14);
      top: rem(10);
      right: rem(250);
      border-radius: rem(20);
   
      &:before {
        border-left: 6px solid #e3e3e3;
        border-right: 6px solid transparent;
        border-top: 8px solid #e3e3e3;
        border-bottom: 8px solid transparent;
        left: 189px;
        bottom: 18px;
      }
       &:after {
        border-left: 6px solid #ffffff;
        border-right: 6px solid transparent;
        border-top: 8px solid #ffffff;
        border-bottom: 8px solid transparent;
        left: 187px;
        bottom: 17px;
      } 
    }

    &__subtitle {
      padding-left: 15%;
      padding-right: 10%;
    }

    &__primary-title {
      &::after {
        display: none;
      }

    }

    .AutoShipForm {
      margin-top: 50px;
      width: 100%;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: center;
      justify-content: center;

      &__select {
        width: 100%;
      }

      &__select-title {
        font-size: 17px;
      }

      &__start-date {
        font-size: 17px;
      }

      &__or {
        width: 100%;
        .or-line {
          width: 100%;
        }

        .or-text {
          font-size: 16px;
        }
      }

      &__button {
        width: 100%;
      }
      &__date-wrap {
        width: 100% !important;
      }

      &__datepicker {
        width: 100% !important;
        height: 30px !important;
        font-size: 16px;

        @supports (-webkit-touch-callout: none) {
          min-width: 94%;
          border-radius: 0;
        }
      }
    }

    &__nav {
      display: none;
    }

    &__container {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
    &__content-title {
      font-weight: 600;
      font-size: rem(35);
      margin-bottom: rem(50);
      line-height: em(32px, 35px);
      text-align: center;
    }

    &__content {
      &::after {
        display: none;
      }
    }

    .Status {
      padding-left: 10%;
      padding-right: 10%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 11rem;
      &__text {
        margin: 0;
        text-align: center;
      }
    }
  }
}
