@import "src/styles/lib";

.ContactUsForm {
  margin-top: rem(24);
  padding-bottom: rem(52);
  background: white;
  width: rem(737);
  height: 100%;

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: rem(40);
    margin-top: rem(20) !important;
  }

  &__link-container {
    text-align: start;
    font-size: rem(23);
    font-weight: 400;
    margin-top: rem(20);
    display: flex;
    justify-content: space-between;
  }

  &__label {
    font-size: rem(26);
    font-weight: 400;
  }

  &__link {
    font-size: rem(23);
    font-weight: 400;
    color: #0d89aa;
    text-align: end;
    &:hover {
      color: #0d89aa;
    }
  }

  &__input-container {
    margin-bottom: rem(60) !important;
    position: relative;
    height: rem(60);

    &--comment {
      margin-top: rem(25) !important;
      //margin-bottom: rem(17) !important;
      height: fit-content !important;
    }
  }

  &__required {
    font-size: rem(22);
    color: red;
    margin-top: rem(5);
  }
  &__thankyouNote {
    font-size: rem(22);
    color: #00d1d6;
    margin-top: rem(5);
  }

  &__input {
    width: 100%;
    border-radius: 0 !important;
    font-size: 16px !important;
    border: 1px solid #000000 !important;

    &--comment {
      height: rem(142) !important;
      width: 100% !important;
    }

    &::placeholder {
      color: black !important;
    }
  }

  &__row {
    display: flex;
  }

  &__remember-me {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__remember {
    display: flex;
    align-items: center;
  }

  &__button {
    color: black;
    margin-top: rem(55);
    height: rem(60);
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    background-color: transparent;
    border: 3px solid #000000;
    width: 100%;
    font-size: rem(32);
    font-weight: 500;
    font-family: $rubik;
    cursor: pointer;
    outline: none;
    span {
      font-family: $cairo;
    }
  }
}

@include mobile {
  .ContactUsForm {
    border: none;
    width: 90%;

    &__button {
      height: 46px !important;
    }
  }
}
