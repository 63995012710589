@import "./src/styles/lib";

.CustomerReviews {
    width: 100%;
    height: 100%;
    margin: rem(180) auto 0;
    font-family: $cairo;
    position: relative;
 
    &__container {
        margin: rem(150) auto 0;
        width: 80%;  
        position: relative;
      
          &::after {
            content: "";
            position: absolute;
            top: rem(148);
            right: rem(-175);
            background: url("https://assets.gocoffeego.com/v2/assets/images/girl-in-circle.png");
            background-repeat: no-repeat;
            background-size: 100%;
            z-index: 1;
            width: rem(382);
            height: rem(487);
          }
    }

    &__review-wrap {
        padding: rem(60);
        font-size: rem(28);
        text-align: center;
        border: 1px solid #ced4da;
        margin-bottom: rem(20);
        z-index: 1;
    }

    &__review-title {
       margin: rem(20) 0;
       font-weight: 700; 
       font-size: rem(30);
       text-align: center;
       z-index: 4;
    }
    &__review-text {
        font-size: rem(28);
        text-align: center;
        margin-bottom: rem(20);
        z-index: 5;
     }
}

@include mobile {
    .CustomerReviews {
        &__container {
              &::after {
               display: none;
              }
        }
    }
}