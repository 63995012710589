@import "src/styles/lib";

.Newsletter {
  padding-top: rem(250);
  font-family: $cairo;
  color: $black;
  overflow: hidden;
  padding-bottom: rem(100);
  font-size: rem(24);
  font-weight: 600;
  line-height: rem(45);
  &::before {
    content: "";
    position: absolute;
    width: rem(235);
    height: rem(235);
    background-color: #ff008e;
    border-radius: 50%;
    top: rem(490);
    left: rem(570);
    z-index: 3;
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: rem(380);
    background-color: $black;
    top: rem(650);
    left: 0;
    z-index: -1;
  }
  &__container {
    border: 3px solid $black;
    margin-top: rem(160);
    margin-right: rem(130);
    margin-left: rem(130);
    padding: rem(10) 0 rem(40);
    position: relative;
    background-color: $white;
    position: relative;
    z-index: 3;
    &::before {
      content: "";
      position: absolute;
      width: rem(225);
      height: rem(225);
      background: url("https://assets.gocoffeego.com/v2/assets/images/hotair-balloon.png")
        no-repeat center center;
      background-size: 100%;
      bottom: rem(50);
      right: rem(-120);
      z-index: 5;
    }
    &::after {
      content: "";
      position: absolute;
      width: rem(154);
      height: rem(142);
      background: url("https://assets.gocoffeego.com/v2/assets/images/3-stars.png")
        no-repeat center center;
      background-size: 100%;
      bottom: rem(-60);
      left: rem(-35);
      z-index: 5;
    }
  }

  &__main-title {
    padding: 0 rem(100);
    height: rem(210);
    z-index: 2;
  }

  &__wrap {
    display: flex;
    padding: 0 rem(60);
    justify-content: center;
  }

  &__title {
    text-align: center;
    margin-bottom: rem(40);
  }

  &__text {
    width: rem(395);
    margin-right: rem(120);
  }
}

@include laptop {
  .Newsletter {
    &::after {
      top: rem(730);
      height: 250px;
      left: 0;
      z-index: -1;
    }
    &__container {
      &::before {
        bottom: -10px;
      }
    }
    &__text {
      width: 100%;
      padding-left: rem(50);
      padding-right: rem(50);
      margin-right: 0;
      margin-bottom: rem(40);
    }
    &__wrap {
      flex-direction: column;
      align-items: center;
    }
  }
}

@include mobile {
  .Newsletter {
    padding-top: 60px;
    &::after {
      top: rem(800);
      height: 350px;
    }
    &::before {
      top: rem(570);
      left: rem(60);
    }
    &__container {
      margin: 80px 30px 0;
    }

    &__title {
      font-size: 16px;
      padding: 10px;
    }

    &__text {
      padding: 0;
    }
  }
}
