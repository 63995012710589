@import "src/styles/lib";

.Roasting {
  padding-top: rem(100);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &__main-image {
    width: rem(900);
    object-fit: contain;
    margin-bottom: 20px;
    transform: rotateY(180deg);
  }

  &__main-title {
    font-size: rem(30);
    font-weight: 700;
    position: relative;
    margin-bottom: rem(50);
    margin-top: rem(40);
    text-transform: uppercase;
  }

  &__title {
    font-size: rem(30);
    font-weight: 700;
    position: relative;
    margin-top: rem(40);
    text-align: center;
    margin-bottom: rem(10);
  }

  &__title1 {
    font-size: rem(35);
    font-weight: 700;
    position: relative;
    margin-top: rem(40);
    text-align: center;
    margin-bottom: rem(20);
  }

  &__title2 {
    font-size: rem(30);
    font-weight: 700;
    position: relative;
    text-align: center;
    margin-bottom: rem(50);
    margin-top: 0;
  }

  &__shape {
    width: rem(67);
    height: rem(67);
    position: absolute;
    bottom: rem(46);
    left: rem(-54);
  }

  &__description {
    max-width: rem(1169);
    margin: 0;
    font-size: rem(24);
    font-weight: 400;
    position: relative;
    margin-bottom: rem(30);
    line-height: em(35px, 24px);
  }
}

@include tablet {
  .Roasting {
    &__peaberry {
    }
  }
}

@include mobile {
  .Roasting {
    padding-top: 20px;
    &__main-image {
      width: 80%;
    }
    &__flowers {
      display: none;
    }

    &__content {
      padding: 0;
    }

    &__peaberry-sitting {
      display: none;
    }

    &__description {
      font-size: 16px;
      min-width: auto;
    }

    &__line {
      display: none;
    }
  }
}
