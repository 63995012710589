@import "src/styles/lib";

.SavedItems {
  height: 100%;
  width: 100%;
  margin-top: rem(146);
  margin-bottom: rem(100);
  position: relative;

  &__content {
    //background: url("https://assets.gocoffeego.com/v2/assets/images/saved-items-backgorund.svg")
    //  no-repeat top 0 left 0;
    background-size: cover;
    background: black;
  }

  &__title {
    padding-top: rem(101);
  }

  &__no-items {
    color: white;
    min-height: rem(100);
    font-size: rem(35);
    text-align: center;
  }

  &__slider {
    padding-bottom: rem(120);
    z-index: 100;
    padding-left: 11%;
    padding-right: 11%;
  }

  &__line {
    &::before {
      content: "";
      position: absolute;
      height: rem(445);
      width: rem(445);
      border-radius: 50%;
      bottom: rem(-165);
      left: rem(-154);
      background-color: $blue;
      z-index: 0;
    }
  }
}

@include mobile {
  .SavedItems {
    &__slider {
      padding-right: 5%;
      padding-left: 5%;
    }

    &__content {
      background: black;
    }

    &__line {
      &::before {
        display: none;
      }
    }
  }
}
