@import "../../../styles/lib";

.OrderProduct {
  color: $black;
  background-color: #f6f6f6;
  font-size: rem(20);
  line-height: 1.2;
  max-width: 370px;
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: all 200ms ease-in-out;

  &__button,
  &__button-review {
    width: rem(265);
    height: rem(66);
    text-transform: uppercase;
    font-size: rem(16);
    font-weight: 700;
    letter-spacing: 0.2em;
    line-height: rem(32);
    border: 3px solid $black;
  }

  &__button {
    margin: 0 auto rem(38);

    &--download {
      margin-bottom: rem(10);
      background: $blue;
      color: $white;
      border: none;
      line-height: 1.2;
      position: relative;
     
      &::after {
        content: '';
        width: 100%;
        height: 100%;
        border: 3px solid $blue;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        background: $blue;
        border-radius: inherit;
        @include animateDowloadButton(pulse, 2.5s, linear, infinite);
      } 
    }

    &--download-order {
      margin-bottom: rem(15);
      margin-top: rem(30);
      width: 100%;
      background: $blue;
      color: $white;
      border: none;
      font-size: rem(24);
      position: relative;
     
      &::after {
        content: '';
        width: 100%;
        height: 100%;
        border: 3px solid $blue;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        background: $blue;
        border-radius: inherit;
        @include animateDowloadButton(pulse, 2.5s, linear, infinite);
      } 
    }
  }

  &__download-note {
    font-size: rem(20);
    margin-bottom: 15px;
  }


  &__button-review {
    margin: 0 auto rem(10);
  }

  &__label-black-background {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    background-color: $black;
    clip-path: polygon(0 0, 0% 100%, 100% 0);
    height: 100%;
    width: 100%;
  }

  &__wrap {
    position: relative;
    height: rem(354);
  }

  &__pinterest {
    position: absolute !important;
    right: rem(24) !important;
    top: rem(90) !important;
    z-index: 4 !important;
  }

  &__pinterest-icon {
    max-height: rem(49) !important;
    height: auto !important;
    width: fit-content !important;
  }

  &__label-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: rem(15);
    padding-left: rem(15);
    margin-bottom: rem(10);
    height: rem(76);
  }

  &__label-new,
  &__label-sale {
    position: relative;
    display: inline-flex;
    text-align: center;
    align-items: center;
    width: rem(67);
    height: rem(67);
    color: $white;
    border-radius: 50%;
    z-index: 1;
    text-transform: uppercase;
    font-size: rem(16);
    line-height: rem(24);
  }

  &__label-sale {
    background-color: $white;
    color: $black;
    overflow: hidden;
  }

  &__label-sale-procent {
    font-size: rem(24);
    line-height: rem(24);
    letter-spacing: 0;
  }

  &__label-new {
    background-color: $red;
    margin-right: rem(12.8);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    cursor: pointer;
    position: relative;
    display: flex;
    margin: 0 auto;
    z-index: 1;
    min-width: rem(250);
    max-width: rem(320);
    height: rem(268);
    object-fit: contain;
  }

  &__text-wrap {
    padding-left: rem(52);
    padding-right: rem(52);
    display: flex;
    flex-direction: column;
    flex: 1 1;
  }

  &__size-wrap {
    margin-bottom: rem(30);
    flex-grow: 1;
  }

  &__title {
    text-transform: uppercase;
    line-height: rem(24);
    margin-bottom: rem(20);
    margin-top: rem(20);
    width: fit-content;
    background-color: #d7d7d7;
    //text-align: center;
    padding: 0 rem(5);
  }

  &__coffee-acidity {
    line-height: normal;
  }

  &__coffee-body {
    line-height: normal;
  }

  &__desc,
  &__price,
  &__size {
    font-weight: 700;
    text-transform: capitalize;
    letter-spacing: 0.05em;
    font-size: rem(24);
  }

  &__desc {
    margin-bottom: rem(27);
    color: $black;
    //font-size: rem(20);
  }

  &__size {
    color: #6d6c6c;
  }
}

@include mobile {
  .OrderProduct {
    height: auto;
    width: 280px;

    &__button,
    &__button-review {
      width: 200px;
      height: 54px;
      font-size: 14px;
    }

    &__button {
      margin-bottom: 20px;
      &--download-order {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    &__download-note {
      font-size: 10px;
      margin-bottom: 15px;
    }

    &__wrap {
      height: auto;
      display: flex;
      flex-direction: column;
    }

    &__pinterest {
      top: 60px !important;
      right: 20px;
    }

    &__pinterest-icon {
      max-height: 36px !important;
      width: fit-content !important;
    }

    &__label-wrap {
      height: 55px;
    }

    &__label-new,
    &__label-sale {
      font-size: 14px;
      letter-spacing: rem(2);
      width: 55px;
      height: 55px;
    }

    &__label-sale-procent {
      font-size: 20px;
      line-height: 19px;
    }

    &__label-new {
      margin-right: 0.9rem;
    }

    &__image {
      min-width: 149px;
      max-width: 219px;
      height: 219px;
      object-fit: contain;
    }

    &__text-wrap {
      margin-top: 0.5rem;
      font-size: rem(20);
      padding-left: 44px;
      padding-right: 40px;
    }

    &__title {
      font-size: 14px;
      letter-spacing: 0.01em;
    }

    &__desc,
    &__size,
    &__price {
      line-height: 1.5;
      font-size: 14px;
      letter-spacing: 0.7px;
      //flex-grow: 1;
    }
  }
}
