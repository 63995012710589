@import "src/styles/lib";

.Coffe101 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__image {
    position: absolute;
    right: rem(80);
    top: rem(-15);
    width: rem(630);
    height: rem(500);
    object-fit: contain;
  }

  &__image-mobile {
    width: 269px;
    height: 204px;
    display: none;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: rem(98);
    padding-bottom: rem(98);
    width: 100%;
    position: relative;
  }

  &__line {
    width: 100%;
    position: relative;
  }

  &__circle {
    position: absolute;
    bottom: rem(-109);
    left: rem(105);
    width: rem(261);
    height: rem(261);
    background: #ff8900;
    border-radius: 50%;
  }

  &__circle-disappear,  &__line-disappear {
    display: none
  }

  &__title {
    margin-top: 0;
    font-size: rem(36);
    font-family: $cairo;
    font-weight: 700;
    letter-spacing: 0.6rem;
    margin-bottom: rem(41);
    text-align: center;
  }

  &__title2 {
    width: rem(498);
    margin-top: rem(39);
    font-size: rem(36);
    font-family: $cairo;
    font-weight: 400;
    letter-spacing: 0.4rem;
    margin-bottom: rem(59);
    text-align: center;
    line-height: em(36px, 36px);
    z-index: 2;
  }
  &__button {
    width: rem(360);
    height: rem(77);
    font-size: rem(24);
    letter-spacing: 0.3rem;
    z-index: 2;
    background-color: #ffffff;
  }
}

@include tablet {
  .Coffe101 {
    &__image {
      right: 10px;
      top: rem(10);
      width: rem(540);
      height: rem(450);
      object-fit: contain;
    }
  }
}

@include mobile {
  .Coffe101 {
    &__image {
      display: none;
    }

    &__title {
      font-size: 16px;
      margin-bottom: 18px;
    }

    &__title2 {
      width: 202px;
      font-size: 20px;
      line-height: em(24px, 20px);
      margin-top: 22px;
      margin-bottom: 32px;
    }

    &__circle {
      position: absolute;
      bottom: -46px;
      left: -82px;
      width: 141px;
      height: 141px;
      background: #ff8900;
      border-radius: 50%;
    }

    &__image-mobile {
      display: flex;
      width: 269px;
      height: 204px;
      object-fit: contain;
    }
    &__button {
      font-size: 14px;
    }
  }
}
