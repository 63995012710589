@import "../../../styles/lib";

.Product {
  color: $black;
  background-color: #f6f6f6;
  font-size: rem(20);
  line-height: 1.2;
  max-width: 370px;
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: all 200ms ease-in-out;
  position: relative;
  height: 100%;
  min-height: rem(450);

  &__container {
    height: 85%;
    padding-bottom: rem(20);
  }

  &__wrap {
    position: relative;
  }

  &__award-text {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: rem(22);
    font-weight: 600;
    letter-spacing: 0.02em;
    line-height: 1.5;
  }

  &__review-badge {
    width: rem(88);
    height: rem(88);
    position: absolute;
    z-index: 1;
    left: 77%;
    top: rem(35);
  }
  &__button {
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: rem(20);
    &-label {
      width: rem(265);
      height: rem(66);
      text-transform: uppercase;
      font-size: rem(16);
      font-weight: 700;
      letter-spacing: 0.2em;
      line-height: rem(32);
      border: 3px solid $black;
    }
  }

  &__label-black-background {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    background-color: $black;
    clip-path: polygon(0 0, 0% 100%, 100% 0);
    height: 100%;
    width: 100%;
  }

  &__pinterest {
    position: absolute !important;
    right: rem(14) !important;
    top: rem(150) !important;
    z-index: 4 !important;
  }

  &__pinterest-icon {
    max-height: rem(49) !important;
    height: auto !important;
    width: fit-content !important;
  }
  @-moz-document url-prefix() {
    &__pinterest {
      position: absolute !important;
      right: rem(22) !important;
      top: rem(150) !important;
      z-index: 4 !important;
    }
  }

  &__label-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: rem(15);
    padding-left: rem(15);
    margin-bottom: rem(10);
    height: rem(76);
  }

  &__label-new,
  &__label-sale,
  &__label-top-number {
    position: relative;
    display: inline-flex;
    text-align: center;
    align-items: center;
    width: rem(67);
    height: rem(67);
    color: $white;
    border-radius: 50%;
    z-index: 1;
    text-transform: uppercase;
    font-size: rem(16);
    line-height: rem(24);
    font-weight: 700;
  }

  &__label-sale {
    background-color: $red;
    color: #ffffff;
    overflow: hidden;
  }

  &__label-sale-procent {
    font-size: rem(24);
    line-height: rem(24);
    letter-spacing: 0;
  }

  &__label-new {
    background-color: $blue;
    margin-right: rem(12.8);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__label-top-number {
    background-color: $blue;
    margin-right: rem(12.8);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }

  &__retail-price {
    &--sale {
      text-decoration: line-through;
    }
  }

  &__sale-price {
    color: $red;
  }

  &__image {
    cursor: pointer;
    position: relative;
    display: flex;
    margin: 0 auto;
    z-index: 1;
    min-width: rem(250);
    max-width: rem(380);
    width: 100%;
    height: rem(380);
    object-fit: contain;
  }

  &__text-wrap {
    padding-left: rem(52);
    padding-right: rem(52);
    display: flex;
    flex-direction: column;
    flex: 1 1;
  }

  &__size-wrap {
    margin-bottom: rem(30);
    flex-grow: 1;
  }

  &__title {
    text-transform: uppercase;
    line-height: rem(24);
    margin-bottom: rem(20);
    margin-top: rem(20);
    width: fit-content;
    background-color: #d7d7d7;
    padding: 0 rem(5);
  }

  &__coffee-acidity {
    line-height: normal;
  }

  &__coffee-body {
    line-height: normal;
    text-overflow: ellipsis;
    overflow: hidden; 
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &__desc,
  &__size {
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: 0.05em;
    font-size: rem(24);
  }

  &__price {
    font-weight: 700;
    text-transform: lowercase;
    letter-spacing: 0.05em;
    font-size: rem(24);
  }

  &__desc {
    margin-bottom: rem(27);
    color: $black;
    cursor: pointer;
  }

  &__purveyor {
    font-weight: 700;
    font-size: rem(22);
    text-transform: uppercase;
    margin-bottom: rem(20);
    cursor: pointer;
  }

  &__size {
    color: #6d6c6c;
  }
}

@include mobile {
  .Product {
    height: auto;
    width: 302px;

    &__button {
      &-label {
        width: 216px;
        height: 54px;
        font-size: 14px;
      }
    }

    &__wrap {
      height: auto;
      display: flex;
      flex-direction: column;
    }
    &__award-text {
      font-size: 16px;
    }

    &__pinterest {
      top: 120px !important;
      right: 20px;
      position: relative;
    }

    &__pinterest-icon {
      max-height: 36px !important;
      width: fit-content !important;
    }
    &__review-badge {
      width: 60px;
      height: 60px;
      position: absolute;
      left: 242px;
      top: 50px;
    }

    &__label-wrap {
      height: 55px;
    }

    &__label-new,
    &__label-sale,
    &__label-top-number {
      font-size: 14px;
      letter-spacing: rem(2);
      width: 55px;
      height: 55px;
    }

    &__label-sale-procent {
      font-size: 20px;
      line-height: 19px;
    }

    &__label-new {
      margin-right: 0.9rem;
    }

    &__label-top-number {
      margin-right: 0.9rem;
      font-size: 20px;
    }

    &__image {
      min-width: 149px;
      max-width: 219px;
      height: 219px;
      object-fit: contain;
    }

    &__text-wrap {
      margin-top: 0.5rem;
      font-size: rem(20);
      padding-left: 44px;
      padding-right: 40px;
    }

    &__title {
      font-size: 14px;
      letter-spacing: 0.01em;
    }
    &__purveyor {
      font-size: 14px;
    }

    &__desc,
    &__size,
    &__price {
      line-height: 1.5;
      font-size: 14px;
      letter-spacing: 0.7px;
    }

    &__button {
      font-size: 14px;
      margin-bottom: 29px;
    }
  }
}

@include tablet {
  .Product {
    width: auto;
  }
}
