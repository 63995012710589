@import "src/styles/lib";

.BulkCoffees {
    margin-top: rem(180);
    &__banner-wrapper {
        display: flex;
        flex-direction: column;
  
    }
    &__main-title {
        text-align: center;
        font-size: rem(34);
        margin-bottom: rem(24);
        font-weight: 700;
    }
    &__main-description {
        text-align: center;
        font-size: 16px;
        margin-bottom: rem(32);
        font-weight: 500;
    }
    &__banner {
        margin: rem(20) auto 0;
        max-width: rem(1800);
        max-height: rem(550);
        object-fit: contain;
        padding: 2rem 0;
    }

    &__banner-text {
        margin: rem(20) auto 0;
        text-transform: uppercase;
        font-weight: 600;
    }

    &__subtitle {
        max-width: rem(1500);
        padding: 0 5%;
        margin: 0 auto rem(100);
        text-align: center;
        font-weight: 600;
    }
    &__middle-line {
        margin: 0 auto rem(40);
    }
    &__products-wrapper {
        max-width: rem(1800);
        margin: 0 auto rem(100);
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: rem(20);
    }

    &__container {
        display: grid;
        grid-template-columns: 20% 70%;
        justify-content: center;
        grid-gap: rem(30);
        padding: 0 2%;
        margin-top: rem(150);
    }
}

@include desktop {
    .BulkCoffees {
        &__products-wrapper {
            grid-template-columns: repeat(3, 1fr);
            padding: 0 4%;
        }
    }
}


@include tablet-big {
    .BulkCoffees {
        &__products-wrapper {
            grid-template-columns: repeat(3, 1fr);
            padding: 0 4%;
        }
    }
}

@include tablet {
    .BulkCoffees {
        &__products-wrapper {
            grid-template-columns: repeat(2, 1fr);
            padding: 0 4%;
        }
        &__container {
            padding: 0;
        }
        &__banner {
            width: 90%;
          }
    }
}
@include mobile {
    .BulkCoffees {
        &__products-wrapper {
            grid-template-columns: 1fr;
            justify-items: center;
        }
        &__banner {
          width: 100%;
        }
        &__container {
            display: grid;
            grid-template-columns: 100%;
            justify-content: center;
            grid-gap: rem(30);
            padding: 0 2%;
            margin-top: rem(110);
        }
      
    }
}