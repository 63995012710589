@import "../../../styles/lib";

.FilterRadioButton {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &__outer-circles {
    position: relative;
    width: 18px;
    height: 18px;
    border: 1px solid $black;
    border-radius: 100%;
    margin-right: rem(10);
  }

  &__inner-circle {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: transparent;
    transition: all 0.1s linear;
  
    &--active {
      background-color: #00d1d6;
    }
  }

  &__labels {
    color: $black;
    padding-right: rem(8);
    font-size: rem(22);
  }
}

@include mobile {
  .FilterRadioButton{
    padding-bottom: 1rem;
    &__labels {
      font-size: 16px;
    }
    &__inner-circle {
      width: 8px;
      height: 8px;
    }

    &__outer-circles {
      width: 14px;
      height: 14px;
    }
  }
}
