@import "./src/styles/lib";

.Checkout {
  padding-top: rem(200);
  padding-bottom: rem(150);
  overflow-x: hidden;

  &__autoship-title {
    margin-top: rem(-40);
    margin-bottom: rem(40);
    font-weight: 600;
    font-size: rem(34);
    line-height: em(35px, 34px);
    text-align: center;
    &--second {
      font-weight: 400;
      margin-bottom: rem(49);
    }
  }

  &__change-button {
    //margin-right: rem(23);
    cursor: pointer;
    outline: none;
    color: #00d1d6;
    padding: 0;
    text-decoration: underline;
    border: none;
    font-size: rem(25);
    font-weight: 400;
    font-family: $cairo;
    background: transparent;
  }

  &__addresses {
    display: grid;
    grid-template-columns: 1fr;
    align-content: flex-start;
    grid-gap: rem(70);
    margin-bottom: rem(45);
    &--billing {
      grid-template-columns: 1fr;
    }
  }

  &__title-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(8);
  }

  &__title {
    font-size: rem(25);
    font-weight: 700;
    height: fit-content;
    //margin-left: rem(23);
  }

  &__titles {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: rem(70);
  }

  &__nav {
    background: #ebebeb;
    height: rem(35);
    margin-top: rem(160);
    align-items: center;
    display: flex;
    padding-left: 10%;
    padding-right: 20%;
  }

  &__nav-title {
    font-weight: 400;
    font-size: rem(16);
    line-height: em(24px, 16px);
    color: $black;
    opacity: 0.3;
    cursor: pointer;
    outline: none;
    border: none;
    background: transparent;
    &--active {
      color: $black;
      opacity: 1;
    }
  }

  &__or-divider {
    margin-top: 40px;
    margin-bottom: 0px;
    font-size: rem(33);
    font-weight: 400;
    overflow: hidden;
    text-align: center;
    width: 100%;
    > span {
      position: relative;
      display: inline-block;
      &::after, &::before {
        content: '';
        position: absolute;
        top: 50%;
        border-bottom: 1px solid;
        width: rem(400);
        margin: 0 20px;
      }
      &::before {
        right: 100%;
      }
      &::after {
        left: 100%;
      }
    }
  }

  &__or-container {
    margin-top: rem(17);
    margin-bottom: rem(17);
  }

  &__or {
    font-size: rem(22);
    color: white;
    background: black;
    height: 100%;
    text-align: center;
    padding-top: rem(9);
    padding-bottom: rem(9);
  }

  &__address-book {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-gap: 5%;
    margin-bottom: rem(20);
  }

  &__right-arrow {
    padding-left: rem(23);
    padding-right: rem(23);
  }

  &__head {
    width: 100%;
    height: rem(94);
    background: $black;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__girl-in-circle {
    position: absolute;
    bottom: rem(30);
    right: rem(30);
    width: rem(382);
    height: rem(487);
    z-index: -1;
  }

  .ShippingInformation {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  &__content {
    background: white;
    margin-top: rem(53);
    padding-top: rem(151);
    padding-bottom: rem(101);
    padding-left: 5%;
    padding-right: 5%;
    margin-left: 10%;
    margin-right: 10%;
    display: grid;
    grid-template-columns: 60% 35%;
    grid-gap: rem(100);
    border: 1px solid #707070;
    position: relative;
    box-sizing: border-box;

    &--shipping {
      &::after {
        content: "";
        position: absolute;
        bottom: rem(-122);
        right: rem(-175);
        background: url("https://assets.gocoffeego.com/v2/assets/images/girl-in-circle.png");
        background-repeat: no-repeat;
        background-size: 100%;
        z-index: -1;
        width: rem(382);
        height: rem(487);
      }
    }

    &--payment {
      &::after {
        content: "";
        position: absolute;
        bottom: rem(-44);
        left: rem(-188);
        background: #ff008e;
        border-radius: 50%;
        z-index: -1;
        width: rem(312);
        height: rem(312);
      }
    }
  }
}

@include tablet-big {
  .Checkout {
    &__content {
      margin-left: 5%;
      margin-right: 5%;
    }
  }
}

@include tablet {
  .Checkout {
    &__content {
      margin-left: 5%;
      margin-right: 5%;

      &::after {
        display: none;
      }
    }
    &__content {
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }
  }
}

@include mobile {
  .Checkout {
    padding-top: 70px;

    &__content {
      padding-left: 0;
      padding-right: 0;
      margin-left: 5%;
      margin-right: 5%;

      &::after {
        display: none;
      }
    }

    &__title {
      font-size: 15px;
    }

    &__addresses {
      grid-template-columns: 1fr;
    }

    &__change-button {
      font-size: 15px;
      margin-right: 0;
    }

    .PaymentMethod {
      width: 100%;
      &__cards {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 0;
      }
      &__content {
        grid-template-columns: 1fr;
      }
    }

    &__address-book {
      grid-template-columns: 1fr;
    }

    .ShippingInformation {
      grid-template-columns: 1fr;
    }

    &__content {
      border: none;
      padding: 0;
      padding-top: 40px;

      &:after {
        display: none;
      }
    }

    &__nav {
      display: none;
    }

    &__content {
      grid-template-columns: 1fr;
    }
  }
}
