@import "src/styles/lib";

.Recipes {
  padding-top: rem(100);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  //background: url("https://assets.gocoffeego.com/v2/assets/images/articles-background.svg")
  //  no-repeat left rem(148) top rem(482);
  //background-size: 90%;

  &__select-box {
    margin-bottom: rem(30);
  }

  &__peaberry-sitting {
    position: absolute;
    bottom: rem(10);
    left: 0;
    width: 20%;
  }

  &__flowers {
    position: absolute;
    top: 0;
    right: rem(-35);
    margin-top: auto;
    margin-bottom: auto;
    bottom: 0;
    width: rem(274);
    height: rem(428);
  }
  &__background {
    position: absolute;
    left: rem(148);
    bottom: 0;
    z-index: -100;
    width: 80%;
    height: 70%;
  }

  &__title {
    font-size: rem(30);
    font-weight: 700;
    position: relative;
    margin-bottom: rem(50);
    margin-top: rem(40);
    text-transform: uppercase;
  }

  &__shape {
    width: rem(150);
    height: rem(150);
    position: absolute;
    bottom: rem(15);
    left: rem(-80);
    opacity: 0.7;
    border-radius: 50%;
    background: #0066b2;
    z-index: -1;
  }

  &__description {
    min-width: rem(1169);
    margin: 0;
    font-size: rem(24);
    font-weight: 400;
    position: relative;
    border-bottom: 1px solid $black;
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: rem(30);
    margin-top: rem(96);
    grid-row-gap: rem(76);
    margin-bottom: rem(69);
  }
  &__bottom-link {
    margin: rem(30);
    text-align: center;
    font-weight: bold;
    &:hover {
      color: #00d1d6;
    }
  }
  &__line {
    width: 100%;
    margin-top: rem(101);
  }
}
@include laptop {
  .Recipes {
    &__background {
      left: rem(148);
      bottom: rem(220);
    }
  }
}

@include tablet-big {
  .Recipes {
    &__background {
      left: rem(148);
      bottom: rem(150);
    }
  }
}

@include mobile {
  .Recipes {
    padding-left: 10%;
    padding-right: 10%;
    //background: url("https://assets.gocoffeego.com/v2/assets/images/articles-background-mobile.svg")
    //  no-repeat left 0 top 454px;
    //background-size: 100%;

    &__shape {
      width: 28px;
      height: 28px;
      top: -20px;
      left: -20px;
    }

    &__title {
      font-size: 16px;
      margin: 0;
      margin-bottom: 20px;
      font-weight: 700;
      border-bottom: 2px solid $black;
    }

    &__peaberry {
      display: none;
    }
    &__flowers {
      display: none;
    }
    &__background {
      position: absolute;
      width: 100%;
      left: 0;
    }

    &__peaberry-sitting {
      display: none;
    }

    &__items {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 30px;
    }

    &__description {
      min-width: auto;
    }

    &__line {
      display: none;
    }
  }
}
