@import "./src/styles/lib";

.Shop {
  font-family: $cairo;
  overflow: hidden;
  position: relative;
  &__roaster-message {
    text-align: center;
    font-size: rem(32);
    margin-bottom: rem(50);
    font-weight: 600;
   }
   &__main-title {
    text-align: center;
    font-size: rem(34);
    margin-bottom: rem(24);
    font-weight: 700;
   }
   &__main-description {
    text-align: center;
    font-size: 16px;
    margin-bottom: rem(32);
    font-weight: 500;
   }

  &__banner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    margin-top: rem(110);
  }

  &__banner-grid {
    max-width: rem(1600);
    display: grid;
    grid-template-columns: rem(324) rem(1240);
    grid-gap: rem(80);
    margin: rem(80) auto 0;
  }
  &__filter-desktop {
    display: inline-block;
  }
  &__filter-mobile {
    display: none;
  }

  &__wrapper {
    justify-content: center;
    margin: 0 auto;
    padding-top: rem(60);
  }

  &__message {
    font-size: rem(42);
    width: 100%;
    text-align: center;
    margin-top: rem(200);
  }

  &__products {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: rem(25);
    margin-bottom: rem(50);

    &::after {
      content: "";
      position: absolute;
      height: rem(278);
      width: rem(278);
      border-radius: 50%;
      bottom: rem(630);
      right: rem(-100);
      background-color: #ff0006;
      z-index: -1;
    }
  }

  &__image {
    display: none;
  }
}

@include tablet {
  .Shop {
    &__wrapper {
      grid-template-columns: 1fr 2fr;
      grid-gap: 50px;
    }

    &__products {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: rem(25);
    }

    &__banner-grid {
      display: grid;
      grid-template-columns: 30% 60%;
      margin: 0 auto;
      justify-content: center;
    }
  }
}

@include mobile {
  .Shop {
    &__banner-container {
      display: flex;
      justify-items: center;
      justify-content: center;
    }
    &__filter-desktop {
      display: none;
    }
    &__filter-mobile {
      display: inline-block;
    }

    &__banner-grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 0;
      justify-content: center;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 56px;
      padding-right: 56px;
      padding-top: 38px;
    }

    &__products {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 2.5rem;
      padding-left: 0;
      padding-right: 0;
      margin-bottom: rem(20);

      &::before {
        content: "";
        position: absolute;
        height: 125px;
        width: 125px;
        border-radius: 50%;
        top: rem(310);
        left: rem(-120);
        background-color: #6ae200;
        z-index: -1;
      }

      &::after {
        content: "";
        position: absolute;
        height: 176px;
        width: 176px;
        border-radius: 50%;
        top: rem(1850);
        right: rem(-250);
        background-color: #ff0006;
        z-index: -1;
      }
    }

    &__image {
      display: block;
      width: 350px;
      height: 455px;
      margin: 0 auto 6rem;
    }

    &__button {
      font-size: 16px;
      margin-top: 40px;
    }
  }

}