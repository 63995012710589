@import "../../../styles/lib";

.QuizButton {
    width: rem(210);
    height: rem(210);
    border-radius: 50%;
    position: relative;
    background-color: #0464B4;
    z-index: 0;
    cursor: pointer;
   
    &__text {
        text-align: center;
        line-height: 1;
        font-size: rem(50);
        font-weight: 600;
        z-index: 4;
        position: absolute;
        top: rem(58);
        left: 0;
        text-transform: uppercase;   
    }

    &__outer {
        background-color: $white;
        width: rem(140);
        height: rem(140);
        transform: translate(25%, 25%);
        border-radius: 50%;
        z-index: 0;
    }

    &__inner {
        background-color: $red;
        width: rem(210);
        height: rem(210);
        transform:scale(0.45) translate(-35%, -35%);
        border-radius: 50%;
        z-index: 0;
    }
}

.QuizButton .QuizButton__inner {
    animation: 2s linear 0s infinite alternate buttonHover;
}

@keyframes buttonHover { 
    from { transform:scale(0.45) translate(-35%, -35%);
         } 
    to { 
        transform: translate(-16.6%, -16.6%) scale(0.67); 
    }
}

@include mobile {
    .QuizButton:hover .QuizButton__inner {
        animation-play-state: paused;
    }
}