@import "src/styles/lib";

.Music {
    margin-top: rem(130);
    font-family: $cairo;

    &__main-title {
        width: rem(600);
        height: rem(200);
    }

    &__subtitle {
        margin-top: rem(130);
        text-align: center;
        font-size: rem(32);
        font-weight: 600;
    }

    &__subtitle-text {
        margin-top: rem(20);
        text-align: center;
        font-size: rem(24);
        letter-spacing: 0.02em;
    }

    &__container {
        margin: rem(120) 0;
        position: relative;
        &::after {
          position: absolute;
          content: "";
          width: rem(250);
          height: rem(250);
          background-color: $red;
          border-radius: 50%;
          z-index: -1;
          bottom: rem(800);
          left: rem(440);
        }
        &::before {
          position: absolute;
          content: "";
          width: rem(250);
          height: rem(250);
          background: url("https://assets.gocoffeego.com/v2/assets/images/Learn-flowers2.png")
            no-repeat center center;
          background-size: 100%;
          z-index: 0;
          top: rem(-140);
          right: rem(460);
        }
    }

    &__wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: rem(20);
        margin: 0 auto;
        align-items: center;
        justify-content: space-between;
        max-width: rem(1000);
        width: 100%;
        margin-bottom: rem(50);
        padding: rem(40);
        border: 3px solid $black;
        background-color: $white;
        z-index: 1;
    }

    &__image {
        width: rem(350);
        height: rem(350);
        object-fit: contain;
    }

    &__description {
        font-size: rem(22);
        font-weight: 500;
    }

    &__song-title {
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 0.02em;
        font-size: rem(30);
        margin-bottom: rem(10);
    }

    &__song-artist, &__song-contact {
        padding-left: rem(10);
        font-weight: 600;
    }

    &__song-artist {
        text-transform: uppercase;
    }

    &__song-album,  &__song-available  {
        padding-left: rem(10);
        font-weight: 600;
    }

}

@include mobile {
    .Music {
        &__wrapper {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 0;
            align-items: center;
            justify-items: center;
            width: 350px;
            padding: rem(40);
            border: 3px solid $black;
        }

        &__image {
            margin-bottom: rem(20);
        }

        &__container {
            &::after {
              position: absolute;
              content: "";
              bottom: rem(520);
              left: rem(-60);
            }
            &::before {
              position: absolute;
              content: "";
              top: rem(-140);
              right: rem(-40);
            }
        }
    }
}