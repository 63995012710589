@import "../../../styles/lib";

.Line {
  width: 100%;
  height: rem(21);
  margin-bottom: rem(10);
  background: #0a0a0a;

  //&:nth-child(2) {
  //  margin-bottom: 0;
  //}
}

@include mobile {
  .Line {
    //z-index: 0 !important;
  }
}
