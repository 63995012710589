@import "src/styles/lib";

.NewsletterSuccessModal {
  // margin: rem(21);
  border: 3px solid black;
  max-width: rem(1587);
  width: 100%;
  min-height: rem(650);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    right: -28px;
    bottom: -10px;
    width: rem(208);
    height: rem(208);
    background-color: #6ae200;
    border-radius: 50%;
  }
  &::before {
    content: "";
    position: absolute;
    left: 5px;
    top: 0;
    width: rem(325);
    height: rem(247);
    background: url("https://assets.gocoffeego.com/v2/assets/images/peaberry-lounge.png")
      no-repeat center center;
    background-size: 100%;
    transform: rotateY(180deg);
  }

  &__button {
    &:hover {
      border-color: $black;
      color: $black;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: rem(40);
    position: relative;
  }

  &__title {
    font-size: rem(32);
    line-height: rem(38);
    font-weight: 700;
    margin-top: rem(15);
    margin-bottom: rem(20);
  }

  &__desc {
    margin-top: rem(20);
    margin-left: rem(64);
    margin-right: rem(64);
    width: rem(1028);
    font-weight: 400;
    font-size: rem(32);
    line-height: rem(38);
    text-align: center;
    margin-bottom: rem(30);
    z-index: 2;
  }
}

@include mobile {
  .NewsletterSuccessModal {
    height: auto;
    padding: 0 20%;
    &::before {
      display: none;
    }

    &__container {
      margin-top: 0;
    }

    &__title {
      font-size: 13px;
    }

    &__desc {
      margin-left: 0;
      margin-right: 0;
      padding: 0 25%;
      font-size: 12px;
    }
  }
}
