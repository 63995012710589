@import "src/styles/lib";

.SelectBoxShipments {
  white-space: nowrap;
  min-width: rem(175);
  position: relative;
  padding: rem(10);

  &--shipment {
    border: 1px solid #ccd9e2;
  }

  &__item {
    font-size: rem(22);
    font-weight: 400;
    margin: 0;
    line-height: em(25px, 22px);
    cursor: pointer;
  }

  &__container {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: rem(10);
    padding-left: 0;
    height: rem(34);
    position: relative;
    cursor: pointer;
  }

  &__header {
    font-size: rem(23);
    font-weight: 400;
    color: black;
    margin-right: rem(25);
  }

  &__icon {
    position: absolute;
    right: 0;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    cursor: pointer;
  }

  &__items {
    left: 0;
    width: 100%;
    position: absolute;
    z-index: 5;
    background: #ffffff;
    display: none;
    //border-radius: 4px;
    border: 2px solid #ccd9e2;
    margin-top: rem(10);
    padding: 10px;
    max-height: rem(350);

    &.active {
      display: block;
    }
  }

  &__item-container {
    padding: rem(8);
    border-radius: 4px;
    line-height: rem(21);
    cursor: pointer;

    &.activeLabel {
      background: #f2f5f7;
      cursor: pointer;
    }

    &:hover {
      background: #f2f5f7;
      cursor: pointer;
    }
  }
}

@include tablet-big {
  .SelectBoxShipments {
  &__header {
    font-size: rem(20);
  }
}
}

@include tablet {
  .SelectBoxShipments {
  &__header {
    font-size: rem(20);
  }
 }
}

@include mobile {
  .SelectBoxShipments {
    width: 112px;
    border-bottom: none;

    &--shipment {
      margin-top: 10px;
      border: 1px solid #ccd9e2;
      height: 33px;
      align-items: center;
    }

    &__item {
      font-size: 14px;
      line-height: em(20px, 14px);
      margin-bottom: rem(10);
    }

    &__header {
      font-size: 14px;
    }

    &__items {
      margin-top: rem(20);
      padding: 10px 5px;
    }
  }
}
