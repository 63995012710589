@import "../../../../styles/lib";

.FilterCategory {
  line-height: rem(24);
  &__wrap {
    position: relative;
    display: flex;
    align-items: center;
    border-top: rem(2) solid $black;
    padding: rem(24) 0;
    transition: all 200ms ease-in-out;
    cursor: pointer;
  }

  &__name {
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: $black;
    font-weight: 700;
    font-size: rem(20);
    line-height: rem(24);
  }

  &__line1, &__line2 {
    position: absolute;
    width: 16px;
    height: 1px;
    background-color: $black;
    top: rem(37);
    right: 0;
    transition: all 200ms ease-in-out;
  }

  &__line2 {
    transform: rotate(90deg);
  }

  &__items {
    display: none;
  }

  &__item {
    display: block;
    position: relative;
    cursor: pointer;
    padding-left: rem(30);
    margin-bottom: rem(12);
    font-size: rem(16);
    user-select: none;
  }

  .open {
    .FilterCategory__line2 {
      transform: rotate(45deg);
    }

    .FilterCategory__line1 {
      transform: rotate(-45deg);
    }
  }

  .open {
    &__items {
      display: block;
      line-height: rem(24);
      transition: all 300ms ease-in-out;
    }
  }
}

@include mobile {
  .FilterCategory {
    &__name {
      font-size: 14px;
      padding: 5px 0;
    }

    &__line1, &__line2 {
      width: 16px;
    }
  }
}
