@import "../../../styles/lib";

.PriceRange {
    border: 3px solid $black;
    height: auto;
    max-width: rem(1587);
    width: 100%;
    min-height: rem(600);
    padding: rem(90) rem(150) rem(50);

    &__title {
        text-align: center;
        font-size: rem(40);
        margin-bottom: rem(40);
        font-weight: 600;
    }

    &__text {
        font-size: rem(26); 
        margin-bottom: rem(20);
    }
} 

@include mobile {
    .PriceRange {
        height: auto;
        padding: 10%;
    } 
}