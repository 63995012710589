@import "../../../styles/lib";

.CoffeeFaqHeadline {
  margin-top: rem(395);
  &__background {
    background-image: url("https://assets.gocoffeego.com/v2/assets/images/coffee-faq-headline-bg.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    display: block;
    padding-bottom: 100px;
    position: relative;
  }

  &__mobile {
    display: none;
  }

  &__reading {
    position: absolute;
    right: 0;
    top: rem(-313);
    width: 37%;
  }

  &__peaberry {
    position: absolute;
    left: rem(282);
    top: rem(-240);
    width: 30%;
  }

  &__flowers {
    position: absolute;
    left: rem(-112);
    top: rem(-225);
    width: 20%;
  }

  &__content {
    padding-top: 18%;
    padding-left: 18%;
  }

  &__title {
    font-size: rem(30);
    font-weight: 600;
    line-height: em(36px, 30px);
    letter-spacing: 0.5rem;
    color: $white;
    max-width: rem(490);
    margin-bottom: rem(63);
  }

  &__button-container {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      height: rem(151);
      width: rem(151);
      border-radius: 50%;
      top: rem(15);
      left: rem(-94);
      background-color: #ff008e;
      opacity: 0.9;
    }
  }

  &__button {
    color: $white;
    border: 3px solid $white;
    position: relative;
    z-index: 10;
    transition: all 200ms ease-in-out;
    &:hover {
      color: $white;
    }
  }
}

@include mobile {
  .CoffeeFaqHeadline {
    margin-top: rem(300);
    &__mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 58px;
    }

    &__background {
      background: url("https://assets.gocoffeego.com/v2/assets/images/coffee-faq-headline-bg-mb.svg")
        no-repeat left 0;
      height: 100%;
      background-size: 100%;
      min-height: 243px;
    }

    &__peaberry {
      position: absolute;
      left: 22px;
      top: (-23px);
      width: 55%;
    }

    &__reading {
      right: 0;
      top: -43px;
    }

    &__content {
      display: none;
    }

    &__title {
      margin-top: 24px;
      width: auto;
      font-size: 20px;
      font-weight: 600;
      color: $black;
      text-align: center;
      margin-bottom: 54px;
    }

    &__button {
      color: $black;
      border: 3px solid $black;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        height: 56px;
        width: 56px;
        border-radius: 50%;
        top: -35px;
        left: -32px;
        background-color: #ff008e;
        opacity: 0.9;
        z-index: -1;
      }
    }
  }
}

@media only screen and (min-width: 512px) and (max-width: 768px) {
  .CoffeeFaqHeadline {
    &__background {
      min-height: 380px;
    }
  }
}
