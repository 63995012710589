@import "src/styles/lib";

.QuizQuestion1 {
  margin-top: rem(48);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: rem(107);
  width: 100%;

  &__main-image {
    width: rem(298);
    height: rem(298);
    object-fit: contain;
    margin-bottom: rem(44);
  }

  &__answers {
    margin-top: rem(87);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: rem(40);
  }

  &__answer {
    cursor: pointer;
    padding: rem(10);

    &--active {
      background: #ffec00;
    }
  }

  &__answer-title {
    font-weight: 700;
    font-size: rem(28);
    letter-spacing: 0.01em;
    text-transform: uppercase;
    text-align: center;

    &--active {
      color: $blue;
    }
  }

  &__description {
    margin-top: rem(19);
    font-weight: 400;
    font-size: rem(24);
    letter-spacing: 0.08em;
    line-height: em(24px, 17px);
    max-width: rem(300);
    text-align: center;

    &--active {
      color: $blue;
    }
  }

  &__mens {
    position: absolute;
    width: rem(445);
    height: rem(390);
    object-fit: contain;
    right: 0;
    bottom: rem(93);
  }

  &__target {
    height: rem(114);
    width: rem(114);
    object-fit: contain;
    position: absolute;
    bottom: rem(-30);
    right: rem(26);
  }
  &__lines-style {
    margin-top: rem(268);
  }

  &__lines {
    width: 100%;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: rem(249);
      left: 0;
      background: url("https://assets.gocoffeego.com/v2/assets/images/red-car.png");
      width: rem(255);
      height: rem(255);
      background-size: contain;
    }

    &::before {
      content: "";
      position: absolute;
      width: rem(368);
      height: rem(368);
      background: $blue;
      bottom: rem(-20);
      left: rem(-160);
      border-radius: 50%;
    }
  }
}

@include mobile {
  .QuizQuestion1 {
    &__mens {
      display: none;
    }

    &__answers {
      grid-template-columns: 1fr;
    }

    &__answer-title {
      font-size: 16px;
      text-align: center;
    }

    &__description {
      text-align: center;
      font-size: 15px;
      max-width: 200px;
    }

    &__lines {
      &::after {
        display: none;
      }

      &::before {
        display: none;
      }
    }
  }
}

@include tablet {
  .QuizQuestion1 {
    &__mens {
      width: rem(405);
      height: rem(350);
    }

    &__lines {
      &::after {
        width: rem(205);
        height: rem(205);
      }
    }
    &__mens {
      width: rem(285);
      height: rem(230);
    }
  }
}
