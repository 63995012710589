@import "../../../styles/lib";

.ShippingInfoModal {
  width: 60vw;
  height: 70vh;
  overflow-y: auto;

  &__container {
    font-size: 16px;
  }

  &__header {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(2, 0, 36);
    background: linear-gradient(
      0deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(255, 255, 255, 0) 0%,
      rgba(245, 233, 13, 1) 40%
    );
  }
  &__header-title {
    font-weight: bold;
    padding: 0px 10px;
  }
  &__close {
    margin-right: 20px;
    cursor: pointer;
  }
  &__body {
    padding: 0px 10px;
  }
  &__list-title {
    font-weight: bold;
    padding-bottom: 20px;
  }
  &__list-paragraph {
    padding-bottom: 5px;
  }
  &__paragraph-section {
    padding: 20px 0px;
  }
  &__bags-title {
    font-weight: bold;
    text-decoration: underline;
  }
  &__title-section {
    padding-bottom: 10px;
    font-weight: bold;
    text-decoration: underline;
  }
  &__email {
    font-weight: bold;
  }
  p {
    line-height: 28px;
  }
  &__underline {
    text-decoration: underline;
  }
  &__paragraph-section-important {
    margin: 10px 0;
    font-weight: 700;
    padding: 10px;
    border: 3px solid #ffff00;
    background-color: #ffff00;
  }
}

@include mobile {
  .ShippingInfoModal {
    width: 100%;
  }
}
