@import "../../../styles/lib";

.RoastersItem {
  width: rem(380);
  height: rem(360);
  border: 3px solid $black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  padding: 1rem 0.5rem 0;
  z-index: 6;
  &__image {
    min-height: rem(118);
    min-width: rem(150);
    max-height: 138px;
    max-width: rem(220);
    object-fit: contain;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &__name {
    text-align: center;
    font-size: rem(24);
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: rem(10);
  }

  &__desc {
    text-align: center;
    font-size: rem(24);
    line-height: 1.2;
    font-weight: 600;
    margin-bottom: rem(10);
  }
}

@include mobile {
  .RoastersItem {
    width: 170px;
    height: 150px;
    &__image {
      height: auto;
      width: 60%;
    }
  }
}
