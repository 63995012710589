@import "src/styles/lib";

.PeaberryRecommends {
  margin-top: rem(234);
  margin-bottom: rem(234);

  &__filters {
    padding-top: rem(42);
  }

  &__latest-recommend {
    margin-bottom: rem(130);
  }

  &__line {
    margin-bottom: 0;
    z-index: 5;
  }

  &__cards {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: rem(-100);
      left: rem(-145);
      height: rem(245);
      width: rem(245);
      background: #ff0006;
      border-radius: 50%;
      z-index: -1;
    }
  }

  &__content {
    padding-left: rem(130);
    padding-right: rem(130);
    padding-top: rem(0);
    display: grid;
    grid-template-columns: 1fr 3fr;
  }

  &__title {
    max-width: rem(1200);
    text-align: center;
    font-size: rem(36);
    margin: rem(50) auto rem(30);
    font-weight: 700;
  }
  &__main-description {
    text-align: center;
    font-size: 16px;
    margin-bottom: rem(80);
    font-weight: 500;
}

  &__title2 {
    max-width: rem(600);
    margin-bottom: rem(-12);
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@include tablet {
  .PeaberryRecommends {
    &__content {
      padding-left: rem(40);
      padding-right: rem(40);
    }
  }
}

@include mobile {
  .PeaberryRecommends {
    margin-top: 70px;

    &__filters {
      padding-top: 0;
      margin-left: auto;
      margin-right: auto;
    }

    &__content {
      margin-top: 20px;
      padding-left: 0;
      padding-right: 0;
      display: flex;
      flex-direction: column;
    }

    &__cards {
      padding-left: 5%;
      padding-right: 5%;
      &::before {
        display: none;
      }
    }
  }
}
