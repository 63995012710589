@import "../../../../styles/lib";

.AddContact {
  padding-top: rem(80);

  &__form {
    height: auto;
    max-width: 732px;
    width: 100%;
    background-color: $white;
    padding: 0 4rem 4rem;
    font-family: $cairo;
    z-index: 1;
    margin: 0 auto;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      background-color: #ff008e;
      width: 475px;
      height: 475px;
      border-radius: 50%;
      left: rem(-450);
      top: rem(-60);
      z-index: -1;
    }
    &::after {
      content: "";
      position: absolute;
      background: url("https://assets.gocoffeego.com/v2/assets/images/mocha.png")
        no-repeat center center;
      background-size: 100%;
      width: 194px;
      height: 387px;
      right: rem(-200);
      top: 20px;
    }
  }

  &__select {
    margin-bottom: 0;
  }

  &__message {
    color: $blue;
    text-align: center;
    font-size: rem(24);
  }

  &__title-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(60);
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      background-color: #6ae200;
      width: 183px;
      height: 183px;
      border-radius: 50%;
      left: rem(-550);
      top: rem(430);
      opacity: 0.8;
      z-index: -1;
    }
  }

  &__title {
    text-transform: uppercase;
    font-size: rem(40);
    line-height: rem(48);
    font-weight: 300;
    margin-top: rem(20);
    z-index: 1;
  }

  &__close {
    font-size: rem(40);
    &:hover {
      cursor: pointer;
      color: $blue;
    }
  }

  &__grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: rem(60);
  }

  &__input-container {
    margin-bottom: rem(10);
  }

  &__error {
    color: $red;
    margin-top: rem(10);
    margin-left: rem(15);
    text-align: right;
    font-size: rem(20);
  }

  &__input {
    width: 100% !important;
    border: 1px solid $black !important;
    height: rem(62) !important;
    border-radius: 0 !important;
    font-size: rem(25) !important;
  }

  &__button {
    font-size: rem(25);
    font-weight: 400;
    color: $black;
    background: $white;
    border: 3px solid $black;
    width: 100%;
    border-radius: 0;
    margin-top: rem(55);
    margin-bottom: rem(10);
    height: rem(62);
    transition: all 200ms ease-in-out;
    &:hover {
      border: 3px solid $blue;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@include mobile {
  .AddContact {
    &__grid-container {
      grid-template-columns: 1fr;
      gap: 0;
    }

    &__form {
      &::after {
        display: none;
      }
    }

    &__input {
      height: rem(70) !important;
    }

    &__button {
      height: 33px;
    }
  }
}

@include tablet {
  .AddContact {
    &__form {
      &::after {
        display: none;
      }
    }
  }
}

@include tablet-big {
  .AddContact {
    &__form {
      &::after {
        width: 144px;
        height: 337px;
      }
    }
  }
}
