@use "sass:math";

@import "./screens";

/*=====================================
=            MEDIA QUERIES            =
=====================================*/
/// Media Query mixin for mobile devices 0 < 768
///@content CSS blocks on the desired element or if used by itself you can play selectors inside
///@output It outputs the typed mediaquery and its css blocks if used inside an element
///@example
/// input {
///   font-size:32px;
///   @include mobile {
///     font-size:12px;
///     }
/// }

@mixin imagePlaceHolder($color) {
  object-fit: cover;
  background: $color;
  width: 100%;
  height: 100%;
  animation: fadeIn 2s;

  @media only screen and (max-width: 768px) {
    width: 75%;
    height: 200px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@mixin animateDowloadButton($animation,$duration,$method,$times){
  animation: $animation $duration $method $times;

  @keyframes pulse {
    0% {
      opacity: 1;
      transform: scaleY(1) scaleX(1);
    }
    20% {
      opacity: 0.5;
    }
    70% {
      opacity: 0.2;
      transform: scaleY(1.3) scaleX(1.15);
    }
    80% {
      opacity: 0;
      transform: scaleY(1.3) scaleX(1.15);
    }
    90% {
      opacity: 0;
      transform: scaleY(1) scaleX(1);
    }
  }
  
}

@mixin empty-message {
  min-height: rem(100);
  font-size: rem(35);
  text-align: center;
}

@mixin arrow-down {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  top: 0;
  height: rem(210);
  width: rem(45);
  background: url("https://assets.gocoffeego.com/v2/assets/images/arrow-down.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

@mixin mobile {
  @media only screen and (max-width: $screen-sm) {
    @content;
  }
}

/// Media Query mixin for small mobiles like iphone x, iphone 5, 6, or nexus 5
///@author Enis Rasimi
///@content CSS blocks on the desired element or if used by itself you can play selectors inside
///@output It outputs the typed mediaquery and its css blocks if used inside an element
///@example
/// input {
///   font-size:32px;
///   @include tablet {
///     font-size:12px;
///     }
/// }

@mixin smallMobile {
  @media only screen and (max-width: $screen-xxs) {
    @content;
  }
}

/// Media Query mixin for small tablets 768 < 992
///@author Enis Rasimi
///@content CSS blocks on the desired element or if used by itself you can play selectors inside
///@output It outputs the typed mediaquery and its css blocks if used inside an element
///@example
/// input {
///   font-size:32px;
///   @include tablet {
///     font-size:12px;
///     }
/// }
@mixin tablet {
  @media only screen and (min-width: $screen-sm) and (max-width: $screen-md) {
    @content;
  }
}
/// Media Query mixin for big tablets 991 < 1199
///@author Enis Rasimi
///@content CSS blocks on the desired element or if used by itself you can play selectors inside
///@output It outputs the typed mediaquery and its css blocks if used inside an element
///@example
/// input {
///   font-size:32px;
///   @include tablet-big {
///     font-size:12px;
///     }
/// }
@mixin tablet-big {
  @media only screen and (min-width: $screen-md) and (max-width: $screen-lg) {
    @content;
  }
}
/// Media Query mixin for laptop devices over 1200px  > 1200
///@author Enis Rasimi
///@content CSS blocks on the desired element or if used by itself you can play selectors inside
///@output It outputs the typed mediaquery and its css blocks if used inside an element
///@example
/// input {
///   font-size:32px;
///   @include desktop {
///     font-size:42px;
///     }
/// }
@mixin laptop {
  @media only screen and (max-width: $screen-lg) {
    @content;
  }
}

/// Media Query mixin for desktop devices over 1200px  > 1200
///@author Enis Rasimi
///@content CSS blocks on the desired element or if used by itself you can play selectors inside
///@output It outputs the typed mediaquery and its css blocks if used inside an element
///@example
/// input {
///   font-size:32px;
///   @include desktop {
///     font-size:42px;
///     }
/// }
@mixin desktop {
  @media only screen and (min-width: $screen-lg) and (max-width: $screen-xl) {
    @content;
  }
}

/// Media Query mixin for desktop devices over 1200px  > 1200
///@author Enis Rasimi
///@content CSS blocks on the desired element or if used by itself you can play selectors inside
///@output It outputs the typed mediaquery and its css blocks if used inside an element
///@example
/// input {
///   font-size:32px;
///   @include desktop {
///     font-size:42px;
///     }
/// }
@mixin desktop-big {
  @media only screen and (min-width: $screen-xl) and (max-width: $screen-xxl) {
    @content;
  }
}

@mixin print {
  @media print {
    @content;
  }
}

/*=====  End of MEDIA QUERIES  ======*/

// function for line-height
$font-size: 16;
@function em($pixels, $context: $font-size) {
  @return #{math.div($pixels, $context)}em;
}

$global-font-size: 100% !default;

/*
Removes the unit (e.g. px, em, rem) from a value, returning the number only.
@param {Number} $num - Number to strip unit from.
@returns {Number} The same number, sans unit.
*/

@function strip-unit($num) {
  @return math.div($num, $num * 0 + 1);
}

/*
Converts one or more pixel values into matching rem values.
@param {Number|List} $values - One or more values to convert. Be sure to separate them with spaces and not commas. If you need to convert a comma-separated list, wrap the list in parentheses.

@param {Number} $base [null] - The base value to use when calculating the `rem`. If you're using Foundation out of the box, this is 16px. If this parameter is `null`, the function will reference the `$base-font-size` variable as the base.

@returns {List} A list of converted values.
*/

@function rem($values, $base: null) {
  $rem-values: ();
  $count: length($values);

  /* If no base is defined, defer to the global font size */
  @if $base == null {
    $base: $global-font-size;
  }

  /* If the base font size is a %, then multiply it by 16px
  This is because 100% font size = 16px in most all browsers
  */
  @if unit($base) == "%" {
    $base: math.div($base, 100%) * 16px;
  }

  /* Using rem as base allows correct scaling */
  @if unit($base) == "rem" {
    $base: strip-unit($base) * 16px;
  }

  @if $count == 1 {
    @return -to-rem($values, $base);
  }

  @for $i from 1 through $count {
    $rem-values: append($rem-values, -to-rem(nth($values, $i), $base));
  }

  @return $rem-values;
}

/*
Converts a pixel value to matching rem value. *Any* value passed, regardless of unit, is assumed to be a pixel value. By default, the base pixel value used to calculate the rem value is taken from the `$global-font-size` variable.

@access private
@param {Number} $value - Pixel value to convert.
@param {Number} $base [null] - Base for pixel conversion.

@returns {Number} A number in rems, calculated based on the given value and the base pixel value. rem values are passed through as is.
*/
@function -to-rem($value, $base: null) {
  /* Check if the value is a number */
  @if type-of($value) != "number" {
    @warn inspect($value) + ' was passed to rem(), which is not a number.';
    @return $value;
  }

  /* Transform em into rem if someone hands over 'em's */
  @if unit($value) == "em" {
    $value: strip-unit($value) * 1rem;
  }

  /* Calculate rem if units for $value is not rem or em */
  @if unit($value) != "rem" {
    $value: math.div(strip-unit($value), strip-unit($base)) * 1rem;
  }

  /* Turn 0rem into 0 */
  @if $value == 0rem {
    $value: 0;
  }

  @return $value;
}
