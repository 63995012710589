@import "../../../styles/lib";

.MerchItem {
    z-index: 2;
    &:not(:last-child) {
        margin-right: rem(30);
    } 
    &__image {
        width: rem(210);
        height: rem(210);
        border: rem(4) solid $black;
        border-radius: 50%; 
        background-color: $white;
    }
}

@include mobile {
    .MerchItem {
        padding-bottom: rem(20);
        &:not(:last-child) {
            margin-right: 1rem;
        } 

        &__image {
            width: 136px;
            height: 136px; 
        }
    }
}