@import "src/styles/lib";

.ArticleContainer {
  margin-top: rem(250);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;

  &__title {
    width: rem(1169);
    font-size: rem(30);
    font-weight: 700;
    position: relative;
    margin-bottom: rem(50);
    margin-top: rem(40);
    text-transform: uppercase;
  }

  &__content {
    width: 100%;
    padding-left: 20%;
    padding-right: 20%;
  }

  &__shape {
    width: rem(150);
    height: rem(150);
    position: absolute;
    bottom: rem(15);
    left: rem(-80);
    border-radius: 50%;
    background: #ff008e;
    z-index: -1;
  }

  &__line {
    width: 100%;
    margin-top: rem(101);
  }
}

@include laptop {
  .ArticleContainer {
    &__shape {
      width: 28px;
      height: 28px;
      top: -29px;
      left: -20px;
    }
  }
}

@include tablet-big {
  .ArticleContainer {
    &__shape {
      width: 28px;
      height: 28px;
      top: -29px;
      left: -20px;
    }
    //&__content {
    //  padding-left: 25px;
    //  padding-right: 25px;
    //}
  }
}

@include tablet {
  .ArticleContainer {
    &__shape {
      width: 28px;
      height: 28px;
      top: -29px;
      left: -20px;
    }
  }
}

@include mobile {
  .ArticleContainer {
    margin-top: 100px;

    &__shape {
      width: 28px;
      height: 28px;
      top: -20px;
      left: -20px;
    }

    &__title {
      width: 90%;
      font-size: 16px;
      margin: 0;
      margin-bottom: 20px;
      font-weight: 700;
      border-bottom: 2px solid $black;
      margin-left: 15px;
      margin-right: 15px;
    }

    &__content {
      padding-left: 25px;
      padding-right: 25px;
    }

    &__line {
      display: none;
    }
  }
}
