@import "src/styles/lib";

.QuizRecommendations {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding-bottom: rem(309);
    padding-top: rem(96);
    font-family: $cairo;

    &__products-container {
        display: grid;
        grid-template-columns: 1fr;
        margin-bottom: rem(50);
    }
   
    &__products {
        position: relative;
        display: grid;
        grid-template-columns: repeat(4, rem(370));
        grid-gap: rem(25);
        margin-bottom: rem(50);
        margin: 0 auto;
    }

    .products-auto {
      display: flex;
      flex-wrap: wrap;
      margin:  auto;

     }


    &__product {
      display: flex;
      min-width: rem(270);
      max-width: rem(370);
    }
  
    &__title {
      margin-top: rem(42);
      margin-bottom: rem(88);
      font-size: rem(36);
      font-family: $cairo;
      font-weight: 400;
      letter-spacing: 0.6rem;
      line-height: em(36px, 36px);
    }
    &__no-recommendations {
      font-size: rem(30);
      text-align: center;
      margin: rem(100) auto;

     
    }
  }
  
  @include mobile {
    .QuizRecommendations {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 250px;
      &__products {
        grid-auto-flow: row;
         grid-template-columns: 1fr;
      }

      &__product {
        max-width: rem(600);
        margin: 0 auto;
    }

  
      &__title {
        text-align: center;
        margin-top: 22px;
        margin-bottom: 33px;
        font-size: 16px;
        font-family: $cairo;
        font-weight: 600;
        letter-spacing: 0.3rem;
        line-height: em(24px, 20px);
        width: 230px;
      }
    }
  }

  @include tablet {
    .QuizRecommendations {
      margin-left: 55px;
      margin-right: 55px;
      &__products {
        grid-auto-flow: unset;
        grid-template-columns: 1fr 1fr;
      }
  
      &__title {
        text-align: center;
        margin-top: 22px;
        margin-bottom: 33px;
        font-size: 16px;
        font-family: $cairo;
        font-weight: 600;
        letter-spacing: 0.3rem;
        line-height: em(24px, 20px);
        width: 230px;
      }
    }
  }