@import "./src/styles/lib";

.Quiz {
  width: 100%;
  overflow: hidden;

  &__questions {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
