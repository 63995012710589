@import "../../../styles/lib";

.TopHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: rem(60);
  padding: 1.2rem;
  background: #000000;
  transition: height 0.3s ease-out;
  &__text-container {
    width: 100%;
    display: block;
    margin: 0 auto;
  }
  &__text {
    margin: 0;
    font-size: rem(22);
    font-weight: 700;
    color: #fefefe;
    font-family: $cairo;
    transition: color 200ms ease-in;
    width: 100%;
    display: block;
    text-align: center;
    &:hover {
      color: #f5e90d;
    }
  }
 
  &__button-container {
    position: absolute;
    right: rem(130);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  &__userName {
    color: #fefefe;
    font-weight: 600;
    font-family: $cairo;
    font-size: rem(20);
    padding: 1px 6px;
    border-right: 2px solid #f5e90d;

    &-link {
      &:hover {
        color: #f5e90d;
        cursor: pointer;
      }
    }
  }

  &__button {
    background-color: transparent;
    border: none;
    color: #fefefe;
    font-weight: 600;
    font-family: $cairo;
    font-size: rem(20);
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color: #f5e90d;
    }
  }
  &__icon {
    height: rem(30);
    width: rem(38);
    object-fit: contain;
    padding-left: rem(6);
  }
}

@include desktop {
  .TopHeader {
    &__button-container {
      right: rem(80);
    }
  }
}

@include tablet-big {
  .TopHeader {
    &__button-container {
      right: rem(50);
    }

    &__button{
      display: none;
    }
  }
}

@include tablet {
  .TopHeader {
    position: sticky;
    top: 0;
    height: 43px;
    padding: 5px;
    z-index: 999;
    overflow: hidden;

    &__button{
      display: none;
    }
  }
}

@include mobile {
  .TopHeader {
    position: fixed;
    top: 0;
    height: 43px;
    padding: 5px;
    z-index: 999;
    overflow: hidden;

    &__text-container {
      justify-content: flex-start;
      max-width: 250px;
      margin: 0;
    }

    &__text {
      margin: 0;
      text-align: left;
      font-size: 12px;
      overflow: hidden;
    }

    &__button-container {
      right: 10px;
      max-width: 60px;
    }
  }
}
