@import "../../../../styles/lib";

.ChangeInfoForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: rem(100) auto rem(153);
  font-family: $cairo;
  width: rem(800);
  padding-bottom: rem(30);
  background-color: rgba(255, 255, 255, 1);
  border: 3px solid $black;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: rem(-80);
    left: rem(-100);
    width: rem(215);
    height: rem(215);
    border-radius: 50%;
    background-color: $red;
    z-index: -1;
  }
  &::after {
    content: "";
    position: absolute;
    top: rem(440);
    right: rem(-100);
    width: rem(270);
    height: rem(270);
    border-radius: 50%;
    background-color: #ffec00;
    z-index: -1;
  }

  &__title {
    font-size: rem(34);
    line-height: 63px;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    color: $black;
    margin-top: rem(16);
    margin-bottom: rem(40);
  }

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__container-wrap {
    display: grid;
    //  grid-template-columns: repeat(2, 1fr);
    // gap: 1rem;
    margin-bottom: rem(30);
    margin-left: auto;
    margin-right: auto;
    padding-right: 10%;
  }

  &__message {
    font-size: rem(24);
    color: $red;
  }

  &__input-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    margin-bottom: rem(20);
  }

  &__label {
    font-size: rem(20);
    color: $black;
    padding-right: rem(40);
    text-align: right;
  }

  &__input {
    width: rem(333);
    height: rem(36);
    border: 1px solid #707070;
    padding: 0 rem(10);
    font-size: rem(20) !important;
    &:focus-visible {
      outline: 1px solid $black;
    }
  }

  &__error {
    color: red;
    margin-top: rem(10);
    font-size: rem(18);
  }

  &__success-message {
    color: $blue;
    font-size: rem(18);
  }

  &__button {
    background-color: transparent;
    border: 3px solid $black;
    font-weight: 500;
    font-family: $rubik;
    cursor: pointer;
    outline: none;
    width: rem(348);
    height: rem(66);
    text-transform: uppercase;
    font-size: rem(16);
    letter-spacing: 0.02em;
    margin: 0 auto rem(10);
    transition: all 200ms ease-in-out;
    color: black;
    &:hover {
      border: 3px solid $blue;
    }
  }
}

@include mobile {
  .ChangeInfoForm {
    width: rem(680);
    padding: 0 rem(20);
    margin-top: 17px;

    &::after {
      top: rem(800);
      right: rem(-100);
      width: rem(270);
      height: rem(270);
    }

    &__form {
      padding-bottom: 20px;
    }

    &__container-wrap {
      grid-template-columns: 1fr;
      gap: 0;
      padding-right: 0;
    }

    &__title {
      margin-bottom: 0;
      font-size: 18px;
    }

    &__label {
      font-size: 14px;
      text-align: left;
    }

    &__error,
    &__success-message {
      font-size: 12px;
    }

    &__input {
      width: 100% !important;
      height: 24px;
      padding: 0 rem(10);
      font-size: 13px !important;
      &:focus-visible {
        outline: 1px solid $black;
      }
    }

    &__button {
      font-size: 12px;
    }

    &__input-container {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 0;
      margin-bottom: rem(20);
    }
  }
}
