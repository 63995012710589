@import "./src/styles/lib";

.RecommendationItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;


  &__image {
    width: rem(303);
    height: rem(303);
    object-fit: contain;
    border-radius: 50%;
  }

  &__title-container {
    background-color: $white;
    padding-top: rem(22);
    padding-bottom: rem(22);
    margin-top: rem(19);
    cursor: pointer;
    border: 3px solid #000000;
    padding-left: rem(10);
    padding-right: rem(10);
    width: rem(260);
    height: rem(77);
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    transition: all 200ms ease-in-out;
    &:hover {
      border: 3px solid $blue;
    }
  }

  &__title {
    margin: 0;
    padding: 0;
    font-family: $cairo;
    font-size: rem(20);
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    text-align: center;
  }
}

@include mobile {
  .RecommendationItem {
    &__image {
      width: 250px;
      height: 250px;
      object-fit: contain;
    }

    &__title-container {
      min-width: 170px;
      height: 60px;
      padding: 13px;
      margin-top: 25px;
    }

    &__title {
      font-size: 14px;
      line-height: 1.2;
    }
  }
}
