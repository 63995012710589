@import "src/styles/lib";

.SubscriptionDetailsModal {
  border: 3px solid $black;
  max-width: rem(796);
  padding-left: rem(72);
  padding-right: rem(72);
  padding-top: rem(58);
  padding-bottom: rem(58);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;

  &__title {
    font-size: rem(37);
    font-weight: 600;
    max-width: rem(324);
    text-align: center;
    line-height: em(41px, 37px);
  }

  &__description {
    font-size: rem(24);
    font-weight: 400;
    line-height: em(45px, 24px);
    margin-top: rem(37);
    min-width: rem(651);
  }
}
