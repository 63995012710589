@import "src/styles/lib";

.QuizQuestion2 {
  position: relative;
  width: 100%;
  margin-bottom: rem(135);

  &__quiz-lines {
    margin-top: rem(0);
  }

  &::after {
    position: absolute;
    content: "";
    background: url("https://assets.gocoffeego.com/v2/assets/images/cafecito.png")
      no-repeat center center;
    background-size: 100%;
    width: rem(167);
    height: rem(432);
    bottom: rem(188);
    left: rem(170);
    object-fit: contain;
  }
  &__answer-text {
    font-size: rem(28);
    font-family: $cairo;
    text-transform: uppercase;
    font-weight: 700;
    line-height: rem(47);
    letter-spacing: 0.08px;
    cursor: pointer;
    padding: rem(10);
    &--active {
      background: #ffec00;
    }
  }

  &__answer {
    &--active {
      background: #ffec00;
    }
  }

  &__wrap-95,
  &__wrap-94,
  &__wrap-93,
  &__wrap-102 {
    border-right: 2px solid #4c4c4c;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    width: rem(800);
    align-items: center;
    justify-content: center;
    margin: rem(47) auto rem(85);
  }

  &__wrap {
    min-width: rem(250);
    height: rem(200);
    padding-top: rem(24);
    margin-bottom: rem(60);
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  &__red-car {
    position: absolute;
    right: 0;
    top: 0;
    width: rem(424);
    height: rem(424);
    z-index: 1;
  }

  &__checkerboard {
    position: absolute;
    right: rem(10);
    top: rem(310);
    width: rem(190);
    height: rem(190);
  }

  &__small-target {
    position: absolute;
    bottom: rem(-130);
    left: rem(-80);
    background: url("https://assets.gocoffeego.com/v2/assets/images/sm-target.png")
      no-repeat center center;
    background-size: 100%;
    width: rem(277);
    height: rem(277);
    object-fit: contain;
  }
}

@include mobile {
  .QuizQuestion2 {
    &::after {
      left: -20px;
      bottom: rem(360);
    }

    &__wrap-95,
    &__wrap-103,
    &__wrap-102 {
      border-right: 2px solid #4c4c4c;
    }

    &__wrap-94,
    &__wrap-93 {
      border-right: none;
    }

    &__container {
      width: 340px;
    }

    &__red-car,
    &__checkerboard {
      display: none;
    }
    &__answer-text {
      font-size: 16px;
    }

    &__small-target {
      position: absolute;
      bottom: rem(-60);
      left: rem(-60);
      background: url("https://assets.gocoffeego.com/v2/assets/images/sm-target.png")
        no-repeat center center;
      background-size: 90%;
      width: 100px;
      height: 100px;
      object-fit: contain;
    }
  }
}

@include tablet {
  .QuizQuestion2 {
    &__red-car {
      width: rem(354);
      height: rem(354);
    }
  }
}
