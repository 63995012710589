@import "src/styles/lib";

.ShippingAddress {
  width: 100%;

  &__button {
    width: rem(350);
    text-transform: uppercase;
    height: rem(70);
    font-size: rem(26);
    font-family: $cairo;
    border: 3px solid $blue;
    background: $blue;
    color: #ffffff;
    font-weight: 700;
    letter-spacing: 0.03em;
    line-height: 1.2;
    position: relative;
    z-index: 1;
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      border: inherit;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background: $blue;
      border-radius: inherit;
      @include animateDowloadButton(pulse, 2.5s, linear, infinite);
    } 
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      border: inherit;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background: $blue;
      border-radius: inherit;
      @include animateDowloadButton(pulse, 3s, linear, infinite);
    } 
    &:focus {
      border: 1px solid #008080;
      outline: none;
    }
  }
  &__warning {
    margin: 10px 0;
    font-weight: 700;
    padding: 10px;
    border: 3px solid #ffff00;
    background-color: #ffff00;
    font-size: 16px;
  }
  &__warning-link {
     cursor: pointer;
     color: $blue;
     &:hover {
      color: $blue;
     }
  }

  &__buttons {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__or {
    font-size: rem(20);
    margin-left: rem(15);
    margin-right: rem(15);
  }
}

@include mobile {
  .ShippingAddress {
    &__button {
      font-size: 16px !important;
      height: 45px !important;
    }
  }
} ;
