@import "../../../styles/lib";

.Progress {
    display: flex;
    justify-content: center;
    letter-spacing: 1px;
    font-size: 12px;
     
    &__step {
        margin: 0.5rem 2rem;
        display: flex;
        align-items: center;
        color: $lightGray;
     
    
        a {
          color: $blue;
        }
    
        div:first-of-type {
          border: 1px solid #ccc;
          padding: 7px;
          border-radius: 50%;
          margin-right: 0.5rem;
          width: 2.5rem;
          height: 2.5rem;
          text-align: center;
          line-height: 100%;
        }
    
        :last-child {
          margin-top: 3%;
          //text-transform: uppercase;
        }
    
        &.active {
          div:first-of-type {
            background-color: $blue;
            border: 1px solid transparent;
            color: #fff;
          }
    
          div:last-of-type {
            color: $blue;
          }
        }
      }
}

@include laptop {
  .Progress {
    &__step {
    div:first-of-type {
      border: 1px solid #ccc;
      padding: rem(7);
      border-radius: 50%;
      margin-right: 0.5rem;
      width: 2.5rem;
      height: 2.5rem;
      text-align: center;
      line-height: 100%;
    }
  }
  }
}

@include mobile {
  .Progress {
    &__step {
      font-size: 12px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      div:first-of-type {
        border: 1px solid #ccc;
        padding: 5px;
        border-radius: 50%;
        margin-right: 0.5rem;
        width: 3rem;
        height: 3rem;
        text-align: center;
        line-height: 100%;
       // padding-top: 8%;
      }
  
      :last-child {
        margin-top: 3%;
        text-align: center;
      }
  
    }
  }
}