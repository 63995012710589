@import "src/styles/lib";

.Ages {
  &__content {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid black;
    margin-bottom: rem(100);
    padding-bottom: rem(50);
  }
  &__title {
    text-align: center;
    font-weight: bold;
    font-size: rem(28);
  }
  &__images {
    display: flex;
    justify-content: center;
  }
  &__image {
    object-fit: contain;
    max-width: 300px;
  }
  &__link {
    &:hover {
      color: $blue;
    }
  }
}

@include laptop {
  .Ages {
    &__content {
      display: flex;
      flex-direction: column;
    }
  }
}

@include desktop {
  .Ages {
    &__content {
      display: flex;
      flex-direction: column;
    }
  }
}
@include mobile {
  .Ages {
    &__images {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &__image {
    }
  }
}
