@import "../../../styles/lib";

.SelectBox {
  width: 240px;
  min-width: rem(220);
  border: 2px solid $black;
  position: relative;
  min-height: rem(46);
  height: 100%;
  &__item {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
  }

  &__container {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: rem(5);
    padding-left: rem(10);
    position: relative;
    cursor: pointer;
    height: 100%;
  }

  &__header {
    font-size: 16px;
    font-weight: 600;
    color: black;
    margin-right: rem(25);
  }

  &__icon {
    position: absolute;
    right: 5px;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    cursor: pointer;
  }

  &__items {
    overflow-y: scroll;
    max-height: rem(280);
    width: 100%;
    position: absolute;
    z-index: 5;
    background: #ffffff;
    display: none;
    min-width: rem(220);
    //border-radius: 4px;
    border: 2px solid #ccd9e2;
    margin-top: rem(10);
    padding: 10px;

    &.active {
      display: block;
    }
  }

  &__item-container {
    padding: rem(8);
    border-radius: 4px;
    line-height: rem(21);

    &.activeLabel {
      background: #f2f5f7;
    }

    &:hover {
      background: #f2f5f7;
      cursor: pointer;
    }
  }
}

@include mobile {
  .SelectBox {
    height: 30px !important;

    &__items {
      margin-top: 10px;
      max-height: 250px;
    }
    &__item {
      font-size: 18px;
    }
  }
}
