@import "src/styles/lib";

.SubscriptionFormModal {
  margin: rem(21);
  border: 3px solid black;
  width: rem(1116);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &__close {
    position: absolute;
    top: rem(15);
    right: rem(15);
    cursor: pointer;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: rem(151);
    background: url("https://assets.gocoffeego.com/v2/assets/images/PayAsYouGoImages/Bicycle-Girl.png")
      no-repeat;
    background-size: 100%;
    background-color: transparent;
    width: rem(200);
    height: rem(200);
  }

  &__image-container {
    height: rem(256);
    width: rem(356);
    margin-top: rem(24);
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: rem(-216);
      top: rem(55);
      background: url("https://assets.gocoffeego.com/v2/assets/images/left-stars.png")
        no-repeat;
      background-size: contain;
      width: rem(173);
      height: rem(173);
    }

    &::after {
      content: "";
      position: absolute;
      right: rem(-216);
      top: rem(55);
      background: url("https://assets.gocoffeego.com/v2/assets/images/right-stars.png")
        no-repeat;
      background-size: contain;
      width: rem(173);
      height: rem(173);
    }
  }

  &__image {
    object-fit: contain;
    height: rem(256);
    width: rem(356);
  }

  &__title {
    font-size: rem(24);
    font-weight: 600;
    margin-top: rem(15);
  }

  &__description {
    margin-top: rem(20);
    margin-left: rem(64);
    margin-right: rem(64);
    font-weight: 400;
    font-size: rem(18);
    line-height: em(33px, 18px);
  }

  &__description-container {
    width: 100%;
    border-bottom: 1px solid $lightGray;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: rem(31);
  }

  &__description2 {
    font-weight: 700;
    font-size: rem(18);
    text-align: center;
    padding-bottom: rem(25);
    position: relative;
    width: fit-content;

    //&::after {
    //  content: "";
    //  position: absolute;
    //  left: rem(-149);
    //  bottom: rem(0);
    //  background: url("../../../assets/images/peaberry-with-coffee.png")
    //    no-repeat;
    //  background-size: contain;
    //  width: rem(100);
    //  height: rem(79);
    //}
  }
}

@include mobile {
  .SubscriptionFormModal {
    margin: 5% !important;
    width: 90% !important;

    &::before {
      display: none;
    }

    &__close {
      top: 14px;
      right: 14px;
      //padding: 3px;
    }

    &__image-container {
      &::before {
        display: none;
      }

      &::after {
        display: none;
      }
    }

    &__title {
      font-size: 14px;
      text-align: center;
      margin-left: rem(64);
      margin-right: rem(64);
    }

    &__description {
      font-size: 14px;
      line-height: em(22px, 14px);
    }

    &__description2 {
      margin-top: 20px;
      font-size: 15px;

      &::after {
        display: none;
      }
    }
  }
}
