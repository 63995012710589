@import "src/styles/lib";

.ContactUs {
  padding-top: rem(200);
  padding-bottom: rem(210);

  &__title {
    text-align: center;
    margin-top: rem(30);
    margin-bottom: rem(30);
    font-weight: 600;
    font-size: 16px;
  }
  &__seo-description {
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    max-width: rem(1200);
    padding: 0 10px;
  }
  &__seo-title {
    max-width: rem(1200);
    text-align: center;
    font-size: rem(36);
    margin: rem(130) auto rem(30);
    font-weight: 700;
    padding: 0 10px;
  }

  &__container {
    display: flex;
    justify-content: center;
  }

  &__left-container {
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }

  &__leftContent-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: rem(20);
    padding: rem(10);
  }
  &__image {
    width: rem(571);
    object-fit: contain;
  }
  &__text-container {
    width: rem(424);
    text-align: center;
  }

  &__text1 {
    text-align: center;
    max-width: rem(424);
    font-size: rem(26);
    margin-top: rem(25);
    margin-bottom: rem(30);
  }

  &__text2 {
    text-align: center;
    max-width: rem(350);
    font-size: rem(26);
  }

  &__right-container {
    height: 100%;
    display: flex;
    justify-content: flex-start;
  }
  &__rightContent-container {
    display: flex;
    flex-direction: column;
    margin: rem(20);
    padding: rem(10);
  }
  &__checkbox-container {
    width: 100%;
  }
  &__checkbox-div {
    display: flex;
    align-items: center;
    margin-bottom: rem(15);
  }
  &__checkbox-icon {
    width: 30px;
    padding-left: rem(10);
  }
  &__checkbox-input {
    cursor: pointer;
  }
  &__checkbox-label {
    cursor: pointer;
    font-size: rem(20);
    padding-left: rem(10);
  }
  &__reasonError {
    font-size: rem(22);
    color: red;
    margin-top: rem(5);
  }
  &__form-container {
    width: 100%;
  }
}

@include mobile {
  .ContactUs {
    &__left-container {
      justify-content: center !important;
    }

    &__checkbox-container {
      width: fit-content;
    }

    &__rightContent-container {
      align-items: center;
      width: fit-content;
    }

    &__right-container {
      justify-content: center !important;
      justify-items: center;
    }

    &__container {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;

      &__image {
        width: rem(50);
        height: rem(50);
      }
    }
  }
}
