@import "src/styles/lib";

.ProductPopUp {
  cursor: pointer;
  display: none;
  z-index: 10;
  background: #f6f6f6;
  width: 100%;
  position: absolute;
  top: 0;
  padding: rem(20);
  height: 85%;
  overflow: hidden;

  &--show {
    display: block;
  }

  &__pinterest {
    position: absolute !important;
    left: 60px !important;
    top: 26px !important;
    z-index: 4 !important;
  }

  &__pinterest-icon {
    max-height: 26px !important;
    height: auto !important;
    width: fit-content !important;
  }
  @-moz-document url-prefix() {
    &__pinterest {
      position: absolute !important;
      left: 60px !important;
      top: 26px !important;
      z-index: 4 !important;
    }
  }

  &__details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(10);
  }

  &__image {
    height: rem(180);
    max-width: 120px;
  }

  &__title {
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    text-align: right;
  }
  &__subtitle {
    font-weight: 500;
    font-size: 14px;
    text-align: right;
    margin-bottom: rem(15);
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__price {
    font-weight: 700;
    text-transform: lowercase;
    letter-spacing: 0.05em;
    font-size: rem(22);
    text-align: right;
  }
  &__description {
    font-size: rem(22);
    margin-bottom: rem(30);
  }
  &__characteristics {
    font-size: rem(24);
  }
  &__title2 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    letter-spacing: 0.02em;
    padding-bottom: rem(15);
    font-weight: 700;
    &--name {
      color: #ff6600;
    }
  }
}

.Product__container {
  &:hover {
    .ProductPopUp {
      display: block;
    }
  }
}

.AwardWinnersItem {
  &:hover {
    .ProductPopUp {
      display: block;
      height: 100%;
      background: #ffffff;

      &__details {
        margin-bottom: rem(10);
      }

      &__description {
        font-size: rem(20);
        margin-bottom: rem(10);
        line-height: rem(30);
      }

      &__characteristics {
        font-size: rem(21);
        p {
          padding: rem(0);
          letter-spacing: 0em;
        }
      }
      &__title2 {
        padding-bottom: 0;
      }
    }
  }

}

.PrePaidSubscription__image-container {
  &:hover {
    .PrePaidPopUp {
      display: block;
    }
  } 
}

.Purveyor {
  &:hover {
    .ProductPopUp {
      display: block;
      height: 100%;
      &__details {
        margin-bottom: rem(10);
      }
      &__description {
        font-size: rem(20);
        margin-bottom: rem(10);
        &--modifier {
          display: none;
        }
      }
      &__characteristics {
        font-size: rem(21);
        p {
          padding-bottom: rem(5);
          letter-spacing: 0em;
        }
      }
      &__title2 {
        padding-bottom: 0;
      }
    }
  }
}

.RoasterPopUp {
  display: none;
  background: white;
  position: absolute;
  width: 100%;
  height: 95%;
  text-align: center;
  padding: rem(10);
  font-size: rem(18);
  font-weight: 600;

  &__title {
    font-size: rem(22);
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: rem(10);
  }
  &__image {
    width: rem(60);
    margin-bottom: rem(10);
  }
  &__description {
    margin-bottom: rem(10);
  }
  &__location {
    font-weight: 700;
    :first-child {
      color: #ff6600;
    }
  }
}

.PrePaidPopUp {
  display: none;
  background: #ffffff;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: rem(30) rem(10) rem(10);
  font-size: rem(18);
  font-weight: 600;
  border: 2px solid $black;

  &__title {
    font-size: rem(22);
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: rem(10);
    color: #ff6600;
  }

  &__subtitle {
    font-size: rem(22);
    font-weight: 700;
    margin-bottom: rem(10);
    
  }

  &__description {
    margin-bottom: rem(10);
  }
 
}

.RoastersItem {
  position: relative;
  &:hover {
    .RoasterPopUp {
      display: block;
    }
  }
}

@include mobile {
  .Product__container {
    &:hover {
      .ProductPopUp {
        display: none;
      }
    }
  }
  .Purveyor {
    &:hover {
      .ProductPopUp {
        display: none;
      }
    }
  }

  .RoastersItem {
    &:hover {
      .RoasterPopUp {
        display: none;
      }
    }
  }

  .AwardWinnersItem {
    &:hover {
      .ProductPopUp {
        display: none;
      }
    }
  }
  .PrePaidSubscription__image-container {
    &:hover {
      .PrePaidPopUp {
        display: none;
      }
    } 
  }
}

@include tablet {
  .Product__container {
    &:hover {
      .ProductPopUp {
        display: none;
      }
    }
  }
  .Purveyor {
    &:hover {
      .ProductPopUp {
        display: none;
      }
    }
  }

  .RoastersItem {
    &:hover {
      .RoasterPopUp {
        display: none;
      }
    }
  }
  .AwardWinnersItem {
    &:hover {
      .ProductPopUp {
        display: none;
      }
    }
  }
  .PrePaidSubscription__image-container {
    &:hover {
      .PrePaidPopUp {
        display: none;
      }
    } 
  }
}

@include tablet-big {
  .Product__container {
    &:hover {
      .ProductPopUp {
        display: none;
      }
    }
  }
  .Purveyor {
    &:hover {
      .ProductPopUp {
        display: none;
      }
    }
  }

  .PrePaidSubscription__image-container {
    &:hover {
      .PrePaidPopUp {
        display: none;
      }
    } 
  }

  .RoastersItem {
    &:hover {
      .RoasterPopUp {
        display: none;
      }
    }
  }
  .AwardWinnersItem {
    &:hover {
      .ProductPopUp {
        display: none;
      }
    }
  }
}
