@import "src/styles/lib";

.SimilarCoffees {
  width: 100%;
  position: relative;
  &::after {
    content: "";
    width: rem(277);
    height: rem(277);
    background: #ff8900;
    border-radius: 50%;
    position: absolute;
    right: rem(-54);
    top: rem(-61);
    z-index: -1;
  }

  &__message {
    font-size: rem(35);
    text-align: center;
  }

  &__products {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: rem(30);
    align-items: center;
   // justify-items: center;
    z-index: 10;
    max-width: rem(1400);
    width: 100%;
  }

  &__image {
    position: absolute;
    top: rem(-167);
    left: 0;
    width: rem(673);
    height: rem(783);
    object-fit: contain;
  }

  &__content {
    display: grid;
    justify-items: center;
    padding-top: rem(100);
    padding-bottom: rem(100);
  }

  &__title {
    font-size: rem(36);
    font-family: $cairo;
    font-weight: 700;
    letter-spacing: 0.6rem;
    text-align: center;
    text-transform: uppercase;
    line-height: em(36px, 36px);
    margin-top: rem(37);
    margin-bottom: rem(90);
  }
}

@include mobile {
  .SimilarCoffees {
    &__image {
      width: 378px;
      height: 465px;
    }

    &::after {
      width: 110px;
      height: 110px;
      left: 20px;
      top: -12px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 365px;
    }
    &__products {
      grid-template-columns: 1fr;
      justify-content: center;
      justify-items: center;
    
   
    }
  }
}
@include tablet {
  .SimilarCoffees {
    &__image {
      position: absolute;
      top: rem(-150);
      left: rem(-20);
      width: rem(373);
      height: rem(483);
    }
  }
}

@include tablet-big {
  .SimilarCoffees {
    &__image {
      position: absolute;
      top: rem(-430);
      left: rem(-100);
    }
  }
}

@include desktop {
  .SimilarCoffees {
    &__image {
      position: absolute;
      top: rem(-430);
      left: rem(-100);
    }
  }
}
