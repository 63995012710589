@import "../../../../styles/lib";

.Dropzone {
    text-align: center;
    padding: rem(60);
    border: 3px dashed #eeeeee;
    background-color: #fafafa;
    color: #bdbdbd;
    margin: 2rem 4rem;

    &__content-text {
        margin-top: rem(40);
        font-size: rem(24);
    }
}