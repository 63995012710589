@import "../../../styles/lib";

.PhotoSection {
  font-family: $cairo;
  line-height: 1.2;

  &__main-title-wrap {
   margin-top: rem(220);
  }

  &__main-title {
    position: relative;
    width: rem(627) !important;
    height: rem(179) !important;
    &::after {
      content: "";
      position: absolute;
      top: rem(-140);
      right: rem(-100);
      width: rem(196);
      height: rem(196);
      border-radius: 50%;
      background-color: #ffec00;
      z-index: 3;
    }
  }

  &__wrap {
    padding: 0 20%;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  &__img-wrap {
    position: relative;
    z-index: -1;
    &::after {
      content: "";
      position: absolute;
      bottom: -3rem;
      left: -5rem;
      width: rem(67);
      height: rem(67);
      background-color: black;
      clip-path: polygon(0 0, 0% 100%, 100% 0);
      z-index: 2;
    }
  }

  &__img-circle {
    position: absolute;
    bottom: -2rem;
    left: -16rem;
    width: rem(219);
    height: rem(219);
    border-radius: 50%;
    background-color: $blue;
  }

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d1d3d4;
    width: rem(540);
    height: rem(540);
    border-radius: 50%;
    text-transform: uppercase;
    font-size: rem(36);
    letter-spacing: 0.05rem;
    margin-left: -10rem;
    position: relative;
    z-index: -1;

    span {
      width: rem(285);
      text-align: center;
    }
  }

  &__upload-img {
    text-transform: uppercase;
    font-size: rem(30);
    font-weight: 700;
    letter-spacing: 0.08em;
    margin-top: rem(50);
    margin-left: -4rem;
    cursor: pointer;
  }

  &__welcome-section {
    margin-left: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__welcome-text {
    width: rem(479);
    letter-spacing: 0.05em;
    font-size: rem(24);
    line-height: 1.5;
    margin-bottom: 0;
  }

  &__welcome-title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: rem(30);
    letter-spacing: 0.08em;
    margin-bottom: 1rem;
  }
}

@include tablet {
  .PhotoSection {
    &__wrap {
      margin-top: 4.5rem;
    }
    &__welcome-section {
      margin-left: rem(60);
    }
  }
}

@include mobile {
  .PhotoSection {
    &__wrap {
      padding-right: 25px;
      padding-left: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 0;
    }
    &__welcome-section {
      margin-left: 0;
    }

    &__main-title-wrap {
      margin-top: rem(140);
      margin-bottom: rem(100);
     }

    &__line1 {
      height: 8.7px;
      top: 43%;
    }

    &__line2 {
      height: 8.7px;
      top: 50%;
    }

    &__main-title {
      height: 9rem;
      width: 23.5rem;
      font-size: rem(35);
      letter-spacing: 0.06em;
      &::after {
        content: "";
        position: absolute;
        top: -4rem;
        right: -2rem;
        width: rem(114);
        height: rem(114);
        border-radius: 50%;
        background-color: #ffec00;
        z-index: 2;
      }
    }

    &__img-wrap {
      &::after {
        content: "";
        position: absolute;
        bottom: -2rem;
        left: 2rem;
        width: rem(49);
        height: rem(49);
        background-color: black;
        clip-path: polygon(0 0, 0% 100%, 100% 0);
        z-index: 2;
      }
    }

    &__img-circle {
      position: absolute;
      bottom: 0;
      left: -3rem;
      width: rem(113);
      height: rem(113);
    }

    &__img {
      margin: 0;
      height: rem(410);
      width: rem(410);
      font-size: rem(28.5);

      span {
        width: rem(200);
      }
    }

    &__upload-img {
      text-align: center;
      font-size: rem(24);
    }

    &__welcome-title {
      font-size: rem(29);
      margin-top: 3rem;
      text-align: center;
      letter-spacing: 1.6px;
    }

    &__welcome-text {
      width: fit-content;
      text-align: center;
      margin-top: 0;
      margin-bottom: 5rem;
      font-size: 16px;
      letter-spacing: 0.8px;
    }
  }
}
