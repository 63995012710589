@import "./src/styles/lib";

.RockstarCoffes {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding-top: rem(101);
  padding-bottom: rem(202);

  &__content {
    width: 100%;
  }

  &__title {
    font-size: rem(36);
    font-family: $cairo;
    font-weight: 400;
    letter-spacing: 0.6rem;
    margin-top: rem(28);
    margin-bottom: rem(100);
  }

  &__slider {
    position: relative;
    width: 100%;
    padding: 10px;
  }

  &__man {
    position: absolute;
    left: 35%;
    bottom: rem(-110);
    width: rem(300);
    height: rem(400);
    z-index: 10;
  }
}

@include mobile {
  .RockstarCoffes {
    &__title {
      margin-bottom: 17px;
    }
    &__man {
      left: 68%;
      bottom: -40px;
      width: 120px;
      height: 205px;
      z-index: 10;
    }
  }
}

@include tablet-big {
  .RockstarCoffes {
    &__man {
      left: 30%;
      width: 150px;
    }
  }
}

@include tablet {
  .RockstarCoffes {
    &__man {
      left: 25%;
    }
  }
}
