@import "src/styles/lib";

.PeaberryRecommendedCoffees {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: rem(376);
  padding-right: rem(376);
  padding-bottom: rem(100);
  padding-top: rem(133);
  height: fit-content;

  &__products {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: rem(30);
  }

  &__title {
    margin-top: rem(42);
    margin-bottom: rem(88);
    font-size: rem(36);
    font-family: $cairo;
    font-weight: 400;
    letter-spacing: 0.6rem;
    line-height: em(36px, 36px);
  }
}

@include mobile {
  .PeaberryRecommendedCoffees {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 43px;
    &__products {
      grid-template-columns: 1fr;
    }

    &__title {
      text-align: center;
      margin-top: 22px;
      margin-bottom: 33px;
      font-size: 16px;
      font-family: $cairo;
      font-weight: 600;
      letter-spacing: 0.3rem;
      line-height: em(24px, 20px);
      width: 230px;
    }
  }
}
