@import "../../../../styles/lib";

.SelectCard {
  min-width: rem(470);
  width: 100%;
  border: 1px solid black;
  height: rem(60);
  padding-top: rem(5);
  padding-bottom: rem(5);
  //border-radius: rem(15);
  position: relative;
  background-color: $white;

  &__arrow-down {
    cursor: pointer;
    width: 0;
    height: 0;
    border-left: rem(10) solid transparent;
    border-right: rem(10) solid transparent;
    border-top: rem(10) solid #00d1d6;
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
  }

  &__icon {
    position: absolute;
    right: 10px;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    cursor: pointer;
  }

  &__item {
    font-size: 14px;
    font-weight: 400;
    line-height: em(16px, 16px);
    margin: 0;
  }

  &__header {
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding-left: rem(19);
    padding-right: rem(44);
    position: relative;
    cursor: pointer;
  }

  &__title {
    font-size: rem(25);
    font-weight: 400;
    color: #d1cece;
  }

  &__title-container {
    width: 100%;
    min-width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__title2 {
    font-size: rem(25);
    font-weight: 400;

    &--last {
      margin-right: auto;
    }
  }

  &__last-numbers {
    font-size: rem(25);
    font-weight: 400;
    margin-right: 12px;
  }

  &__title3 {
    font-size: rem(25);
    font-weight: 400;
    color: $blue;
    outline: none;
    border: none;
    background: transparent;
    margin-left: auto;
  }

  &__icon {
    position: absolute;
    right: 10px;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    cursor: pointer;
  }

  &__items {
    overflow-y: scroll;
    // max-height: rem(500);
    max-height: rem(300);
    width: 100%;
    position: absolute;
    z-index: 5;
    display: none;
    background: white;
    border: 2px solid #ccd9e2;
    margin-top: rem(10);
    padding: 10px;

    &.active {
      display: block;
    }
  }

  &__item-container {
    padding: rem(8);
    border-radius: 4px;
    line-height: rem(21);
    display: flex;
    justify-content: space-between;
    &.activeLabel {
      background: #f2f5f7;
    }

    &:hover {
      background: #f2f5f7;
      cursor: pointer;
    }
  }
}

@include tablet-big {
  .SelectCard {
    // width: rem(370);
    width: 100%;
  }
}

@include tablet {
  .SelectCard {
    // width: rem(270);
    width: 100%;
  }
}

@include mobile {
  .SelectCard {
    //  width: 60%;
    width: 100%;
    height: 40px !important;
    padding-top: rem(5);
    padding-bottom: rem(5);

    &__title2 {
      font-size: 16px;
    }

    &__last-numbers {
      font-size: 16px;
    }

    &__items {
      // max-height: rem(700);
      max-height: 250px;
    }
  }
}
