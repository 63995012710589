@import "../../../styles/lib";

.RadioButtonSquare {
  margin: 0 0 rem(24);
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &__outer-square {
    width: rem(20);
    height: rem(20);
    min-width: rem(18);
    min-height: rem(18);
    border: rem(1) solid $black;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: rem(10);
    // transition: all 0.1s linear;
  }

  &__inner-square {
    width: rem(17);
    height: rem(17);
    background-color: transparent;
    transition: all 0.1s linear;

    &--active {
      background-color: #00d1d6;
    }
  }

  &__label {
    color: $black;
    padding-right: rem(8);
    font-size: rem(22) !important;
    line-height: em(22px, 22px);
  }
}

@include desktop {
  .RadioButtonSquare {
    &__inner-square {
      width: 12px;
      height: 12px;
    }

    &__outer-square {
      width: 18px;
      height: 18px;
    }
  }
}

@include laptop {
  .RadioButtonSquare {
    &__inner-square {
      width: 10px;
      height: 10px;
    }

    &__outer-square {
      width: 16px;
      height: 16px;
    }
  }
}

@include mobile {
  .RadioButtonSquare {
    margin-top: 15px !important;
    &__label {
      font-size: 14px !important;
    }

    &__inner-square {
      width: 8px;
      height: 8px;
    }

    &__outer-square {
      width: 14px;
      height: 14px;
    }
  }
}
