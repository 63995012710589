@import "../../../styles/lib";

.QuizPreferences {
  //margin-top: rem(100);
  padding-bottom: rem(80);
  font-family: $cairo;
  display: flex;
  flex-direction: column;
  margin-left: -4rem;
  max-width: rem(650);
  width: 100%;
  position: relative;

  &__title {
    text-transform: uppercase;
    font-size: rem(36);
    letter-spacing: 0.2em;
    font-weight: normal;
    line-height: rem(48);
    position: relative;
    text-align: left;
    margin-bottom: rem(60);
    &::before {
      content: "";
      position: absolute;
      top: -16.5rem;
      left: 14.5rem;
      height: rem(471);
      width: 2px;
      transform: rotate(90deg);
      background-color: $black;
      z-index: 2;
    }
  }

  &__item {
    font-size: rem(24);
    letter-spacing: 0.05em;
    line-height: rem(50);
  }

  &__preference {
    color: #0066b2;
  }

  &__quiz-btn {
    position: absolute;
    left: rem(100);
    bottom: rem(200);
  }
}

ul,
li {
  list-style: none;
  padding-left: 0;
}
@include desktop {
  .QuizPreferences {
    &__quiz-btn {
      right: 0;
    }
  }
}

@include tablet {
  .QuizPreferences {
    &__quiz-btn {
      position: absolute;
      right: -70px;
    }
  }
}

@include tablet-big {
  .QuizPreferences {
    &__quiz-btn {
      right: 0;
    }
  }
}

@include mobile {
  .QuizPreferences {
    align-items: center;
    margin-bottom: 5rem;
    margin-top: 0;
    margin-left: 0;

    &__item {
      font-size: 16px;
    }
    &__title {
      text-align: center;
      margin-left: 0;
      &::before {
        left: 50%;
      }
    }
    &__list {
      margin-bottom: 6rem;
      padding-left: 2rem;
    }

    &__quiz-btn {
      right: 50%;
      left: 35%;
      bottom: rem(-110);
    }
  }
}

@include desktop {
  .QuizPreferences {
    &__quiz-btn {
      right: rem(30);
    }
  }
}
