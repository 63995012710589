@import "src/styles/lib";

.PeaberryRecommendsCard {
 // width: rem(1200);
  width: 100%;
  background: white;
  position: relative;

  &__grid {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    align-items: center;
  }

  &.circle {
    margin-bottom: rem(45);

    &:before {
      content: "";
      height: rem(263);
      width: rem(263);
      background: #6ae200;
      border-radius: 50%;
      position: absolute;
      left: rem(-72);
      top: rem(166);
      z-index: -1;
    }
  }

  &__column-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: rem(40);
    padding-right: rem(40);
  }

  &__column-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__product {
    width: rem(400);
    height: rem(400);
    object-fit: contain;
  }

  &__title {
    margin-bottom: rem(5);
    font-weight: 700;
    font-size: rem(22);
    text-align: center;
    color: $black;
  }

  &__description {
    font-size: rem(20);
    line-height: em(22px, 20px);
    text-align: center;
    color: #000;
    max-width: rem(236);
  }

  &__weight {
    font-size: rem(20);
    line-height: em(20px, 20px);
    text-align: center;
    color: #000;
    max-width: rem(236);
    margin-bottom: rem(20);
  }

  &__review {
    font-size: rem(20);
    line-height: em(30px, 20px);
  }

  &__peaberry {
    width: rem(200);
    height: 100%;
    object-fit: contain;
  }

  &__date {
    margin-top: rem(13);
    font-weight: 600;
    font-size: rem(20);
    text-align: center;
    color: #000;
    max-width: rem(290);
  }

  &__type {
    font-weight: 600;
    font-size: rem(20);
    text-align: center;
    color: #000;
    max-width: rem(290);
  }

  &__button {
    margin-top: rem(27);
  }

  &__person-details {
    font-weight: 400;
    font-size: rem(20);
    line-height: em(20px, 20px);
    text-align: center;
    color: $black;
    margin-top: rem(10);
  }
}

@include tablet {
  .MemberPicksCard {
    width: fit-content;
    padding: 20px;
  }
}

@include mobile {
  .PeaberryRecommendsCard {
    width: fit-content;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    &__product {
      width: 160px;
      height: 160px;
    }

    &__review {
      font-size: 15px;
    }

    &__title {
      font-size: 16px;
    }

    &__description {
      font-size: 15px;
    }

    &__weight {
      font-size: 15px;
    }

    &__grid {
      grid-template-columns: 1fr;
    }

    &__column-1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      order: 1;
    }

    &__column-3 {
      display: none !important;
    }

    &__column-2 {
      order: 2;
      padding-left: 10px;
      padding-right: 10px;
    }

    &__title {
      margin-top: 10px;
    }
  }
}
