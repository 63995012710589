@import "src/styles/lib";

.AlertMessage {
  width: 100%;
  min-height: rem(60);
  justify-content: center;
  align-items: center;
  display: none;

  &--isOpen {
    display: flex;
    position: sticky;
    top: rem(130);
    z-index: 1049;
  }

  &--error {
    background: #ef3525;
  }

  &--success {
    background: #82bd40;
  }

  &--warning {
    background: #f6c015;
  }

  &__text {
    font-size: rem(26);
    color: white;
    font-family: $cairo;
  }

  &__close-icon {
    width: rem(20);
    height: rem(20);
    position: absolute;
    right: 20px;

    g {
      stroke: white;
    }

    :hover {
      cursor: pointer;
      g {
        stroke: black;
      }
    }
  }
}

@include mobile {
  .AlertMessage {
    position: fixed;
    top: 0;
    z-index: 999;
    min-height: 43px;
    padding-top: 5px;
    padding-bottom: 5px;

    &__text {
      max-width: 80%;
      font-size: 14px;
      line-height: em(14px, 14px);
    }
    &__close-icon {
      max-width: 20%;
    }
  }
}
