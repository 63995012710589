@import "src/styles/lib";

.Blog {
    width: 100%;
    height: 100%;
    padding-top: rem(170);

    &__categories {
        margin: rem(50) auto;
    }

    &__select-box {
        margin: rem(80) 20% 0;
    }

    &__main-title {
       height: rem(200);
    }

    &__container {
        margin: rem(100) auto;
    }
    &__post {
        margin: rem(30) 20% rem(80);
        text-align: center;
        border: 3px solid $black;
    }
    &__main-description {
        margin: 0 auto;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        max-width: rem(1200);
        padding: 0 10px;
      }
      &__seo-title {
        max-width: rem(1200);
        text-align: center;
        font-size: rem(36);
        margin: rem(130) auto rem(30);
        font-weight: 700;
        padding: 0 10px;
      }

    &__image-wrapper {
        background: $black;
    }

    &__image {
        width: 100%;
        height: rem(500);
        object-fit: contain;
    }

    &__post-details {
        display: flex;
        justify-content: center;
        font-size: rem(22);
        padding: rem(10);
    }

    &__post-category {
        padding-left: rem(10);
        margin-left: rem(10);
        text-transform: uppercase;
        border-left: 2px solid #cdcdcd;
    }

    &__post-title {
        margin: rem(10) 0 rem(20);
        padding: 0 rem(20);
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.02em;
        transition: all 200ms ease-in-out;
        &:hover {
          color: $blue;
        }
    }
}