@import "src/styles/lib";

.PrivacyPolicy {
    margin-top: rem(160);
    margin-bottom: rem(100);
    padding: 0 rem(130);
    font-size: rem(24);
    font-family: $cairo;
    overflow: hidden;

    &__main-title {
        font-size: rem(52);
        text-align: center;
        margin-bottom: rem(40);
        font-weight: 600;
        text-transform: uppercase;
    }

    &__line {
        margin: 0 auto;
        margin-bottom: rem(40);
    }

    &__title {
        font-weight: 500;
        text-transform: uppercase;
        margin: rem(20) 0;
    }

    &__subtitle {
        margin: rem(20) 0;
    }

    &__text {
        margin: rem(20) 0;
    }

    &__description {
        margin-bottom: rem(10);
    }
}

@include mobile {
    .PrivacyPolicy {
        margin-top: 0;
        padding: 0 5%;
    }
}