@import "../../../styles/lib";

.GiftSubscriptions {
  font-family: $cairo;
  padding-bottom: rem(40);

  &__types,
  &__wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__types {
    margin-bottom: rem(160);
    position: relative;
    &::after {
      content: "";
      position: absolute;
      right: rem(-46);
      top: rem(190);
      width: rem(898);
      height: rem(430);
      background-image: url("https://assets.gocoffeego.com/v2/assets/images/shape-square.svg");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      z-index: 0;
    }
  }

  &__cert,
  &__subsc {
    box-sizing: border-box;
    position: relative;
    width: rem(569);
    height: rem(569);
    padding-left: rem(40);
    padding-right: rem(42);
    padding-top: rem(40);
    padding-bottom: rem(42);
    z-index: 1;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    clip-path: polygon(0 0, 0% 100%, 100% 0);
    background-color: #000000;
    margin-top: -0.2rem;
    z-index: 1;
  }

  &__cert {
    background-color: #00d1d6;
    margin-right: rem(30);
  }

  &__subsc {
    background-color: #6ae200;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      height: rem(139);
      width: rem(139);
      border-radius: 50%;
      right: rem(-104);
      top: rem(75);
      background-color: #0066b2;
      z-index: 3;
    }
  }

  &__title,
  &__subsc-title {
    position: relative;
    color: $white;
    font-size: rem(36);
    line-height: rem(36);
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    font-weight: 700;
    z-index: 2;
    margin-bottom: rem(40);
    width: rem(300);
  }

  &__text,
  &__subsc-text {
    position: relative;
    display: flex;
    flex-direction: column;
    color: $white;
    font-weight: 600;
    font-size: rem(24);
    line-height: rem(30);
    letter-spacing: 0.05em;
    z-index: 2;
  }

  &__image-girl {
    position: absolute;
    width: rem(201);
    height: rem(264);
    top: rem(150);
    right: rem(75);
    z-index: 2;
  }

  &__image-man {
    position: absolute;
    width: rem(250);
    height: rem(285);
    top: rem(140);
    right: rem(35);
    z-index: 2;
  }

  &__jetset-wrap {
    position: relative;
    margin-right: rem(90);
    &::before {
      content: "";
      position: absolute;
      height: rem(181);
      width: rem(181);
      border-radius: 50%;
      left: rem(-90);
      top: rem(-110);
      background-color: #00d1d6;
      z-index: 2;
    }
  }

  &__tours {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: rem(511);
    position: relative;
    &::before {
      content: "";
      position: absolute;
      height: rem(106);
      width: rem(106);
      border-radius: 50%;
      left: rem(70);
      bottom: rem(3);
      background-color: #6ae200;
      z-index: -1;
    }
  }

  &__subtitle {
    text-transform: uppercase;
    font-weight: bold;
    font-size: rem(24);
    line-height: rem(32);
    letter-spacing: 0.2em;
    margin-top: -1.5rem;
    text-align: center;
  }

  &__tours-title {
    position: relative;
    text-transform: uppercase;
    text-align: center;
    font-size: rem(36);
    letter-spacing: 0.2em;
    line-height: rem(36);
    margin-bottom: rem(50);
    margin-top: rem(80);
    &::before {
      content: "";
      position: absolute;
      height: rem(8);
      width: rem(82);
      left: 40%;
      right: 0;
      top: rem(-50);
      background-color: $black;
    }
  }

  &__tours-btn {
    margin-bottom: rem(55);
    width: rem(220);
    height: rem(66);
    font-size: rem(16);
    text-transform: uppercase;
    letter-spacing: 0.2em;
    font-weight: 700;
    line-height: rem(32);
  }

  &__jetset {
    border: 3px solid $black;
    padding: 0 3px;
    height: rem(214);
    width: rem(549);
  }

  &__gift-btn {
    text-transform: uppercase;
    letter-spacing: rem(2.8);
    width: rem(255);
    height: rem(66);
    position: absolute;
    right: 3.125rem;
    bottom: 2.5rem;
    font-size: rem(16);
    transition: all 200ms ease-in-out;

    &:hover {
      color: $black;
      border: 3px solid $black;
    }

    &:focus {
      color: $black;
      border: 3px solid $black;
    }
  }
}

@include mobile {
  .GiftSubscriptions {
    &__types,
    &__wrap {
      flex-direction: column;
      align-items: center;
    }

    &__types {
      margin-bottom: rem(54);
    }

    &__types,
    &__tours,
    &__jetset-wrap {
      &::before {
        display: none;
      }
    }

    &__text,
    &__subsc-text {
      font-size: rem(16);
      width: rem(284);
    }

    &__cert,
    &__subsc {
      width: 324px;
      height: 324px;
      margin: 0;
      padding-left: rem(34);
      padding-top: rem(28);
    }

    &__cert {
      margin-bottom: rem(29);
    }

    &__subsc {
      &::after {
        display: none;
      }
    }

    &__title,
    &__subsc-title {
      font-size: 20px;
      letter-spacing: 4px;
      margin-bottom: 20px;
      line-height: 24px;
    }

    &__text,
    &__subsc-text {
      font-size: 16px;
      letter-spacing: 0.8px;
      line-height: 20px;
    }

    &__jetset {
      display: none;
    }

    &__image-girl {
      width: 116px;
      height: 151px;
      right: 64px;
      top: 89px;
    }

    &__image-man {
      width: 142px;
      height: 162px;
      right: 64px;
      top: 82px;
    }

    &__gift-btn {
      width: fit-content;
      min-width: 200px;
      height: 46px;
      font-size: 14px;
      letter-spacing: 0.1px;
      line-height: 22px;
    }

    &__tours {
      width: rem(378);
      margin-left: 0;
      margin-top: 1rem;
    }

    &__subtitle {
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 3.2px;
      text-align: center;
      margin-top: 0;
    }

    &__tours-title {
      font-size: 20px;
      margin-top: rem(58);
      margin-bottom: 4rem;
      &::before {
        height: rem(6);
        width: rem(59);
        top: rem(-34);
      }
    }

    &__tours-btn {
      width: fit-content;
      height: 46px;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 2.8px;
      font-family: $rubik;
    }
  }
}

@include tablet-big {
  .GiftSubscriptions {
    &__subsc {
      &::after {
        display: none;
      }
    }

    &__image-girl {
      width: 50%;
      height: 50%;
      right: rem(6);
    }

    &__image-man {
      width: 50%;
      height: 50%;
      right: rem(8);
    }
  }
}

@include tablet {
  .GiftSubscriptions {
    &__subsc {
      &::after {
        display: none;
      }
    }

    &__image-girl {
      width: 50%;
      height: 50%;
      right: rem(6);
    }

    &__image-man {
      width: 50%;
      height: 50%;
      right: rem(8);
    }
  }
}
