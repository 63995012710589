@import "../../../styles/lib";

.MyGiftSubscriptions {
  padding-top: rem(250);
  font-family: $cairo;
  color: $black;
  overflow: hidden;
  padding-bottom: rem(100);
  &__default-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 3px solid $black;
    margin-top: rem(160);
    margin-right: rem(130);
    margin-left: rem(130);
    padding: rem(60) 0 rem(70);
    position: relative;
    background-color: $white;
    &::before {
      content: "";
      background: url("https://assets.gocoffeego.com/v2/assets/images/2men.png")
        no-repeat center center;
      background-size: 100%;
      position: absolute;
      width: rem(183);
      height: rem(260);
      top: rem(-110);
      right: rem(90);
      z-index: -1;
    }
    &::after {
      content: "";
      position: absolute;
      width: rem(272);
      height: rem(272);
      border-radius: 50%;
      background-color: #6ae200;
      top: rem(-60);
      left: rem(-120);
      z-index: 2;
    }
  }

  &__button {
    width: rem(312);
    height: rem(70);
    font-weight: 600;
    font-size: rem(34);
    line-height: rem(50);
    font-family: $cairo;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: rem(189);
      height: rem(189);
      border-radius: 50%;
      background-color: #ff8900;
      bottom: rem(-145);
      left: rem(-155);
      z-index: 2;
    }
  }

  &__title {
    font-weight: 600;
    font-size: rem(34);
    margin-bottom: rem(48);
    line-height: em(50px, 34px);
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 3px solid $black;
    margin-top: rem(160);
    margin-right: rem(130);
    margin-left: rem(130);
    padding-top: rem(30);
    position: relative;
    background-color: $white;
    &::before {
      content: "";
      background: url("https://assets.gocoffeego.com/v2/assets/images/2men.png")
        no-repeat center center;
      background-size: 100%;
      position: absolute;
      width: rem(183);
      height: rem(260);
      top: rem(-110);
      right: rem(90);
      z-index: -1;
    }
    &::after {
      content: "";
      position: absolute;
      width: rem(272);
      height: rem(272);
      border-radius: 50%;
      background-color: #ff008e;
      top: rem(60);
      right: rem(-120);
      z-index: 2;
    }
  }

  &__shipment-info {
    font-size: rem(38);
    line-height: rem(50);
    font-weight: 600;
    text-align: center;
  }

  &__shipment-info-date {
    font-weight: 400;
    font-size: rem(34);
    margin-top: rem(10);
  }

  &__tour {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: rem(50);
  }

  &__image {
    width: rem(350);
    height: rem(250);
    margin: rem(20) auto;
  }

  &__info {
    background-color: $black;
    width: 100%;
    height: 100%;
    z-index: 0;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: rem(325);
      height: rem(325);
      border-radius: 50%;
      background-color: #ffec00;
      top: rem(-260);
      left: rem(-120);
      z-index: -1;
    }
  }

  &__info-text {
    color: $white;
    text-align: center;
    font-size: rem(34);
    line-height: rem(50);
    font-weight: 400;
    margin: rem(20) 0;
  }

  &__buttons {
    margin-bottom: rem(50);
    z-index: 2;
  }
  &__btn {
    margin-left: rem(20);
    width: rem(312);
    height: rem(70);
    font-size: rem(34);
    line-height: rem(50);
    font-family: $cairo;
    z-index: 2;
    font-weight: 600;
    transition: all 200ms ease-in-out;
    &:hover {
      border-color: $blue;
    }
  }

  &__tour-name {
    font-size: rem(38);
    line-height: rem(50);
    font-weight: 600;
  }

  &__plan,
  &__plan-coffees {
    font-size: rem(34);
    line-height: rem(50);
    font-weight: 400;
  }
}

@include mobile {
  .MyGiftSubscriptions {
    padding-top: 50px;

    &__button {
      width: fit-content;
    }

    &__default-container {
      padding: 5% 10%;
      margin: 90px 5%;
      &::after {
        display: none;
      }
    }

    &__container {
      margin: 90px 5%;
      &::after {
        top: rem(20);
        right: rem(-140);
        z-index: -1;
      }
    }

    &__image,
    &__shipment {
      z-index: 3;
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;
    }

    &__btn {
      margin-bottom: 10px;
    }

    &__info {
      &::before {
        top: rem(-300);
      }
    }
    &__info-text {
      padding: 10px 20px;
    }
  }
}
