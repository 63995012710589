@import "src/styles/lib";

.Login {
  width: 100%;
  margin-top: rem(234);
  padding-bottom: rem(150);
  background: url("https://assets.gocoffeego.com/v2/assets/images/login-background.svg")
    no-repeat top 0 left 0;
  background-size: cover;

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: rem(-25);
      left: rem(-56);
      border-radius: 50%;
      background: #ffec00;
      height: rem(251);
      width: rem(251);
    }
  }

  &__or {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: rem(33);
    font-weight: 400;
    overflow: hidden;
    text-align: center;
    width: rem(737);
    background-color: white;
    border: 1px solid black;
    > span {
      position: relative;
      display: inline-block;
      &::after, &::before {
        content: '';
        position: absolute;
        top: 50%;
        border-bottom: 1px solid;
        width: rem(300);
        margin: 0 20px;
      }
      &::before {
        right: 100%;
      }
      &::after {
        left: 100%;
      }
    }
  }
}

@include tablet {
  .Login {
    //background: none;
  }
}

@include mobile {
  .Login {
    margin-top: 70px;
    background: none;

    &__or {
      border: 0;
    }

    &__input {
      font-size: 16px !important;
    }

    &__title {
      &::after {
        display: none;
      }
    }
  }
}
