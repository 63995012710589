@import "src/styles/lib";

.Reviews {
  padding-left: 20%;
  padding-right: 20%;
  margin-top: rem(132);
  padding-bottom: rem(100);
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &__review {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: rem(74);
  }

  &__message {
    margin-top: rem(200);
    font-weight: 600;
    text-align: center;
    font-size: rem(38);
    align-items: center;
    border: 2px solid $black;
    padding: 6rem 4rem;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: rem(222);
      height: rem(230);
      background: url("https://assets.gocoffeego.com/v2/assets/images/peaberry1.png")
        no-repeat;
      background-size: 100%;
      top: rem(-172);
      right: rem(270);
      z-index: 3;
    }
  }

  &__description {
    margin-top: 1rem;
    &--link {
      color: $blue;
    }
  }

  &__line {
    margin-top: rem(74);
  }
}

@include mobile {
  .Reviews {
    padding-left: 5%;
    padding-right: 5%;
    &__message {
      &::before {
        right: rem(100);
      }
    }
  }
}
