@import "src/styles/lib";

.Top25 {
    margin-top: rem(180);
   
    &__products-wrapper {
        max-width: rem(1800);
        margin: 0 auto rem(100);
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: rem(20);
        align-content: baseline;
    }
    &__wrapper {
        max-width: rem(1500);
        margin: rem(180) auto 0;
        padding: 0 10px;
    }

    &__subtitle {
        text-align: center;
        font-size: rem(34);
        margin-bottom: rem(24);
        font-weight: 700;
    }
    &__main-description {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
    }

    &__container {
        display: grid;
        grid-template-columns: 20% 70%;
        justify-content: center;
        grid-gap: rem(30);
        padding: 0 2%;
        margin-top: rem(90);
        margin-bottom: rem(60);
    }
}

@include desktop {
    .Top25 {
        &__products-wrapper {
            grid-template-columns: repeat(3, 1fr);
            padding: 0 4%;
        }
    }
}


@include tablet-big {
    .Top25  {
        &__products-wrapper {
            grid-template-columns: repeat(3, 1fr);
            padding: 0 4%;
        }
    }
}

@include tablet {
    .Top25 {
        &__products-wrapper {
            grid-template-columns: repeat(2, 1fr);
            padding: 0 4%;
        }
        &__container {
            padding: 0;
        }
    }
}
@include mobile {
    .Top25 {
        &__products-wrapper {
            grid-template-columns: 1fr;
            justify-items: center;
        }
      
        &__container {
            display: grid;
            grid-template-columns: 100%;
            justify-content: center;
            grid-gap: rem(30);
            padding: 0 2%;
            margin-top: rem(110);
        }

        &__filter {
            margin: 0 auto;
        }
      
    }
}