@import "src/styles/lib";

.AutoShipCheckout {
  width: 100%;
  height: 100%;
  padding-top: rem(200);
  padding-bottom: rem(100);
  overflow-x: hidden !important;
  overflow-y: hidden !important;

  &__form {
    display: flex;
    justify-items: center;
    justify-content: center;
    margin-bottom: rem(60);
  }

  &__form-test {
    width: rem(800);
  }

  &__required {
    color: red;
    font-size: rem(22);
  }

  &__updated-message {
    margin-top: rem(10);
    font-size: rem(25);
    color: #6ae200;
  }

  &__nav {
    background: #ebebeb;
    height: rem(35);
    margin-top: rem(160);
    align-items: center;
    display: flex;
    padding-left: 10%;
    padding-right: 20%;
  }

  &__nav-title {
    font-weight: 400;
    font-size: rem(16);
    line-height: em(24px, 16px);

    &--active {
      color: #ff0000;
    }
  }

  &__right-arrow {
    padding-left: rem(23);
    padding-right: rem(23);
  }

  &__title {
    font-weight: 600;
    font-size: rem(34);
    margin-top: rem(163);
    line-height: em(35px, 34px);
    text-align: center;
    &--second {
      font-weight: 400;
      margin-bottom: rem(29);
      margin-top: 0;
    }
  }

  &__subtitle {
    position: relative;
    font-size: rem(24);
    margin-top: 0;
    line-height: em(35px, 24px);
    margin-bottom: rem(49);
    text-align: center;
    z-index: 2;
    padding-left: 5%;
    padding-right: 5%;
  }

  &__title-2 {
    font-weight: 600;
    font-size: rem(34);
    margin-top: rem(163);
    margin-bottom: rem(49);
    line-height: em(35px, 34px);
    text-align: center;
    color: #6ae200;
  }

  &__content-title {
    font-weight: 600;
    font-size: rem(35);
    margin-bottom: rem(50);
    line-height: em(32px, 35px);
    margin-left: 5%;
  }

  &__primary-title {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      height: rem(436);
      width: rem(436);
      border-radius: 50%;
      top: rem(-74);
      opacity: 0.8;
      right: rem(-138);
      background-color: #ff008e;
      z-index: 10;
      background-repeat: no-repeat;
    }
  }

  &__content {
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  &__grid {
    display: grid;
    grid-template-columns: auto auto;
  }

  &__container {
    display: grid;
    grid-template-columns: 70% 30%;
    padding-left: 10%;
    padding-right: 10%;
  }

  &__button4 {
    width: 100%;
    background: $blue;
    text-transform: uppercase;
    font-size: rem(28);
    color: white;
    font-weight: 600 !important;
    border: 3px solid $blue;
    height: 45px;
    margin: rem(50) auto rem(250);
  }

   &__select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(8);
    margin-top: rem(150);
  }

  .Items {
    border-right: 1px solid #c7c7c7;

    &__title {
      text-align: center;
      font-size: rem(20);
      font-weight: 400;

      &--item {
        text-align: left;
        padding-left: rem(50);
      }

      &:first-child {
        text-align: left;
      }
    }

    &__row {
      display: grid;
      grid-template-columns: 90% 10%;
    }

    &__menu {
      display: grid;
      grid-template-columns: rem(80) 40% 30% 15% 15%;
      border-bottom: 1px solid #c7c7c7;
      padding-bottom: rem(10);
    }

    &__content-title {
      text-align: center;
      font-size: rem(20);
      font-weight: 400;

      &:first-child {
        text-align: left;
      }
    }
  }

  .AutoShipForm {
    padding-left: rem(52);

    &__select-title {
      font-size: rem(24);
      font-weight: 600;
    }

    &__start-date {
      font-size: rem(24);
      font-weight: 600;
      margin-top: rem(22);
      margin-bottom: rem(10);
    }

    &__datepicker {
      width: rem(385);
      height: rem(46);
      border: 1px solid black;
      padding-left: rem(10);
      font-size: rem(24);
      font-weight: 400;
    }

    &__button {
      width: rem(385);
      margin-top: rem(23);
      border: 3px solid #6ae200;
      background: #6ae200;
      display: block;

      &--yellow {
        border: 3px solid #ffec00;
        background: #ffec00;
      }
    }

    &__select {
      border: 1px solid black;
      width: rem(385);
      height: rem(46);
      margin-top: rem(10);

      &--hold {
        margin-bottom: rem(23);
      }
    }

    &__or {
      display: flex;
      align-items: center;
      margin-top: rem(34);
      margin-bottom: rem(40);

      .or-line {
        border-bottom: 1px solid #e3e3e3;
        width: rem(154);
      }

      .or-text {
        font-size: rem(25);
        font-weight: 400;
        margin-left: rem(34);
        margin-right: rem(34);
      }
    }
  }

  .Status {
    margin-top: rem(100);
    display: flex;
    justify-content: center;
    justify-items: center;
    &__text {
      font-size: rem(28);
      font-weight: 400;
      margin-right: rem(50);
      margin-left: rem(50);
      &--bold {
        font-weight: 600;
        color: #ff8900;
      }
    }
  }
}

@include tablet-big {
  .AutoShip {
    &__content {
      &::after {
        display: none;
      }
    }
  }
}

@include tablet {
  .AutoShip {
    &__content {
      &::after {
        display: none;
      }
    }

    &__container {
      padding-left: 3%;
      padding-right: 3%;
      grid-gap: rem(10);
    }
  }
}

@include mobile {
  .AutoShipCheckout {
    padding-top: 70px;

    &__form-test {
      max-width: rem(700);
      padding: 0 rem(10);
    }

    &__primary-title {
      &::after {
        display: none;
      }
    }

    .AutoShipForm {
      margin-top: 50px;
      width: 100%;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: center;
      justify-content: center;

      &__select {
        width: 100%;
      }

      &__select-title {
        font-size: 17px;
      }

      &__start-date {
        font-size: 17px;
      }

      &__or {
        width: 100%;
        .or-line {
          width: 100%;
        }

        .or-text {
          font-size: 16px;
        }
      }

      &__button {
        width: 100%;
      }
      &__date-wrap {
        width: 100% !important;
      }

      &__datepicker {
        width: 100% !important;
        height: 30px !important;
        font-size: 16px;

        @supports (-webkit-touch-callout: none) {
          min-width: 94%;
          border-radius: 0;
        }

        //-webkit-appearance: none;
      }
    }

    &__nav {
      display: none;
    }

    &__container {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }

    &__content-title {
      font-weight: 600;
      font-size: rem(35);
      margin-bottom: rem(50);
      line-height: em(32px, 35px);
      text-align: center;
    }

    &__content {
      &::after {
        display: none;
      }
    }

    .Status {
      padding-left: 10%;
      padding-right: 10%;
      display: flex;
      flex-direction: column;
      align-items: center;
      &__text {
        margin: 0;
      }
    }
  }
}
