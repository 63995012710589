@import "../../../styles/lib";

.AddressBook {
  width: 100%;
  height: 100%;
  padding-bottom: rem(300);
  padding-top: rem(100);
  font-family: $cairo;
  overflow: hidden;

  &__radio-button {
    margin-top: rem(20);
    margin-bottom: rem(10);
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    &__outer-circles {
      min-width: 100px !important;
      width: rem(25);
      height: rem(20);
      min-height: rem(18);
      border: rem(1) solid $black;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: rem(10);
      // transition: all 0.1s linear;
    }

    &__inner-circle {
      width: rem(14);
      height: rem(14);
      border-radius: 50%;
      background-color: transparent;
      transition: all 0.1s linear;

      &--active {
        background-color: #00d1d6;
      }
    }
  }

  &__container {
    margin: rem(100) rem(130) 0;
    padding-bottom: rem(20);
    display: flex;
    justify-content: center;
  }

  &__cards {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    //grid-auto-flow: column;
    //grid-template-columns: repeat(3, rem(461));
    //grid-template-rows: repeat(2, 1fr);
    // gap: rem(20);
    position: relative;
    overflow: visible;
    &::before {
      content: "";
      background-color: #00d1d6;
      border-radius: 50%;
      position: absolute;
      top: rem(-120);
      right: rem(-130);
      width: rem(361);
      height: rem(361);
      z-index: -1;
    }
    &::after {
      content: "";
      background-color: #ff008e;
      position: absolute;
      border-radius: 50%;
      bottom: rem(-170);
      left: rem(-180);
      width: rem(400);
      height: rem(400);
      z-index: -1;
    }
  }

  &__no-cards-container {
    position: relative;
    &::before {
      content: "";
      background-color: #00d1d6;
      border-radius: 50%;
      position: absolute;
      top: rem(-120);
      right: rem(-130);
      width: rem(361);
      height: rem(361);
      z-index: -1;
    }
    &::after {
      content: "";
      background-color: #ff008e;
      position: absolute;
      border-radius: 50%;
      bottom: rem(-170);
      left: rem(-180);
      width: rem(400);
      height: rem(400);
      z-index: -1;
    }
  }

  &__card {
    display: grid;
    grid-template-rows: 68% 8% 4% 4% 4%;
    grid-gap: 8px;
    width: rem(461);
    height: rem(660);
    background-color: $white;
    border: 3px solid $black;
    padding: rem(20) rem(40) rem(10);
    font-size: rem(25);
    line-height: rem(47);
    margin-right: rem(20);
    margin-top: rem(20);
  }

  &__card-info {
    margin-bottom: rem(30);
    height: rem(440);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    max-width: rem(400);
    word-break: break-all;
  }

  &__no-cards {
    width: rem(461);
    height: rem(660);
    border: 3px solid $black;
    margin-right: rem(20);
    margin-top: rem(20);
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: rem(30);
  }

  &__no-cards-img {
    width: rem(300);
    height: auto;
    margin-top: rem(100);
    margin-left: rem(20);
  }

  &__button {
    color: black;
    border: none;
    outline: none;
    width: rem(309);
    height: rem(66);
    background-color: #00d1d6;
    font-size: rem(25);
    line-height: rem(47);
    margin-bottom: rem(100);
    transition: all 200ms ease-in-out;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }
  }

  &__title {
    font-weight: 600;
    font-size: rem(25);
    padding-top: rem(10);
    margin-bottom: rem(44);
    line-height: em(47px, 25px);
    //text-align: center;
    padding-left: 300px;
  }

  &__icon {
    margin-left: auto;
    margin-right: 4rem;
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    align-items: center;
    padding: 0 rem(300);
  }

  &__item {
    padding-top: rem(20);
    padding-bottom: rem(40);
    width: rem(300);
  }

  &__no-address {
    padding-top: 8rem;
    margin: 0 auto;
    text-align: center;
  }

  &__no-address-title {
    font-size: rem(30);
    margin-bottom: 2rem;
  }

  &__item-name {
    font-size: rem(24);
    letter-spacing: 0.02em;
    line-height: rem(47);
  }

  &__item-address1,
  &__item-address2,
  &__item-country,
  &__item-company-name {
    font-weight: 500;
    font-size: rem(24);
    line-height: rem(47);
  }

  &__buttons-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: rem(40);
  }

  &__cta-button {
    text-transform: uppercase;
    letter-spacing: 0.02em;
    margin-right: rem(100);
  }

  &__item-add-btn {
    transition: all 200ms ease-in-out;
    font-size: rem(25);
    line-height: rem(24);
    letter-spacing: 0.02em;
    color: #0064d6;
    &:hover {
      color: $black;
      cursor: pointer;
    }
  }

  .close {
    display: none;
  }

  &__label {
    font-size: 12px;
  }

  &__labels {
    font-size: rem(20);
    cursor: pointer;
    line-height: em(22px, 22px);
    width: rem(350);
    &--disabled {
      opacity: 0.6;
    }
  }

  &__outer-circles {
    width: 16px;
    height: 16px;
    min-width: rem(18);
    min-height: rem(18);
    border: rem(1) solid #707070;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: rem(10);
    transition: all 200ms;

    &--disabled {
      opacity: 0.6;
    }
  }

  &__inner-circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: transparent;
    transition: all 200ms;

    &--active {
      background-color: #00ff6f;
    }
  }

  &__message {
    color: $blue;
    font-size: rem(24);
    text-align: center;
    padding-top: rem(60);
  }

  &__success-message {
    color: $blue;
    font-size: rem(24);
    text-align: center;
  }
}

.goBackLink {
  font-size: rem(24);
  color: $blue;
  padding-left: 10%;
  margin-top: rem(150);
  margin-bottom: rem(-190);
}

@include mobile {
  .AddressBook {
    padding-top: 0;
    &__container {
      margin: 5%;
      //  display: flex;
      //   flex-direction: column;
      padding: 0 20px;
    }

    &__cards {
      //  grid-template-columns: rem(461);
    }
    &__card {
      display: grid;
      grid-template-rows: 70% 10% 4% 4%;
      grid-gap: 8px;
    }

    &__title {
      margin-right: 25px;
    }

    &__items {
      grid-template-columns: 1fr;
    }

    &__buttons-wrap {
      flex-direction: column;
      align-items: flex-start;
    }

    &__message {
      height: 200px;
      margin-top: 80px;
      font-size: 14px;
      padding: 5%;
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .goBackLink {
    font-size: rem(24);
    color: $blue;
    padding-left: 10%;
    margin-top: 20px;
    margin-bottom: -20px;
  }
}
