@import "src/styles/lib";

.ForgotPassword {
  width: 100%;
  margin-top: rem(234);
  padding-bottom: rem(150);
  background: url("https://assets.gocoffeego.com/v2/assets/images/login-background.svg")
    no-repeat top 0 left 0;
  background-size: cover;

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: rem(-25);
      left: rem(-56);
      border-radius: 50%;
      background: #ffec00;
      height: rem(251);
      width: rem(251);
    }
  }
}

@include tablet {
  .ForgotPassword {
    //background: none;
  }
}

@include mobile {
  .ForgotPassword {
    margin-top: 70px;
    background: none;

    &__title {
      &::after {
        display: none;
      }
    }
  }
}
