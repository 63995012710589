@import "src/styles/lib";

.Channel {
  height: auto;
  display: flex;
  justify-content: center;
  margin-bottom: rem(100);
  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: rem(50);
    grid-row-gap: rem(50);
    width: rem(1500);
    padding-top: rem(100);
    border: 2px solid black;
    padding-bottom: rem(70);
    margin-top: rem(70);
  }
  &__content-details {
    font-size: rem(23);
    font-family: $cairo;
    text-align: center;
  }
  &__title {
    font-size: rem(25);
    font-weight: 650;
    padding-bottom: rem(20);
    cursor: pointer;
    &:hover {
      color: #0acdd4;
    }
  }
  &__image {
    cursor: pointer;
  }
  &__blurb {
    font-size: rem(25);
    padding: rem(20) rem(100) rem(20) rem(100);
    font-weight: 550;
  }
  &__watch-videos {
    font-weight: 620;
    cursor: pointer;

    &:hover {
      color: #0acdd4;
    }
  }
  &__modal {
    width: rem(1621);
    height: rem(1418);
    background: red;
  }
}
@include mobile {
  .Channel {
    &__content {
      display: grid;
      grid-template-columns: 1fr;
    }
    &__blurb {
      padding-right: 80px;
      padding-left: 80px;
    }
  }
}
