@import "src/styles/lib";

.CreditAndBillingAddress {
  margin-top: rem(56);
  width: 100%;

  &__button4 {
    width: 100%;
    background: #3dcc50;
    text-transform: uppercase;
    font-size: rem(36);
    letter-spacing: 0.03em;
    color: white;
    font-weight: 600 !important;
    border: 3px solid #3dcc50;
    height: 55px;
    &:hover {
      // background: #83bf41;
      filter: brightness(1.08);
    }
  }

  &__card {
    .kIDwYO {
      color: red;
      font-size: rem(20) !important;
      margin-top: rem(10) !important;
    }

    &__error {
      color: red;
      font-size: rem(20);
      margin-top: rem(10);

      &--card {
        margin-bottom: rem(20);
      }
    }

    .jUqMDv {
      height: rem(30) !important;
    }

    #card-expiry {
      //background: green !important
    }

    #cvc {
      width: 45px !important;
    }

    .credit-card-input {
      font-size: rem(24) !important;
    }
  }

  &__label {
    font-weight: 600;
    font-size: rem(20);
    margin-bottom: rem(10);
  }

  &__row {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-size: rem(30);
    font-weight: 700;
    line-height: em(32px, 25px);
    margin-bottom: rem(22);
    text-transform: uppercase;
    &--billing {
      margin-top: rem(40);
      margin-bottom: rem(0);
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: rem(26);
  }

  &__title1 {
    font-size: rem(25);
    font-weight: 400;
    line-height: em(32px, 25px);
    margin-bottom: rem(22);
  }

  &__title2 {
    font-size: rem(20);
    font-weight: 400;
    line-height: em(32px, 20px);
  }

  &__title3 {
    font-size: rem(25);
    font-weight: 400;
    line-height: em(32px, 25px);
    margin-bottom: rem(22);
  }

  &__link {
    font-size: rem(20);
    font-weight: 400;
    color: $blue;
    margin-left: rem(10);

    &:hover {
      color: $blue;
    }
  }

  &__input-container {
    margin-bottom: rem(13);
    margin-top: rem(13);

    input[type="number"] {
      -moz-appearance: textfield;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &__error {
    color: red;
    font-size: rem(20);
    margin-top: rem(10);
  }

  &__input {
    border: 1px solid #000000 !important;
    width: 100%;
    height: rem(60) !important;
    //border-radius: rem(15) !important;
    border-radius: 0 !important;
    font-size: rem(25) !important;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &__checkbox-container {
    display: flex;
    align-items: center;
  }

  &__checkbox-desc {
    font-size: rem(16);
    font-weight: 300;
    margin-left: rem(10);
  }

  &__buttons {
    display: flex;
    margin-top: rem(26);
  }

  &__button {
    font-size: rem(25);
    font-weight: 400;
    color: black;
    background: transparent;
    width: rem(280);
    border: 2px solid black;
    height: rem(62);

    &:hover {
      border: 2px solid $blue;
    }
  }

  &__button2 {
    font-size: rem(20);
    border: none;
    color: $blue;

    &:hover {
      border: none;
    }

    &:focus {
      border: none;
    }
  }
}

@include mobile {
  .CreditAndBillingAddress {
    border-right: none;
    padding-right: 0;

    &__grid {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }

    &__checkbox-desc {
      font-size: 12px;
    }

    &__error {
      font-size: 10px;
    }

    &__button2 {
      margin-left: auto;
    }
  }
}
