@import "src/styles/lib";

.NewsletterSignUpModal {
    border: 3px solid black;
    max-width: rem(1087);
    width: 100%;
    min-height: rem(800);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    text-align: center;
    padding: rem(230) rem(100) rem(50);
    &__container {
    position: relative;
    z-index: 1;
    background-color: #ffffff;
    &::after {
        content: "";
        position: absolute;
        right: -100px;
        bottom: -135px;
        width: rem(220);
        height: rem(220);
        background-color: #6ae200;
        border-radius: 50%;
        z-index: 1;
        } 
    }
    &__image {
      position: absolute;
      left: 5px;
      top: 0;
      width: rem(340);
      height: auto;
      transform: rotateY(180deg);
      z-index: 0;
    } 

    &__title {
        font-size: rem(44);
        font-weight: 700;
        font-family: $cairo;
        margin-bottom: rem(40);
        z-index: 4;
        background-color: $black;
        color: #ffffff;
        padding: rem(10);
        z-index: 5;
    }
    &__subtitle {
        font-size: rem(26);
        font-weight: 600;
        margin-bottom: rem(20);
    }

    &__text {
        font-size: rem(26);
        font-weight: 400;
        margin-bottom: rem(40); 
    }

    &__input-container {
        position: relative;
        width: rem(550);
        margin: 0 auto;
        z-index: 1;
    }

    &__input {
        width: rem(550);
        height: rem(60);
        border-radius: rem(20);
        padding-left: rem(20);
        padding-right: rem(45);
        position: relative;
        font-size: 16px;

        &:focus {
          outline: none;
        }
      }

      &__input-icon {
        content: "";
        background: url("https://assets.gocoffeego.com/v2/assets/icons/arrow-right.svg")
          no-repeat center center;
        background-size: 100%;
        width: 15px;
        position: absolute;
        margin-top: auto;
        margin-bottom: auto;
        top: 0;
        bottom: 0;
        right: rem(17);
        cursor: pointer;
      }

      &__success-subscription {
        margin-top: rem(10);
        font-size: rem(24);
        color: $blue;
        background-color: #ffffff;
        &--code {
          font-weight: 700;
          letter-spacing: 0.04em;
        }
      }

      &__error-subscription {
        margin-top: rem(10);
        font-size: rem(24);
        color: $red;
        background-color: #ffffff;
      }

      &__close {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 5;
        cursor: pointer;
    }
    
}

@include mobile {
    .NewsletterSignUpModal {
        padding: 32% 5% 15%;
        height: rem(950);
        max-width: 100%;
        &__close {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 5;
        }

        &__image {
          position: absolute;
          left: 5px;
          top: -10px;
          width: rem(360);
          height: auto;
          transform: rotateY(180deg);
          z-index: 0;
        }

        &__container {
          &::after {
              content: "";
              position: absolute;
              right: -50px;
              bottom: -120px;
              } 
         }
    }
}