@import "src/styles/lib";

.MoreCoffees {
  &__main {
    margin-top: rem(25);
    height: rem(61);
    background: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &::after {
      content: "";
      width: rem(240);
      height: rem(240);
      border-radius: 50%;
      background: #6ae200;
      position: absolute;
      top: 0;
      left: rem(133);
      z-index: 3;
    }
  }

  &__section {
    padding-top: rem(39);
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    &::after {
      content: "";
      position: absolute;
      background: url("https://assets.gocoffeego.com/v2/assets/images/more-coffees-bg.svg")
        no-repeat;
      background-size: 100%;
      height: rem(1100);
      width: 100%;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }

  &__items {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: rem(92);
    margin-bottom: rem(103);
  }

  &__line {
    margin-top: rem(16);
    margin-bottom: rem(16);
  }

  &__title {
    font-size: rem(21);
    font-weight: 600;
    color: white;
    text-align: center;

    &--yellow {
      color: $yellow;
    }
  }
}

@include mobile {
  .MoreCoffees {
    &__main {
      height: fit-content;
      &::after {
        display: none;
      }
    }

    &__title {
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 16px;
    }

    &__section-title {
      margin-top: 15px;
    }

    &__items {
      margin-top: 20px;
      grid-template-columns: 1fr;
    }
  }
}
