@import "src/styles/lib";

.AddressForm {
  margin-top: rem(10);

  &__button4 {
    width: 100%;
    background: $blue;
    text-transform: uppercase;
    font-size: rem(24);
    color: white;
    font-weight: 500 !important;
    border: 3px solid $blue;

    &:hover {
      border: 3px solid black;
    }

    &:focus {
      border: 3px solid black;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-size: rem(30);
    font-weight: 700;
    line-height: em(32px, 25px);
    margin-bottom: rem(22);
    text-transform: uppercase;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: rem(26);
  }

  &__title1 {
    font-size: rem(25);
    font-weight: 400;
    line-height: em(32px, 25px);
    margin-bottom: rem(22);
  }

  &__title2 {
    font-size: rem(20);
    font-weight: 400;
    line-height: em(32px, 20px);
  }

  &__title3 {
    font-size: rem(25);
    font-weight: 400;
    line-height: em(32px, 25px);
    margin-bottom: rem(22);
  }

  &__link {
    font-size: rem(20);
    font-weight: 400;
    color: $blue;
    margin-left: rem(10);

    &:hover {
      color: $blue;
    }
  }

  &__input-container {
    margin-bottom: rem(26);
  }

  &__error {
    color: red;
    font-size: rem(20);
    margin-top: rem(10);
  }

  &__error-2 {
    color: red;
    font-size: rem(20);
    margin-top: rem(-10);
    margin-bottom: 10px;
  }

  &__input {
    border: 1px solid #000000 !important;
    width: 100%;
    height: rem(60) !important;
    //border-radius: rem(15) !important;
    border-radius: 0 !important;
    font-size: rem(25) !important;

    &--highlight {
      background: #c4f4f6 !important;
    }

    &:focus {
      border: 1px solid $blue !important;
    }

    //&:empty {
    //  background: red !important;
    //}
  }

  &__checkbox-container {
    display: flex;
    align-items: center;
  }

  &__checkbox-desc {
    font-size: rem(16);
    font-weight: 300;
    margin-left: rem(10);
  }

  &__buttons {
    display: flex;
    margin-top: rem(26);
  }

  &__button {
    font-size: rem(25);
    font-weight: 400;
    color: black;
    background: transparent;
    width: rem(280);
    border: 2px solid black;
    height: rem(62);

    &:hover {
      border: 2px solid $blue;
    }
    &:focus {
      border: none;
      outline: none;
    }
  }

  &__button2 {
    font-size: rem(20);
    border: none;
    color: $blue;

    &:hover {
      border: none;
    }

    &:focus {
      border: none;
    }
  }
}

@include mobile {
  .AddressForm {
    border-right: none;
    padding-right: 0;

    &__input {
      font-size: 16px !important;
    }

    &__grid {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }

    &__checkbox-desc {
      font-size: 12px;
    }

    &__error {
      font-size: 10px;
    }

    &__button2 {
      margin-left: auto;
    }
  }
  .ShippingAddress__button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
