@import "src/styles/lib";

.RecentlyProduct {
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  align-items: center;
  width: rem(345);
  padding-left: rem(39);
  padding-right: rem(39);
  // padding-top: rem(28);
  padding-bottom: rem(28);
  position: relative;
  margin-right: rem(63);

  &__image-container {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: center;
    height: rem(345);
    width: rem(345);
    border: 3px solid black;
    border-radius: 50%;
    background: white;
  }

  &__image {
    object-fit: contain;
    width: rem(200);
    height: rem(220);
  }

  &__message {
    font-size: rem(20);
    margin-top: rem(3);
  }

  .Remove {
    display: flex;
    position: absolute;
    right: rem(10);
    top: rem(10);
    cursor: pointer;

    &:hover {
      .Remove__title {
        display: block;
      }
    }

    &__title {
      display: none;
      font-size: rem(16);
      font-weight: 400;
      margin-right: rem(16);
    }

    &__icon {
      width: rem(15);
      height: rem(15);
      object-fit: contain;
    }
  }

  &__title {
    margin-top: rem(8);
    font-size: rem(20);
    font-weight: 600;
  }

  &__description {
    font-size: rem(20);
    font-weight: 400;
  }

  &__button {
    border: 3px solid black;
    background: white;
    font-size: rem(20);
    font-weight: 400;
    margin-top: rem(43);
    height: rem(66);
  }
}

@include mobile {
  .RecentlyProduct {
    width: 150px;
    margin-right: 20px;
    &__image-container {
      width: 150px;
      height: 150px;
    }
  }
}
