@import "src/styles/lib";

.AwardWinnersItem {
  border: 3px solid $black;
  width: rem(425);
  height: rem(649);
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  font-family: $cairo;
  padding: rem(120) 0 rem(28);
  background-color: $white;
  z-index: 1;
  position: relative;
  margin-right: 0;
  overflow: hidden;

  &__badge {
    position: absolute;
    top: rem(20);
    right: rem(20);
    height: rem(100);
    width: rem(100);
    object-fit: contain;
  }

  &__image {
    object-fit: contain;
    width: rem(250);
    height: rem(250);
    margin-bottom: rem(10);
  }

  &__title {
    font-size: rem(24);
    font-weight: 600;
    text-align: center;
    flex-grow: 1;
    padding: 0 rem(20);
    margin-bottom: rem(10);
  }

  &__details {
    font-size: rem(24);
    line-height: 1;
    font-weight: 600;
    text-align: center;
    flex-grow: 1;
    //  padding: 0 rem(80);
    margin-bottom: rem(10);
  }

  &__description {
    font-size: rem(24);
    line-height: 1.5;
    font-weight: 700;
    text-align: center;
    flex-grow: 1;
    overflow: auto;
    height: 100%;
    padding: 0 rem(35);
    &::-webkit-scrollbar {
      width: 0.2em;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    &::-webkit-scrollbar-thumb {
      background-color: $black;
    }
  }
}

@include mobile {
  .AwardWinnersItem {
    width: 100%;
    padding-top: 30px;

    &__title {
      padding: 0 2%;
    }
  }
}
