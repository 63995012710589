@import "src/styles/lib";

.Roasters {
  padding: rem(180) 0 rem(160);
  position: relative;
  font-family: $cairo;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    background: url("https://assets.gocoffeego.com/v2/assets/images/bg-black.png")
      no-repeat center center;
    background-size: 100%;
    background-attachment: scroll;
    width: rem(1700);
    height: rem(1490);
    top: rem(250);
    left: 0;
    z-index: -1;
  }

  &__title-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: rem(60) 0 rem(70);
    color: $white;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: rem(208);
      height: rem(208);
      border-radius: 50%;
      background-color: #ffec00;
      background-attachment: scroll;
      top: rem(150);
      right: rem(550);
      z-index: -1;
    }
    &::after {
      content: "";
      position: absolute;
      width: rem(278);
      height: rem(278);
      background: url("https://assets.gocoffeego.com/v2/assets/images/girl-black.png")
        no-repeat center center;
      background-size: 100%;
      top: rem(-180);
      right: rem(110);
    }
  }

  &__middle-line {
    background-color: $white;
  }

  &__description {
    margin: rem(50) auto 0;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    max-width: rem(1100);
    padding: rem(30);
    border: 2px solid #000000;
    background-color: #ffffff;
    color: #000000;
  }
  &__title {
    font-size: rem(42);
    font-weight: 700;
    letter-spacing: 0.03em;
    line-height: rem(42);
    -webkit-text-stroke-width: rem(0.9);
    -webkit-text-stroke-color: black;
    padding: rem(70) rem(12) 0;
    text-align: center;
  }

  &__container {
    justify-items: center;
    padding: 0 10%;
    display: grid;
    grid-template-columns: repeat(4, rem(380));
    grid-gap: rem(60);
    align-items: center;
    justify-content: center;
    &::before {
      content: "";
      position: absolute;
      width: rem(181);
      height: rem(181);
      border-radius: 50%;
      background: lighten($red, 27%);
      background-attachment: scroll;
      top: rem(740);
      right: rem(890);
      z-index: -1;
    }
    &::after {
      content: "";
      position: absolute;
      width: rem(816);
      height: rem(816);
      border-radius: 50%;
      background-color: #ff008e;
      background-attachment: scroll;
      top: rem(940);
      left: rem(-140);
      z-index: -1;
    }
  }

  &__main-container {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: rem(146);
      height: rem(489);
      background: url("https://assets.gocoffeego.com/v2/assets/images/blackhair-girl.png")
        no-repeat center center;
      background-size: 100%;
      background-attachment: scroll;
      top: rem(1550);
      left: rem(100);
      z-index: 2;
    }
    &::before {
      content: "";
      position: absolute;
      width: rem(750);
      height: rem(332);
      background: url("https://assets.gocoffeego.com/v2/assets/images/Trolley.png")
        no-repeat center center;
      background-size: 100%;
      background-attachment: scroll;
      top: rem(1500);
      right: rem(-390);
      z-index: 3;
    }
  }
}

@include tablet {
  .Roasters {
    &__container {
      display: grid;
      grid-template-columns: repeat(3, rem(380));
      grid-gap: 30px;
      align-items: center;
      justify-content: center;
    }
  }
}

@include mobile {
  .Roasters {
    padding: 20px 0 40px;
    &::after {
      width: 300px;
      height: 259px;
      top: 50px;
      left: 0;
    }
    &::before {
      content: "";
      position: absolute;
      width: 241px;
      height: 241px;
      border-radius: 50%;
      background-color: $blue;
      background-attachment: scroll;
      top: 1680px;
      left: 0;
      z-index: 1;
    }
    &__container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: rem(30);
      align-items: center;
      justify-content: center;
      &::after {
        width: 408px;
        height: 408px;
        top: 220px;
        left: -170px;
      }
      &::before {
        width: 408px;
        height: 408px;
        background: $red;
        top: 1280px;
        right: 0;
        left: 0;
        z-index: -1;
      }
    }

    &__title-wrap {
      padding-top: 15px;
      &::after {
        display: none;
      }
      &::before {
        width: 210px;
        height: 210px;
        top: rem(1900);
        right: rem(-50);
        z-index: -1;
      }
    }

    &__middle-line {
      display: none;
    }

    &__title {
      padding-top: 20px;
    }
    &__main-container {
      &::before,
      &::after {
        display: none;
      }
    }
    &__bottom {
      position: relative;
      z-index: -1;
      &::before {
        width: 300px;
        height: 259px;
        bottom: -40px;
        right: 0;
        z-index: -1;
      }
      &::after {
        content: "";
        position: absolute;
        width: 241px;
        height: 241px;
        background-color: #ff8900;
        bottom: rem(800);
        right: 10px;
        z-index: 2;
      }
    }
  }
}
