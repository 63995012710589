@import "src/styles/lib";

.MusicTrack {
  &__song-text {
    font-size: 10px;
    //letter-spacing: 0.02em;
    //line-height: rem(36);
    margin-top: rem(-10);
    font-weight: 700;
    position: relative;
    text-transform: uppercase;
    color: $white;
    text-align: center;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    &:hover {
        color: #f5e90d;
    }
/*     &::before {
      content: "";
      background: url("https://assets.gocoffeego.com/v2/assets/icons/music-note.svg")
        no-repeat center center;
      background-size: 100%;
      position: absolute;
      top: rem(-15);
      left: rem(-100);
      width: rem(64);
      height: rem(76);
    }
    &::after {
      content: "";
      background: url("https://assets.gocoffeego.com/v2/assets/icons/music-note.svg")
        no-repeat center center;
      background-size: 100%;
      width: rem(64);
      height: rem(76);
      position: absolute;
      top: rem(-5);
      right: rem(-60);
    } */
  } 

  &__play-icon {
    background: url("https://assets.gocoffeego.com/v2/assets/icons/music/play.svg")
      no-repeat center center;
    //filter: brightness(0) invert(1);
    background-size: cover;
    width: rem(20);
    height: rem(20);
   // margin-bottom: rem(30);
    border: none;
    outline: none;
    cursor: pointer;
  }

  &__controls {
    display: flex;
    margin-top: 0;
    justify-content: space-evenly;
    min-width: 100px;
  }

  &__prev,
  &__next {
    outline: none;
    border: none;
    width: rem(20);
    height: rem(20);
  }

  &__prev {
    background: url("https://assets.gocoffeego.com/v2/assets/icons/music/previous.svg")
      no-repeat center center;
     // filter: brightness(0) invert(1);
      background-size: 100%;
  }

  &__next {
    background: url("https://assets.gocoffeego.com/v2/assets/icons/music/next.svg")
      no-repeat center center;
    //  filter: brightness(0) invert(1);
      background-size: 100%;
  }

  &__pause-icon {
    background: url("https://assets.gocoffeego.com/v2/assets/icons/music/stop.svg")
      no-repeat center center;
    //filter: brightness(0) invert(1);
    background-size: 100%;
    width: rem(20);
    height: rem(20);
    // margin: 0;
   // margin-bottom: rem(30);
    cursor: pointer;
    border: none;
    outline: none;
  }

  &__play-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  //  margin: 0 rem(30);
  }

  &__play {
    font-size: rem(12);
    color: $white;
    font-weight: 600;
    letter-spacing: 0.05em;
    line-height: rem(24);
    cursor: pointer;
    text-transform: uppercase;
  }
}

@include mobile {
  .MusicTrack {
    &__song-text {
      text-align: center;
      width: 240px;
      &::before {
        top: rem(-15);
        left: rem(-80);
      }
    }
  }
}
