@import "src/styles/lib";


.VideosModal {
  text-align: center;
  width: rem(1550);
  font-family: $cairo;
  &__header {
    background: #2cb1ef;
    color: white;
    font-size: rem(28);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: rem(15);
    padding-top: rem(15);
  }
  &__banner {
    padding-left: rem(10);
  }
  &__close {
    cursor: pointer;
    position: relative;
    right: rem(20);
  }

  &__title {
    font-size: rem(50);
    padding-top: rem(40);
    font-weight: 600;
  }
  &__description {
    font-size: rem(35);
    padding: rem(40) rem(60) rem(80) rem(60);
  }
  &__video {
    display: flex;
    justify-content: center;
    padding-top: rem(65);
  }
}
@include tablet {
  .VideosModal {
    width: 100%;
  }
}
@include mobile {
  .VideosModal {
    width: 100%;
  }
}
