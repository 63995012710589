@import "src/styles/lib";
.CartItemMobile {
  //  border-top: 1px solid #c7c7c7;
  //  padding-top: 20px;
  padding-bottom: 20px;
  // background-color: #ffffff;

  &__container {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(20);
  }

  &__shipment-number {
    font-weight: 600;
    font-size: 16px;
  }

  &__specials-message {
    font-weight: 600;
    font-size: 12px;
    margin-bottom: rem(10);
    line-height: em(22px, 12px);
    letter-spacing: 0.03em;
    &--link {
      color: $blue;
      text-decoration: underline;
    }
  }

  &__add-more {
    background-color: #e4f0d9;
    transition: all 600ms ease-in-out;
    padding: 10px;
  }

  &__combine-drop {
    background-color: #e4f0d9;
    font-size: rem(40);
    padding: rem(100);
    text-align: center;
    transition: all 300ms ease-in-out;
  }

  &__border-top {
    border-top: 1px solid #c7c7c7;
    padding-top: 20px;
  }

  &__combine {
    background-color: #e4f0d9;
    border-bottom: transparent;
    padding-bottom: 10px;
  }

  &__add-more-to-shipment {
    font-size: 14px;
    padding-top: 10px;
    margin-bottom: 10px;
  }

  &__display-none {
    border: transparent;
  }

  &__row {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px;
    align-items: center;
  }

  &__column1 {
    display: flex;
  }

  &__input-container {
    margin-top: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #c7c7c7;
    border-radius: 5px;

    input[type="number"] {
      -moz-appearance: textfield;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &__title {
    font-size: 16px;
    &--purveyor:hover {
      color: $blue;
    }
  }

  &__product-name {
    font-size: 16px;
  }

  &__sale-price {
    color: $red;
    font-weight: 600;
  }

  &__retail-price {
    &--sale {
      text-decoration: line-through;
      margin-right: 1rem;
    }
  }

  &__plus {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    background: transparent;
    outline: none;
    border: none;
    color: black;
  }

  &__minus {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    background: transparent;
    outline: none;
    border: none;
    color: black;
  }

  &__input {
    width: 50%;
    height: 100%;
    appearance: unset;
    outline: none;
    border: white;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    background: transparent !important;
    color: black !important;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:disabled {
      color: black !important;
    }
  }

  &__column2 {
    display: flex;
    flex-direction: column;
  }

  &__image {
    object-fit: contain;
    width: 59px;
    height: 87px;
    margin-right: 16px;
  }

  &__details {
    max-width: 150px;
  }
  &__title {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  &__name-wrapper {
    display: flex;
    font-size: 13px;
  }

  &__gift-from {
    margin-right: rem(13);
  }

  &__gift-from-wrapper {
    // border: 1px solid $black;
    //  width: rem(230);
    //  padding: 0 rem(10);
    font-size: 13px;
  }

  &__gift-to {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 13px;
    margin-bottom: 5px;
  }

  &__gift-to-name {
    border: 1px solid $black;
    width: 100%;
    height: 26px;
    padding: 3px 5px 0;
  }

  &__gift-to-wrapper {
    font-size: 13px;

    // border: 1px solid $black;
    // width: rem(230);
    //  margin-left: rem(45);
  }

  &__gift-msg {
    border: 1px solid $black;
    width: 100%;
    height: 80px;
    margin-top: rem(10);
    padding: rem(10);
    overflow: auto;
    word-break: break-all;
    &--msg {
      font-size: 13px;
    }
  }

  &__giftSubscription {
    width: rem(350);
    height: rem(400);
  }

  &__gift-grid {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    width: 100%;
  }

  &__edit-btn {
    background-color: transparent;
    font-size: 14px;
    text-align: right;
    border: none;
    border-bottom: 1px solid $blue;
    color: $blue;
    margin-top: rem(-10);
    height: 18px;
    padding: 0;
    cursor: pointer;
    font-weight: 400;
  }

  &__icon {
    object-fit: contain;
    font-size: 20px;
    margin-right: 16px;
    cursor: pointer;
    border: none;
    background: transparent;
    padding: 0;
  }

  &__heart {
    object-fit: contain;
    width: 20px;
    height: 20px;
    cursor: pointer;
    color: transparent;
    margin-top: 2px;

    &--active {
      color: red;
    }
  }

  &__title2 {
    text-align: end;
    font-size: 15px;
    font-weight: 400;
  }
}
@include desktop {
  .CartItemMobile {
    &__minus {
      padding-left: 10px;
    }
    &__plus {
      padding-right: 10px;
    }
  }
}
@include tablet {
  .CartItemMobile {
    &__edit-btn {
      position: relative;
      bottom: 30px;
    }
  }
}

@include mobile {
  .CartItemMobile {
    &__column2 {
      justify-content: space-between;
    }

    &__title {
      margin-bottom: 0;
    }
    &__input-container {
      margin-top: 10px;
      border: 1px solid #ccd9e2;
      border-radius: 0;
      height: 33px;
      width: 85%;
    }
    &__input {
      width: 60%;
    }
    &__minus {
      width: 20%;
    }
    &__plus {
      width: 20%;
    }
  }
}
