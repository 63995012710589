@import "src/styles/lib";

.QuizQuestion4 {
  position: relative;
  width: 100%;
  padding-top: rem(190);
  margin-bottom: rem(107);
  &:after {
    content: "";
    position: absolute;
    width: rem(208);
    height: rem(208);
    background-color: #1568b3;
    border-radius: 50%;
    object-fit: contain;
    bottom: rem(-80);
    right: -1.5px;
  }

  &__scooter-image {
    position: absolute;
    width: rem(420);
    height: rem(420);
    object-fit: contain;
    top: rem(-40);
    left: 0;
    z-index: 1;
  }

  &__uk-flag {
    position: absolute;
    width: rem(175);
    height: rem(175);
    object-fit: contain;
    top: rem(330);
    left: 0;
  }

  &__girl-img {
    position: absolute;
    width: rem(160);
    height: rem(541);
    object-fit: contain;
    bottom: rem(80);
    right: 0;
    z-index: 1;
  }

  &__text {
    font-size: rem(25);
    font-weight: 400;
    text-align: center;
    line-height: rem(47);
  }

  &__answer {
    z-index: 2;
    cursor: pointer;
    // background-color: $white;
    //  border-radius: 100%;
  }

  &__answers {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: rem(70);
    max-width: 1308px;
    justify-items: center;
    align-self: center;
    text-align: center;
    margin: rem(60) auto rem(300);
  }

  &__answer-title {
    font-size: rem(28);
    line-height: 23px;
    text-transform: uppercase;
    min-height: rem(56);
    width: fit-content;
    margin: 0 auto;
    padding: rem(10) rem(19);

    &--active {
      background: #ffec01;
      padding: rem(10) rem(19);
    }
  }

  &__image {
    object-fit: contain;
    margin-bottom: rem(30);
    width: rem(220);
    height: rem(220);
    object-fit: contain;
    &--border {
      width: rem(220);
      height: rem(220);
      border: 3px solid black;
      border-radius: 50%;
      padding: rem(19);

      &--active {
        border: 3px solid #ffec01;
      }
    }
  }
}

@include desktop {
  .QuizQuestion4 {
    &__answers {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      padding: 0 5%;
    }

    &__image {
      object-fit: cover;
      width: 148px;
      height: 148px;
      margin-bottom: rem(30);
      &--border {
        border: 3px solid black;
        border-radius: 50%;
        padding: rem(19);

        &--active {
          border: 3px solid #ffec01;
        }
      }
    }
  }
}

@include tablet-big {
  .QuizQuestion4 {
    &__answers {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: rem(50);
      justify-items: center;
      padding: 0 15%;
    }
  }
}

@include tablet {
  .QuizQuestion4 {
    &__answers {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: rem(50);
      justify-items: center;
      padding: 0 15%;
    }
  }
}

@include mobile {
  .QuizQuestion4 {
    padding-top: 0;
    &__answers {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: rem(10);
      justify-items: center;
      padding: 0 5%;
    }
    &__text {
      font-size: 15px;
    }
    &__answer-title {
      font-size: 15px;
      margin-bottom: 10px;
    }
    &__image {
      margin-bottom: 10px;
    }

    &__scooter-image,
    &__uk-flag,
    &__girl-img {
      display: none;
    }
  }
}
