@import "../../../styles/lib";

.CoffeeReviews {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: $cairo;
  padding-top: rem(100);
  padding-bottom: rem(192);

  &__title {
    text-transform: uppercase;
    font-weight: normal;
    font-size: rem(36);
    letter-spacing: 0.2em;
    line-height: rem(36);
    margin: rem(38) 0 rem(100);
  }

  &__filter-wrapper {
    padding: 0 5%;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  &__checkbox-container {
    display: flex;
    align-items: center;
    font-size: rem(24);
    margin-left: rem(20);
  }

  &__checkbox-label {
    padding-left: rem(5);
  }

  &__text {
    //cursor: pointer;
    display: grid;
    grid-template-columns: 20% 25% 50%;
    grid-gap: rem(20);
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
    font-size: rem(25);
    letter-spacing: 0.05em;
    line-height: rem(30);
    padding-top: rem(60);
    padding-bottom: rem(60);
    // margin-bottom: rem(70);
    text-align: center;
    flex: 1;
  }
  &__product-name {
    cursor: pointer;
    padding-top: rem(15);
    font-size: rem(28);
  }
  &__price {
    padding-top: rem(15);
  }

  &__text:not(:last-child) {
    border-bottom: 1px solid #c7c7c7;
  }

  &__image {
    width: 200px;
    height: 200px;
    object-fit: contain;
  }

  &__date {
    margin-left: auto;
  }

  &__wrap {
    width: 100%;
    padding: rem(80) rem(100);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  &__review-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: rem(300);
   
  }

  &__review-text {
    margin: rem(20) auto;
    width: fit-content;
    line-height: 1.5;
    text-align: center;
    position: relative;
    font-size: 18px;
 
  }
  &__update-review {
    border: none;
    background-color: transparent;
    color: $blue;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: auto;
   
  }

  &__no-review-text {
    margin: rem(100) 0 rem(50);
    font-size: rem(32);
    letter-spacing: 0.05em;
    line-height: rem(30);
    padding: 0 5rem;
  }

  &__review-btn {
    border: none;
    background-color: transparent;
    color: $blue;
    display: inline;
  }
  &__button-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__order-button,
  &__autoship-button {
    width: rem(300);
    height: rem(66);
    font-weight: 400;
    line-height: rem(47);
    font-size: rem(24);
    position: relative;
    background-color: $white;
    z-index: 5;
    border: 3px solid $black;
    transition: all 200ms ease-in-out;
    color: black;
    &:hover {
      border: 3px solid $blue;
    }
  }
  &__order-button {
    margin: rem(40) auto rem(20);
  }
  &__slug-link {
    text-transform: uppercase;
    position: relative;
  }
  &__slug-title {
    font-weight: 700;
    margin-bottom: rem(5);
  }

  &__info-wrapper {
    display: flex;
    flex-direction: column;
  }
}
@include desktop {
  .CoffeeReviews {
    &__review-text {
      width: fit-content;
    
    }
  }
}
@include tablet {
  .CoffeeReviews {
    text-align: center;
    &__text {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
    }

    &__product-name {
      margin: rem(20) auto;
    }
    &__review-wrap {
      text-align: center;
      padding-bottom: 40px;
      height: auto;
    }
  }
}

@include mobile {
  .CoffeeReviews {
    text-align: center;

    &__wrap {
      padding: 5%;
    }

    &__text {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      font-size: 14px;
    }

    &__filter-wrapper {
      padding: 0 5%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
    }

    &__checkbox-container {
      margin-left: 0;
    }

    &__review-wrap {
      padding-bottom: 40px;
      height: auto;
    }

    &__review-text {
      font-size: 16px;
    }

    &__review {
      font-size: 13px;
      line-height: 1.5;
    }

    &__product-name {
      margin: 10px auto;
    }

    &__review-customer {
      margin: 10px auto;
    }

    &__button-wrap {
      margin-bottom: rem(30);
    }
  }
}
