@import "src/styles/lib";

.HowSubscriptionsWork {
  position: relative;
  padding-top: rem(104);

  &__section-title {
    text-transform: uppercase;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: rem(103);
  }

  &__title {
    font-size: rem(36);
    font-family: $cairo;
    font-weight: 400;
    letter-spacing: 0.6rem;
    margin-top: rem(54);
    margin-bottom: rem(31);
    text-align: center;
  }

  &__line {
    position: relative;
  }

  &__quiz {
    position: absolute;
    left: rem(105);
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
}

@include tablet-big {
  .HowSubscriptionsWork {
    &__image {
      right: 10px;
    }
  }
}

@include tablet {
  .HowSubscriptionsWork {
    &__image {
      right: 10px;
    }
  }
}

@include mobile {
  .HowSubscriptionsWork {
    &__items {
      display: grid;
      grid-template-columns: 1fr;
    }

    &__image {
      display: none;
    }

    &__title {
      font-weight: 600;
      font-size: 20px;
      line-height: em(24px, 20px);
      width: 165px;
      letter-spacing: 0.4rem;
    }
  }
}
